import styled from "styled-components";


export const Container = styled.div`
width: 17px;
height: 150px;
background-color: red;
background: rgb(255,255,255);
background: linear-gradient(353deg, rgba(255,255,255,1) 0%, rgba(41,88,150,1) 100%);
/* border: 1px solid rgba(41,88,150,1); */
margin-right: 20%;

p{
  color: black !important;
  margin-left: 25px;
}

p:nth-child(2){
  margin-top: 125px;
}
`