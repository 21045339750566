import {
  ViariaIcon,
  PainelIcon,
  AmbientalIcon,
  EconomicoIcon,
  AtendimentoIcon,
  QualidadeServico,
} from './styles';
import { MenuBarItemProps } from './interfaces';

export const Colors = {
  main: '#E4EFED',
  hover: '#2A3B43',
};

/**
 * @description a propriedade ref deste objeto está intimamente
 * relacionada com o funcionamento completo da exibição das sessões
 * por meio da interface SelectedSidebarItem, logo ref deve conter o
 * mesmo valor da chave de SelectedSidebarItem
 */
export const MuiMenubarItems: MenuBarItemProps[] = [
  {
    main_icon: <PainelIcon />,
    ref: 'panel',
    text: 'O Painel',
    clicked: true,
    colors: Colors
  },
  {
    main_icon: <AtendimentoIcon/>,
    ref: 'user_attendance',
    text: 'Atendimento aos usuários',
    clicked: false,
    colors: Colors
  },
  {
    main_icon: <EconomicoIcon />,
    ref: 'impacto_economico',
    text: 'Impacto econômico',
    clicked: false,
    colors: Colors
  },
  {
    main_icon: <AmbientalIcon />,
    ref: 'impacto_ambiental',
    text: 'Impacto ambiental e social',
    clicked: false,
    colors: Colors
  },
  {
    main_icon: <ViariaIcon />,
    ref: 'seguranca_viaria',
    text: 'Segurança viária',
    clicked: false,
    colors: Colors,
  },
  {
    main_icon: <QualidadeServico />,
    clicked: false,
    colors: Colors,
    ref: 'qualidade_servicos',
    text: 'Qualidade dos serviços',
  },
];