export enum shapeEnum {
  lineChart = "line",
  donutsChart = "donuts",
  columnBarChart = "columnBar",
  areaChart = "stackedArea",
  velocimeterChart = "velocimeter",
  barChart = "clusteredBar",
  reverseChart = "reverseLine",
  bigNumber = "bigNumber",
  blockText = "text",
  map = "map"
}