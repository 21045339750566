import { MenuBarItemProps } from "@/context/sidebar/interfaces";
import { useSidebar } from "@/hooks/useContexts";
import { toggleActiveAction } from "@/utils/toggleActiveAction";

export const ViewSecurityIcon = () => {
  const { setItems, setClickReport, items } = useSidebar();
  const handleSecuritView = () => {
  const newItems = toggleActiveAction<MenuBarItemProps>({
    array: items,
    index: 4
  });
    setClickReport(false);
    setItems(newItems);
  };
  return (
    <>
      <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473 664">
  <defs>
    <style>
      {
        `
        .cls-1,
        .cls-7,
        .cls-8 {
          fill: none;
          stroke: #1e303a;
          stroke-miterlimit: 10;
        }
  
        .cls-1 {
          stroke-width: 4px;
        }
  
        .cls-2 {
          fill: #2f82c1;
        }
  
        .cls-3 {
          fill: #1e303a;
        }
  
        .cls-10,
        .cls-4 {
          fill: #fff;
        }
  
        .cls-5 {
          fill: #006680;
        }
  
        .cls-6 {
          fill: #1d1d1b;
        }
  
        .cls-8 {
          stroke-width: 2.5px;
        }
  
        .cls-9 {
          fill: #93c022;
        }
  
        .cls-10 {
          opacity: 0;
        }
        `
      }
    </style>
  </defs>
  <title>Painel ABCR mobile</title>
  <line className="cls-1" x1="11.78" y1="123" x2="459.78" y2="123" />
  <path className="cls-2"
    d="M199.33,23.37v6.7h-3.14V12h6.91a7,7,0,0,1,4.81,1.58,5.31,5.31,0,0,1,1.77,4.17,5.15,5.15,0,0,1-1.74,4.14,7.35,7.35,0,0,1-4.88,1.47Zm0-2.53h3.77a3.71,3.71,0,0,0,2.55-.78,2.9,2.9,0,0,0,.88-2.28,3.13,3.13,0,0,0-.89-2.34,3.45,3.45,0,0,0-2.45-.9h-3.86Z" />
  <path className="cls-2"
    d="M222.55,30.07a4.83,4.83,0,0,1-.34-1.25,4.7,4.7,0,0,1-3.52,1.5,4.77,4.77,0,0,1-3.3-1.15,3.7,3.7,0,0,1-1.28-2.86A3.82,3.82,0,0,1,215.7,23a7.75,7.75,0,0,1,4.56-1.15h1.85V21a2.36,2.36,0,0,0-.58-1.66,2.31,2.31,0,0,0-1.78-.63,2.65,2.65,0,0,0-1.68.51,1.6,1.6,0,0,0-.66,1.31h-3a3.34,3.34,0,0,1,.74-2.06,4.8,4.8,0,0,1,2-1.51,7,7,0,0,1,2.81-.55,5.6,5.6,0,0,1,3.75,1.19,4.19,4.19,0,0,1,1.44,3.33v6a6.84,6.84,0,0,0,.51,2.89v.21Zm-3.31-2.17a3.39,3.39,0,0,0,1.68-.43,2.92,2.92,0,0,0,1.19-1.17V23.77h-1.63a4.5,4.5,0,0,0-2.51.58,1.88,1.88,0,0,0-.85,1.65,1.8,1.8,0,0,0,.58,1.39A2.24,2.24,0,0,0,219.24,27.9Z" />
  <path className="cls-2"
    d="M230.82,13.17a1.63,1.63,0,0,1,.44-1.16,2,2,0,0,1,2.53,0,1.63,1.63,0,0,1,.44,1.16,1.57,1.57,0,0,1-.44,1.13,2,2,0,0,1-2.53,0A1.57,1.57,0,0,1,230.82,13.17Zm3.2,16.9h-3V16.65h3Z" />
  <path className="cls-2"
    d="M242.64,16.65l.09,1.55a4.81,4.81,0,0,1,3.9-1.8q4.2,0,4.27,4.8v8.87h-3V21.38a2.75,2.75,0,0,0-.55-1.89,2.32,2.32,0,0,0-1.8-.62,2.91,2.91,0,0,0-2.72,1.65v9.55h-3V16.65Z" />
  <path className="cls-2"
    d="M262.42,30.32a6.23,6.23,0,0,1-4.65-1.81,6.56,6.56,0,0,1-1.78-4.8v-.37a8,8,0,0,1,.78-3.59,5.85,5.85,0,0,1,2.17-2.46,5.76,5.76,0,0,1,3.13-.89,5.26,5.26,0,0,1,4.23,1.75,7.39,7.39,0,0,1,1.5,5v1.22H259A4.05,4.05,0,0,0,260.14,27a3.36,3.36,0,0,0,2.45,1A4.08,4.08,0,0,0,266,26.24l1.63,1.55a5.57,5.57,0,0,1-2.15,1.87A6.79,6.79,0,0,1,262.42,30.32Zm-.36-11.5a2.55,2.55,0,0,0-2,.87,4.58,4.58,0,0,0-1,2.42h5.74v-.22a3.62,3.62,0,0,0-.8-2.29A2.52,2.52,0,0,0,262.06,18.82Z" />
  <path className="cls-2" d="M275.88,30.07h-3V11h3Z" />
  <path className="cls-3"
    d="M171.23,68.61H159.77l-2,6.48h-8.94L161.56,40.4h7.86l12.82,34.69h-9Zm-9.46-6.46h7.46l-3.74-12Z" />
  <path className="cls-3"
    d="M189.65,75.09V40.4h12.46q6.69,0,10.18,2.45a8.17,8.17,0,0,1,3.5,7.13,8.32,8.32,0,0,1-1.24,4.6,7.3,7.3,0,0,1-3.65,2.81,7.25,7.25,0,0,1,4.17,2.67,7.74,7.74,0,0,1,1.45,4.76c0,3.4-1.07,6-3.23,7.66s-5.34,2.58-9.56,2.61ZM198,54.86h4.36a6.18,6.18,0,0,0,3.91-1A3.56,3.56,0,0,0,207.45,51a3.76,3.76,0,0,0-1.27-3.17,6.78,6.78,0,0,0-4.07-1H198Zm0,5.48v8.32h5.48a5.22,5.22,0,0,0,3.48-1,3.62,3.62,0,0,0,1.21-2.92c0-2.89-1.43-4.34-4.31-4.36Z" />
  <path className="cls-3"
    d="M254.07,63.35a12.65,12.65,0,0,1-1.95,6.39,11.77,11.77,0,0,1-5,4.3,17,17,0,0,1-7.37,1.53q-6.84,0-10.77-4.46T225,58.53V56.82a21.08,21.08,0,0,1,1.77-8.93A13.45,13.45,0,0,1,231.91,42a14.28,14.28,0,0,1,7.72-2.08q6.31,0,10.15,3.32a13.13,13.13,0,0,1,4.36,9.16H245.8q-.09-3.16-1.59-4.55a6.58,6.58,0,0,0-4.58-1.38,5,5,0,0,0-4.57,2.34c-1,1.55-1.48,4-1.53,7.45V58.7c0,3.7.47,6.35,1.4,7.93s2.53,2.39,4.8,2.39a6.46,6.46,0,0,0,4.41-1.36,5.82,5.82,0,0,0,1.62-4.31Z" />
  <path className="cls-3"
    d="M275.8,62.82h-4.53V75.09h-8.36V40.4h13.65q6.18,0,9.65,2.74a9.26,9.26,0,0,1,3.48,7.73,11.35,11.35,0,0,1-1.46,6,10.46,10.46,0,0,1-4.59,3.86l7.24,14v.35h-9Zm-4.53-6.43h5.29a4.74,4.74,0,0,0,3.59-1.26,4.84,4.84,0,0,0,1.2-3.5,4.9,4.9,0,0,0-1.21-3.52,4.73,4.73,0,0,0-3.58-1.28h-5.29Z" />
  <path className="cls-3" d="M313.73,55.41h8.13V62.3h-8.13v9.31h-7.51V62.3h-8.14V55.41h8.14v-9h7.51Z" />
  <circle className="cls-4" cx="236.52" cy="123" r="22" />
  <path className="cls-3"
    d="M238.56,148A25.05,25.05,0,1,1,261.49,125,25.07,25.07,0,0,1,238.56,148Zm.1-45.83a21,21,0,1,0,18.72,18.71A21,21,0,0,0,238.66,102.14Z" />
  <path className="cls-5" d="M250.68,121.14a14.29,14.29,0,1,1-12.31-12.3A14.29,14.29,0,0,1,250.68,121.14Z" />
  <line className="cls-1" x1="236.52" y1="145" x2="236.52" y2="163" />
  <path className="cls-6" d="M190.92,544.81h-6.4v17.76h-2.61V544.81h-6.39v-2.14h15.4Z" />
  <path className="cls-6"
    d="M203.86,562.57a5.49,5.49,0,0,1-.35-1.55,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.19,4.19,0,0,1,1.75-3.59,8.3,8.3,0,0,1,4.94-1.27h2.47v-1.17a2.85,2.85,0,0,0-.8-2.11,3.21,3.21,0,0,0-2.34-.79,3.69,3.69,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.54a3.51,3.51,0,0,1,.78-2.14,5.42,5.42,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.56,4.56,0,0,1,1.5,3.52v6.81a8.5,8.5,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.45,4.45,0,0,0,2.26-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3.06,3.06,0,0,0,199.66,560.65Z" />
  <path className="cls-6"
    d="M217.72,553.18l3.28-5.4h3l-4.84,7.32,5,7.47h-2.93L217.76,557l-3.41,5.53h-2.94l5-7.47-4.84-7.32h2.92Z" />
  <path className="cls-6"
    d="M238.57,562.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.55a3.52,3.52,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.59,4.59,0,0,1,1.5,3.52v6.81a8.5,8.5,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.34,2.34,0,0,0,.79,1.86A3.09,3.09,0,0,0,234.37,560.65Z" />
  <path className="cls-6"
    d="M256.58,555.05a8.64,8.64,0,0,1,1.62-5.47,5.09,5.09,0,0,1,4.22-2.07,5.14,5.14,0,0,1,4.12,1.78v-7.72h2.53v21h-2.33l-.12-1.58a5.15,5.15,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94A3.35,3.35,0,0,0,263,560.7a3.69,3.69,0,0,0,3.52-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.37,3.37,0,0,0-2.9,1.44A7.24,7.24,0,0,0,259.11,555.34Z" />
  <path className="cls-6"
    d="M281.93,562.85a6.46,6.46,0,0,1-4.9-2,7.37,7.37,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.85,5.85,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.27,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,281.93,562.85Zm-.32-13.26A3.39,3.39,0,0,0,279,550.7a5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.17,3.17,0,0,0,281.61,549.59Z" />
  <path className="cls-6"
    d="M162.09,594.65a1.85,1.85,0,0,0-.77-1.59,7.28,7.28,0,0,0-2.69-1,12.71,12.71,0,0,1-3-1,4.42,4.42,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.81,3.81,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.13,4.13,0,0,1,1.57,3.32h-2.55a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.52,1.52,0,0,0,.73,1.38,8.75,8.75,0,0,0,2.61.89,13.43,13.43,0,0,1,3.07,1,4.39,4.39,0,0,1,1.75,1.42,3.52,3.52,0,0,1,.56,2,3.81,3.81,0,0,1-1.58,3.18,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5.12,5.12,0,0,1-2.14-1.76,4.18,4.18,0,0,1-.77-2.44h2.53a2.61,2.61,0,0,0,1,2,3.94,3.94,0,0,0,2.51.74,4.08,4.08,0,0,0,2.31-.58A1.79,1.79,0,0,0,162.09,594.65Z" />
  <path className="cls-6"
    d="M176.86,598.85a6.46,6.46,0,0,1-4.9-2,7.32,7.32,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.83,5.83,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.32,5.32,0,0,0,1.28,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,176.86,598.85Zm-.32-13.26A3.38,3.38,0,0,0,174,586.7a5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.83,4.83,0,0,0-1-3A3.17,3.17,0,0,0,176.54,585.59Z" />
  <path className="cls-6" d="M193.06,595.14l3.66-11.36h2.58L194,598.57h-1.93l-5.36-14.79h2.59Z" />
  <path className="cls-6"
    d="M210.5,598.85a6.46,6.46,0,0,1-4.9-2,7.37,7.37,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.85,5.85,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.27,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,210.5,598.85Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.17,3.17,0,0,0,210.18,585.59Z" />
  <path className="cls-6"
    d="M229.14,586.05a7.94,7.94,0,0,0-1.24-.09,3.34,3.34,0,0,0-3.38,2.11v10.5H222V583.78h2.46l.05,1.71a3.92,3.92,0,0,1,3.52-2,2.51,2.51,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M234.28,579.86a1.51,1.51,0,0,1,.38-1,1.42,1.42,0,0,1,1.11-.43,1.44,1.44,0,0,1,1.12.43,1.48,1.48,0,0,1,.39,1,1.41,1.41,0,0,1-.39,1,1.43,1.43,0,0,1-1.12.41,1.41,1.41,0,0,1-1.11-.41A1.45,1.45,0,0,1,234.28,579.86ZM237,598.57h-2.53V583.78H237Z" />
  <path className="cls-6"
    d="M243.25,591.05a8.7,8.7,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.32l-.13-1.58a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.52,6.52,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.35,3.35,0,0,0-2.89,1.44A7.16,7.16,0,0,0,245.78,591.34Z" />
  <path className="cls-6"
    d="M271.59,598.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.92,1.65h-2.54a3.46,3.46,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.18,7.18,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.5,4.5,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,267.39,596.65Z" />
  <path className="cls-6"
    d="M279.87,591.05a8.7,8.7,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21H290L289.9,597a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.52,6.52,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.71,3.71,0,0,0-3.48-2.1,3.37,3.37,0,0,0-2.9,1.44A7.16,7.16,0,0,0,282.4,591.34Z" />
  <path className="cls-6"
    d="M305.21,598.85a6.46,6.46,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.54,6.54,0,0,1,2.36-2.7,5.81,5.81,0,0,1,3.27-1,5.52,5.52,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1H301a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,305.21,598.85Zm-.31-13.26a3.38,3.38,0,0,0-2.57,1.11,5.45,5.45,0,0,0-1.29,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.17,3.17,0,0,0,304.9,585.59Z" />
  <path className="cls-6"
    d="M138.31,627.05a8.64,8.64,0,0,1,1.62-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.33l-.12-1.58a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.68,8.68,0,0,1-1.63-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.36,3.36,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.38,3.38,0,0,0-2.9,1.44A7.24,7.24,0,0,0,140.84,627.34Z" />
  <path className="cls-6"
    d="M163.66,634.85a6.43,6.43,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.53,5.53,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,163.66,634.85Zm-.31-13.26a3.4,3.4,0,0,0-2.58,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.16,3.16,0,0,0,163.35,621.59Z" />
  <path className="cls-6"
    d="M194,634.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.55a3.52,3.52,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.59,4.59,0,0,1,1.5,3.52v6.81a8.5,8.5,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,189.82,632.65Z" />
  <path className="cls-6"
    d="M208.85,632.78a3.65,3.65,0,0,0,2.37-.82,2.91,2.91,0,0,0,1.12-2.05h2.39a4.59,4.59,0,0,1-.88,2.42,5.58,5.58,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.06,6.06,0,0,1-4.8-2,8,8,0,0,1-1.79-5.52v-.42a9,9,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93A5.89,5.89,0,0,1,213,621a5.3,5.3,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.55,3.55,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1.05,3.94A3.56,3.56,0,0,0,208.85,632.78Z" />
  <path className="cls-6"
    d="M220.39,615.86a1.51,1.51,0,0,1,.37-1,1.44,1.44,0,0,1,1.12-.43,1.45,1.45,0,0,1,1.12.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.43,1.35,1.35,0,0,1-1.49-1.43Zm2.73,18.71h-2.53V619.78h2.53Z" />
  <path className="cls-6"
    d="M229.35,627.05a8.64,8.64,0,0,1,1.62-5.47,5.09,5.09,0,0,1,4.22-2.07,5.14,5.14,0,0,1,4.12,1.78v-7.72h2.53v21h-2.33l-.12-1.58a5.15,5.15,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.37,3.37,0,0,0-2.9,1.44A7.24,7.24,0,0,0,231.88,627.34Z" />
  <path className="cls-6"
    d="M254.7,634.85a6.46,6.46,0,0,1-4.9-2,7.37,7.37,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.85,5.85,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.27,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,254.7,634.85Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.17,3.17,0,0,0,254.38,621.59Z" />
  <path className="cls-6"
    d="M268.59,619.78l.08,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.77H275.3v-9.78a3.41,3.41,0,0,0-.73-2.37,3,3,0,0,0-2.24-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V619.78Z" />
  <path className="cls-6"
    d="M287.87,616.2v3.58h2.77v2h-2.77v9.18a2.12,2.12,0,0,0,.37,1.34,1.59,1.59,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.86-2.91v-9.17h-2.7v-2h2.7V616.2Z" />
  <path className="cls-6"
    d="M302.53,634.85a6.46,6.46,0,0,1-4.9-2,7.37,7.37,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.85,5.85,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.27,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,302.53,634.85Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.83,4.83,0,0,0-1-3A3.17,3.17,0,0,0,302.21,621.59Z" />
  <path className="cls-6"
    d="M322.64,630.65a1.86,1.86,0,0,0-.78-1.59,7.28,7.28,0,0,0-2.69-1,12.71,12.71,0,0,1-3.05-1,4.42,4.42,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.83,3.83,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.1,4.1,0,0,1,1.57,3.32h-2.54a2.29,2.29,0,0,0-.89-1.79,3.28,3.28,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.52,1.52,0,0,0,.73,1.38,8.73,8.73,0,0,0,2.62.89,13.5,13.5,0,0,1,3.06,1,4.39,4.39,0,0,1,1.75,1.42,3.45,3.45,0,0,1,.57,2,3.79,3.79,0,0,1-1.59,3.18,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5.12,5.12,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.59,2.59,0,0,0,1,2,3.91,3.91,0,0,0,2.5.74,4.08,4.08,0,0,0,2.31-.58A1.78,1.78,0,0,0,322.64,630.65Z" />
  <path className="cls-6" d="M191.14,440.09h-6.4v17.76h-2.61V440.09h-6.39v-2.15h15.4Z" />
  <path className="cls-6"
    d="M204.08,457.85a5.24,5.24,0,0,1-.35-1.56,5.66,5.66,0,0,1-4.22,1.83,5.18,5.18,0,0,1-3.58-1.24,4,4,0,0,1-1.41-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.66,3.66,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.5,3.5,0,0,1,.79-2.14,5.37,5.37,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.56,4.56,0,0,1,1.5,3.52v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,199.88,455.92Z" />
  <path className="cls-6"
    d="M217.94,448.45l3.28-5.4h3l-4.84,7.32,5,7.48H221.4L218,452.31l-3.42,5.54h-2.93l5-7.48-4.84-7.32h2.92Z" />
  <path className="cls-6"
    d="M238.79,457.85a5.17,5.17,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.66,3.66,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.44,3.44,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3,3,0,0,0,234.59,455.92Z" />
  <path className="cls-6"
    d="M256.8,450.33a8.65,8.65,0,0,1,1.62-5.48,5.09,5.09,0,0,1,4.22-2.07,5.14,5.14,0,0,1,4.12,1.78v-7.71h2.52v21H267l-.12-1.59a5.15,5.15,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.59,6.59,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16V447a3.71,3.71,0,0,0-3.49-2.09,3.39,3.39,0,0,0-2.9,1.43A7.26,7.26,0,0,0,259.33,450.61Z" />
  <path className="cls-6"
    d="M282.15,458.12a6.46,6.46,0,0,1-4.9-2,7.29,7.29,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.91,5.91,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.27,3.54,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,282.15,458.12Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11A5.44,5.44,0,0,0,278,449.1h7.41v-.19a4.84,4.84,0,0,0-1-3A3.17,3.17,0,0,0,281.83,444.86Z" />
  <path className="cls-6"
    d="M171.26,493.85a5.5,5.5,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.66,3.66,0,0,0-2.27.68,2,2,0,0,0-.91,1.66H162a3.5,3.5,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.59,4.59,0,0,1,1.5,3.52v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,167.06,491.92Z" />
  <path className="cls-6"
    d="M186.09,492.06a3.71,3.71,0,0,0,2.37-.82,3,3,0,0,0,1.12-2.06H192a4.59,4.59,0,0,1-.88,2.42,5.69,5.69,0,0,1-2.15,1.84,6.29,6.29,0,0,1-2.85.68,6.06,6.06,0,0,1-4.8-2,8,8,0,0,1-1.79-5.51v-.43a9,9,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.94,5.94,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87h-2.39a3.54,3.54,0,0,0-1.09-2.36,3.4,3.4,0,0,0-2.41-.92,3.53,3.53,0,0,0-3,1.39,6.47,6.47,0,0,0-1.06,4v.48a6.38,6.38,0,0,0,1.05,3.93A3.55,3.55,0,0,0,186.09,492.06Z" />
  <path className="cls-6"
    d="M197.63,475.13a1.51,1.51,0,0,1,.37-1,1.43,1.43,0,0,1,1.12-.42,1.45,1.45,0,0,1,1.12.42,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.44,1.47,1.47,0,0,1-1.12-.41A1.48,1.48,0,0,1,197.63,475.13Zm2.73,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M206.59,486.33a8.65,8.65,0,0,1,1.62-5.48,5.09,5.09,0,0,1,4.22-2.07,5.14,5.14,0,0,1,4.12,1.78v-7.71h2.53v21h-2.33l-.12-1.59a5.15,5.15,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.59,6.59,0,0,0,1,3.94A3.35,3.35,0,0,0,213,492a3.69,3.69,0,0,0,3.52-2.16V483a3.71,3.71,0,0,0-3.49-2.09,3.39,3.39,0,0,0-2.9,1.43A7.26,7.26,0,0,0,209.12,486.61Z" />
  <path className="cls-6"
    d="M231.94,494.12a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.27,3.54,4,4,0,0,0,3.12,1.35,4.42,4.42,0,0,0,2.26-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,231.94,494.12Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.17,3.17,0,0,0,231.62,480.86Z" />
  <path className="cls-6"
    d="M245.83,479.05l.08,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.78h-2.53v-9.79a3.41,3.41,0,0,0-.73-2.37,3,3,0,0,0-2.24-.76,3.7,3.7,0,0,0-2.16.65,4.46,4.46,0,0,0-1.45,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M265.11,475.47v3.58h2.77v2h-2.77v9.18a2.1,2.1,0,0,0,.37,1.34,1.55,1.55,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.87-2.91V481h-2.69v-2h2.69v-3.58Z" />
  <path className="cls-6"
    d="M279.77,494.12a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.27,3.54,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,279.77,494.12Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13H283v-.19a4.78,4.78,0,0,0-1-3A3.17,3.17,0,0,0,279.45,480.86Z" />
  <path className="cls-6"
    d="M299.88,489.92a1.86,1.86,0,0,0-.78-1.59,7.46,7.46,0,0,0-2.69-1,12.71,12.71,0,0,1-3.05-1,4.33,4.33,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.85,3.85,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.11,4.11,0,0,1,1.57,3.32h-2.54a2.29,2.29,0,0,0-.89-1.79,3.28,3.28,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.52,1.52,0,0,0,.73,1.38,8.73,8.73,0,0,0,2.62.89,13.77,13.77,0,0,1,3.07,1,4.6,4.6,0,0,1,1.74,1.42,3.46,3.46,0,0,1,.57,2,3.76,3.76,0,0,1-1.59,3.17,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5.11,5.11,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.59,2.59,0,0,0,1,2,3.92,3.92,0,0,0,2.5.75,4.08,4.08,0,0,0,2.31-.59A1.77,1.77,0,0,0,299.88,489.92Z" />
  <path className="cls-5"
    d="M140.08,364.19a2.78,2.78,0,0,0-1.06-2.32,12.91,12.91,0,0,0-3.8-1.64,18.05,18.05,0,0,1-4.38-1.84,5.84,5.84,0,0,1-3.11-5.09,5.56,5.56,0,0,1,2.24-4.54,9.14,9.14,0,0,1,5.82-1.78,9.89,9.89,0,0,1,4.24.88,6.91,6.91,0,0,1,2.92,2.49,6.38,6.38,0,0,1,1.06,3.59h-3.93a3.58,3.58,0,0,0-1.12-2.79,4.63,4.63,0,0,0-3.2-1,4.85,4.85,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.51,2.51,0,0,0,1.16,2.09,13.35,13.35,0,0,0,3.81,1.62,17.77,17.77,0,0,1,4.27,1.8,6.79,6.79,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.42,5.42,0,0,1-2.18,4.52,9.46,9.46,0,0,1-5.92,1.68,11.08,11.08,0,0,1-4.53-.92,7.62,7.62,0,0,1-3.22-2.52,6.25,6.25,0,0,1-1.15-3.75H131a3.7,3.7,0,0,0,1.28,3,5.61,5.61,0,0,0,3.67,1.06,4.89,4.89,0,0,0,3.11-.83A2.72,2.72,0,0,0,140.08,364.19Z" />
  <path className="cls-5" d="M164,359.89h-9.34v7h10.92v3.16H150.7V347.3h14.76v3.18H154.65v6.28H164Z" />
  <path className="cls-5"
    d="M189.43,367.09a7.57,7.57,0,0,1-3.42,2.44,14,14,0,0,1-5,.83,9.7,9.7,0,0,1-5.06-1.3,8.56,8.56,0,0,1-3.4-3.71,13.07,13.07,0,0,1-1.25-5.63v-1.78c0-3.44.83-6.13,2.47-8.06a8.59,8.59,0,0,1,6.89-2.9,9,9,0,0,1,6,1.89,8.13,8.13,0,0,1,2.68,5.41h-3.87q-.66-4.11-4.77-4.11a4.68,4.68,0,0,0-4,1.91,9.78,9.78,0,0,0-1.43,5.62v1.74a9.25,9.25,0,0,0,1.56,5.73,5.13,5.13,0,0,0,4.32,2c2,0,3.46-.46,4.31-1.37v-4.46H180.8v-3h8.63Z" />
  <path className="cls-5"
    d="M213.61,347.3v15.2a7.37,7.37,0,0,1-2.32,5.74,10.15,10.15,0,0,1-12.38,0,7.43,7.43,0,0,1-2.3-5.79V347.3h3.94v15.21a4.84,4.84,0,0,0,1.16,3.49,4.5,4.5,0,0,0,3.4,1.2c3,0,4.57-1.6,4.57-4.81V347.3Z" />
  <path className="cls-5"
    d="M229.39,361.3H225v8.75H221V347.3h8a9.3,9.3,0,0,1,6.08,1.76,6.26,6.26,0,0,1,2.14,5.12,6.43,6.43,0,0,1-1.1,3.82,7,7,0,0,1-3.07,2.37l5.11,9.47v.21H234ZM225,358.11h4.07a4.52,4.52,0,0,0,3.12-1,3.52,3.52,0,0,0,1.13-2.76,3.76,3.76,0,0,0-1-2.83,4.46,4.46,0,0,0-3.1-1H225Z" />
  <path className="cls-5"
    d="M256.93,364.75h-8.81l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.7-3.19h6.6l-3.3-9.44Z" />
  <path className="cls-5" d="M286.74,370.05h-3.95l-10.14-16.14v16.14H268.7V347.3h3.95l10.17,16.2V347.3h3.92Z" />
  <path className="cls-5"
    d="M312,362.64a8.23,8.23,0,0,1-2.69,5.68,9.16,9.16,0,0,1-6.24,2,8.88,8.88,0,0,1-4.79-1.29,8.46,8.46,0,0,1-3.19-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.65,8.65,0,0,1,3.27-3.78,9.19,9.19,0,0,1,4.93-1.33,8.78,8.78,0,0,1,6.06,2A8.55,8.55,0,0,1,312,354.8H308a5.65,5.65,0,0,0-1.43-3.54,4.71,4.71,0,0,0-3.37-1.09,4.61,4.61,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.37,4.37,0,0,0,3.86,1.94,5.1,5.1,0,0,0,3.5-1.05,5.46,5.46,0,0,0,1.49-3.48Zm-7.57,7.44-.17.84a2.51,2.51,0,0,1,2.34,2.7,2.94,2.94,0,0,1-1.34,2.57,6.63,6.63,0,0,1-3.76.92l-.11-2.14a2.9,2.9,0,0,0,1.58-.37,1.16,1.16,0,0,0,.56-1,1,1,0,0,0-.56-1,5.09,5.09,0,0,0-1.8-.35l.5-2.17Z" />
  <path className="cls-5"
    d="M331.44,364.75h-8.81l-1.85,5.3h-4.1l8.59-22.75h3.55l8.61,22.75H333.3Zm-7.7-3.19h6.59l-3.3-9.44Z" />
  <path className="cls-5" d="M183.16,402.09,189,384.3h4.34l-8.19,22.75h-3.83l-8.15-22.75h4.33Z" />
  <path className="cls-5" d="M203.22,407.05h-3.93V384.3h3.93Z" />
  <path className="cls-5"
    d="M224.3,401.75h-8.81l-1.85,5.3h-4.11l8.6-22.75h3.54l8.61,22.75h-4.12Zm-7.7-3.19h6.59l-3.3-9.44Zm4.48-20.36h4.33l-4.33,4.74H218Z" />
  <path className="cls-5"
    d="M244.42,398.3H240v8.75h-3.95V384.3h8a9.3,9.3,0,0,1,6.08,1.76,6.26,6.26,0,0,1,2.14,5.12,6.43,6.43,0,0,1-1.1,3.82,7,7,0,0,1-3.07,2.37l5.11,9.47v.21H249ZM240,395.11h4.07a4.52,4.52,0,0,0,3.12-1,3.52,3.52,0,0,0,1.13-2.76,3.76,3.76,0,0,0-1-2.83,4.46,4.46,0,0,0-3.1-1H240Z" />
  <path className="cls-5" d="M263.4,407.05h-3.94V384.3h3.94Z" />
  <path className="cls-5"
    d="M284.48,401.75h-8.82l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.12Zm-7.71-3.19h6.6l-3.3-9.44Z" />
  <line className="cls-7" x1="111.78" y1="518.79" x2="351.78" y2="518.79" />
  <line className="cls-8" x1="73.9" y1="135.5" x2="73.9" y2="144" />
  <circle className="cls-4" cx="73.9" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M75.25,135.78a12.36,12.36,0,1,1,11-11.63A12.38,12.38,0,0,1,75.25,135.78Zm-.59-22.61a10.35,10.35,0,1,0,9.5,9A10.36,10.36,0,0,0,74.66,113.17Z" />
  <path className="cls-5" d="M80.86,122.38a7.05,7.05,0,1,1-6.25-5.9A7.05,7.05,0,0,1,80.86,122.38Z" />
  <circle className="cls-4" cx="398.56" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M399.56,135.81a12.36,12.36,0,1,1,11.32-11.31A12.37,12.37,0,0,1,399.56,135.81Zm.06-22.62a10.35,10.35,0,1,0,9.23,9.24A10.37,10.37,0,0,0,399.62,113.19Z" />
  <path className="cls-5" d="M405.55,122.57a7,7,0,1,1-6.08-6.07A7.06,7.06,0,0,1,405.55,122.57Z" />
  <line className="cls-8" x1="398.56" y1="135.5" x2="398.56" y2="144" />
  <path className="cls-3"
    d="M236.46,299.67a61.52,61.52,0,0,1-58.8-43.82,2.51,2.51,0,1,1,4.81-1.44,56.45,56.45,0,1,0,37.82-70.31,2.52,2.52,0,0,1-1.44-4.82,61.48,61.48,0,1,1,17.61,120.39Z" />
  <path className="cls-3"
    d="M236.51,318.33a80.65,80.65,0,0,1-76.73-57.11A80.22,80.22,0,0,1,236.56,158a80.17,80.17,0,0,1-.05,160.33ZM236.56,163a75.14,75.14,0,1,0,71.92,53.54A75.26,75.26,0,0,0,236.56,163Z" />
  <path className="cls-9"
    d="M239.15,253.94H263L249.89,219h-12l.18,4.71h-3l.17-4.71h-12L210,253.94h23.87l.39-10.54h4.5Zm-.57-16.25h-4.06l.31-8.27h3.47Z" />
  <path className="cls-3"
    d="M73.9,200.07a25.1,25.1,0,0,1-7.23-49.13,1,1,0,0,1,1.28.69,1,1,0,0,1-.69,1.27A23,23,0,1,0,96,168.34a1,1,0,0,1,2-.59,25.09,25.09,0,0,1-24,32.32Z" />
  <path className="cls-3"
    d="M73.88,207.7a32.73,32.73,0,1,1,31.34-42.14A32.71,32.71,0,0,1,83.3,206.31,32.91,32.91,0,0,1,73.88,207.7Zm0-63.4a30.68,30.68,0,0,0,0,61.35,30.68,30.68,0,0,0,0-61.35Z" />
  <path className="cls-9"
    d="M84.25,165.71S83,167.87,78,168.3s-7.63,1.84-9.35,5.15a7.8,7.8,0,0,0-.49,5.15l7.27-3.71-2.58,2-7.07,5.43,1.48,1.54c1.07-1,2.26-1.92,3.35-2.94a9.42,9.42,0,0,0,9-.44c5.29-3.35,4.67-14.81,4.67-14.81Z" />
  <path className="cls-3"
    d="M405.48,199.06a1,1,0,0,1-.29-2,23,23,0,1,0-28.71-15.44,1,1,0,0,1-2,.59A25.1,25.1,0,1,1,405.77,199,.93.93,0,0,1,405.48,199.06Z" />
  <path className="cls-3"
    d="M398.54,207.71a32.73,32.73,0,1,1,31.35-42.15,32.77,32.77,0,0,1-31.35,42.15Zm0-63.41a30.68,30.68,0,0,0,0,61.36,30.69,30.69,0,0,0,29.39-39.51A30.88,30.88,0,0,0,398.57,144.3Z" />
  <path className="cls-9"
    d="M398.21,165.67a7.54,7.54,0,1,0,7.53,7.54A7.55,7.55,0,0,0,398.21,165.67Zm0,3.46a.53.53,0,0,1,.48.27l1.08,2.13,2.37.33a.52.52,0,0,1,.29.89l-1.7,1.67.42,2.35a.52.52,0,0,1-.75.56l-2.13-1.1-2.11,1.13a.52.52,0,0,1-.76-.55l.39-2.35L394,172.8a.52.52,0,0,1,.28-.89l2.37-.36,1-2.14a.5.5,0,0,1,.45-.28Z" />
  <path className="cls-9"
    d="M394.45,180.9V187a.52.52,0,0,0,.82.42l2.94-2.07,2.94,2.07A.52.52,0,0,0,402,187V180.9a8.47,8.47,0,0,1-7.52,0Z" />
  <circle onClick={() => handleSecuritView()} className="cls-10" cx="236" cy="239" r="85" />
  <rect onClick={() => handleSecuritView()} className="cls-10" x="109" y="331" width="246" height="87" />
  <rect onClick={() => handleSecuritView()} className="cls-10" x="139" y="427" width="183" height="81" />
  <rect onClick={() => handleSecuritView()} className="cls-10" x="124" y="529" width="219" height="119" />
</svg>
    </>
  );
}