import { MakeNumberToLocale } from "@/utils/factory";

export interface IData {
  label: string;
  data: {
    opening: string;
    period: string;
    value: number;
  }[]
}

interface R {
  concedidas: number;
  period: string;
  nao_concedidas: number;
}

export const buildingColumnSeries = (r: IData[]) => {
  return r.reduce((acc, curr) => {
    if (curr.label ===  'Concedidas') {
      curr.data.forEach(item => {
        acc.period = item.period.split('-')[0];
        acc.concedidas = Number(item.value.toFixed(1));
      });
    }

    if (curr.label === 'Não concedidas') {
      curr.data.forEach(item => {
        acc.period = item.period.split('-')[0];
        acc.nao_concedidas = Number(item.value.toFixed(1));
      });
    }
    return acc;
  }, {
    period: '', concedidas: 0, nao_concedidas: 0
  } as R);
};