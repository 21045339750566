import React from 'react';
import { useSidebar } from '@/hooks/useContexts';
import { toggleActiveAction } from '@/utils/toggleActiveAction';
import { MenuBarItemProps } from '@/context/sidebar/interfaces';

export const AbcrFigure: React.FC = () => {
  const { setItems, setClickReport, items } = useSidebar();
  const handleServiceQuality = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 5
    });
    setClickReport(false);
    setItems(newItems);
  };

  const handleSecuritView = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 4
    });
    setClickReport(false);
    setItems(newItems);
  };

  const handleAmbientalImpact = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 3
    });
    setClickReport(false);
    setItems(newItems);
  };

  const handleEconomicImpact = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 2
    });
    setClickReport(false);
    setItems(newItems);
  };

  const handleUserAtendence = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 1
    });
    setClickReport(false);
    setItems(newItems);
  };


  return (
    <>
      <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 774.98 440.39">
  <defs>
    <style>
      {
        `
        .cls-1,
        .cls-5 {
          fill: #1e303a;
        }
  
        .cls-2 {
          fill: #006681;
        }
  
        .cls-3 {
          font-size: 15.63px;
          fill: #2f83c2;
          font-family: Roboto;
          font-weight: 500;
          letter-spacing: 0.09em;
        }
  
        .cls-4 {
          letter-spacing: 0.1em;
        }
  
        .cls-5 {
          font-size: 30.02px;
          font-family: Roboto, Roboto Black;
          font-weight: 800;
          letter-spacing: 0.1em;
        }
  
        .cls-6 {
          fill: #94c122;
        }
  
        .cls-7 {
          fill: #1d1d1b;
        }
  
        .cls-8 {
          fill: none;
          stroke: #1e303a;
          stroke-miterlimit: 10;
        }
  
        .cls-9 {
          fill: #fff;
        }
  
        .cls-10,
        .cls-9 {
          opacity: 0;
        }
  
        .cls-10 {
          fill: #682021;
        }
        `
      }
    </style>
    </defs>
    <title>Figura ABCR_ajustada</title>
    <path className="cls-1" d="M521.52,61.37a3.46,3.46,0,0,1,.57,2.57h10.14V61.37Z" />
    <path className="cls-1" d="M483.54,153.44a2.83,2.83,0,0,1-1.93,1.71l8.55,8.55,1.82-1.82Z" />
    <path className="cls-1" d="M389.88,192.1v12.39h2.58V192.31A3.26,3.26,0,0,1,389.88,192.1Z" />
    <path className="cls-1" d="M298.66,153.9l-8.53,8.53,1.82,1.82,8.28-8.28A2.92,2.92,0,0,1,298.66,153.9Z" />
    <path className="cls-1" d="M260.35,62.15h-11v2.57H261.1A3.33,3.33,0,0,1,260.35,62.15Z" />
    <path className="cls-1"
      d="M461.62,152.92a111.74,111.74,0,0,1-57,23.73,2.76,2.76,0,0,1-.28,2.62,113.79,113.79,0,0,0,59-24.44A3.69,3.69,0,0,1,461.62,152.92Z" />
    <path className="cls-1"
      d="M292.78,122.11h0c-1.11-1.82-2.16-3.69-3.18-5.58-.18-.34-.37-.68-.55-1-.83-1.57-1.6-3.18-2.35-4.79-.49-1-1-2.12-1.41-3.19A109.1,109.1,0,0,1,281.08,96a114.07,114.07,0,0,1-4-19.07,3,3,0,0,1-.43.09,3.2,3.2,0,0,1-1.36-.29,2.09,2.09,0,0,1-.36.07,3.26,3.26,0,0,1-.45-.09,116.37,116.37,0,0,0,4.09,20c.86,2.85,1.86,5.63,2.93,8.37h0c.3.79.68,1.54,1,2.32.57,1.39,1.15,2.77,1.78,4.12.71,1.56,1.47,3.09,2.25,4.61.3.58.6,1.16.91,1.73a118.24,118.24,0,0,0,11.05,16.86,2.87,2.87,0,0,1,2.51-1A111.36,111.36,0,0,1,292.78,122.11Z" />
    <path className="cls-1"
      d="M375.88,176.56a114.86,114.86,0,0,1-55.28-23.42,3.53,3.53,0,0,1-2,1.7,117.36,117.36,0,0,0,56.94,24.27A3.53,3.53,0,0,1,375.88,176.56Z" />
    <path className="cls-1"
      d="M506.94,76.6a2.71,2.71,0,0,1-.58.12,2.85,2.85,0,0,1-1.87-.67A115.37,115.37,0,0,1,486,126.43l0,0c-1.53,2.31-3.18,4.55-4.88,6.74a2.77,2.77,0,0,1,2.52.94A118,118,0,0,0,498.77,108l.27-.64c.32-.76.66-1.51,1-2.28h-.1A116.6,116.6,0,0,0,506.94,76.6Z" />
    <path className="cls-1"
      d="M506.72,78.47a15.82,15.82,0,1,1,15.82-15.82A15.84,15.84,0,0,1,506.72,78.47Zm0-29A13.24,13.24,0,1,0,520,62.65,13.25,13.25,0,0,0,506.72,49.42Z" />
    <path style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-2" d="M515.74,62.65a9,9,0,1,1-9-9A9,9,0,0,1,515.74,62.65Z" />
    <path className="cls-1"
      d="M274.82,78.47a15.82,15.82,0,1,1,15.82-15.82A15.84,15.84,0,0,1,274.82,78.47Zm0-29a13.24,13.24,0,1,0,13.24,13.23A13.25,13.25,0,0,0,274.82,49.42Z" />
    <path style={{ cursor: 'pointer' }} onClick={() => handleUserAtendence()} className="cls-2" d="M283.83,62.65a9,9,0,1,1-9-9A9,9,0,0,1,283.83,62.65Z" />
    <path className="cls-1"
      d="M472.78,160.45a15.82,15.82,0,1,1,15.82-15.82A15.84,15.84,0,0,1,472.78,160.45Zm0-29A13.24,13.24,0,1,0,486,144.63,13.25,13.25,0,0,0,472.78,131.4Z" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleSecuritView()} className="cls-2" cx="472.78" cy="144.63" r="9.02" />
    <path className="cls-1"
      d="M309.19,161.05A15.82,15.82,0,1,1,325,145.24,15.83,15.83,0,0,1,309.19,161.05Zm0-29.05a13.24,13.24,0,1,0,13.24,13.24A13.26,13.26,0,0,0,309.19,132Z" />
    <path style={{ cursor: 'pointer' }} onClick={() => handleEconomicImpact()} className="cls-2" d="M318.21,145.24a9,9,0,1,1-9-9A9,9,0,0,1,318.21,145.24Z" />
    <path className="cls-1"
      d="M390.52,194.72a15.82,15.82,0,1,1,15.82-15.82A15.83,15.83,0,0,1,390.52,194.72Zm0-29.06a13.24,13.24,0,1,0,13.24,13.24A13.26,13.26,0,0,0,390.52,165.66Z" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleAmbientalImpact()} className="cls-2" cx="390.52" cy="178.9" r="9.02" />
    <path className="cls-1"
      d="M586.18,96a1.46,1.46,0,0,1-.42-2.85,32.8,32.8,0,1,0-40.85-22,1.46,1.46,0,1,1-2.79.84A35.71,35.71,0,1,1,586.6,95.89,1.58,1.58,0,0,1,586.18,96Z" />
    <path className="cls-1"
      d="M522.27,228.86a35.76,35.76,0,0,1-34.16-25.46,1.46,1.46,0,0,1,2.8-.84,32.79,32.79,0,1,0,22-40.84,1.46,1.46,0,1,1-.84-2.8,35.71,35.71,0,1,1,10.23,69.94Z" />
    <path className="cls-1"
      d="M391.05,283.63a35.71,35.71,0,0,1-10.29-69.92,1.46,1.46,0,1,1,.84,2.79,32.8,32.8,0,1,0,40.84,22,1.46,1.46,0,1,1,2.79-.84,35.71,35.71,0,0,1-34.18,46Z" />
    <path className="cls-1"
      d="M259.57,229.56a35.72,35.72,0,1,1,34.25-46,1.46,1.46,0,0,1-2.8.84,32.79,32.79,0,1,0-22,40.84,1.46,1.46,0,0,1,.84,2.8A35.87,35.87,0,0,1,259.57,229.56Z" />
    <path className="cls-1"
      d="M214.79,96.84a1.46,1.46,0,0,1-.41-2.86,32.79,32.79,0,1,0-40.85-22,1.46,1.46,0,1,1-2.79.84,35.71,35.71,0,1,1,44.47,23.93A1.52,1.52,0,0,1,214.79,96.84Z" />
    <path className="cls-1"
      d="M576.31,108.26a46.58,46.58,0,1,1,44.61-60,46.6,46.6,0,0,1-44.61,60Zm0-90.23a43.66,43.66,0,1,0,41.78,31.1A43.73,43.73,0,0,0,576.34,18Z" />
    <path className="cls-1"
      d="M522.3,239.69a46.86,46.86,0,0,1-44.58-33.17,46.6,46.6,0,0,1,44.61-60,46.57,46.57,0,0,1,0,93.13Zm0-90.21a43.64,43.64,0,1,0,41.78,31.09A43.72,43.72,0,0,0,522.33,149.48Z" />
    <path className="cls-1"
      d="M391,294.48a46.57,46.57,0,1,1,44.6-60,46.6,46.6,0,0,1-44.6,60Zm0-90.22a43.65,43.65,0,1,0,38.41,64.33,43.29,43.29,0,0,0,3.36-33.23A43.9,43.9,0,0,0,391.05,204.26Z" />
    <path className="cls-1"
      d="M259.6,240.4A46.86,46.86,0,0,1,215,207.23a46.6,46.6,0,0,1,44.61-60,46.57,46.57,0,0,1,0,93.13Zm0-90.22a43.65,43.65,0,1,0,41.78,31.1A43.73,43.73,0,0,0,259.63,150.18Z" />
    <path className="cls-1"
      d="M204.93,109.14A46.86,46.86,0,0,1,160.35,76,46.57,46.57,0,0,1,205,16a46.56,46.56,0,0,1,13.37,91.15A46.31,46.31,0,0,1,204.93,109.14Zm0-90.22A43.65,43.65,0,1,0,246.73,50,43.73,43.73,0,0,0,205,18.92Z" />
    <text className="cls-3" transform="translate(366.84 68.17)">P<tspan className="cls-4" x="11.47" y="0">ainel</tspan>
      </text><text className="cls-5" transform="translate(338.88 95.87)">ABCR+</text>
    <path className="cls-6"
      d="M523.83,202.29H537.7L530.07,182h-7l.11,2.74h-1.77l.1-2.74h-7l-7.63,20.3h13.87l.23-6.12h2.61Zm-.33-9.44h-2.36l.18-4.8h2Z" />
    <path className="cls-7" d="M499.21,341.21H496v8.88H494.7v-8.88h-3.19v-1.07h7.7Z" />
    <path className="cls-7"
      d="M505.68,350.09a2.47,2.47,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23V345a1.39,1.39,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.69,2.69,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.27,2.27,0,0,1,.75,1.76v3.4A4.21,4.21,0,0,0,507,350v.11Zm-2.1-1a2.29,2.29,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8V346.5h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,503.58,349.13Z" />
    <path className="cls-7"
      d="M512.61,345.39l1.64-2.7h1.47l-2.41,3.66,2.49,3.74h-1.46l-1.71-2.77-1.71,2.77h-1.47l2.49-3.74-2.42-3.66H511Z" />
    <path className="cls-7"
      d="M523,350.09a1.16,1.16,0,1,0-2.28.14,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23V345a1.43,1.43,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.45.83H518.4a1.7,1.7,0,0,1,.4-1.07,2.59,2.59,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.92,2.92,0,0,1,2,.64,2.31,2.31,0,0,1,.75,1.76v3.4a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.26,2.26,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8V346.5h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,520.93,349.13Z" />
    <path className="cls-7"
      d="M532,346.33a4.34,4.34,0,0,1,.81-2.74,2.53,2.53,0,0,1,2.11-1,2.56,2.56,0,0,1,2.06.89v-3.86h1.26v10.5h-1.16l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.51,2.51,0,0,1-2.09-1.06,4.35,4.35,0,0,1-.81-2.74Zm1.26.14a3.32,3.32,0,0,0,.52,2,1.68,1.68,0,0,0,1.44.71,1.85,1.85,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.75-1.05,1.69,1.69,0,0,0-1.45.72A3.66,3.66,0,0,0,533.3,346.47Z" />
    <path className="cls-7"
      d="M544.71,350.23a3.24,3.24,0,0,1-2.45-1,3.7,3.7,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.18,3.18,0,0,1,1.18-1.35,2.91,2.91,0,0,1,1.63-.49,2.76,2.76,0,0,1,2.25.95,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.63,2.63,0,0,0,.63,1.77,2,2,0,0,0,1.56.68,2.2,2.2,0,0,0,1.13-.28,2.8,2.8,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,544.71,350.23Zm-.16-6.63a1.67,1.67,0,0,0-1.28.55,2.72,2.72,0,0,0-.64,1.57h3.7v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,544.55,343.6Z" />
    <path className="cls-7"
      d="M559.63,348.13a1,1,0,0,0-.38-.8,3.8,3.8,0,0,0-1.35-.49,6.26,6.26,0,0,1-1.53-.49,2.22,2.22,0,0,1-.83-.68,1.66,1.66,0,0,1-.27-.95,1.92,1.92,0,0,1,.77-1.53,3,3,0,0,1,2-.63,3.09,3.09,0,0,1,2,.65,2.05,2.05,0,0,1,.78,1.66h-1.27a1.16,1.16,0,0,0-.44-.9,1.68,1.68,0,0,0-1.12-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.77.77,0,0,0,.37.69,4.15,4.15,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.35,2.35,0,0,1,.87.71,1.74,1.74,0,0,1,.29,1,1.9,1.9,0,0,1-.8,1.59,3.3,3.3,0,0,1-2.05.6,3.74,3.74,0,0,1-1.58-.32,2.59,2.59,0,0,1-1.07-.88,2.15,2.15,0,0,1-.38-1.22h1.26a1.32,1.32,0,0,0,.51,1,2.31,2.31,0,0,0,2.41.08A.88.88,0,0,0,559.63,348.13Z" />
    <path className="cls-7"
      d="M567,350.23a3.2,3.2,0,0,1-2.44-1,3.67,3.67,0,0,1-1-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.76,2.76,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.59,2.59,0,0,0,.64,1.77,2,2,0,0,0,1.55.68,2.24,2.24,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,567,350.23Zm-.15-6.63a1.7,1.7,0,0,0-1.29.55,2.72,2.72,0,0,0-.64,1.57h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.56,1.56,0,0,0,566.86,343.6Z" />
    <path className="cls-7" d="M575.11,348.37l1.83-5.68h1.3l-2.66,7.4h-1l-2.68-7.4h1.29Z" />
    <path className="cls-7"
      d="M583.83,350.23a3.22,3.22,0,0,1-2.44-1,3.67,3.67,0,0,1-.95-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.74,2.74,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.59,2.59,0,0,0,.64,1.77,2,2,0,0,0,1.55.68,2.24,2.24,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,583.83,350.23Zm-.15-6.63a1.7,1.7,0,0,0-1.29.55,2.72,2.72,0,0,0-.64,1.57h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.56,1.56,0,0,0,583.68,343.6Z" />
    <path className="cls-7"
      d="M593.16,343.83a4.13,4.13,0,0,0-.63-.05,1.65,1.65,0,0,0-1.68,1.06v5.25h-1.27v-7.4h1.23l0,.86a2,2,0,0,1,1.77-1,1.39,1.39,0,0,1,.56.09Z" />
    <path className="cls-7"
      d="M595.72,340.73a.79.79,0,0,1,.19-.52.85.85,0,0,1,1.12,0,.74.74,0,0,1,.19.52.73.73,0,0,1-.19.52.88.88,0,0,1-1.12,0A.77.77,0,0,1,595.72,340.73Zm1.37,9.36h-1.26v-7.4h1.26Z" />
    <path className="cls-7"
      d="M600.21,346.33a4.34,4.34,0,0,1,.81-2.74,2.74,2.74,0,0,1,4.16-.14v-3.86h1.27v10.5h-1.16l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.51,2.51,0,0,1-2.09-1.06,4.35,4.35,0,0,1-.81-2.74Zm1.26.14a3.32,3.32,0,0,0,.52,2,1.68,1.68,0,0,0,1.44.71,1.83,1.83,0,0,0,1.75-1.08v-3.39a1.85,1.85,0,0,0-1.74-1.05,1.69,1.69,0,0,0-1.45.72A3.66,3.66,0,0,0,601.47,346.47Z" />
    <path className="cls-7"
      d="M614.38,350.09a2.79,2.79,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23V345a1.39,1.39,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.62,2.62,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.24,2.24,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.29,2.29,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8V346.5h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,612.28,349.13Z" />
    <path className="cls-7"
      d="M618.52,346.33a4.34,4.34,0,0,1,.81-2.74,2.53,2.53,0,0,1,2.11-1,2.56,2.56,0,0,1,2.06.89v-3.86h1.26v10.5H623.6l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.51,2.51,0,0,1-2.09-1.06,4.35,4.35,0,0,1-.81-2.74Zm1.26.14a3.32,3.32,0,0,0,.52,2,1.68,1.68,0,0,0,1.44.71,1.85,1.85,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.75-1.05,1.69,1.69,0,0,0-1.45.72A3.66,3.66,0,0,0,619.78,346.47Z" />
    <path className="cls-7"
      d="M631.19,350.23a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.18,3.18,0,0,1,1.18-1.35,2.91,2.91,0,0,1,1.63-.49,2.76,2.76,0,0,1,2.25.95,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.69,2.69,0,0,0,.64,1.77,2,2,0,0,0,1.56.68,2.2,2.2,0,0,0,1.13-.28,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,631.19,350.23ZM631,343.6a1.68,1.68,0,0,0-1.28.55,2.72,2.72,0,0,0-.64,1.57h3.7v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,631,343.6Z" />
    <path className="cls-7"
      d="M491.82,363.13a4.34,4.34,0,0,1,.81-2.74,2.75,2.75,0,0,1,4.17-.14v-3.86h1.26v10.5H496.9l-.06-.79a2.58,2.58,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1,4.4,4.4,0,0,1-.81-2.75Zm1.27.14a3.25,3.25,0,0,0,.52,2A1.65,1.65,0,0,0,495,366a1.84,1.84,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.74-1.05,1.68,1.68,0,0,0-1.45.72A3.59,3.59,0,0,0,493.09,363.27Z" />
    <path className="cls-7"
      d="M504.5,367a3.22,3.22,0,0,1-2.45-1,3.67,3.67,0,0,1-.95-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.31,3.31,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.74,2.74,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.63,2.63,0,0,0,.64,1.76,2,2,0,0,0,1.55.68,2.24,2.24,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,504.5,367Zm-.16-6.63a1.7,1.7,0,0,0-1.29.55,2.79,2.79,0,0,0-.64,1.57h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,504.34,360.4Z" />
    <path className="cls-7"
      d="M519.68,366.89a2.22,2.22,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.6,2.6,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.08,4.08,0,0,1,2.47-.64h1.23v-.58a1.39,1.39,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.62,2.62,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.31,2.31,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.25,2.25,0,0,0,1.12-.31,1.87,1.87,0,0,0,.78-.8V363.3h-1c-1.55,0-2.33.46-2.33,1.36a1.13,1.13,0,0,0,.4.93A1.48,1.48,0,0,0,517.58,365.93Z" />
    <path className="cls-7"
      d="M527.09,366a1.82,1.82,0,0,0,1.18-.41,1.43,1.43,0,0,0,.56-1H530a2.27,2.27,0,0,1-.44,1.21,2.91,2.91,0,0,1-1.07.92,3.23,3.23,0,0,1-1.43.34,3,3,0,0,1-2.4-1,4,4,0,0,1-.89-2.76v-.21a4.51,4.51,0,0,1,.39-1.92,3.11,3.11,0,0,1,1.14-1.31,3.24,3.24,0,0,1,1.75-.46,3,3,0,0,1,2.07.74A2.68,2.68,0,0,1,530,362h-1.2a1.7,1.7,0,0,0-.54-1.18,1.68,1.68,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.28,3.28,0,0,0-.53,2v.24a3.23,3.23,0,0,0,.53,2A1.77,1.77,0,0,0,527.09,366Z" />
    <path className="cls-7"
      d="M532.86,357.53a.74.74,0,0,1,.19-.52.69.69,0,0,1,.55-.21.7.7,0,0,1,.56.21.75.75,0,0,1,.2.52.69.69,0,0,1-.76.72.67.67,0,0,1-.74-.72Zm1.37,9.36H533v-7.4h1.27Z" />
    <path className="cls-7"
      d="M537.34,363.13a4.34,4.34,0,0,1,.81-2.74,2.75,2.75,0,0,1,4.17-.14v-3.86h1.26v10.5h-1.16l-.06-.79a2.58,2.58,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1,4.4,4.4,0,0,1-.81-2.75Zm1.27.14a3.25,3.25,0,0,0,.52,2,1.65,1.65,0,0,0,1.43.71,1.85,1.85,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.74-1.05,1.68,1.68,0,0,0-1.45.72A3.59,3.59,0,0,0,538.61,363.27Z" />
    <path className="cls-7"
      d="M550,367a3.22,3.22,0,0,1-2.44-1,3.67,3.67,0,0,1-1-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.74,2.74,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.63,2.63,0,0,0,.64,1.76,2,2,0,0,0,1.55.68,2.24,2.24,0,0,0,1.14-.28A2.77,2.77,0,0,0,552,365l.77.6A3.11,3.11,0,0,1,550,367Zm-.15-6.63a1.7,1.7,0,0,0-1.29.55,2.79,2.79,0,0,0-.64,1.57h3.7v-.1a2.3,2.3,0,0,0-.52-1.49A1.54,1.54,0,0,0,549.86,360.4Z" />
    <path className="cls-7"
      d="M557,359.49l0,.93a2.68,2.68,0,0,1,2.21-1.06q2.35,0,2.37,2.64v4.89h-1.27V362a1.69,1.69,0,0,0-.36-1.19,1.49,1.49,0,0,0-1.12-.38,1.82,1.82,0,0,0-1.08.33,2.23,2.23,0,0,0-.72.86v5.27h-1.27v-7.4Z" />
    <path className="cls-7"
      d="M566.6,357.7v1.79H568v1H566.6v4.59a1,1,0,0,0,.19.67.76.76,0,0,0,.63.22,2.74,2.74,0,0,0,.6-.08v1a3.79,3.79,0,0,1-1,.14,1.58,1.58,0,0,1-1.28-.51,2.21,2.21,0,0,1-.43-1.46v-4.59H564v-1h1.35V357.7Z" />
    <path className="cls-7"
      d="M573.93,367a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.18,3.18,0,0,1,1.18-1.35,2.89,2.89,0,0,1,1.63-.49,2.74,2.74,0,0,1,2.24.95,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.63,2.63,0,0,0,.64,1.76A2,2,0,0,0,574,366a2.23,2.23,0,0,0,1.13-.28A2.94,2.94,0,0,0,576,365l.77.6A3.11,3.11,0,0,1,573.93,367Zm-.16-6.63a1.67,1.67,0,0,0-1.28.55,2.79,2.79,0,0,0-.65,1.57h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,573.77,360.4Z" />
    <path className="cls-7"
      d="M584,364.93a1,1,0,0,0-.38-.8,3.8,3.8,0,0,0-1.35-.49,6,6,0,0,1-1.52-.49,2.18,2.18,0,0,1-.84-.68,1.66,1.66,0,0,1-.27-1,1.92,1.92,0,0,1,.77-1.53,3,3,0,0,1,2-.63,3.06,3.06,0,0,1,2,.65,2.05,2.05,0,0,1,.78,1.66h-1.27a1.16,1.16,0,0,0-.44-.9,1.67,1.67,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.27,2.27,0,0,1,.87.71,1.74,1.74,0,0,1,.29,1,1.92,1.92,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.32,2.46,2.46,0,0,1-1.07-.88,2.08,2.08,0,0,1-.39-1.22h1.26a1.35,1.35,0,0,0,.51,1,2,2,0,0,0,1.26.38,2.06,2.06,0,0,0,1.15-.29A.91.91,0,0,0,584,364.93Z" />
    <path className="cls-7" d="M499.21,302.23H496v8.88H494.7v-8.88h-3.19v-1.07h7.7Z" />
    <path className="cls-7"
      d="M505.68,311.11a2.47,2.47,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.41,1.41,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.69,2.69,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.27,2.27,0,0,1,.75,1.76v3.4A4.21,4.21,0,0,0,507,311v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,503.58,310.15Z" />
    <path className="cls-7"
      d="M512.61,306.42l1.64-2.7h1.47l-2.41,3.65,2.49,3.74h-1.46l-1.71-2.77-1.71,2.77h-1.47l2.49-3.74-2.42-3.65H511Z" />
    <path className="cls-7"
      d="M523,311.11a1.16,1.16,0,1,0-2.28.14,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.44,1.44,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.45.83H518.4a1.7,1.7,0,0,1,.4-1.07,2.59,2.59,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.92,2.92,0,0,1,2,.64,2.31,2.31,0,0,1,.75,1.76v3.4a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.16,2.16,0,0,0,1.13-.31,1.8,1.8,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,520.93,310.15Z" />
    <path className="cls-7"
      d="M532,307.35a4.31,4.31,0,0,1,.81-2.73,2.52,2.52,0,0,1,2.11-1,2.59,2.59,0,0,1,2.06.89v-3.86h1.26v10.5h-1.16l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.53,2.53,0,0,1-2.09-1.05,4.39,4.39,0,0,1-.81-2.75Zm1.26.15a3.34,3.34,0,0,0,.52,2,1.7,1.7,0,0,0,1.44.71A1.85,1.85,0,0,0,537,309.1v-3.4a1.86,1.86,0,0,0-1.75-1.05,1.69,1.69,0,0,0-1.45.72A3.67,3.67,0,0,0,533.3,307.5Z" />
    <path className="cls-7"
      d="M544.71,311.25a3.24,3.24,0,0,1-2.45-1,3.7,3.7,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.91,2.91,0,0,1,1.63-.49,2.79,2.79,0,0,1,2.25.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.63,1.76,2,2,0,0,0,1.56.68,2.11,2.11,0,0,0,1.13-.28,2.8,2.8,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,544.71,311.25Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,544.55,304.62Z" />
    <path className="cls-7"
      d="M559.89,311.11a1.16,1.16,0,1,0-2.28.14,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.44,1.44,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.46.83h-1.27a1.7,1.7,0,0,1,.4-1.07,2.59,2.59,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.92,2.92,0,0,1,2,.64A2.31,2.31,0,0,1,561,306v3.4a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.8,1.8,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,557.79,310.15Z" />
    <path className="cls-7"
      d="M567.31,310.22a1.84,1.84,0,0,0,1.18-.41,1.47,1.47,0,0,0,.56-1h1.2a2.32,2.32,0,0,1-.44,1.21,3,3,0,0,1-1.08.92,3.29,3.29,0,0,1-3.83-.67,4,4,0,0,1-.89-2.76v-.21a4.37,4.37,0,0,1,.4-1.92,3,3,0,0,1,1.14-1.31,3.22,3.22,0,0,1,1.75-.46,3,3,0,0,1,2.07.74,2.7,2.7,0,0,1,.88,1.94h-1.2a1.79,1.79,0,0,0-.54-1.18,1.69,1.69,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.23,3.23,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.52,2A1.78,1.78,0,0,0,567.31,310.22Z" />
    <path className="cls-7"
      d="M573.07,301.75a.81.81,0,0,1,.19-.52.85.85,0,0,1,1.12,0,.76.76,0,0,1,.19.52.73.73,0,0,1-.19.52.88.88,0,0,1-1.12,0A.77.77,0,0,1,573.07,301.75Zm1.37,9.36h-1.26v-7.39h1.26Z" />
    <path className="cls-7"
      d="M577.56,307.35a4.36,4.36,0,0,1,.8-2.73,2.74,2.74,0,0,1,4.17-.15v-3.86h1.27v10.5h-1.16l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.53,2.53,0,0,1-2.09-1.05,4.39,4.39,0,0,1-.81-2.75Zm1.26.15a3.34,3.34,0,0,0,.52,2,1.7,1.7,0,0,0,1.44.71,1.83,1.83,0,0,0,1.75-1.08v-3.4a1.85,1.85,0,0,0-1.74-1.05,1.69,1.69,0,0,0-1.45.72A3.67,3.67,0,0,0,578.82,307.5Z" />
    <path className="cls-7"
      d="M590.23,311.25a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.89,2.89,0,0,1,1.63-.49,2.77,2.77,0,0,1,2.24.95,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.11,2.11,0,0,0,1.13-.28,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,590.23,311.25Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,590.07,304.62Z" />
    <path className="cls-7"
      d="M597.17,303.72l.05.93a2.66,2.66,0,0,1,2.21-1.07c1.56,0,2.35.88,2.37,2.65v4.88h-1.27v-4.89a1.7,1.7,0,0,0-.36-1.18,1.47,1.47,0,0,0-1.12-.39A1.82,1.82,0,0,0,598,305a2.35,2.35,0,0,0-.73.86v5.27H596v-7.39Z" />
    <path className="cls-7"
      d="M606.82,301.93v1.79h1.38v1h-1.38v4.6a1,1,0,0,0,.18.66.79.79,0,0,0,.63.23,2.72,2.72,0,0,0,.6-.09v1a3.67,3.67,0,0,1-1,.14,1.59,1.59,0,0,1-1.28-.51,2.27,2.27,0,0,1-.43-1.46v-4.59h-1.34v-1h1.34v-1.79Z" />
    <path className="cls-7"
      d="M614.14,311.25a3.22,3.22,0,0,1-2.44-1,3.67,3.67,0,0,1-1-2.64v-.23a4.32,4.32,0,0,1,.43-2,3.28,3.28,0,0,1,1.17-1.35,2.92,2.92,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.55.68,2.14,2.14,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.1,3.1,0,0,1,614.14,311.25Zm-.15-6.63a1.68,1.68,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,614,304.62Z" />
    <path className="cls-7"
      d="M624.2,309.15a.94.94,0,0,0-.39-.8,3.69,3.69,0,0,0-1.34-.48,6.68,6.68,0,0,1-1.53-.5,2.22,2.22,0,0,1-.83-.68,1.62,1.62,0,0,1-.27-.94,1.9,1.9,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.1,3.1,0,0,1,2,.65,2,2,0,0,1,.79,1.66h-1.28a1.14,1.14,0,0,0-.44-.9,1.64,1.64,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.79.77.77,0,0,0,.37.69,4.51,4.51,0,0,0,1.3.44,6.43,6.43,0,0,1,1.54.51,2.24,2.24,0,0,1,.87.7,1.76,1.76,0,0,1,.28,1,1.89,1.89,0,0,1-.79,1.59,3.32,3.32,0,0,1-2.06.6,3.73,3.73,0,0,1-1.57-.32,2.56,2.56,0,0,1-1.07-.87,2.12,2.12,0,0,1-.38-1.22h1.26a1.27,1.27,0,0,0,.51,1,2,2,0,0,0,1.25.38,2.1,2.1,0,0,0,1.16-.29A.91.91,0,0,0,624.2,309.15Z" />
    <path className="cls-2"
      d="M497.61,261.23a1.63,1.63,0,0,0-.4-1.18,4.8,4.8,0,0,0-1.46-.83,6.72,6.72,0,0,1-2.77-1.71,3.42,3.42,0,0,1-.84-2.31,3.23,3.23,0,0,1,1.15-2.6,4.32,4.32,0,0,1,2.91-1,4.24,4.24,0,0,1,2.1.5,3.36,3.36,0,0,1,1.42,1.4,4.2,4.2,0,0,1,.5,2h-2.58a2.08,2.08,0,0,0-.38-1.36,1.31,1.31,0,0,0-1.1-.47,1.38,1.38,0,0,0-1.06.4,1.49,1.49,0,0,0-.38,1.07,1.31,1.31,0,0,0,.43,1,4.86,4.86,0,0,0,1.49.88,6.4,6.4,0,0,1,2.72,1.66,3.69,3.69,0,0,1,.85,2.51,3.31,3.31,0,0,1-1.07,2.61,4.22,4.22,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.81,3.81,0,0,1-1.59-1.46,4.27,4.27,0,0,1-.58-2.24h2.6a2.44,2.44,0,0,0,.43,1.61,1.75,1.75,0,0,0,1.4.5C497.16,262.67,497.61,262.19,497.61,261.23Z" />
    <path className="cls-2" d="M510.33,259.05h-4v3.4h4.76v2.14h-7.35v-12.8h7.33V254h-4.74v3h4Z" />
    <path className="cls-2"
      d="M523.06,263.14a4.68,4.68,0,0,1-1.84,1.22,6.7,6.7,0,0,1-2.37.41,4.36,4.36,0,0,1-3.43-1.37,5.92,5.92,0,0,1-1.26-4v-2.3a6.32,6.32,0,0,1,1.16-4.07,4.08,4.08,0,0,1,3.37-1.43,4.32,4.32,0,0,1,3.14,1,4.82,4.82,0,0,1,1.23,3.22h-2.52a2.89,2.89,0,0,0-.51-1.66,1.61,1.61,0,0,0-1.26-.45,1.65,1.65,0,0,0-1.52.77,4.81,4.81,0,0,0-.49,2.43v2.32a4.76,4.76,0,0,0,.52,2.55,1.9,1.9,0,0,0,1.72.79,2.3,2.3,0,0,0,1.24-.3l.22-.16v-2.35h-1.81v-1.95h4.41Z" />
    <path className="cls-2"
      d="M535,251.79v8.86a4.16,4.16,0,0,1-1.1,3.06,4.28,4.28,0,0,1-3.1,1.06,4.34,4.34,0,0,1-3.15-1.07,4.2,4.2,0,0,1-1.08-3.11v-8.8h2.6v8.81a2.68,2.68,0,0,0,.35,1.56,1.52,1.52,0,0,0,1.28.46,1.48,1.48,0,0,0,1.26-.46,2.56,2.56,0,0,0,.35-1.51v-8.86Z" />
    <path className="cls-2"
      d="M542.64,259.92h-1.28v4.67h-2.58v-12.8h4.12a4.2,4.2,0,0,1,3,1,4.37,4.37,0,0,1-.8,6.43l2.25,5.24v.12h-2.78Zm-1.28-2.16h1.47a1.37,1.37,0,0,0,1.16-.51,2.22,2.22,0,0,0,.39-1.38c0-1.28-.51-1.92-1.51-1.92h-1.51Z" />
    <path className="cls-2" d="M556.64,262h-3.52l-.69,2.62H549.7l4-12.8h2.36l4,12.8h-2.76Zm-3-2.15h2.39l-1.19-4.56Z" />
    <path className="cls-2" d="M572,264.59h-2.58l-3.78-8.39v8.39h-2.59v-12.8h2.59l3.79,8.41v-8.41H572Z" />
    <path className="cls-2"
      d="M584.58,260.33a4.74,4.74,0,0,1-1.23,3.31,4.35,4.35,0,0,1-3.2,1.13,4,4,0,0,1-3.33-1.43,6.32,6.32,0,0,1-1.15-4.07v-2.16a6.18,6.18,0,0,1,1.19-4.06,4.73,4.73,0,0,1,6.5-.26,5.29,5.29,0,0,1,1.24,3.36H582a3.53,3.53,0,0,0-.42-1.87,1.63,1.63,0,0,0-1.4-.52,1.58,1.58,0,0,0-1.47.73,5,5,0,0,0-.46,2.38v2.42a5.49,5.49,0,0,0,.43,2.62,1.55,1.55,0,0,0,1.46.71,1.71,1.71,0,0,0,1.41-.49,3.38,3.38,0,0,0,.44-1.8Zm-3.5,4.21-.08.51a1.25,1.25,0,0,1,.79.5,1.76,1.76,0,0,1,.28,1,1.86,1.86,0,0,1-.7,1.53,3.09,3.09,0,0,1-2,.56l-.06-1.47c.43,0,.64-.24.64-.72a.53.53,0,0,0-.22-.49,1.86,1.86,0,0,0-.76-.18l.24-1.26Z" />
    <path className="cls-2" d="M594.08,262h-3.52l-.69,2.62h-2.73l4-12.8h2.36l4,12.8h-2.75Zm-3-2.15h2.39l-1.2-4.56Z" />
    <path className="cls-2" d="M496.37,282.68l2.15-9.29h2.88l-3.69,12.8H495l-3.66-12.8h2.86Z" />
    <path className="cls-2" d="M507.11,286.19h-2.59v-12.8h2.59Z" />
    <path className="cls-2"
      d="M517.34,283.57h-3.52l-.69,2.62H510.4l4-12.8h2.36l4,12.8H518ZM516.1,270h2.8l-2.44,2.72h-2.08Zm-1.72,11.45h2.39l-1.19-4.56Z" />
    <path className="cls-2"
      d="M527.66,281.52h-1.28v4.67h-2.59v-12.8h4.13a4.2,4.2,0,0,1,3,1,3.79,3.79,0,0,1,1.06,2.86,3.72,3.72,0,0,1-1.86,3.57l2.24,5.24v.12h-2.77Zm-1.28-2.16h1.47a1.37,1.37,0,0,0,1.16-.51,2.28,2.28,0,0,0,.38-1.38q0-1.92-1.5-1.92h-1.51Z" />
    <path className="cls-2" d="M538.25,286.19h-2.58v-12.8h2.58Z" />
    <path className="cls-2" d="M548.49,283.57H545l-.68,2.62h-2.74l4-12.8h2.37l4,12.8h-2.76Zm-3-2.15h2.4l-1.2-4.56Z" />
    <line className="cls-8" x1="491.17" y1="325.63" x2="626.51" y2="325.63" />
    <path className="cls-6"
      d="M404.91,232.84s-1.85,3.28-9.57,3.94-11.62,2.79-14.25,7.84a11.91,11.91,0,0,0-.74,7.84l11.07-5.64-3.92,3.1-10.77,8.27,2.25,2.34c1.63-1.47,3.45-2.91,5.1-4.47,3.08,1.33,8.44,2.69,13.73-.67,8.06-5.11,7.1-22.55,7.1-22.55Z" />
    <path className="cls-7" d="M349.58,421.6h-4.32v3.53h5v1.07H344v-9.95h6.26v1.07h-4.95v3.21h4.32Z" />
    <path className="cls-7"
      d="M354.3,418.8l0,.82a2.77,2.77,0,0,1,2.2-.95,2.1,2.1,0,0,1,2.11,1.19,2.74,2.74,0,0,1,1-.86,2.84,2.84,0,0,1,1.4-.33c1.62,0,2.45.86,2.47,2.57v5h-1.26v-4.88a1.73,1.73,0,0,0-.36-1.19,1.62,1.62,0,0,0-1.22-.39,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.13v4.91h-1.27v-4.85a1.41,1.41,0,0,0-1.58-1.61,1.68,1.68,0,0,0-1.7,1.06v5.4H353.1v-7.4Z" />
    <path className="cls-7"
      d="M373,422.58a4.49,4.49,0,0,1-.77,2.73,2.52,2.52,0,0,1-2.1,1,2.7,2.7,0,0,1-2.11-.86V429h-1.27V418.8h1.16l.06.82a2.61,2.61,0,0,1,2.14-.95,2.51,2.51,0,0,1,2.11,1,4.46,4.46,0,0,1,.78,2.8Zm-1.27-.14a3.22,3.22,0,0,0-.53-2,1.69,1.69,0,0,0-1.46-.73,1.85,1.85,0,0,0-1.72,1v3.53a1.84,1.84,0,0,0,1.73,1,1.7,1.7,0,0,0,1.45-.73A3.54,3.54,0,0,0,371.76,422.44Z" />
    <path className="cls-7"
      d="M379.61,419.94a4,4,0,0,0-.62-.05A1.67,1.67,0,0,0,377.3,421v5.25H376v-7.4h1.23l0,.86a2,2,0,0,1,1.76-1,1.36,1.36,0,0,1,.56.09Z" />
    <path className="cls-7"
      d="M385.11,426.34a3.24,3.24,0,0,1-2.45-1,3.7,3.7,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.18,3.18,0,0,1,1.18-1.35,2.91,2.91,0,0,1,1.63-.49,2.76,2.76,0,0,1,2.25.95,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.59,2.59,0,0,0,.64,1.77,2,2,0,0,0,1.55.68,2.24,2.24,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,385.11,426.34Zm-.16-6.63a1.68,1.68,0,0,0-1.28.55,2.72,2.72,0,0,0-.64,1.57h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.57,1.57,0,0,0,385,419.71Z" />
    <path className="cls-7"
      d="M390.56,422.44a4.37,4.37,0,0,1,.8-2.75,2.76,2.76,0,0,1,4.23-.07l.06-.82h1.16V426a3.06,3.06,0,0,1-.85,2.27,3.15,3.15,0,0,1-2.29.82,3.8,3.8,0,0,1-1.57-.34,2.7,2.7,0,0,1-1.17-.94l.66-.75a2.47,2.47,0,0,0,2,1,1.91,1.91,0,0,0,1.44-.52,2,2,0,0,0,.52-1.46v-.64a2.6,2.6,0,0,1-2.08.88,2.53,2.53,0,0,1-2.1-1A4.62,4.62,0,0,1,390.56,422.44Zm1.27.14a3.34,3.34,0,0,0,.51,2,1.65,1.65,0,0,0,1.44.71,1.86,1.86,0,0,0,1.76-1.08V420.8a1.88,1.88,0,0,0-1.75-1.06,1.69,1.69,0,0,0-1.44.72A3.59,3.59,0,0,0,391.83,422.58Z" />
    <path className="cls-7"
      d="M399.78,422.43a4.3,4.3,0,0,1,.43-1.95,3.11,3.11,0,0,1,1.19-1.34,3.41,3.41,0,0,1,4.18.57,4.05,4.05,0,0,1,.93,2.79v.08a4.49,4.49,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.31,3.31,0,0,1-1.77.48,3.11,3.11,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.93,1.93,0,0,0-3.05,0A3.46,3.46,0,0,0,401.05,422.58Z" />
    <path className="cls-7"
      d="M413.81,424.24a.94.94,0,0,0-.39-.8,3.8,3.8,0,0,0-1.35-.49,6,6,0,0,1-1.52-.49,2.18,2.18,0,0,1-.84-.68,1.66,1.66,0,0,1-.27-.95,1.92,1.92,0,0,1,.77-1.53,3.46,3.46,0,0,1,4,0A2.05,2.05,0,0,1,415,421h-1.27a1.16,1.16,0,0,0-.44-.9,1.65,1.65,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.31,2.31,0,0,1,.88.71,1.82,1.82,0,0,1,.28,1,1.89,1.89,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.32,2.46,2.46,0,0,1-1.07-.88,2.08,2.08,0,0,1-.39-1.22h1.27a1.31,1.31,0,0,0,.5,1,2.31,2.31,0,0,0,2.41.08A.89.89,0,0,0,413.81,424.24Z" />
    <path className="cls-7"
      d="M423.61,426.2v-6.42h-1.17v-1h1.17v-.75a2.52,2.52,0,0,1,.63-1.84,2.37,2.37,0,0,1,1.8-.65,3.7,3.7,0,0,1,.87.11l-.07,1a3.74,3.74,0,0,0-.68-.06,1.27,1.27,0,0,0-1,.36,1.47,1.47,0,0,0-.33,1v.78h1.58v1h-1.58v6.42Z" />
    <path className="cls-7"
      d="M428.91,422.43a4.3,4.3,0,0,1,.43-1.95,3.11,3.11,0,0,1,1.19-1.34,3.41,3.41,0,0,1,4.18.57,4.05,4.05,0,0,1,.93,2.79v.08a4.49,4.49,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.31,3.31,0,0,1-1.77.48,3.11,3.11,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.2,3.2,0,0,0-.58-2,1.93,1.93,0,0,0-3.05,0A3.46,3.46,0,0,0,430.18,422.58Z" />
    <path className="cls-7"
      d="M442.2,419.94a4,4,0,0,0-.62-.05,1.67,1.67,0,0,0-1.69,1.06v5.25h-1.26v-7.4h1.23l0,.86a2,2,0,0,1,1.76-1,1.33,1.33,0,0,1,.56.09Z" />
    <path className="cls-7"
      d="M446,418.8l0,.82a2.74,2.74,0,0,1,2.19-.95,2.12,2.12,0,0,1,2.12,1.19,2.58,2.58,0,0,1,1-.86,2.8,2.8,0,0,1,1.39-.33c1.63,0,2.45.86,2.48,2.57v5h-1.27v-4.88a1.73,1.73,0,0,0-.36-1.19,1.61,1.61,0,0,0-1.22-.39,1.67,1.67,0,0,0-1.16.42,1.71,1.71,0,0,0-.54,1.13v4.91H449.3v-4.85a1.41,1.41,0,0,0-1.58-1.61,1.7,1.7,0,0,0-1.7,1.06v5.4h-1.26v-7.4Z" />
    <path className="cls-7"
      d="M463,426.2a2.79,2.79,0,0,1-.18-.78,2.8,2.8,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.43,1.43,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.45.83h-1.28a1.76,1.76,0,0,1,.4-1.07,2.66,2.66,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.92,2.92,0,0,1,2,.64,2.31,2.31,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.26,2.26,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.13,1.13,0,0,0,.4.93A1.47,1.47,0,0,0,460.9,425.24Z" />
    <path className="cls-7"
      d="M467.46,416.84a.74.74,0,0,1,.19-.52.69.69,0,0,1,.55-.21.7.7,0,0,1,.56.21.74.74,0,0,1,.19.52.73.73,0,0,1-.19.52.73.73,0,0,1-.56.2.67.67,0,0,1-.74-.72Zm1.37,9.36h-1.27v-7.4h1.27Z" />
    <path className="cls-7"
      d="M476.56,424.24a.94.94,0,0,0-.39-.8,3.8,3.8,0,0,0-1.35-.49,6,6,0,0,1-1.52-.49,2.18,2.18,0,0,1-.84-.68,1.66,1.66,0,0,1-.27-.95,1.92,1.92,0,0,1,.77-1.53,3.46,3.46,0,0,1,4,0,2.05,2.05,0,0,1,.78,1.66h-1.27a1.16,1.16,0,0,0-.44-.9,1.65,1.65,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.31,2.31,0,0,1,.88.71,1.82,1.82,0,0,1,.28,1,1.89,1.89,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.32,2.46,2.46,0,0,1-1.07-.88,2.08,2.08,0,0,1-.39-1.22h1.27a1.31,1.31,0,0,0,.5,1,2.31,2.31,0,0,0,2.41.08A.89.89,0,0,0,476.56,424.24Z" />
    <path className="cls-7"
      d="M349.45,386h-4.17l-.94,2.6H343l3.8-9.95h1.15l3.8,9.95H350.4ZM345.67,385h3.39l-1.7-4.66Zm2.34-9h1.53l-1.83,2h-1Z" />
    <path className="cls-7"
      d="M357.86,382.38a4,4,0,0,0-.62-.05,1.68,1.68,0,0,0-1.69,1.06v5.25h-1.26v-7.39h1.23l0,.85a2,2,0,0,1,1.76-1,1.19,1.19,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M363.36,388.78a3.2,3.2,0,0,1-2.44-1,3.67,3.67,0,0,1-.95-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.79,2.79,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.54,2.54,0,0,0,.64,1.76,2,2,0,0,0,1.55.68,2.14,2.14,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,363.36,388.78Zm-.15-6.63a1.68,1.68,0,0,0-1.29.56,2.66,2.66,0,0,0-.64,1.56H365v-.1a2.36,2.36,0,0,0-.52-1.49A1.59,1.59,0,0,0,363.21,382.15Z" />
    <path className="cls-7"
      d="M373.68,388.64a2.22,2.22,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.62,2.62,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.41,1.41,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.62,2.62,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.29,2.29,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.21,2.21,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,371.58,387.68Z" />
    <path className="cls-7"
      d="M386.57,382.38a4,4,0,0,0-.63-.05,1.67,1.67,0,0,0-1.68,1.06v5.25H383v-7.39h1.23l0,.85a2,2,0,0,1,1.77-1,1.22,1.22,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M392.07,388.78a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.28,3.28,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.13,2.13,0,0,0,1.13-.28,2.77,2.77,0,0,0,.81-.72l.78.6A3.11,3.11,0,0,1,392.07,388.78Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,391.91,382.15Z" />
    <path className="cls-7" d="M400.17,386.93l1.83-5.68h1.29l-2.65,7.39h-1L397,381.25h1.29Z" />
    <path className="cls-7"
      d="M408.89,388.78a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.28,3.28,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.13,2.13,0,0,0,1.13-.28,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,408.89,388.78Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,408.73,382.15Z" />
    <path className="cls-7"
      d="M414.34,384.88a4.31,4.31,0,0,1,.8-2.75,2.75,2.75,0,0,1,4.23-.06l.06-.82h1.15v7.22a3,3,0,0,1-.85,2.26,3.13,3.13,0,0,1-2.28.83,3.78,3.78,0,0,1-1.57-.35,2.69,2.69,0,0,1-1.17-.93l.66-.76a2.46,2.46,0,0,0,2,1,1.94,1.94,0,0,0,1.44-.52,2,2,0,0,0,.51-1.46v-.64a2.56,2.56,0,0,1-2.07.88,2.52,2.52,0,0,1-2.1-1A4.51,4.51,0,0,1,414.34,384.88Zm1.27.15a3.31,3.31,0,0,0,.51,2,1.66,1.66,0,0,0,1.44.72,1.86,1.86,0,0,0,1.75-1.09v-3.38a1.88,1.88,0,0,0-1.74-1.06,1.66,1.66,0,0,0-1.44.72A3.6,3.6,0,0,0,415.61,385Z" />
    <path className="cls-7"
      d="M427,388.78a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.91,2.91,0,0,1,1.63-.49,2.81,2.81,0,0,1,2.25.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.11,2.11,0,0,0,1.13-.28,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,427,388.78Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,426.8,382.15Z" />
    <path className="cls-7"
      d="M434.42,379.46v1.79h1.38v1h-1.38v4.6a1,1,0,0,0,.19.66.77.77,0,0,0,.63.23,2.89,2.89,0,0,0,.6-.09v1a3.73,3.73,0,0,1-1,.14,1.58,1.58,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.59h-1.35v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M443.25,388.64a2.47,2.47,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.41,1.41,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.62,2.62,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.29,2.29,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,441.15,387.68Z" />
    <path className="cls-7"
      d="M447.39,384.88a4.31,4.31,0,0,1,.81-2.73,2.54,2.54,0,0,1,2.11-1,2.59,2.59,0,0,1,2.06.89v-3.86h1.26v10.5h-1.16l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.53,2.53,0,0,1-2.09-1,4.39,4.39,0,0,1-.81-2.75Zm1.26.15a3.34,3.34,0,0,0,.52,2,1.7,1.7,0,0,0,1.44.71,1.85,1.85,0,0,0,1.76-1.08v-3.4a1.86,1.86,0,0,0-1.75-1.05,1.69,1.69,0,0,0-1.45.72A3.67,3.67,0,0,0,448.65,385Z" />
    <path className="cls-7"
      d="M461.56,388.64a2.79,2.79,0,0,1-.18-.78,2.83,2.83,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.41,1.41,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.83,1.83,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.69,2.69,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.25,2.25,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.51,1.51,0,0,0,459.46,387.68Z" />
    <path className="cls-2" d="M346.52,321.34h-2.58V308.55h2.58Z" />
    <path className="cls-2"
      d="M353.87,308.55l2.42,9.25,2.4-9.25h3.38v12.79h-2.59v-3.46l.23-5.34-2.55,8.8H355.4l-2.56-8.8.24,5.34v3.46h-2.59V308.55Z" />
    <path className="cls-2"
      d="M368.53,316.84v4.5h-2.59V308.55h4.36a4,4,0,0,1,3,1.17,4.27,4.27,0,0,1,1.13,3.06,3.92,3.92,0,0,1-1.12,3,4.21,4.21,0,0,1-3.09,1.09Zm0-2.15h1.77a1.43,1.43,0,0,0,1.15-.48,2.23,2.23,0,0,0,.4-1.41,2.59,2.59,0,0,0-.41-1.53,1.36,1.36,0,0,0-1.11-.57h-1.8Z" />
    <path className="cls-2" d="M382.53,318.72H379l-.68,2.62h-2.74l4-12.79H382l4,12.79h-2.76Zm-3-2.15H382L380.76,312Z" />
    <path className="cls-2"
      d="M397.46,317.08a4.83,4.83,0,0,1-1.23,3.31,4.37,4.37,0,0,1-3.2,1.13,4,4,0,0,1-3.33-1.43,6.32,6.32,0,0,1-1.15-4.07v-2.16a6.14,6.14,0,0,1,1.19-4.06,4.72,4.72,0,0,1,6.5-.26,5.29,5.29,0,0,1,1.23,3.36h-2.59a3.35,3.35,0,0,0-.42-1.87,1.65,1.65,0,0,0-1.4-.52,1.56,1.56,0,0,0-1.46.73,5.06,5.06,0,0,0-.46,2.39V316a5.65,5.65,0,0,0,.42,2.62,1.57,1.57,0,0,0,1.47.71,1.67,1.67,0,0,0,1.4-.49,3.21,3.21,0,0,0,.44-1.8Z" />
    <path className="cls-2" d="M408.88,310.7h-3.16v10.64h-2.6V310.7H400v-2.15h8.87Z" />
    <path className="cls-2"
      d="M420.93,316.1a6,6,0,0,1-1.22,4,4.21,4.21,0,0,1-3.38,1.43,4.26,4.26,0,0,1-3.38-1.41,5.88,5.88,0,0,1-1.25-3.95V314a6.32,6.32,0,0,1,1.22-4.12,4.63,4.63,0,0,1,6.76,0,6.22,6.22,0,0,1,1.25,4.09ZM418.34,314a5.2,5.2,0,0,0-.5-2.57,1.62,1.62,0,0,0-1.53-.85,1.65,1.65,0,0,0-1.52.82,5.15,5.15,0,0,0-.51,2.47v2.28a4.69,4.69,0,0,0,.51,2.47,1.7,1.7,0,0,0,1.54.8,1.66,1.66,0,0,0,1.51-.78,4.83,4.83,0,0,0,.5-2.42Z" />
    <path className="cls-2" d="M349.93,340.32h-3.52l-.69,2.62H343l4-12.79h2.36l4,12.79h-2.76Zm-3-2.15h2.39l-1.19-4.56Z" />
    <path className="cls-2"
      d="M359.75,330.15l2.42,9.25,2.4-9.25H368v12.79h-2.59v-3.46l.23-5.34-2.55,8.8h-1.76l-2.56-8.8.24,5.34v3.46h-2.59V330.15Z" />
    <path className="cls-2"
      d="M371.82,342.94V330.15h4a4.52,4.52,0,0,1,3,.89,3.22,3.22,0,0,1,1.05,2.61,3.14,3.14,0,0,1-.44,1.68,2.54,2.54,0,0,1-1.17,1,2.2,2.2,0,0,1,1.32,1,3.36,3.36,0,0,1,.47,1.83,3.65,3.65,0,0,1-1,2.81,4.21,4.21,0,0,1-3,.95Zm2.59-7.44h1.34a1.37,1.37,0,0,0,1.52-1.57,1.78,1.78,0,0,0-.35-1.25,1.49,1.49,0,0,0-1.14-.38h-1.37Zm0,1.88v3.42h1.65a1.31,1.31,0,0,0,1.05-.43,1.87,1.87,0,0,0,.36-1.22c0-1.15-.41-1.74-1.24-1.77Z" />
    <path className="cls-2" d="M386.42,342.94h-2.58V330.15h2.58Z" />
    <path className="cls-2" d="M397,337.41h-4v3.39h4.77v2.14h-7.35V330.15h7.33v2.15H393v3h4Z" />
    <path className="cls-2" d="M410,342.94h-2.58l-3.78-8.39v8.39H401V330.15h2.59l3.79,8.4v-8.4H410Z" />
    <path className="cls-2" d="M421.88,332.3h-3.17v10.64h-2.59V332.3H413v-2.15h8.87Z" />
    <path className="cls-2" d="M430.22,340.32h-3.53l-.68,2.62h-2.74l4-12.79h2.37l4,12.79H430.9Zm-3-2.15h2.39l-1.2-4.56Z" />
    <path className="cls-2" d="M439.24,340.8h4.54v2.14h-7.12V330.15h2.58Z" />
    <path className="cls-2" d="M350.43,359h-4v3.39h4.76v2.14h-7.35V351.75h7.33v2.15h-4.74v3h4Z" />
    <path className="cls-2"
      d="M365.81,361.19a1.6,1.6,0,0,0-.4-1.19,4.85,4.85,0,0,0-1.45-.83,6.59,6.59,0,0,1-2.77-1.71,3.43,3.43,0,0,1-.85-2.31,3.26,3.26,0,0,1,1.15-2.6,4.77,4.77,0,0,1,5-.48,3.48,3.48,0,0,1,1.42,1.4,4.21,4.21,0,0,1,.49,2.06h-2.57a2.09,2.09,0,0,0-.39-1.37,1.31,1.31,0,0,0-1.1-.47,1.36,1.36,0,0,0-1.05.4,1.45,1.45,0,0,0-.38,1.07,1.34,1.34,0,0,0,.42,1,4.86,4.86,0,0,0,1.49.88,6.4,6.4,0,0,1,2.72,1.66,3.69,3.69,0,0,1,.85,2.51,3.31,3.31,0,0,1-1.06,2.61,4.24,4.24,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.89,3.89,0,0,1-1.6-1.46,4.26,4.26,0,0,1-.57-2.24h2.59a2.44,2.44,0,0,0,.43,1.61,1.79,1.79,0,0,0,1.41.5C365.36,362.62,365.81,362.14,365.81,361.19Z" />
    <path className="cls-2"
      d="M380.94,359.3a6,6,0,0,1-1.22,4,4.21,4.21,0,0,1-3.38,1.43,4.28,4.28,0,0,1-3.39-1.41,5.88,5.88,0,0,1-1.24-3.95v-2.19a6.27,6.27,0,0,1,1.22-4.12,4.63,4.63,0,0,1,6.76,0,6.28,6.28,0,0,1,1.25,4.09Zm-2.6-2.15a5.22,5.22,0,0,0-.49-2.57,1.81,1.81,0,0,0-3.05,0,4.92,4.92,0,0,0-.51,2.47v2.28a4.81,4.81,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.62,4.62,0,0,0,.5-2.42Z" />
    <path className="cls-2"
      d="M393.27,360.28a4.79,4.79,0,0,1-1.24,3.31,4.33,4.33,0,0,1-3.19,1.13,4,4,0,0,1-3.33-1.43,6.32,6.32,0,0,1-1.16-4.07v-2.16a6.15,6.15,0,0,1,1.2-4.06,4.72,4.72,0,0,1,6.5-.26,5.34,5.34,0,0,1,1.23,3.36h-2.59a3.35,3.35,0,0,0-.42-1.87,1.65,1.65,0,0,0-1.4-.52,1.59,1.59,0,0,0-1.47.73,5.08,5.08,0,0,0-.45,2.39v2.41a5.65,5.65,0,0,0,.42,2.62,1.56,1.56,0,0,0,1.47.71,1.68,1.68,0,0,0,1.4-.49,3.21,3.21,0,0,0,.44-1.8Z" />
    <path className="cls-2" d="M399.35,364.54h-2.58V351.75h2.58Z" />
    <path className="cls-2" d="M409.59,361.92h-3.52l-.69,2.62h-2.73l4-12.79H409l4,12.79h-2.76Zm-3-2.15H409l-1.2-4.56Z" />
    <path className="cls-2" d="M418.62,362.4h4.53v2.14H416V351.75h2.59Z" />
    <line className="cls-8" x1="342.8" y1="402" x2="463.04" y2="402" />
    <path className="cls-6"
      d="M218.5,68l-7.15,2v0a2.78,2.78,0,0,1-.3,2.12,2.81,2.81,0,0,1-1.78,1.41,3,3,0,0,1-2.27-.26L202.87,71l.37-.7,4.13,2.3a2.1,2.1,0,0,0,1.64.19,2.13,2.13,0,0,0,1.3-1,2.32,2.32,0,0,0,.26-.86,1.71,1.71,0,0,0-.86-1.78l-10-5.59a3.09,3.09,0,0,0-2.45-.29h0c-.26,0-11,3.12-11.46,3.23h0L188,74.24l7.18-2c.75.45,6.22,3.84,6.89,4.25a4.07,4.07,0,0,0,3,.22s14.66-4.17,14.66-4.17a2.45,2.45,0,0,0,1.45-3A2.19,2.19,0,0,0,218.5,68Z" />
    <path className="cls-6"
      d="M219.13,55.22h-1.3a7.48,7.48,0,0,0-.9-2.16l1-1a.42.42,0,0,0,.11-.3.42.42,0,0,0-.11-.29L216.52,50a.39.39,0,0,0-.55,0l-1.08,1.07a7.32,7.32,0,0,0-1.94-.81V48.86a.41.41,0,0,0-.41-.41h-2a.42.42,0,0,0-.41.41V50.2a6.17,6.17,0,0,0-2,.82L207,49.86a.43.43,0,0,0-.56,0L205,51.32a.42.42,0,0,0-.11.29.44.44,0,0,0,.11.3L206.07,53a6.2,6.2,0,0,0-.93,2.19h-1.31a.42.42,0,0,0-.41.41v2a.41.41,0,0,0,.41.41h1.31a5.92,5.92,0,0,0,.82,2l-1.05,1a.42.42,0,0,0-.11.29.42.42,0,0,0,.11.3l1.45,1.45a.42.42,0,0,0,.3.12.41.41,0,0,0,.3-.12l1-1a6.51,6.51,0,0,0,2.23.93v1.15a.41.41,0,0,0,.41.41h2a.41.41,0,0,0,.41-.41V63a6.57,6.57,0,0,0,2.12-.89L216,63a.42.42,0,0,0,.3.12.41.41,0,0,0,.3-.12L218,61.55a.41.41,0,0,0,.12-.3A.42.42,0,0,0,218,61l-.89-.89a7,7,0,0,0,.82-2h1.3a.41.41,0,0,0,.41-.41v-2c-.15-.22-.3-.41-.52-.41Zm-7.67,5.4a4,4,0,1,1,4-4A4,4,0,0,1,211.46,60.62Z" />
    <path className="cls-7" d="M17.56,154H13.39l-.93,2.6H11.1l3.8-10h1.15l3.81,10H18.51Zm-3.77-1.07h3.38l-1.69-4.66Z" />
    <path className="cls-7"
      d="M24,147.4v1.79h1.38v1H24v4.6a1,1,0,0,0,.19.66.76.76,0,0,0,.62.23,2.88,2.88,0,0,0,.61-.09v1a3.79,3.79,0,0,1-1,.14,1.57,1.57,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.59H21.39v-1h1.35V147.4Z" />
    <path className="cls-7"
      d="M31.33,156.72a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.28,3.28,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.13,2.13,0,0,0,1.13-.28,2.77,2.77,0,0,0,.81-.72l.78.6A3.11,3.11,0,0,1,31.33,156.72Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.66,2.66,0,0,0-.65,1.56H33v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,31.17,150.09Z" />
    <path className="cls-7"
      d="M38.27,149.19l0,.93a2.72,2.72,0,0,1,2.22-1.07c1.56,0,2.35.88,2.36,2.65v4.88H41.63v-4.89a1.72,1.72,0,0,0-.37-1.18,1.45,1.45,0,0,0-1.12-.39,1.84,1.84,0,0,0-1.08.33,2.32,2.32,0,0,0-.72.86v5.27H37.08v-7.39Z" />
    <path className="cls-7"
      d="M45.89,152.82a4.31,4.31,0,0,1,.81-2.73,2.74,2.74,0,0,1,4.17-.15v-3.86h1.26v10.5H51l-.06-.79a2.58,2.58,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1.05,4.39,4.39,0,0,1-.81-2.75Zm1.27.15a3.27,3.27,0,0,0,.52,2,1.67,1.67,0,0,0,1.43.71,1.85,1.85,0,0,0,1.76-1.08v-3.4a1.86,1.86,0,0,0-1.74-1,1.68,1.68,0,0,0-1.45.72A3.6,3.6,0,0,0,47.16,153Z" />
    <path className="cls-7"
      d="M55.5,147.22a.72.72,0,0,1,.19-.51.69.69,0,0,1,.56-.22.7.7,0,0,1,.56.22.72.72,0,0,1,.19.51.73.73,0,0,1-.19.52.77.77,0,0,1-.56.2.75.75,0,0,1-.56-.2A.73.73,0,0,1,55.5,147.22Zm1.37,9.36H55.6v-7.39h1.27Z" />
    <path className="cls-7"
      d="M61.48,149.19l0,.82a2.72,2.72,0,0,1,2.19-1,2.11,2.11,0,0,1,2.11,1.19,2.76,2.76,0,0,1,2.36-1.19c1.62,0,2.45.86,2.48,2.58v5H69.39V151.7a1.68,1.68,0,0,0-.36-1.18,1.57,1.57,0,0,0-1.22-.4,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.13v4.91H64.83v-4.84a1.41,1.41,0,0,0-1.58-1.62,1.7,1.7,0,0,0-1.7,1.06v5.4H60.29v-7.39Z" />
    <path className="cls-7"
      d="M77,156.72a3.2,3.2,0,0,1-2.44-1,3.67,3.67,0,0,1-1-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.24,3.24,0,0,1,1.18-1.35,2.92,2.92,0,0,1,1.64-.49,2.79,2.79,0,0,1,2.24.95,4.12,4.12,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.55.68,2.14,2.14,0,0,0,1.14-.28,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,77,156.72Zm-.15-6.63a1.68,1.68,0,0,0-1.29.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.59,1.59,0,0,0,76.88,150.09Z" />
    <path className="cls-7"
      d="M84,149.19l0,.93a2.69,2.69,0,0,1,2.21-1.07c1.57,0,2.35.88,2.37,2.65v4.88H87.33v-4.89a1.67,1.67,0,0,0-.36-1.18,1.45,1.45,0,0,0-1.12-.39,1.82,1.82,0,0,0-1.08.33,2.23,2.23,0,0,0-.72.86v5.27H82.78v-7.39Z" />
    <path className="cls-7"
      d="M93.62,147.4v1.79H95v1H93.62v4.6a1,1,0,0,0,.19.66.76.76,0,0,0,.62.23,2.88,2.88,0,0,0,.61-.09v1a3.79,3.79,0,0,1-1,.14,1.57,1.57,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.59H91v-1h1.35V147.4Z" />
    <path className="cls-7"
      d="M97.41,152.82a4.44,4.44,0,0,1,.42-2,3.14,3.14,0,0,1,2.93-1.81,3.12,3.12,0,0,1,2.45,1,4,4,0,0,1,.93,2.78V153a4.45,4.45,0,0,1-.41,1.93,3.11,3.11,0,0,1-1.19,1.34,3.25,3.25,0,0,1-1.76.48,3.1,3.1,0,0,1-2.44-1.05,4,4,0,0,1-.93-2.76Zm1.27.15a3.14,3.14,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.44,3.44,0,0,0,.57-2.11,3.23,3.23,0,0,0-.58-2,1.93,1.93,0,0,0-3,0A3.47,3.47,0,0,0,98.68,153Z" />
    <path className="cls-7"
      d="M13.06,166l0,.82a2.75,2.75,0,0,1,2.2-1A2.12,2.12,0,0,1,17.4,167a2.75,2.75,0,0,1,1-.86,2.81,2.81,0,0,1,1.4-.33c1.62,0,2.44.86,2.47,2.58v4.95H21V168.5a1.64,1.64,0,0,0-.37-1.18,1.55,1.55,0,0,0-1.21-.4,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.14v4.9H16.41v-4.84a1.42,1.42,0,0,0-1.58-1.62,1.69,1.69,0,0,0-1.7,1.06v5.4H11.86V166Z" />
    <path className="cls-7"
      d="M28.61,173.52a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.23a4.45,4.45,0,0,1,.42-2,3.28,3.28,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24,1,4.07,4.07,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.76,2,2,0,0,0,1.56.68,2.13,2.13,0,0,0,1.13-.28,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,28.61,173.52Zm-.16-6.63a1.64,1.64,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,28.45,166.89Z" />
    <path className="cls-7"
      d="M37.32,172.49a1.87,1.87,0,0,0,1.19-.41,1.5,1.5,0,0,0,.56-1h1.19a2.24,2.24,0,0,1-.44,1.21,2.84,2.84,0,0,1-1.07.92,3.1,3.1,0,0,1-1.43.34,3.07,3.07,0,0,1-2.4-1,4,4,0,0,1-.89-2.76v-.21a4.51,4.51,0,0,1,.39-1.92,3.11,3.11,0,0,1,1.14-1.31,3.34,3.34,0,0,1,1.76-.46,2.94,2.94,0,0,1,2.06.75,2.62,2.62,0,0,1,.88,1.93H39.07a1.75,1.75,0,0,0-.55-1.18,1.67,1.67,0,0,0-1.2-.46,1.79,1.79,0,0,0-1.5.69,3.3,3.3,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.53,2A1.77,1.77,0,0,0,37.32,172.49Z" />
    <path className="cls-7"
      d="M47.65,173.38a2.79,2.79,0,0,1-.18-.78,2.81,2.81,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.44,1.44,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.46.83H43a1.7,1.7,0,0,1,.4-1.07,2.59,2.59,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.94,2.94,0,0,1,2,.64,2.32,2.32,0,0,1,.75,1.76v3.4a4,4,0,0,0,.26,1.62v.11Zm.52-8.63v.07H47.12l-1-1.16-1,1.16H44v-.08l1.69-1.86h.76Zm-2.62,7.67a2.19,2.19,0,0,0,1.13-.31,1.8,1.8,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,45.55,172.42Z" />
    <path className="cls-7"
      d="M53.3,166l0,.93a2.69,2.69,0,0,1,2.21-1.07c1.56,0,2.35.88,2.37,2.65v4.88H56.65v-4.89a1.67,1.67,0,0,0-.36-1.18,1.45,1.45,0,0,0-1.12-.39,1.82,1.82,0,0,0-1.08.33,2.35,2.35,0,0,0-.73.86v5.27H52.1V166Z" />
    <path className="cls-7"
      d="M61.23,164a.72.72,0,0,1,.19-.51.68.68,0,0,1,.56-.22.7.7,0,0,1,.56.22.72.72,0,0,1-.56,1.23.73.73,0,0,1-.56-.2A.73.73,0,0,1,61.23,164Zm1.37,9.36H61.33V166H62.6Z" />
    <path className="cls-7"
      d="M69,172.49a1.84,1.84,0,0,0,1.18-.41,1.47,1.47,0,0,0,.56-1h1.2a2.32,2.32,0,0,1-.44,1.21,3,3,0,0,1-1.08.92,3.28,3.28,0,0,1-3.82-.67,4,4,0,0,1-.9-2.76v-.21a4.51,4.51,0,0,1,.4-1.92,3,3,0,0,1,1.14-1.31,3.31,3.31,0,0,1,1.75-.46,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.79,1.79,0,0,0-.54-1.18,1.69,1.69,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.3,3.3,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.52,2A1.8,1.8,0,0,0,69,172.49Z" />
    <path className="cls-7"
      d="M74.41,169.62a4.44,4.44,0,0,1,.43-2A3.24,3.24,0,0,1,76,166.32a3.29,3.29,0,0,1,1.74-.47,3.13,3.13,0,0,1,2.45,1.05,4,4,0,0,1,.93,2.78v.09a4.38,4.38,0,0,1-.42,1.94A3.11,3.11,0,0,1,79.55,173a3.26,3.26,0,0,1-1.77.48,3.09,3.09,0,0,1-2.43-1.05,4,4,0,0,1-.94-2.76Zm1.28.15a3.14,3.14,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.51,3.51,0,0,0,.56-2.11,3.23,3.23,0,0,0-.57-2,1.85,1.85,0,0,0-1.54-.75,1.83,1.83,0,0,0-1.51.74A3.47,3.47,0,0,0,75.69,169.77Z" />
    <path className="cls-7" d="M17.56,99.84H13.39l-.93,2.61H11.1l3.8-10h1.15l3.81,10H18.51Zm-3.77-1.07h3.38l-1.69-4.66Z" />
    <path className="cls-7"
      d="M24,93.26v1.79h1.38v1H24v4.59a1,1,0,0,0,.19.67.75.75,0,0,0,.62.22,3.51,3.51,0,0,0,.61-.08v1a3.76,3.76,0,0,1-1,.13,1.57,1.57,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46V96H21.39v-1h1.35V93.26Z" />
    <path className="cls-7"
      d="M31.33,102.58a3.27,3.27,0,0,1-2.45-1A3.67,3.67,0,0,1,27.94,99v-.23a4.41,4.41,0,0,1,.42-2,3.31,3.31,0,0,1,1.17-1.36,3,3,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24,1,4.09,4.09,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.22,2.22,0,0,0,1.13-.27,3,3,0,0,0,.82-.73l.77.6A3.09,3.09,0,0,1,31.33,102.58ZM31.17,96a1.67,1.67,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56H33V98a2.45,2.45,0,0,0-.52-1.5A1.6,1.6,0,0,0,31.17,96Z" />
    <path className="cls-7"
      d="M38.27,95.05l0,.93a2.72,2.72,0,0,1,2.22-1.07c1.56,0,2.35.88,2.36,2.65v4.89H41.63v-4.9a1.72,1.72,0,0,0-.37-1.18A1.44,1.44,0,0,0,40.15,96a1.88,1.88,0,0,0-1.08.32,2.22,2.22,0,0,0-.73.87v5.27H37.08v-7.4Z" />
    <path className="cls-7"
      d="M45.89,98.69A4.32,4.32,0,0,1,46.7,96a2.74,2.74,0,0,1,4.17-.15V92h1.26v10.5H51l-.06-.8a2.58,2.58,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1,4.39,4.39,0,0,1-.81-2.75Zm1.27.14a3.27,3.27,0,0,0,.52,2,1.67,1.67,0,0,0,1.43.71,1.84,1.84,0,0,0,1.76-1.08V97a1.85,1.85,0,0,0-1.74-1,1.7,1.7,0,0,0-1.45.71A3.6,3.6,0,0,0,47.16,98.83Z" />
    <path className="cls-7"
      d="M55.5,93.09a.77.77,0,0,1,.19-.52.72.72,0,0,1,.56-.21.69.69,0,0,1,.75.73.72.72,0,0,1-.19.51.73.73,0,0,1-.56.21.72.72,0,0,1-.56-.21A.76.76,0,0,1,55.5,93.09Zm1.37,9.36H55.61v-7.4h1.26Z" />
    <path className="cls-7"
      d="M61.48,95.05l0,.82a2.72,2.72,0,0,1,2.19-1,2.11,2.11,0,0,1,2.11,1.19,3,3,0,0,1,1-.86,2.84,2.84,0,0,1,1.4-.33c1.63,0,2.45.86,2.48,2.58v5H69.39V97.57A1.71,1.71,0,0,0,69,96.38,1.61,1.61,0,0,0,67.81,96a1.64,1.64,0,0,0-1.71,1.55v4.91H64.83V97.6A1.4,1.4,0,0,0,63.25,96a1.69,1.69,0,0,0-1.7,1.06v5.4H60.29v-7.4Z" />
    <path className="cls-7"
      d="M77,102.58a3.25,3.25,0,0,1-2.44-1,3.68,3.68,0,0,1-1-2.65v-.23a4.41,4.41,0,0,1,.42-2,3.34,3.34,0,0,1,1.18-1.36,3,3,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24,1,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.8,2.8,0,0,0,.81-.73l.77.6A3.09,3.09,0,0,1,77,102.58ZM76.88,96a1.71,1.71,0,0,0-1.29.56A2.66,2.66,0,0,0,75,98.07h3.7V98a2.39,2.39,0,0,0-.52-1.5A1.59,1.59,0,0,0,76.88,96Z" />
    <path className="cls-7"
      d="M84,95.05,84,96a2.69,2.69,0,0,1,2.21-1.07c1.57,0,2.36.88,2.37,2.65v4.89H87.33v-4.9A1.67,1.67,0,0,0,87,96.37,1.45,1.45,0,0,0,85.85,96a1.88,1.88,0,0,0-1.08.32,2.19,2.19,0,0,0-.72.87v5.27H82.78v-7.4Z" />
    <path className="cls-7"
      d="M93.62,93.26v1.79H95v1H93.62v4.59a1,1,0,0,0,.19.67.75.75,0,0,0,.62.22,3.51,3.51,0,0,0,.61-.08v1a3.76,3.76,0,0,1-1,.13,1.57,1.57,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46V96H91v-1h1.35V93.26Z" />
    <path className="cls-7"
      d="M97.41,98.68a4.47,4.47,0,0,1,.42-2A3.37,3.37,0,0,1,99,95.38a3.29,3.29,0,0,1,1.74-.47,3.12,3.12,0,0,1,2.45,1,4,4,0,0,1,.93,2.78v.09a4.49,4.49,0,0,1-.41,1.94,3.08,3.08,0,0,1-1.19,1.33,3.25,3.25,0,0,1-1.76.48,3.12,3.12,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.94,1.94,0,0,0,3.06,0,3.47,3.47,0,0,0,.57-2.12,3.18,3.18,0,0,0-.58-2,1.92,1.92,0,0,0-3,0A3.42,3.42,0,0,0,98.68,98.83Z" />
    <path className="cls-7"
      d="M18.12,115.63a4.45,4.45,0,0,1-.77,2.72,2.49,2.49,0,0,1-2.1,1,2.71,2.71,0,0,1-2.12-.85v3.56H11.87V111.85H13l.07.82a2.59,2.59,0,0,1,2.14-1,2.53,2.53,0,0,1,2.11,1,4.45,4.45,0,0,1,.78,2.79Zm-1.27-.14a3.23,3.23,0,0,0-.53-2,1.72,1.72,0,0,0-1.46-.72,1.88,1.88,0,0,0-1.73,1v3.54a1.88,1.88,0,0,0,1.74,1,1.72,1.72,0,0,0,1.45-.72A3.63,3.63,0,0,0,16.85,115.49Z" />
    <path className="cls-7"
      d="M24.7,113a4,4,0,0,0-.62,0A1.66,1.66,0,0,0,22.39,114v5.25H21.12v-7.4h1.23l0,.85a2,2,0,0,1,1.77-1,1.37,1.37,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M30.2,119.38a3.27,3.27,0,0,1-2.45-1,3.67,3.67,0,0,1-.94-2.65v-.23a4.41,4.41,0,0,1,.42-2,3.26,3.26,0,0,1,1.18-1.36,3,3,0,0,1,1.63-.49,2.81,2.81,0,0,1,2.25,1,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.69,2.69,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.2,2.2,0,0,0,1.13-.27,3,3,0,0,0,.82-.73l.77.6A3.09,3.09,0,0,1,30.2,119.38ZM30,112.75a1.67,1.67,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.09a2.45,2.45,0,0,0-.52-1.5A1.6,1.6,0,0,0,30,112.75Zm.62-4h1.53l-1.83,2h-1Z" />
    <path className="cls-7" d="M38.58,115.53H35.24v-1h3.34Z" />
    <path className="cls-7"
      d="M42.47,112.75a2.72,2.72,0,0,1,2.19-1c1.57,0,2.35.88,2.37,2.65v4.89H45.76v-4.9a1.67,1.67,0,0,0-.36-1.18,1.45,1.45,0,0,0-1.12-.38,1.88,1.88,0,0,0-1.08.32,2.3,2.3,0,0,0-.73.87v5.27H41.21v-10.5h1.26Z" />
    <path className="cls-7"
      d="M50,115.48a4.47,4.47,0,0,1,.42-2,3.37,3.37,0,0,1,1.19-1.34,3.29,3.29,0,0,1,1.74-.47,3.13,3.13,0,0,1,2.45,1.05,4,4,0,0,1,.93,2.78v.09a4.49,4.49,0,0,1-.41,1.94,3.08,3.08,0,0,1-1.19,1.33,3.25,3.25,0,0,1-1.76.48,3.12,3.12,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.94,1.94,0,0,0,3.06,0,3.47,3.47,0,0,0,.57-2.12,3.18,3.18,0,0,0-.58-2,1.92,1.92,0,0,0-3.05,0A3.44,3.44,0,0,0,51.26,115.63Z" />
    <path className="cls-7"
      d="M64,117.28a.94.94,0,0,0-.38-.79,3.8,3.8,0,0,0-1.35-.49,6.46,6.46,0,0,1-1.52-.49,2.11,2.11,0,0,1-.84-.69,1.62,1.62,0,0,1-.27-.94,1.93,1.93,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.14,3.14,0,0,1,2,.65,2.08,2.08,0,0,1,.78,1.66H63.94a1.15,1.15,0,0,0-.44-.89,1.68,1.68,0,0,0-1.11-.38,1.71,1.71,0,0,0-1.08.3,1,1,0,0,0-.39.79.78.78,0,0,0,.36.69,4.6,4.6,0,0,0,1.31.44,7,7,0,0,1,1.53.51,2.26,2.26,0,0,1,.87.71,1.72,1.72,0,0,1,.29,1,1.87,1.87,0,0,1-.8,1.58,3.28,3.28,0,0,1-2.05.6,3.74,3.74,0,0,1-1.58-.31,2.59,2.59,0,0,1-1.07-.88A2.17,2.17,0,0,1,59.4,117h1.26a1.33,1.33,0,0,0,.51,1,2,2,0,0,0,1.26.37,2.08,2.08,0,0,0,1.15-.29A.88.88,0,0,0,64,117.28Z" />
    <path className="cls-7"
      d="M74.57,115.63a4.45,4.45,0,0,1-.77,2.72,2.47,2.47,0,0,1-2.09,1,2.7,2.7,0,0,1-2.12-.85v3.56H68.32V111.85h1.16l.06.82a2.61,2.61,0,0,1,2.15-1,2.53,2.53,0,0,1,2.11,1,4.51,4.51,0,0,1,.77,2.79Zm-1.26-.14a3.23,3.23,0,0,0-.54-2,1.71,1.71,0,0,0-1.46-.72,1.87,1.87,0,0,0-1.72,1v3.54a1.88,1.88,0,0,0,1.74,1,1.71,1.71,0,0,0,1.44-.72A3.55,3.55,0,0,0,73.31,115.49Z" />
    <path className="cls-7"
      d="M77.59,109.89a.77.77,0,0,1,.18-.52.73.73,0,0,1,.56-.21.72.72,0,0,1,.56.21.73.73,0,0,1,.19.52.69.69,0,0,1-.75.72.73.73,0,0,1-.56-.21A.76.76,0,0,1,77.59,109.89ZM79,119.25H77.69v-7.4H79Z" />
    <path className="cls-7"
      d="M84.09,110.06v1.79h1.38v1H84.09v4.59a1,1,0,0,0,.19.67.76.76,0,0,0,.63.22,3.54,3.54,0,0,0,.6-.08v1a3.7,3.7,0,0,1-1,.13,1.58,1.58,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.58H81.48v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M92.92,119.25a2.34,2.34,0,0,1-.18-.78,2.82,2.82,0,0,1-2.11.91,2.57,2.57,0,0,1-1.79-.62,2,2,0,0,1-.7-1.56,2.1,2.1,0,0,1,.88-1.8,4.17,4.17,0,0,1,2.47-.64h1.23v-.58a1.42,1.42,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.87,1.87,0,0,0-1.13.34,1,1,0,0,0-.46.83H88.29a1.74,1.74,0,0,1,.39-1.07,2.71,2.71,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.34,2.34,0,0,1,.75,1.76v3.41a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1A2.21,2.21,0,0,0,92,118a1.85,1.85,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.33.45-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.53,1.53,0,0,0,90.82,118.28Z" />
    <path className="cls-7" d="M98.74,119.25H97.47v-10.5h1.27Z" />
    <path className="cls-7"
      d="M106.73,119.25a3,3,0,0,1-.18-.78,2.82,2.82,0,0,1-2.1.91,2.59,2.59,0,0,1-1.8-.62,2,2,0,0,1-.7-1.56,2.1,2.1,0,0,1,.88-1.8,4.17,4.17,0,0,1,2.47-.64h1.23v-.58a1.42,1.42,0,0,0-.4-1.06,1.58,1.58,0,0,0-1.16-.39,1.9,1.9,0,0,0-1.14.34,1,1,0,0,0-.46.83H102.1a1.74,1.74,0,0,1,.39-1.07,2.78,2.78,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.31,2.31,0,0,1,.76,1.76v3.41a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.32.45-2.32,1.36a1.18,1.18,0,0,0,.39.93A1.56,1.56,0,0,0,104.63,118.28Z" />
    <path className="cls-7"
      d="M114.75,113a4,4,0,0,0-.62,0,1.67,1.67,0,0,0-1.69,1.06v5.25h-1.26v-7.4h1.23l0,.85a2,2,0,0,1,1.76-1,1.3,1.3,0,0,1,.56.1Z" />
    <path className="cls-2" d="M18.05,54.33H14.52L13.84,57H11.1l4-12.8h2.37l4,12.8H18.73Zm-3-2.16h2.39l-1.2-4.56Z" />
    <path className="cls-2" d="M31.72,46.3H28.56V57H26V46.3H22.85V44.15h8.87Z" />
    <path className="cls-2" d="M41.62,51.41h-4V54.8h4.77V57H35V44.15h7.33V46.3H37.59v3h4Z" />
    <path className="cls-2" d="M54.59,57H52l-3.78-8.4V57H45.64V44.15h2.58L52,52.55v-8.4h2.58Z" />
    <path className="cls-2"
      d="M58.46,57V44.15h3.39a4.66,4.66,0,0,1,3.57,1.42,5.59,5.59,0,0,1,1.36,3.91v2.07a5.64,5.64,0,0,1-1.33,4A4.77,4.77,0,0,1,61.78,57ZM61.05,46.3v8.5h.77a2.15,2.15,0,0,0,1.82-.68,4,4,0,0,0,.55-2.35V49.55a4.48,4.48,0,0,0-.5-2.5A2,2,0,0,0,62,46.3Z" />
    <path className="cls-2" d="M73.13,57H70.55V44.15h2.58Z" />
    <path className="cls-2"
      d="M80.47,44.15l2.42,9.26,2.41-9.26h3.38V57h-2.6V53.48l.24-5.33L83.76,57H82l-2.55-8.8.23,5.33V57H77.1V44.15Z" />
    <path className="cls-2" d="M99.16,51.41h-4V54.8H99.9V57H92.55V44.15h7.33V46.3H95.13v3h4Z" />
    <path className="cls-2" d="M112.13,57h-2.58l-3.78-8.4V57h-2.59V44.15h2.59l3.79,8.4v-8.4h2.57Z" />
    <path className="cls-2" d="M124,46.3h-3.17V57h-2.59V46.3h-3.11V44.15H124Z" />
    <path className="cls-2"
      d="M136.09,51.7a6,6,0,0,1-1.22,4,4.2,4.2,0,0,1-3.38,1.42,4.28,4.28,0,0,1-3.39-1.41,5.86,5.86,0,0,1-1.24-4V49.57a6.24,6.24,0,0,1,1.22-4.11A4.14,4.14,0,0,1,131.47,44a4.19,4.19,0,0,1,3.37,1.46,6.25,6.25,0,0,1,1.25,4.08Zm-2.6-2.14A5.24,5.24,0,0,0,133,47,1.82,1.82,0,0,0,130,47a5,5,0,0,0-.51,2.47V51.7a4.78,4.78,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.58,4.58,0,0,0,.5-2.41Z" />
    <path className="cls-2"
      d="M145.14,53.59a1.61,1.61,0,0,0-.39-1.18,4.52,4.52,0,0,0-1.46-.83,6.86,6.86,0,0,1-2.77-1.71,3.47,3.47,0,0,1-.84-2.32,3.25,3.25,0,0,1,1.15-2.6,4.32,4.32,0,0,1,2.91-1,4.4,4.4,0,0,1,2.1.5,3.48,3.48,0,0,1,1.42,1.4,4.24,4.24,0,0,1,.5,2.06h-2.58a2.17,2.17,0,0,0-.38-1.37,1.36,1.36,0,0,0-1.11-.47,1.39,1.39,0,0,0-1.05.4,1.53,1.53,0,0,0-.38,1.08,1.32,1.32,0,0,0,.42,1,4.92,4.92,0,0,0,1.5.89,6.37,6.37,0,0,1,2.72,1.66,3.71,3.71,0,0,1,.85,2.5,3.28,3.28,0,0,1-1.07,2.61,4.19,4.19,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.74,3.74,0,0,1-1.59-1.46,4.18,4.18,0,0,1-.58-2.24H142a2.42,2.42,0,0,0,.44,1.61,1.72,1.72,0,0,0,1.4.5C144.69,55,145.14,54.54,145.14,53.59Z" />
    <path className="cls-2" d="M18.05,75.93H14.52l-.68,2.62H11.1l4-12.8h2.37l4,12.8H18.73Zm-3-2.16h2.39l-1.2-4.56Z" />
    <path className="cls-2"
      d="M33.31,73.3a6,6,0,0,1-1.21,4,4.22,4.22,0,0,1-3.38,1.42,4.26,4.26,0,0,1-3.39-1.41,5.91,5.91,0,0,1-1.25-4V71.17a6.24,6.24,0,0,1,1.23-4.11,4.14,4.14,0,0,1,3.39-1.49A4.17,4.17,0,0,1,32.06,67a6.14,6.14,0,0,1,1.25,4.08Zm-2.59-2.14a5.24,5.24,0,0,0-.49-2.58,1.82,1.82,0,0,0-3.05,0A5,5,0,0,0,26.67,71V73.3a4.78,4.78,0,0,0,.5,2.47,1.7,1.7,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.68,4.68,0,0,0,.5-2.41Z" />
    <path className="cls-2"
      d="M42.37,75.19A1.58,1.58,0,0,0,42,74a4.34,4.34,0,0,0-1.45-.83,6.86,6.86,0,0,1-2.77-1.71,3.47,3.47,0,0,1-.84-2.32,3.27,3.27,0,0,1,1.14-2.6,4.77,4.77,0,0,1,5-.48,3.48,3.48,0,0,1,1.42,1.4A4.24,4.24,0,0,1,45,69.53H42.41A2.17,2.17,0,0,0,42,68.16a1.37,1.37,0,0,0-1.11-.47,1.39,1.39,0,0,0-1.05.4,1.53,1.53,0,0,0-.38,1.08,1.32,1.32,0,0,0,.42,1,4.8,4.8,0,0,0,1.5.89,6.43,6.43,0,0,1,2.72,1.66,3.71,3.71,0,0,1,.84,2.5,3.28,3.28,0,0,1-1.06,2.61,4.19,4.19,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.74,3.74,0,0,1-1.59-1.46,4.18,4.18,0,0,1-.58-2.24h2.59a2.48,2.48,0,0,0,.43,1.61,1.76,1.76,0,0,0,1.41.5C41.92,76.62,42.37,76.14,42.37,75.19Z" />
    <path className="cls-2"
      d="M62.76,65.75v8.86a4.12,4.12,0,0,1-1.1,3,4.22,4.22,0,0,1-3.1,1.06,4.25,4.25,0,0,1-3.14-1.07,4.14,4.14,0,0,1-1.09-3.1v-8.8h2.6v8.81a2.66,2.66,0,0,0,.35,1.55,1.52,1.52,0,0,0,1.28.47,1.49,1.49,0,0,0,1.26-.46,2.67,2.67,0,0,0,.36-1.52V65.75Z" />
    <path className="cls-2"
      d="M71.93,75.19a1.62,1.62,0,0,0-.4-1.18,4.52,4.52,0,0,0-1.46-.83,6.86,6.86,0,0,1-2.77-1.71,3.47,3.47,0,0,1-.84-2.32,3.25,3.25,0,0,1,1.15-2.6,4.32,4.32,0,0,1,2.91-1,4.4,4.4,0,0,1,2.1.5A3.48,3.48,0,0,1,74,67.47a4.24,4.24,0,0,1,.5,2.06H72a2.17,2.17,0,0,0-.38-1.37,1.36,1.36,0,0,0-1.11-.47,1.39,1.39,0,0,0-1,.4A1.53,1.53,0,0,0,69,69.17a1.32,1.32,0,0,0,.42,1A4.92,4.92,0,0,0,71,71a6.37,6.37,0,0,1,2.72,1.66,3.71,3.71,0,0,1,.85,2.5,3.28,3.28,0,0,1-1.07,2.61,4.19,4.19,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.74,3.74,0,0,1-1.59-1.46,4.18,4.18,0,0,1-.58-2.24h2.59a2.42,2.42,0,0,0,.44,1.61,1.72,1.72,0,0,0,1.4.5C71.47,76.62,71.93,76.14,71.93,75.19Z" />
    <path className="cls-2"
      d="M86.36,65.75v8.86a4.11,4.11,0,0,1-1.09,3,5.12,5.12,0,0,1-6.25,0,4.18,4.18,0,0,1-1.09-3.1v-8.8h2.61v8.81a2.59,2.59,0,0,0,.35,1.55,2,2,0,0,0,2.54,0,2.68,2.68,0,0,0,.35-1.52V65.75Z" />
    <path className="cls-2"
      d="M96,75.93H92.52l-.69,2.62H89.1l4-12.8h2.36l4,12.8H96.72ZM94.8,62.32h2.8l-2.45,2.73H93.08ZM93.08,73.77h2.39l-1.2-4.56Z" />
    <path className="cls-2"
      d="M106.36,73.87h-1.29v4.68h-2.58V65.75h4.12a4.17,4.17,0,0,1,3,1,4.38,4.38,0,0,1-.79,6.43l2.24,5.23v.13h-2.78Zm-1.29-2.15h1.47a1.34,1.34,0,0,0,1.16-.52,2.21,2.21,0,0,0,.39-1.37c0-1.28-.5-1.93-1.51-1.93h-1.51Z" />
    <path className="cls-2" d="M116.94,78.55h-2.58V65.75h2.58Z" />
    <path className="cls-2"
      d="M129.92,73.3a6,6,0,0,1-1.21,4,4.2,4.2,0,0,1-3.38,1.42,4.26,4.26,0,0,1-3.39-1.41,5.86,5.86,0,0,1-1.24-4V71.17a6.24,6.24,0,0,1,1.22-4.11,4.14,4.14,0,0,1,3.39-1.49A4.19,4.19,0,0,1,128.68,67a6.19,6.19,0,0,1,1.24,4.08Zm-2.59-2.14a5.24,5.24,0,0,0-.49-2.58,1.82,1.82,0,0,0-3,0,5,5,0,0,0-.51,2.47V73.3a4.78,4.78,0,0,0,.5,2.47,1.7,1.7,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.58,4.58,0,0,0,.5-2.41Z" />
    <path className="cls-2"
      d="M139,75.19a1.58,1.58,0,0,0-.4-1.18,4.34,4.34,0,0,0-1.45-.83,6.86,6.86,0,0,1-2.77-1.71,3.47,3.47,0,0,1-.84-2.32,3.27,3.27,0,0,1,1.14-2.6,4.77,4.77,0,0,1,5-.48,3.48,3.48,0,0,1,1.42,1.4,4.24,4.24,0,0,1,.49,2.06H139a2.17,2.17,0,0,0-.38-1.37,1.37,1.37,0,0,0-1.11-.47,1.39,1.39,0,0,0-1.05.4,1.53,1.53,0,0,0-.38,1.08,1.32,1.32,0,0,0,.42,1A4.8,4.8,0,0,0,138,71a6.37,6.37,0,0,1,2.72,1.66,3.71,3.71,0,0,1,.85,2.5,3.28,3.28,0,0,1-1.07,2.61,4.19,4.19,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.74,3.74,0,0,1-1.59-1.46,4.18,4.18,0,0,1-.58-2.24h2.59a2.48,2.48,0,0,0,.43,1.61,1.76,1.76,0,0,0,1.41.5C138.53,76.62,139,76.14,139,75.19Z" />
    <line className="cls-8" x1="10.91" y1="133.05" x2="116.07" y2="133.05" />
    <path className="cls-6"
      d="M575.83,48.45a10.72,10.72,0,1,0,10.72,10.72A10.73,10.73,0,0,0,575.83,48.45Zm-.06,4.92a.74.74,0,0,1,.69.38l1.53,3,3.37.47a.74.74,0,0,1,.42,1.26l-2.42,2.39.6,3.34a.74.74,0,0,1-1.07.79l-3-1.56-3,1.6a.74.74,0,0,1-1.08-.77l.56-3.35-2.46-2.36a.74.74,0,0,1,.4-1.27l3.37-.5,1.49-3.06a.7.7,0,0,1,.63-.39Z" />
    <path className="cls-6"
      d="M570.48,70.11v8.7a.74.74,0,0,0,1.17.6l4.18-2.94L580,79.41a.75.75,0,0,0,1.17-.6v-8.7a12,12,0,0,1-10.7,0Z" />
    <path className="cls-7" d="M636.28,145.55h1.54l-1.84,2h-1Zm.05,12.71H635v-10h1.32Z" />
    <path className="cls-7"
      d="M641.12,150.86l0,.93a2.72,2.72,0,0,1,2.22-1.07c1.56,0,2.35.88,2.36,2.65v4.89h-1.26v-4.9a1.72,1.72,0,0,0-.37-1.18,1.44,1.44,0,0,0-1.11-.38,1.81,1.81,0,0,0-1.08.32,2.22,2.22,0,0,0-.73.87v5.27h-1.26v-7.4Z" />
    <path className="cls-7"
      d="M648.74,154.5a4.32,4.32,0,0,1,.81-2.74,2.74,2.74,0,0,1,4.17-.15v-3.85H655v10.5h-1.16l-.06-.8a2.57,2.57,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1,4.39,4.39,0,0,1-.81-2.75Zm1.27.14a3.27,3.27,0,0,0,.52,2,1.67,1.67,0,0,0,1.43.71,1.84,1.84,0,0,0,1.76-1.08v-3.4a1.85,1.85,0,0,0-1.74-1,1.67,1.67,0,0,0-1.45.71A3.6,3.6,0,0,0,650,154.64Z" />
    <path className="cls-7"
      d="M658.35,148.9a.77.77,0,0,1,.19-.52.85.85,0,0,1,1.12,0,.73.73,0,0,1,.19.52.79.79,0,0,1-1.31.51A.76.76,0,0,1,658.35,148.9Zm1.37,9.36h-1.26v-7.4h1.26Z" />
    <path className="cls-7"
      d="M666.11,157.36a1.82,1.82,0,0,0,1.18-.41,1.41,1.41,0,0,0,.56-1h1.2a2.24,2.24,0,0,1-.44,1.21,2.83,2.83,0,0,1-1.07.92,3.1,3.1,0,0,1-1.43.34,3,3,0,0,1-2.4-1,4,4,0,0,1-.89-2.75v-.22a4.51,4.51,0,0,1,.39-1.92,3.08,3.08,0,0,1,1.14-1.3,3.25,3.25,0,0,1,1.75-.47,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.7,1.7,0,0,0-.54-1.18,1.72,1.72,0,0,0-1.21-.46,1.75,1.75,0,0,0-1.49.7,3.26,3.26,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.53,2A1.77,1.77,0,0,0,666.11,157.36Z" />
    <path className="cls-7"
      d="M674.94,158.39a3.24,3.24,0,0,1-2.45-1,3.67,3.67,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2,3.3,3.3,0,0,1,1.18-1.35,3,3,0,0,1,1.63-.49,2.79,2.79,0,0,1,2.25.95,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.63,2.63,0,0,0,.63,1.77,2,2,0,0,0,1.56.67,2.2,2.2,0,0,0,1.13-.27,3,3,0,0,0,.82-.73l.77.6A3.09,3.09,0,0,1,674.94,158.39Zm-.16-6.63a1.69,1.69,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.6,1.6,0,0,0,674.78,151.76Z" />
    <path className="cls-7"
      d="M685.25,154.5a4.37,4.37,0,0,1,.8-2.74,2.74,2.74,0,0,1,4.17-.15v-3.85h1.27v10.5h-1.16l-.06-.8a2.59,2.59,0,0,1-2.12.93,2.53,2.53,0,0,1-2.09-1,4.39,4.39,0,0,1-.81-2.75Zm1.26.14a3.34,3.34,0,0,0,.52,2,1.7,1.7,0,0,0,1.44.71,1.84,1.84,0,0,0,1.75-1.08v-3.4a1.84,1.84,0,0,0-1.74-1,1.68,1.68,0,0,0-1.45.71A3.67,3.67,0,0,0,686.51,154.64Z" />
    <path className="cls-7"
      d="M697.92,158.39a3.24,3.24,0,0,1-2.45-1,3.63,3.63,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2,3.3,3.3,0,0,1,1.18-1.35,3,3,0,0,1,1.63-.49,2.81,2.81,0,0,1,2.25.95,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.69,2.69,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.2,2.2,0,0,0,1.13-.27,3.15,3.15,0,0,0,.82-.73l.77.6A3.09,3.09,0,0,1,697.92,158.39Zm-.16-6.63a1.67,1.67,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,697.76,151.76Z" />
    <path className="cls-7"
      d="M638.29,168.79a4,4,0,0,0-.62,0,1.67,1.67,0,0,0-1.69,1.06v5.25h-1.26v-7.4H636l0,.85a2,2,0,0,1,1.76-1,1.17,1.17,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M643.8,175.19a3.22,3.22,0,0,1-2.45-1,3.63,3.63,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2A3.35,3.35,0,0,1,642,168a3,3,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,3,3,0,0,0,.81-.73l.77.6A3.07,3.07,0,0,1,643.8,175.19Zm-.16-6.63a1.71,1.71,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,643.64,168.56Z" />
    <path className="cls-7"
      d="M652.51,174.16a1.82,1.82,0,0,0,1.18-.41,1.41,1.41,0,0,0,.56-1h1.2a2.24,2.24,0,0,1-.44,1.21,2.9,2.9,0,0,1-1.07.92,3.1,3.1,0,0,1-1.43.34,3,3,0,0,1-2.4-1,4,4,0,0,1-.89-2.75v-.22a4.51,4.51,0,0,1,.39-1.92,3.08,3.08,0,0,1,1.14-1.3,3.22,3.22,0,0,1,1.75-.47,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.7,1.7,0,0,0-.54-1.18,1.88,1.88,0,0,0-2.7.24,3.26,3.26,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.53,2A1.77,1.77,0,0,0,652.51,174.16Z" />
    <path className="cls-7" d="M659.65,175.06h-1.27v-10.5h1.27Z" />
    <path className="cls-7"
      d="M667.64,175.06a2.34,2.34,0,0,1-.18-.78,2.82,2.82,0,0,1-2.11.91,2.57,2.57,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.08,2.08,0,0,1,.88-1.79,4.08,4.08,0,0,1,2.47-.64h1.23V170a1.42,1.42,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83H663a1.74,1.74,0,0,1,.39-1.07,2.71,2.71,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.32,2.32,0,0,1,.75,1.76v3.41A4.21,4.21,0,0,0,669,175v.11Zm-2.1-1a2.15,2.15,0,0,0,1.12-.31,1.82,1.82,0,0,0,.78-.8v-1.51h-1c-1.55,0-2.33.45-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.53,1.53,0,0,0,665.54,174.09Z" />
    <path className="cls-7"
      d="M673.27,167.66l0,.82a2.72,2.72,0,0,1,2.19-1,2.11,2.11,0,0,1,2.11,1.19,2.88,2.88,0,0,1,1-.86,2.8,2.8,0,0,1,1.39-.33c1.63,0,2.45.86,2.48,2.58v5h-1.27v-4.89a1.7,1.7,0,0,0-.36-1.18,1.61,1.61,0,0,0-1.22-.39,1.7,1.7,0,0,0-1.17.42,1.73,1.73,0,0,0-.54,1.13v4.91h-1.27v-4.85A1.4,1.4,0,0,0,675,168.6a1.69,1.69,0,0,0-1.7,1.06v5.4h-1.26v-7.4Z" />
    <path className="cls-7"
      d="M690.32,175.06a3,3,0,0,1-.18-.78,2.8,2.8,0,0,1-2.1.91,2.59,2.59,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.1,2.1,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23V170a1.46,1.46,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.46.83h-1.27a1.7,1.7,0,0,1,.4-1.07,2.68,2.68,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.32,2.32,0,0,1,.75,1.76v3.41a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.16,2.16,0,0,0,1.13-.31,1.8,1.8,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.32.45-2.32,1.36a1.18,1.18,0,0,0,.39.93A1.56,1.56,0,0,0,688.22,174.09Z" />
    <path className="cls-7"
      d="M697.74,174.16a1.84,1.84,0,0,0,1.18-.41,1.45,1.45,0,0,0,.56-1h1.2a2.32,2.32,0,0,1-.44,1.21,2.86,2.86,0,0,1-1.08.92,3.29,3.29,0,0,1-3.83-.67,4,4,0,0,1-.89-2.75v-.22a4.37,4.37,0,0,1,.4-1.92A3,3,0,0,1,696,168a3.22,3.22,0,0,1,1.75-.47,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.79,1.79,0,0,0-.54-1.18,1.88,1.88,0,0,0-2.7.24,3.2,3.2,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.52,2A1.78,1.78,0,0,0,697.74,174.16Zm.2,1-.08.35a1.12,1.12,0,0,1,1,1.19,1.21,1.21,0,0,1-.55,1,2.6,2.6,0,0,1-1.53.39l0-.73a1.57,1.57,0,0,0,.83-.18.55.55,0,0,0,.3-.5.47.47,0,0,0-.22-.44,2.06,2.06,0,0,0-.89-.21l.22-.91Z" />
    <path className="cls-7"
      d="M703.16,171.29a4.44,4.44,0,0,1,.43-2,3.14,3.14,0,0,1,2.93-1.81,3.1,3.1,0,0,1,2.44,1,4,4,0,0,1,.94,2.78v.09a4.35,4.35,0,0,1-.42,1.94,3.11,3.11,0,0,1-1.18,1.33,3.26,3.26,0,0,1-1.77.48,3.12,3.12,0,0,1-2.43-1,4,4,0,0,1-.94-2.77Zm5.74-6.52a1.69,1.69,0,0,1-.42,1.2,1.35,1.35,0,0,1-1.05.46,1.45,1.45,0,0,1-.49-.07,3.12,3.12,0,0,1-.53-.28,2.4,2.4,0,0,0-.48-.26,1.18,1.18,0,0,0-.34,0,.63.63,0,0,0-.48.21.81.81,0,0,0-.2.55l-.84,0a1.76,1.76,0,0,1,.41-1.21,1.32,1.32,0,0,1,1-.48,1.18,1.18,0,0,1,.43.07,2.74,2.74,0,0,1,.52.26,2.69,2.69,0,0,0,.5.26,1.23,1.23,0,0,0,.39.06.61.61,0,0,0,.49-.23.79.79,0,0,0,.19-.54Zm-4.47,6.67a3.14,3.14,0,0,0,.58,2,1.82,1.82,0,0,0,1.52.75,1.78,1.78,0,0,0,1.53-.76,3.42,3.42,0,0,0,.57-2.11,3.12,3.12,0,0,0-.58-2,1.91,1.91,0,0,0-3,0A3.42,3.42,0,0,0,704.43,171.44Z" />
    <path className="cls-7"
      d="M716,175.19a3.24,3.24,0,0,1-2.45-1,3.67,3.67,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2,3.3,3.3,0,0,1,1.18-1.35,3,3,0,0,1,1.63-.49,2.79,2.79,0,0,1,2.25.95,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.59,2.59,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,3,3,0,0,0,.81-.73l.77.6A3.09,3.09,0,0,1,716,175.19Zm-.16-6.63a1.69,1.69,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.1a2.36,2.36,0,0,0-.52-1.49A1.6,1.6,0,0,0,715.79,168.56Z" />
    <path className="cls-7"
      d="M726,173.09a.92.92,0,0,0-.39-.79,3.8,3.8,0,0,0-1.35-.49,6.46,6.46,0,0,1-1.52-.49,2.28,2.28,0,0,1-.84-.69,1.62,1.62,0,0,1-.27-.94,1.93,1.93,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.1,3.1,0,0,1,2,.65,2.07,2.07,0,0,1,.78,1.66h-1.27a1.15,1.15,0,0,0-.44-.89,1.64,1.64,0,0,0-1.11-.38,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.79.78.78,0,0,0,.36.69,4.6,4.6,0,0,0,1.31.44,6.75,6.75,0,0,1,1.54.51,2.26,2.26,0,0,1,.87.71,1.71,1.71,0,0,1,.28,1,1.87,1.87,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.31,2.52,2.52,0,0,1-1.07-.88,2.11,2.11,0,0,1-.39-1.22h1.27a1.3,1.3,0,0,0,.51,1,1.94,1.94,0,0,0,1.25.37,2.06,2.06,0,0,0,1.15-.29A.89.89,0,0,0,726,173.09Z" />
    <path className="cls-2"
      d="M643.79,51.07c0,2.12-.57,3.65-1.71,4.61l1.76,1.59-1.62,1.49-2.36-2.15-.68,0a4.18,4.18,0,0,1-3.39-1.47,6.22,6.22,0,0,1-1.24-4.07v-2A6.32,6.32,0,0,1,635.77,45a4.64,4.64,0,0,1,6.75,0,6.1,6.1,0,0,1,1.27,4Zm-2.59-2a5.22,5.22,0,0,0-.5-2.58,1.67,1.67,0,0,0-1.53-.84,1.65,1.65,0,0,0-1.52.82,4.92,4.92,0,0,0-.51,2.47v2.12a5.21,5.21,0,0,0,.5,2.59,1.68,1.68,0,0,0,1.54.84,1.64,1.64,0,0,0,1.51-.83,5.2,5.2,0,0,0,.51-2.54Z" />
    <path className="cls-2"
      d="M655.78,43.68v8.86a4.12,4.12,0,0,1-1.1,3.05,4.24,4.24,0,0,1-3.1,1.06,4.32,4.32,0,0,1-3.15-1.07,4.19,4.19,0,0,1-1.08-3.1v-8.8H650v8.81A2.66,2.66,0,0,0,650.3,54a1.52,1.52,0,0,0,1.28.47,1.51,1.51,0,0,0,1.26-.46,2.6,2.6,0,0,0,.35-1.52V43.68Z" />
    <path className="cls-2" d="M665.45,53.86h-3.52l-.69,2.62h-2.73l4-12.8h2.36l4,12.8h-2.76Zm-3-2.16h2.39l-1.19-4.56Z" />
    <path className="cls-2" d="M674.48,54.33H679v2.15h-7.12V43.68h2.59Z" />
    <path className="cls-2" d="M685,56.48h-2.58V43.68H685Z" />
    <path className="cls-2"
      d="M689,56.48V43.68h3.39a4.66,4.66,0,0,1,3.57,1.42,5.58,5.58,0,0,1,1.36,3.9v2.08A5.6,5.6,0,0,1,696,55a4.75,4.75,0,0,1-3.67,1.44Zm2.59-10.65v8.5h.77a2.18,2.18,0,0,0,1.82-.68,4.07,4.07,0,0,0,.56-2.35V49.07a4.51,4.51,0,0,0-.51-2.5,2,2,0,0,0-1.7-.74Z" />
    <path className="cls-2" d="M706.87,53.86h-3.53l-.68,2.62h-2.74l4-12.8h2.37l4,12.8h-2.76Zm-3-2.16h2.39l-1.2-4.56Z" />
    <path className="cls-2"
      d="M713.31,56.48V43.68h3.38a4.66,4.66,0,0,1,3.58,1.42,5.57,5.57,0,0,1,1.35,3.9v2.08a5.6,5.6,0,0,1-1.33,4,4.75,4.75,0,0,1-3.67,1.44Zm2.58-10.65v8.5h.78a2.19,2.19,0,0,0,1.82-.68A4.08,4.08,0,0,0,719,51.3V49.07a4.61,4.61,0,0,0-.5-2.5,2.05,2.05,0,0,0-1.71-.74Z" />
    <path className="cls-2" d="M731.89,50.94h-4v3.39h4.76v2.15h-7.34V43.68h7.33v2.15h-4.75v3h4Z" />
    <path className="cls-2"
      d="M634.79,78.08V65.28h3.38a4.66,4.66,0,0,1,3.58,1.42,5.57,5.57,0,0,1,1.35,3.9v2.08a5.6,5.6,0,0,1-1.33,4,4.74,4.74,0,0,1-3.67,1.44Zm2.58-10.65v8.5h.78a2.18,2.18,0,0,0,1.82-.68,4.08,4.08,0,0,0,.55-2.35V70.67a4.61,4.61,0,0,0-.5-2.5,2,2,0,0,0-1.71-.74Z" />
    <path className="cls-2"
      d="M655.77,72.83a6,6,0,0,1-1.22,4,4.2,4.2,0,0,1-3.38,1.42,4.26,4.26,0,0,1-3.38-1.41,5.88,5.88,0,0,1-1.25-4V70.7a6.32,6.32,0,0,1,1.22-4.12,4.62,4.62,0,0,1,6.76,0,6.19,6.19,0,0,1,1.25,4.08Zm-2.59-2.15a5.2,5.2,0,0,0-.5-2.57,1.65,1.65,0,0,0-1.53-.85,1.67,1.67,0,0,0-1.52.82,5.15,5.15,0,0,0-.51,2.47v2.28a4.78,4.78,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.63,1.63,0,0,0,1.5-.78,4.67,4.67,0,0,0,.51-2.41Z" />
    <path className="cls-2"
      d="M664.83,74.72a1.62,1.62,0,0,0-.4-1.18,4.41,4.41,0,0,0-1.46-.83A6.94,6.94,0,0,1,660.2,71a3.47,3.47,0,0,1-.84-2.32,3.25,3.25,0,0,1,1.15-2.6,4.75,4.75,0,0,1,5-.48,3.42,3.42,0,0,1,1.42,1.4,4.22,4.22,0,0,1,.5,2.06h-2.58a2.09,2.09,0,0,0-.38-1.37,1.34,1.34,0,0,0-1.1-.47,1.39,1.39,0,0,0-1.06.4A1.52,1.52,0,0,0,662,68.7a1.32,1.32,0,0,0,.42,1,4.67,4.67,0,0,0,1.49.88,6.41,6.41,0,0,1,2.72,1.67,3.66,3.66,0,0,1,.85,2.5,3.31,3.31,0,0,1-1.06,2.61,4.22,4.22,0,0,1-2.9.94,5,5,0,0,1-2.25-.51,3.77,3.77,0,0,1-1.6-1.46A4.27,4.27,0,0,1,659,74h2.6a2.42,2.42,0,0,0,.43,1.61,1.77,1.77,0,0,0,1.4.5C664.38,76.15,664.83,75.67,664.83,74.72Z" />
    <path className="cls-2"
      d="M682.3,74.72a1.62,1.62,0,0,0-.4-1.18,4.41,4.41,0,0,0-1.46-.83A6.94,6.94,0,0,1,677.67,71a3.47,3.47,0,0,1-.84-2.32,3.25,3.25,0,0,1,1.15-2.6,4.75,4.75,0,0,1,5-.48,3.42,3.42,0,0,1,1.42,1.4,4.22,4.22,0,0,1,.5,2.06h-2.58a2.15,2.15,0,0,0-.38-1.37,1.34,1.34,0,0,0-1.1-.47,1.38,1.38,0,0,0-1.06.4,1.53,1.53,0,0,0-.38,1.08,1.33,1.33,0,0,0,.43,1,4.67,4.67,0,0,0,1.49.88,6.41,6.41,0,0,1,2.72,1.67,3.66,3.66,0,0,1,.85,2.5,3.34,3.34,0,0,1-1.06,2.61,4.22,4.22,0,0,1-2.9.94,5,5,0,0,1-2.26-.51,3.8,3.8,0,0,1-1.59-1.46,4.27,4.27,0,0,1-.58-2.24h2.6a2.42,2.42,0,0,0,.43,1.61,1.75,1.75,0,0,0,1.4.5C681.85,76.15,682.3,75.67,682.3,74.72Z" />
    <path className="cls-2" d="M695,72.54h-4v3.39h4.76v2.15h-7.35V65.28h7.33v2.15H691v3h4Z" />
    <path className="cls-2"
      d="M702.92,73.4h-1.28v4.68h-2.59V65.28h4.13a4.2,4.2,0,0,1,3,1,3.81,3.81,0,0,1,1.06,2.87,3.72,3.72,0,0,1-1.86,3.56L707.62,78v.13h-2.77Zm-1.28-2.15h1.47a1.38,1.38,0,0,0,1.16-.52,2.27,2.27,0,0,0,.38-1.37c0-1.29-.5-1.93-1.5-1.93h-1.51Z" />
    <path className="cls-2" d="M714.81,74.56,717,65.28h2.89l-3.69,12.8h-2.69l-3.67-12.8h2.87Z" />
    <path className="cls-2" d="M725.55,78.08H723V65.28h2.58Z" />
    <path className="cls-2"
      d="M738.2,73.81A4.85,4.85,0,0,1,737,77.13a4.36,4.36,0,0,1-3.2,1.12,4,4,0,0,1-3.33-1.43,6.32,6.32,0,0,1-1.16-4.07V70.6a6.16,6.16,0,0,1,1.2-4.07,4.72,4.72,0,0,1,6.5-.26,5.34,5.34,0,0,1,1.23,3.36h-2.59a3.37,3.37,0,0,0-.42-1.87,1.64,1.64,0,0,0-1.4-.51,1.58,1.58,0,0,0-1.47.72,5.11,5.11,0,0,0-.45,2.39v2.42a5.6,5.6,0,0,0,.42,2.61,1.57,1.57,0,0,0,1.47.72,1.68,1.68,0,0,0,1.4-.5,3.21,3.21,0,0,0,.44-1.8ZM734.7,78l-.08.51a1.31,1.31,0,0,1,.79.51,1.75,1.75,0,0,1,.27,1,1.86,1.86,0,0,1-.69,1.53,3.08,3.08,0,0,1-2,.56l0-1.46c.43,0,.64-.24.64-.72a.55.55,0,0,0-.22-.5,1.91,1.91,0,0,0-.76-.18l.23-1.26Z" />
    <path className="cls-2"
      d="M750.6,72.83a6,6,0,0,1-1.22,4A4.2,4.2,0,0,1,746,78.25a4.26,4.26,0,0,1-3.39-1.41,5.88,5.88,0,0,1-1.24-4V70.7a6.27,6.27,0,0,1,1.22-4.12,4.62,4.62,0,0,1,6.76,0,6.25,6.25,0,0,1,1.25,4.08ZM748,70.68a5.22,5.22,0,0,0-.49-2.57,1.81,1.81,0,0,0-3,0,4.92,4.92,0,0,0-.51,2.47v2.28a4.78,4.78,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.58,4.58,0,0,0,.5-2.41Z" />
    <path className="cls-2"
      d="M759.65,74.72a1.58,1.58,0,0,0-.4-1.18,4.34,4.34,0,0,0-1.45-.83A6.86,6.86,0,0,1,755,71a3.47,3.47,0,0,1-.84-2.32,3.27,3.27,0,0,1,1.14-2.6,4.77,4.77,0,0,1,5-.48,3.48,3.48,0,0,1,1.42,1.4,4.22,4.22,0,0,1,.5,2.06h-2.58a2.15,2.15,0,0,0-.38-1.37,1.37,1.37,0,0,0-1.11-.47,1.36,1.36,0,0,0-1.05.4,1.53,1.53,0,0,0-.38,1.08,1.32,1.32,0,0,0,.42,1,4.62,4.62,0,0,0,1.5.88,6.47,6.47,0,0,1,2.72,1.67,3.71,3.71,0,0,1,.85,2.5,3.31,3.31,0,0,1-1.07,2.61,4.19,4.19,0,0,1-2.89.94,5,5,0,0,1-2.26-.51,3.74,3.74,0,0,1-1.59-1.46,4.18,4.18,0,0,1-.58-2.24h2.59a2.48,2.48,0,0,0,.43,1.61,1.79,1.79,0,0,0,1.41.5C759.2,76.15,759.65,75.67,759.65,74.72Z" />
    <path className="cls-7" d="M638.21,100.52l2.85-8.21h1.43l-3.69,10h-1.16l-3.69-10h1.43Z" />
    <path className="cls-7"
      d="M647.79,102.4a3.22,3.22,0,0,1-2.44-1,3.67,3.67,0,0,1-1-2.64v-.24a4.41,4.41,0,0,1,.42-2A3.34,3.34,0,0,1,646,95.21a3,3,0,0,1,1.64-.48,2.74,2.74,0,0,1,2.24,1,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.77,2.77,0,0,0,.81-.72l.77.6A3.11,3.11,0,0,1,647.79,102.4Zm-.15-6.64a1.71,1.71,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.7v-.09a2.41,2.41,0,0,0-.51-1.5A1.6,1.6,0,0,0,647.64,95.76Z" />
    <path className="cls-7" d="M654.92,102.26h-1.27V91.76h1.27Z" />
    <path className="cls-7"
      d="M658,98.49a4.43,4.43,0,0,1,.42-1.95,3.14,3.14,0,0,1,2.93-1.81,3.13,3.13,0,0,1,2.45,1,4,4,0,0,1,.93,2.78v.09a4.49,4.49,0,0,1-.41,1.94,3.16,3.16,0,0,1-1.19,1.34,3.25,3.25,0,0,1-1.76.48,3.1,3.1,0,0,1-2.44-1.05,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.94,1.94,0,0,0,3.06,0,3.47,3.47,0,0,0,.56-2.12,3.18,3.18,0,0,0-.57-2,1.86,1.86,0,0,0-1.54-.76,1.81,1.81,0,0,0-1.51.75A3.46,3.46,0,0,0,659.28,98.64Z" />
    <path className="cls-7"
      d="M670.69,101.36a1.77,1.77,0,0,0,1.18-.41,1.39,1.39,0,0,0,.56-1h1.2a2.3,2.3,0,0,1-.44,1.21,2.8,2.8,0,0,1-1.07.91,3.11,3.11,0,0,1-1.43.35,3,3,0,0,1-2.4-1,4,4,0,0,1-.89-2.76v-.21a4.51,4.51,0,0,1,.39-1.92,3.11,3.11,0,0,1,1.14-1.31,3.24,3.24,0,0,1,1.75-.46,3,3,0,0,1,2.07.74,2.68,2.68,0,0,1,.88,1.94h-1.2a1.73,1.73,0,0,0-1.75-1.65,1.77,1.77,0,0,0-1.49.7,3.26,3.26,0,0,0-.53,2v.24a3.19,3.19,0,0,0,.53,2A1.77,1.77,0,0,0,670.69,101.36Z" />
    <path className="cls-7"
      d="M676.46,92.9a.74.74,0,0,1,.19-.52.69.69,0,0,1,.55-.21.74.74,0,0,1,.57.21.79.79,0,0,1,.19.52.73.73,0,0,1-.19.51.74.74,0,0,1-.57.21.69.69,0,0,1-.55-.21A.69.69,0,0,1,676.46,92.9Zm1.37,9.36h-1.27v-7.4h1.27Z" />
    <path className="cls-7"
      d="M680.94,98.5a4.32,4.32,0,0,1,.81-2.74,2.76,2.76,0,0,1,4.17-.15V91.76h1.26v10.5H686l-.06-.79a2.58,2.58,0,0,1-2.11.93,2.54,2.54,0,0,1-2.1-1.06,4.37,4.37,0,0,1-.81-2.75Zm1.27.14a3.25,3.25,0,0,0,.52,2,1.65,1.65,0,0,0,1.43.71,1.85,1.85,0,0,0,1.76-1.08v-3.4a1.87,1.87,0,0,0-1.74-1,1.68,1.68,0,0,0-1.45.72A3.57,3.57,0,0,0,682.21,98.64Z" />
    <path className="cls-7"
      d="M695.11,102.26a3,3,0,0,1-.18-.78,2.81,2.81,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.12,2.12,0,0,1,.88-1.8,4.19,4.19,0,0,1,2.47-.64h1.23v-.58a1.42,1.42,0,0,0-.39-1,1.62,1.62,0,0,0-1.17-.4,1.86,1.86,0,0,0-1.14.35,1,1,0,0,0-.45.82h-1.28a1.72,1.72,0,0,1,.4-1.07,2.66,2.66,0,0,1,1.06-.81,3.63,3.63,0,0,1,1.48-.3,3,3,0,0,1,2,.63,2.34,2.34,0,0,1,.75,1.76v3.41a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.25,2.25,0,0,0,1.13-.3,1.85,1.85,0,0,0,.77-.8V98.67h-1c-1.55,0-2.32.45-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.56,1.56,0,0,0,693,101.29Z" />
    <path className="cls-7"
      d="M699.25,98.5a4.32,4.32,0,0,1,.81-2.74,2.76,2.76,0,0,1,4.17-.15V91.76h1.26v10.5h-1.16l-.06-.79a2.73,2.73,0,0,1-4.21-.13,4.37,4.37,0,0,1-.81-2.75Zm1.27.14a3.25,3.25,0,0,0,.52,2,1.65,1.65,0,0,0,1.43.71,1.84,1.84,0,0,0,1.76-1.08v-3.4a1.87,1.87,0,0,0-1.74-1,1.68,1.68,0,0,0-1.45.72A3.57,3.57,0,0,0,700.52,98.64Z" />
    <path className="cls-7"
      d="M711.93,102.4a3.22,3.22,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.24a4.41,4.41,0,0,1,.42-2,3.31,3.31,0,0,1,1.17-1.36,3,3,0,0,1,1.64-.48,2.74,2.74,0,0,1,2.24,1,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.77,2.77,0,0,0,.81-.72l.77.6A3.09,3.09,0,0,1,711.93,102.4Zm-.16-6.64a1.71,1.71,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.71v-.09a2.47,2.47,0,0,0-.52-1.5A1.6,1.6,0,0,0,711.77,95.76Z" />
    <path className="cls-7"
      d="M635.91,111.66l0,.82a2.74,2.74,0,0,1,2.2-1,2.1,2.1,0,0,1,2.11,1.19,2.87,2.87,0,0,1,1-.87,2.92,2.92,0,0,1,1.4-.32c1.62,0,2.45.85,2.47,2.57v5h-1.26v-4.88a1.71,1.71,0,0,0-.36-1.19,1.62,1.62,0,0,0-1.22-.39,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.13v4.91h-1.27v-4.85a1.41,1.41,0,0,0-1.58-1.61,1.68,1.68,0,0,0-1.7,1.06v5.4h-1.27v-7.4Z" />
    <path className="cls-7"
      d="M651.46,119.2a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.24a4.41,4.41,0,0,1,.42-2,3.31,3.31,0,0,1,1.17-1.36,3,3,0,0,1,1.64-.48,2.74,2.74,0,0,1,2.24,1,4.07,4.07,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.22,2.22,0,0,0,1.13-.27,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,651.46,119.2Zm-.16-6.64a1.67,1.67,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.09a2.47,2.47,0,0,0-.52-1.5A1.6,1.6,0,0,0,651.3,112.56Zm.62-4h1.53l-1.83,2h-1Z" />
    <path className="cls-7"
      d="M656.9,115.3a4.32,4.32,0,0,1,.81-2.74,2.53,2.53,0,0,1,2.11-1,2.59,2.59,0,0,1,2.06.88v-3.85h1.26v10.5H662l-.06-.79a2.59,2.59,0,0,1-2.12.93,2.54,2.54,0,0,1-2.09-1.06,4.37,4.37,0,0,1-.81-2.75Zm1.26.14a3.32,3.32,0,0,0,.52,2,1.68,1.68,0,0,0,1.44.71,1.85,1.85,0,0,0,1.76-1.08v-3.4a1.87,1.87,0,0,0-1.75-1,1.69,1.69,0,0,0-1.45.72A3.64,3.64,0,0,0,658.16,115.44Z" />
    <path className="cls-7"
      d="M666.51,109.7a.74.74,0,0,1,.19-.52.69.69,0,0,1,.55-.21.7.7,0,0,1,.56.21.75.75,0,0,1,.2.52.69.69,0,0,1-.2.51.7.7,0,0,1-.56.21.69.69,0,0,1-.55-.21A.69.69,0,0,1,666.51,109.7Zm1.37,9.36h-1.27v-7.4h1.27Z" />
    <path className="cls-7"
      d="M675.87,119.06a2.34,2.34,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.62,2.62,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.1,2.1,0,0,1,.88-1.8,4.17,4.17,0,0,1,2.47-.64h1.23V114a1.39,1.39,0,0,0-.4-1,1.59,1.59,0,0,0-1.17-.4,1.81,1.81,0,0,0-1.13.35,1,1,0,0,0-.46.82h-1.27a1.77,1.77,0,0,1,.39-1.07,2.69,2.69,0,0,1,1.07-.81,3.59,3.59,0,0,1,1.48-.3,2.94,2.94,0,0,1,2,.63,2.34,2.34,0,0,1,.75,1.77v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.32,2.32,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.45-2.33,1.36a1.13,1.13,0,0,0,.4.93A1.53,1.53,0,0,0,673.77,118.1Z" />
    <line className="cls-8" x1="633.76" y1="133.19" x2="751.75" y2="133.19" />
    <path className="cls-6"
      d="M259.6,180.8a13.92,13.92,0,1,0,13.93,13.92A13.92,13.92,0,0,0,259.6,180.8Zm1.1,21.39v2.5h-2.39v-2.33a9.72,9.72,0,0,1-4.15-1.05l.73-2.85a8.67,8.67,0,0,0,4.05,1.07c1.4,0,2.35-.54,2.35-1.51s-.78-1.52-2.59-2.13c-2.61-.88-4.4-2.1-4.4-4.47,0-2.15,1.52-3.83,4.13-4.35v-2.32h2.4v2.15a8.78,8.78,0,0,1,3.54.81l-.71,2.76a8.11,8.11,0,0,0-3.52-.83c-1.59,0-2.1.68-2.1,1.37s.86,1.31,2.93,2.1c2.91,1,4.08,2.37,4.08,4.56S263.51,201.7,260.7,202.19Z" />
    <path className="cls-7" d="M174.09,346.23h-4.17l-.94,2.61h-1.35l3.8-10h1.14l3.81,10H175Zm-3.78-1.07h3.39L172,340.5Z" />
    <path className="cls-7"
      d="M182.5,342.57a4,4,0,0,0-.62,0,1.66,1.66,0,0,0-1.69,1.06v5.25h-1.27v-7.4h1.23l0,.85a2,2,0,0,1,1.76-1,1.22,1.22,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M188.64,342.57a4.17,4.17,0,0,0-.63,0,1.65,1.65,0,0,0-1.68,1.06v5.25h-1.27v-7.4h1.23l0,.85a2,2,0,0,1,1.77-1,1.22,1.22,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M194.14,349a3.24,3.24,0,0,1-2.45-1,3.65,3.65,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2,3.35,3.35,0,0,1,1.17-1.35,3,3,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95A4.09,4.09,0,0,1,197,345v.53h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.22,2.22,0,0,0,1.13-.27,3,3,0,0,0,.81-.73l.78.6A3.09,3.09,0,0,1,194.14,349Zm-.16-6.63a1.67,1.67,0,0,0-1.28.56,2.73,2.73,0,0,0-.65,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,194,342.34Z" />
    <path className="cls-7"
      d="M202.85,347.94a1.87,1.87,0,0,0,1.19-.41,1.48,1.48,0,0,0,.56-1h1.19a2.24,2.24,0,0,1-.44,1.21,2.75,2.75,0,0,1-1.07.92,3.1,3.1,0,0,1-1.43.34,3,3,0,0,1-2.4-1,4,4,0,0,1-.89-2.75V345a4.51,4.51,0,0,1,.39-1.92,3.08,3.08,0,0,1,1.14-1.3,3.26,3.26,0,0,1,1.76-.47,2.94,2.94,0,0,1,2.06.75,2.62,2.62,0,0,1,.88,1.93H204.6a1.75,1.75,0,0,0-.55-1.18,1.67,1.67,0,0,0-1.2-.46,1.79,1.79,0,0,0-1.5.69,3.32,3.32,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.53,2A1.77,1.77,0,0,0,202.85,347.94Z" />
    <path className="cls-7"
      d="M213.18,348.84a3,3,0,0,1-.18-.78,2.82,2.82,0,0,1-2.1.91,2.59,2.59,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.08,2.08,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64H213v-.58a1.46,1.46,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.46.83h-1.27a1.7,1.7,0,0,1,.4-1.07,2.68,2.68,0,0,1,1.06-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.3,2.3,0,0,1,.76,1.76v3.41a4.1,4.1,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.32.45-2.32,1.36a1.18,1.18,0,0,0,.39.93A1.56,1.56,0,0,0,211.08,347.87Z" />
    <path className="cls-7"
      d="M217.32,345.08a4.32,4.32,0,0,1,.81-2.74,2.74,2.74,0,0,1,4.17-.15v-3.85h1.26v10.5H222.4l-.06-.8a2.58,2.58,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1.05,4.39,4.39,0,0,1-.81-2.75Zm1.27.14a3.27,3.27,0,0,0,.52,2,1.67,1.67,0,0,0,1.43.71,1.85,1.85,0,0,0,1.76-1.08v-3.4a1.85,1.85,0,0,0-1.74-1,1.66,1.66,0,0,0-1.45.71A3.6,3.6,0,0,0,218.59,345.22Z" />
    <path className="cls-7"
      d="M231.49,348.84a3,3,0,0,1-.18-.78,2.82,2.82,0,0,1-2.1.91,2.59,2.59,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.1,2.1,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.46,1.46,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.46.83h-1.27a1.7,1.7,0,0,1,.4-1.07,2.68,2.68,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.32,2.32,0,0,1,.75,1.76v3.41a4.1,4.1,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.8,1.8,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.32.45-2.32,1.36a1.18,1.18,0,0,0,.39.93A1.56,1.56,0,0,0,229.39,347.87Z" />
    <path className="cls-7"
      d="M238.91,347.94a1.84,1.84,0,0,0,1.18-.41,1.45,1.45,0,0,0,.56-1h1.2a2.32,2.32,0,0,1-.44,1.21,2.86,2.86,0,0,1-1.08.92,3.29,3.29,0,0,1-3.83-.67,4,4,0,0,1-.89-2.75V345a4.37,4.37,0,0,1,.4-1.92,3,3,0,0,1,1.14-1.3,3.22,3.22,0,0,1,1.75-.47,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.79,1.79,0,0,0-.54-1.18,1.69,1.69,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.25,3.25,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.52,2A1.79,1.79,0,0,0,238.91,347.94Zm.2,1-.08.35a1.11,1.11,0,0,1,1.05,1.18,1.21,1.21,0,0,1-.55,1.05,2.6,2.6,0,0,1-1.53.39l0-.73a1.57,1.57,0,0,0,.83-.18.55.55,0,0,0,.3-.5.47.47,0,0,0-.22-.44,2.06,2.06,0,0,0-.89-.21l.22-.91Z" />
    <path className="cls-7"
      d="M249.23,348.84a2.7,2.7,0,0,1-.17-.78A2.82,2.82,0,0,1,247,349a2.55,2.55,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.87-1.79,4.14,4.14,0,0,1,2.48-.64H249v-.58a1.47,1.47,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.71,2.71,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.32,2.32,0,0,1,.75,1.76v3.41a4.29,4.29,0,0,0,.26,1.62v.11Zm.88-10.29a1.7,1.7,0,0,1-.42,1.2,1.35,1.35,0,0,1-1.05.46,1.45,1.45,0,0,1-.49-.07,3.12,3.12,0,0,1-.53-.28,2.4,2.4,0,0,0-.48-.26,1.18,1.18,0,0,0-.34,0,.63.63,0,0,0-.48.21.81.81,0,0,0-.2.55l-.84,0a1.76,1.76,0,0,1,.41-1.21,1.34,1.34,0,0,1,1.05-.48,1.18,1.18,0,0,1,.43.07,2.74,2.74,0,0,1,.52.26,2.69,2.69,0,0,0,.5.26,1.23,1.23,0,0,0,.39.06.61.61,0,0,0,.49-.23.79.79,0,0,0,.19-.54Zm-3,9.32a2.15,2.15,0,0,0,1.12-.31,1.82,1.82,0,0,0,.78-.8v-1.51h-1c-1.55,0-2.33.45-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.53,1.53,0,0,0,247.14,347.87Z" />
    <path className="cls-7"
      d="M253.35,345.07a4.31,4.31,0,0,1,.43-2,3.17,3.17,0,0,1,1.19-1.34,3.23,3.23,0,0,1,1.73-.47,3.12,3.12,0,0,1,2.45,1.05,4,4,0,0,1,.93,2.78v.09a4.49,4.49,0,0,1-.41,1.94,3.18,3.18,0,0,1-1.18,1.33,3.31,3.31,0,0,1-1.77.48,3.14,3.14,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.14,3.14,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.42,3.42,0,0,0,.57-2.11,3.18,3.18,0,0,0-.58-2,1.92,1.92,0,0,0-3.05,0A3.42,3.42,0,0,0,254.62,345.22Z" />
    <path className="cls-7"
      d="M168.08,361.88a4.32,4.32,0,0,1,.81-2.74,2.74,2.74,0,0,1,4.17-.15v-3.85h1.26v10.5h-1.16l-.06-.8a2.57,2.57,0,0,1-2.11.93,2.53,2.53,0,0,1-2.1-1,4.39,4.39,0,0,1-.81-2.75Zm1.27.14a3.27,3.27,0,0,0,.52,2,1.67,1.67,0,0,0,1.43.71,1.84,1.84,0,0,0,1.76-1.08v-3.4a1.85,1.85,0,0,0-1.74-1,1.67,1.67,0,0,0-1.45.71A3.6,3.6,0,0,0,169.35,362Z" />
    <path className="cls-7"
      d="M180.76,365.77a3.22,3.22,0,0,1-2.45-1,3.63,3.63,0,0,1-.94-2.64v-.23a4.48,4.48,0,0,1,.42-2,3.35,3.35,0,0,1,1.17-1.35,3,3,0,0,1,1.64-.49,2.77,2.77,0,0,1,2.24.95,4.14,4.14,0,0,1,.8,2.72v.53h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,3,3,0,0,0,.81-.73l.78.6A3.09,3.09,0,0,1,180.76,365.77Zm-.16-6.63a1.71,1.71,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.6,1.6,0,0,0,180.6,359.14Z" />
    <path className="cls-7"
      d="M193.09,356.45v1.79h1.38v1h-1.38v4.59a1.05,1.05,0,0,0,.18.67.8.8,0,0,0,.63.22,3.31,3.31,0,0,0,.6-.08v1a3.7,3.7,0,0,1-1,.13,1.59,1.59,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.58h-1.35v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M200.92,359.37a4,4,0,0,0-.62,0,1.66,1.66,0,0,0-1.69,1.06v5.25h-1.27v-7.4h1.23l0,.85a2,2,0,0,1,1.77-1,1.22,1.22,0,0,1,.56.1Z" />
    <path className="cls-7"
      d="M203.49,356.28a.73.73,0,0,1,.19-.52.69.69,0,0,1,.55-.21.7.7,0,0,1,.56.21.73.73,0,0,1,.19.52.72.72,0,0,1-.19.51.7.7,0,0,1-.56.2.69.69,0,0,1-.55-.2A.72.72,0,0,1,203.49,356.28Zm1.36,9.36h-1.26v-7.4h1.26Z" />
    <path className="cls-7"
      d="M214.54,362a4.37,4.37,0,0,1-.78,2.72,2.48,2.48,0,0,1-2.09,1,2.58,2.58,0,0,1-2.17-1l-.06.86h-1.16v-10.5h1.26v3.91a2.58,2.58,0,0,1,2.11-.95,2.51,2.51,0,0,1,2.12,1,4.55,4.55,0,0,1,.77,2.79Zm-1.27-.14a3.44,3.44,0,0,0-.49-2,1.67,1.67,0,0,0-1.44-.7,1.86,1.86,0,0,0-1.8,1.16v3.2a1.91,1.91,0,0,0,1.81,1.16,1.65,1.65,0,0,0,1.42-.71A3.57,3.57,0,0,0,213.27,361.88Z" />
    <path className="cls-7"
      d="M222.1,364.9a2.69,2.69,0,0,1-2.17.87,2.29,2.29,0,0,1-1.8-.69,3,3,0,0,1-.63-2v-4.81h1.27V363c0,1.12.45,1.68,1.37,1.68a1.88,1.88,0,0,0,1.92-1.08v-5.38h1.27v7.4h-1.2Z" />
    <path className="cls-7"
      d="M228.37,356.45v1.79h1.38v1h-1.38v4.59a1.05,1.05,0,0,0,.18.67.8.8,0,0,0,.63.22,3.31,3.31,0,0,0,.6-.08v1a3.7,3.7,0,0,1-1,.13,1.59,1.59,0,0,1-1.28-.51,2.19,2.19,0,0,1-.43-1.46v-4.58h-1.35v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M232.15,361.87a4.31,4.31,0,0,1,.43-2,3.17,3.17,0,0,1,1.19-1.34,3.29,3.29,0,0,1,1.74-.47,3.1,3.1,0,0,1,2.44,1,4,4,0,0,1,.94,2.78V362a4.35,4.35,0,0,1-.42,1.94,3.11,3.11,0,0,1-1.18,1.33,3.26,3.26,0,0,1-1.77.48,3.15,3.15,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.14,3.14,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.42,3.42,0,0,0,.57-2.11,3.12,3.12,0,0,0-.58-2,1.91,1.91,0,0,0-3,0A3.36,3.36,0,0,0,233.42,362Z" />
    <path className="cls-7"
      d="M246.18,363.67a.92.92,0,0,0-.39-.79,3.62,3.62,0,0,0-1.35-.49,6.62,6.62,0,0,1-1.52-.49,2.16,2.16,0,0,1-.83-.69,1.62,1.62,0,0,1-.27-.94,1.9,1.9,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.1,3.1,0,0,1,2,.65,2.07,2.07,0,0,1,.78,1.66H246.1a1.12,1.12,0,0,0-.44-.89,1.64,1.64,0,0,0-1.11-.38,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.79.78.78,0,0,0,.36.69,4.6,4.6,0,0,0,1.31.44,6.75,6.75,0,0,1,1.54.51,2.17,2.17,0,0,1,.87.71,1.71,1.71,0,0,1,.28,1,1.87,1.87,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.31,2.52,2.52,0,0,1-1.07-.88,2.11,2.11,0,0,1-.39-1.22h1.27a1.3,1.3,0,0,0,.51,1,1.94,1.94,0,0,0,1.25.37,2.06,2.06,0,0,0,1.15-.29A.89.89,0,0,0,246.18,363.67Z" />
    <path className="cls-7"
      d="M169.58,375l0,.82a2.75,2.75,0,0,1,2.2-1,2.12,2.12,0,0,1,2.11,1.19,3,3,0,0,1,1-.86,2.84,2.84,0,0,1,1.4-.33c1.62,0,2.45.86,2.47,2.58v5h-1.26v-4.89a1.7,1.7,0,0,0-.36-1.18,1.61,1.61,0,0,0-1.22-.39,1.64,1.64,0,0,0-1.71,1.55v4.91h-1.27v-4.85a1.41,1.41,0,0,0-1.58-1.61,1.69,1.69,0,0,0-1.7,1v5.41h-1.27V375Z" />
    <path className="cls-7"
      d="M186.63,381.7a2.69,2.69,0,0,1-2.17.87,2.29,2.29,0,0,1-1.8-.69,3,3,0,0,1-.63-2V375h1.27v4.78c0,1.12.45,1.68,1.37,1.68a1.89,1.89,0,0,0,1.92-1.08V375h1.27v7.4h-1.2Z" />
    <path className="cls-7"
      d="M192.38,375l0,.93a2.69,2.69,0,0,1,2.21-1.07c1.56,0,2.35.88,2.37,2.65v4.89h-1.27v-4.9a1.67,1.67,0,0,0-.36-1.18,1.46,1.46,0,0,0-1.12-.38,1.81,1.81,0,0,0-1.08.32,2.35,2.35,0,0,0-.73.86v5.28h-1.26V375Z" />
    <path className="cls-7"
      d="M200.31,373.08a.73.73,0,0,1,.19-.52.7.7,0,0,1,.56-.21.73.73,0,0,1,.56.21.79.79,0,0,1,0,1,.73.73,0,0,1-.56.2.7.7,0,0,1-.56-.2A.72.72,0,0,1,200.31,373.08Zm1.37,9.36h-1.27V375h1.27Z" />
    <path className="cls-7"
      d="M208.07,381.54a1.84,1.84,0,0,0,1.18-.41,1.45,1.45,0,0,0,.56-1H211a2.32,2.32,0,0,1-.44,1.21,2.86,2.86,0,0,1-1.08.92,3.28,3.28,0,0,1-3.82-.67,4,4,0,0,1-.9-2.75v-.22a4.51,4.51,0,0,1,.4-1.92,3,3,0,0,1,1.14-1.3,3.22,3.22,0,0,1,1.75-.47,3,3,0,0,1,2.07.75,2.66,2.66,0,0,1,.88,1.93h-1.2a1.79,1.79,0,0,0-.54-1.18,1.69,1.69,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.32,3.32,0,0,0-.53,2v.24a3.21,3.21,0,0,0,.52,2A1.8,1.8,0,0,0,208.07,381.54Z" />
    <path className="cls-7"
      d="M213.84,373.08a.77.77,0,0,1,.18-.52.73.73,0,0,1,.56-.21.7.7,0,0,1,.56.21.73.73,0,0,1,.19.52.72.72,0,0,1-.19.51.7.7,0,0,1-.56.2.73.73,0,0,1-.56-.2A.76.76,0,0,1,213.84,373.08Zm1.36,9.36h-1.26V375h1.26Z" />
    <path className="cls-7"
      d="M224.88,378.82a4.41,4.41,0,0,1-.78,2.72,2.46,2.46,0,0,1-2.09,1,2.71,2.71,0,0,1-2.12-.85v3.56h-1.26V375h1.15l.06.82a2.61,2.61,0,0,1,2.15-1,2.53,2.53,0,0,1,2.11,1,4.45,4.45,0,0,1,.78,2.79Zm-1.27-.14a3.3,3.3,0,0,0-.53-2,1.74,1.74,0,0,0-1.47-.72,1.85,1.85,0,0,0-1.72,1v3.54a1.88,1.88,0,0,0,1.74,1,1.7,1.7,0,0,0,1.44-.72A3.55,3.55,0,0,0,223.61,378.68Z" />
    <path className="cls-7"
      d="M232.45,382.44a3,3,0,0,1-.18-.78,2.82,2.82,0,0,1-2.11.91,2.59,2.59,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.08,2.08,0,0,1,.88-1.79A4.1,4.1,0,0,1,231,378h1.23v-.58a1.42,1.42,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.71,2.71,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,3,3,0,0,1,2,.64,2.25,2.25,0,0,1,.75,1.76v3.41a4.29,4.29,0,0,0,.26,1.62v.11Zm-2.1-1a2.19,2.19,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.51h-1c-1.55,0-2.33.45-2.33,1.36a1.19,1.19,0,0,0,.4.93A1.54,1.54,0,0,0,230.35,381.47Z" />
    <path className="cls-7"
      d="M236.9,373.08a.77.77,0,0,1,.19-.52.72.72,0,0,1,.56-.21.69.69,0,0,1,.75.73.72.72,0,0,1-.19.51.73.73,0,0,1-.56.2.71.71,0,0,1-.56-.2A.76.76,0,0,1,236.9,373.08Zm1.37,9.36H237V375h1.26Z" />
    <path className="cls-7"
      d="M246,380.47a.92.92,0,0,0-.39-.79,3.49,3.49,0,0,0-1.34-.49,6.72,6.72,0,0,1-1.53-.49,2.24,2.24,0,0,1-.83-.69,1.62,1.62,0,0,1-.27-.94,1.93,1.93,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.1,3.1,0,0,1,2,.65,2,2,0,0,1,.79,1.66h-1.27a1.13,1.13,0,0,0-.45-.89,1.64,1.64,0,0,0-1.11-.38,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.79.77.77,0,0,0,.37.69,4.49,4.49,0,0,0,1.31.44,6.49,6.49,0,0,1,1.53.51,2.17,2.17,0,0,1,.87.71,1.71,1.71,0,0,1,.29,1,1.87,1.87,0,0,1-.8,1.59,3.3,3.3,0,0,1-2.05.6,3.74,3.74,0,0,1-1.58-.31,2.59,2.59,0,0,1-1.07-.88,2.17,2.17,0,0,1-.38-1.22h1.26a1.3,1.3,0,0,0,.51,1,2,2,0,0,0,1.26.37,2.08,2.08,0,0,0,1.15-.29A.88.88,0,0,0,246,380.47Z" />
    <path className="cls-7" d="M170,311.06h-1.32v-10H170Z" />
    <path className="cls-7"
      d="M174.8,303.66l0,.93a2.69,2.69,0,0,1,2.21-1.07c1.56,0,2.35.89,2.37,2.65v4.89h-1.27v-4.9a1.73,1.73,0,0,0-.36-1.18,1.5,1.5,0,0,0-1.12-.38,1.82,1.82,0,0,0-1.08.33,2.2,2.2,0,0,0-.73.86v5.27H173.6v-7.4Z" />
    <path className="cls-7" d="M185.16,309.34l1.84-5.68h1.29l-2.65,7.4h-1l-2.68-7.4h1.29Z" />
    <path className="cls-7"
      d="M193.89,311.19a3.22,3.22,0,0,1-2.45-1,3.68,3.68,0,0,1-.95-2.65v-.23a4.41,4.41,0,0,1,.43-2,3.31,3.31,0,0,1,1.17-1.36,3,3,0,0,1,1.64-.49,2.75,2.75,0,0,1,2.24,1,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.67,2.67,0,0,0,.81-.73l.77.61A3.08,3.08,0,0,1,193.89,311.19Zm-.16-6.63a1.71,1.71,0,0,0-1.29.56,2.72,2.72,0,0,0-.64,1.56h3.71v-.09a2.45,2.45,0,0,0-.52-1.5A1.6,1.6,0,0,0,193.73,304.56Z" />
    <path className="cls-7"
      d="M203.94,309.1a.93.93,0,0,0-.39-.8,3.66,3.66,0,0,0-1.34-.49,6.72,6.72,0,0,1-1.53-.49,2.22,2.22,0,0,1-.83-.68,1.66,1.66,0,0,1-.27-.95,1.91,1.91,0,0,1,.77-1.54,3,3,0,0,1,2-.63,3.1,3.1,0,0,1,2,.65,2.06,2.06,0,0,1,.79,1.67h-1.28a1.13,1.13,0,0,0-.44-.9,1.64,1.64,0,0,0-1.11-.38,1.73,1.73,0,0,0-1.08.3,1,1,0,0,0-.39.79.75.75,0,0,0,.37.69,4.45,4.45,0,0,0,1.3.45,7,7,0,0,1,1.54.5,2.26,2.26,0,0,1,.87.71,1.72,1.72,0,0,1,.28,1,1.89,1.89,0,0,1-.79,1.59,3.31,3.31,0,0,1-2.06.59,3.73,3.73,0,0,1-1.57-.31,2.59,2.59,0,0,1-1.07-.88,2.07,2.07,0,0,1-.38-1.22h1.26a1.29,1.29,0,0,0,.51,1,2,2,0,0,0,1.25.37,2,2,0,0,0,1.16-.29A.88.88,0,0,0,203.94,309.1Z" />
    <path className="cls-7"
      d="M210,301.87v1.79h1.38v1H210v4.59a1.05,1.05,0,0,0,.18.67.76.76,0,0,0,.63.22,3.31,3.31,0,0,0,.6-.08v1a3.65,3.65,0,0,1-1,.13,1.59,1.59,0,0,1-1.28-.51,2.24,2.24,0,0,1-.43-1.45v-4.59h-1.34v-1h1.34v-1.79Z" />
    <path className="cls-7"
      d="M214.23,301.7a.73.73,0,0,1,.19-.52A.72.72,0,0,1,215,301a.73.73,0,0,1,.56.21.77.77,0,0,1,.19.52.75.75,0,0,1-.19.51.73.73,0,0,1-.56.21.72.72,0,0,1-.56-.21A.7.7,0,0,1,214.23,301.7Zm1.37,9.36h-1.27v-7.4h1.27Z" />
    <path className="cls-7"
      d="M220.21,303.66l0,.82a2.72,2.72,0,0,1,2.19-1,2.12,2.12,0,0,1,2.11,1.19,3,3,0,0,1,1-.86,2.84,2.84,0,0,1,1.4-.33c1.62,0,2.45.86,2.48,2.58v5h-1.27v-4.88a1.71,1.71,0,0,0-.36-1.19,1.61,1.61,0,0,0-1.22-.39,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.13v4.91h-1.27v-4.85A1.41,1.41,0,0,0,222,304.6a1.69,1.69,0,0,0-1.7,1.06v5.4H219v-7.4Z" />
    <path className="cls-7"
      d="M235.76,311.19a3.23,3.23,0,0,1-2.45-1,3.71,3.71,0,0,1-.94-2.65v-.23a4.41,4.41,0,0,1,.42-2A3.26,3.26,0,0,1,234,304a3,3,0,0,1,1.63-.49,2.77,2.77,0,0,1,2.25,1,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.59,2.59,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.67,2.67,0,0,0,.81-.73l.77.61A3.11,3.11,0,0,1,235.76,311.19Zm-.16-6.63a1.71,1.71,0,0,0-1.28.56,2.66,2.66,0,0,0-.64,1.56h3.7v-.09a2.39,2.39,0,0,0-.52-1.5A1.6,1.6,0,0,0,235.6,304.56Z" />
    <path className="cls-7"
      d="M242.71,303.66l0,.93a2.69,2.69,0,0,1,2.21-1.07c1.56,0,2.35.89,2.37,2.65v4.89h-1.27v-4.9a1.68,1.68,0,0,0-.36-1.18,1.49,1.49,0,0,0-1.12-.38,1.82,1.82,0,0,0-1.08.33,2.2,2.2,0,0,0-.73.86v5.27h-1.26v-7.4Z" />
    <path className="cls-7"
      d="M252.35,301.87v1.79h1.38v1h-1.38v4.59a1.05,1.05,0,0,0,.18.67.79.79,0,0,0,.63.22,3.51,3.51,0,0,0,.61-.08v1a3.78,3.78,0,0,1-1,.13,1.56,1.56,0,0,1-1.27-.51,2.18,2.18,0,0,1-.43-1.45v-4.59h-1.35v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M256.14,307.29a4.43,4.43,0,0,1,.42-2,3.17,3.17,0,0,1,2.93-1.82,3.13,3.13,0,0,1,2.45,1.05,4,4,0,0,1,.93,2.78v.09a4.49,4.49,0,0,1-.41,1.94,3.16,3.16,0,0,1-1.19,1.34,3.24,3.24,0,0,1-1.76.47,3.09,3.09,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.94,1.94,0,0,0,3.06,0,3.47,3.47,0,0,0,.56-2.12,3.18,3.18,0,0,0-.57-2,1.86,1.86,0,0,0-1.54-.76,1.81,1.81,0,0,0-1.51.75A3.46,3.46,0,0,0,257.41,307.44Z" />
    <path className="cls-2" d="M171.16,264.63h-2.58V251.84h2.58Z" />
    <path className="cls-2"
      d="M178.51,251.84l2.41,9.25,2.41-9.25h3.38v12.79h-2.6v-3.46l.24-5.33-2.56,8.79H180l-2.56-8.79.24,5.33v3.46h-2.59V251.84Z" />
    <path className="cls-2"
      d="M193.17,260.13v4.5h-2.59V251.84h4.36a4,4,0,0,1,3,1.18,4.23,4.23,0,0,1,1.13,3,3.92,3.92,0,0,1-1.12,3,4.21,4.21,0,0,1-3.09,1.09Zm0-2.15h1.77a1.41,1.41,0,0,0,1.14-.48,2.14,2.14,0,0,0,.41-1.41,2.47,2.47,0,0,0-.42-1.52A1.32,1.32,0,0,0,195,254h-1.8Z" />
    <path className="cls-2" d="M207.16,262h-3.52l-.69,2.61h-2.73l4-12.79h2.36l4,12.79h-2.76Zm-3-2.16h2.39l-1.19-4.56Z" />
    <path className="cls-2"
      d="M222.1,260.37a4.79,4.79,0,0,1-1.24,3.31,4.33,4.33,0,0,1-3.19,1.13,4,4,0,0,1-3.33-1.43,6.32,6.32,0,0,1-1.16-4.07v-2.16a6.15,6.15,0,0,1,1.2-4.06,4.72,4.72,0,0,1,6.5-.26,5.34,5.34,0,0,1,1.23,3.36h-2.59a3.37,3.37,0,0,0-.42-1.87,1.64,1.64,0,0,0-1.4-.51,1.58,1.58,0,0,0-1.47.72,5.08,5.08,0,0,0-.45,2.39v2.41a5.62,5.62,0,0,0,.42,2.62,1.57,1.57,0,0,0,1.47.72,1.68,1.68,0,0,0,1.4-.5,3.21,3.21,0,0,0,.44-1.8Z" />
    <path className="cls-2" d="M233.52,254h-3.17v10.64h-2.59V254h-3.11v-2.15h8.87Z" />
    <path className="cls-2"
      d="M245.57,259.39a6,6,0,0,1-1.22,4,4.74,4.74,0,0,1-6.76,0,5.88,5.88,0,0,1-1.25-3.95v-2.19a6.32,6.32,0,0,1,1.22-4.12,4.62,4.62,0,0,1,6.76,0,6.19,6.19,0,0,1,1.25,4.08Zm-2.6-2.15a5.22,5.22,0,0,0-.49-2.57,1.81,1.81,0,0,0-3,0,4.92,4.92,0,0,0-.51,2.47v2.28a4.78,4.78,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.58,4.58,0,0,0,.5-2.41Z" />
    <path className="cls-2" d="M175.07,280.7h-4v3.39h4.76v2.14h-7.35V273.44h7.33v2.15h-4.74v3h4Z" />
    <path className="cls-2"
      d="M187.77,282a4.76,4.76,0,0,1-1.23,3.32,4.35,4.35,0,0,1-3.2,1.12A4,4,0,0,1,180,285a6.32,6.32,0,0,1-1.15-4.07v-2.16a6.2,6.2,0,0,1,1.19-4.06,4.73,4.73,0,0,1,6.5-.26,5.29,5.29,0,0,1,1.24,3.36h-2.6a3.48,3.48,0,0,0-.41-1.87,1.66,1.66,0,0,0-1.4-.51,1.56,1.56,0,0,0-1.47.72,5.06,5.06,0,0,0-.46,2.39v2.41a5.46,5.46,0,0,0,.43,2.62,1.55,1.55,0,0,0,1.46.72,1.71,1.71,0,0,0,1.41-.5,3.38,3.38,0,0,0,.44-1.8Z" />
    <path className="cls-2"
      d="M200.17,281a6,6,0,0,1-1.22,4,4.19,4.19,0,0,1-3.38,1.42,4.26,4.26,0,0,1-3.38-1.41,5.88,5.88,0,0,1-1.25-3.95v-2.19a6.32,6.32,0,0,1,1.22-4.12,4.62,4.62,0,0,1,6.76,0,6.19,6.19,0,0,1,1.25,4.08Zm-2.59-2.15a5.33,5.33,0,0,0-.49-2.57,1.66,1.66,0,0,0-1.53-.85,1.63,1.63,0,0,0-1.52.82,4.92,4.92,0,0,0-.51,2.47V281a4.68,4.68,0,0,0,.5,2.47,1.7,1.7,0,0,0,1.54.8,1.66,1.66,0,0,0,1.51-.78,4.79,4.79,0,0,0,.5-2.41Z" />
    <path className="cls-2" d="M212.77,286.23h-2.58l-3.78-8.39v8.39h-2.59V273.44h2.59l3.79,8.4v-8.4h2.57Z" />
    <path className="cls-2"
      d="M225.65,281a6,6,0,0,1-1.21,4,4.2,4.2,0,0,1-3.38,1.42,4.26,4.26,0,0,1-3.39-1.41,5.88,5.88,0,0,1-1.24-3.95v-2.19a6.27,6.27,0,0,1,1.22-4.12,4.62,4.62,0,0,1,6.76,0,6.19,6.19,0,0,1,1.24,4.08Zm-1.56-8.52v.1h-1.94l-1.1-1.27-1.1,1.27h-1.9v-.14l2.29-2.42h1.42Zm-1,6.37a5.22,5.22,0,0,0-.49-2.57,1.66,1.66,0,0,0-1.53-.85,1.64,1.64,0,0,0-1.52.82,4.92,4.92,0,0,0-.51,2.47V281a4.78,4.78,0,0,0,.5,2.47,1.71,1.71,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.58,4.58,0,0,0,.5-2.41Z" />
    <path className="cls-2"
      d="M232.68,273.44l2.42,9.25,2.41-9.25h3.37v12.79h-2.59v-3.46l.24-5.33L236,286.23h-1.76l-2.55-8.79.23,5.33v3.46h-2.58V273.44Z" />
    <path className="cls-2" d="M247.46,286.23h-2.59V273.44h2.59Z" />
    <path className="cls-2"
      d="M260.1,282a4.76,4.76,0,0,1-1.23,3.32,4.33,4.33,0,0,1-3.2,1.12,4,4,0,0,1-3.32-1.43,6.32,6.32,0,0,1-1.16-4.07v-2.16a6.15,6.15,0,0,1,1.2-4.06,4.09,4.09,0,0,1,3.32-1.43,4.15,4.15,0,0,1,3.18,1.17,5.34,5.34,0,0,1,1.23,3.36h-2.59a3.56,3.56,0,0,0-.42-1.87,1.66,1.66,0,0,0-1.4-.51,1.58,1.58,0,0,0-1.47.72,5.06,5.06,0,0,0-.46,2.39v2.41a5.59,5.59,0,0,0,.43,2.62,1.55,1.55,0,0,0,1.46.72,1.71,1.71,0,0,0,1.41-.5,3.29,3.29,0,0,0,.44-1.8Z" />
    <path className="cls-2"
      d="M272.5,281a5.94,5.94,0,0,1-1.22,4,4.18,4.18,0,0,1-3.37,1.42,4.26,4.26,0,0,1-3.39-1.41,5.93,5.93,0,0,1-1.25-3.95v-2.19a6.27,6.27,0,0,1,1.23-4.12,4.61,4.61,0,0,1,6.75,0,6.19,6.19,0,0,1,1.25,4.08Zm-2.59-2.15a5.22,5.22,0,0,0-.49-2.57,1.66,1.66,0,0,0-1.53-.85,1.64,1.64,0,0,0-1.52.82,4.92,4.92,0,0,0-.51,2.47V281a4.68,4.68,0,0,0,.5,2.47,1.7,1.7,0,0,0,1.55.8,1.66,1.66,0,0,0,1.5-.78,4.68,4.68,0,0,0,.5-2.41Z" />
    <line className="cls-8" x1="167.43" y1="324.6" x2="263.13" y2="324.6" />
    <path className="cls-7"
      d="M634.65,209.14V213h-1.32v-9.95H637a3.7,3.7,0,0,1,2.56.83,2.83,2.83,0,0,1,.93,2.21,2.76,2.76,0,0,1-.91,2.23,3.84,3.84,0,0,1-2.59.78Zm0-1.07H637a2.33,2.33,0,0,0,1.62-.5,1.81,1.81,0,0,0,.56-1.43,1.87,1.87,0,0,0-.56-1.42,2.2,2.2,0,0,0-1.54-.56h-2.43Z" />
    <path className="cls-7"
      d="M646.35,213.18a3.22,3.22,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.24a4.44,4.44,0,0,1,.42-2,3.22,3.22,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.74,2.74,0,0,1,2.24,1,4.07,4.07,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.22,2.22,0,0,0,1.13-.27,2.77,2.77,0,0,0,.81-.72l.78.6A3.11,3.11,0,0,1,646.35,213.18Zm-.16-6.63a1.7,1.7,0,0,0-1.29.55,2.79,2.79,0,0,0-.64,1.57H648v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,646.19,206.55Z" />
    <path className="cls-7"
      d="M655.67,206.78a4,4,0,0,0-.62-.05,1.67,1.67,0,0,0-1.69,1.06V213h-1.27v-7.4h1.24l0,.86a2,2,0,0,1,1.76-1,1.36,1.36,0,0,1,.56.09Z" />
    <path className="cls-7"
      d="M661.07,212.14a1.79,1.79,0,0,0,1.18-.41,1.39,1.39,0,0,0,.56-1H664a2.27,2.27,0,0,1-.44,1.21,3,3,0,0,1-1.08.92,3.16,3.16,0,0,1-1.42.34,3,3,0,0,1-2.4-1,4,4,0,0,1-.9-2.76v-.21a4.51,4.51,0,0,1,.4-1.92,3,3,0,0,1,1.14-1.31,3.22,3.22,0,0,1,1.75-.46,3,3,0,0,1,2.07.74,2.68,2.68,0,0,1,.88,1.94h-1.2a1.74,1.74,0,0,0-.54-1.18,1.69,1.69,0,0,0-1.21-.46,1.77,1.77,0,0,0-1.49.69,3.28,3.28,0,0,0-.53,2v.24a3.11,3.11,0,0,0,.53,2A1.77,1.77,0,0,0,661.07,212.14Z" />
    <path className="cls-7"
      d="M669.9,213.18a3.24,3.24,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.24a4.44,4.44,0,0,1,.42-2,3.18,3.18,0,0,1,1.18-1.35,2.89,2.89,0,0,1,1.63-.49,2.74,2.74,0,0,1,2.24,1,4.07,4.07,0,0,1,.8,2.72v.52h-5a2.69,2.69,0,0,0,.64,1.77,2,2,0,0,0,1.56.67,2.22,2.22,0,0,0,1.13-.27,2.94,2.94,0,0,0,.82-.72l.77.6A3.11,3.11,0,0,1,669.9,213.18Zm-.16-6.63a1.67,1.67,0,0,0-1.28.55,2.79,2.79,0,0,0-.65,1.57h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,669.74,206.55Z" />
    <path className="cls-7"
      d="M676.84,205.64l0,.93a2.71,2.71,0,0,1,2.22-1.06c1.56,0,2.35.88,2.36,2.64V213H680.2v-4.89a1.69,1.69,0,0,0-.37-1.19,1.46,1.46,0,0,0-1.11-.38,1.82,1.82,0,0,0-1.08.33,2.27,2.27,0,0,0-.73.86V213h-1.26v-7.4Z" />
    <path className="cls-7"
      d="M686.49,203.85v1.79h1.38v1h-1.38v4.59a1.05,1.05,0,0,0,.18.67.78.78,0,0,0,.63.22,2.6,2.6,0,0,0,.6-.08v1a3.67,3.67,0,0,1-1,.14,1.57,1.57,0,0,1-1.28-.52,2.22,2.22,0,0,1-.43-1.45v-4.59h-1.34v-1h1.34v-1.79Z" />
    <path className="cls-7"
      d="M695.31,212.31a2.68,2.68,0,0,1-2.17.87,2.31,2.31,0,0,1-1.8-.69,3,3,0,0,1-.62-2v-4.82H692v4.78c0,1.12.46,1.68,1.37,1.68a1.89,1.89,0,0,0,1.93-1.08v-5.38h1.26V213h-1.2Z" />
    <path className="cls-7"
      d="M704.43,213a2.89,2.89,0,0,1-.18-.78,2.83,2.83,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23V208a1.39,1.39,0,0,0-.4-1.06,1.58,1.58,0,0,0-1.16-.39,1.84,1.84,0,0,0-1.14.34,1,1,0,0,0-.46.82H699.8a1.79,1.79,0,0,1,.39-1.07,2.76,2.76,0,0,1,1.07-.81,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.28,2.28,0,0,1,.76,1.76v3.4a4,4,0,0,0,.26,1.62V213Zm-2.1-1a2.29,2.29,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,702.33,212.08Z" />
    <path className="cls-7" d="M710.25,213H709v-10.5h1.26Z" />
    <path className="cls-7"
      d="M718.23,209.28a4.34,4.34,0,0,1,.81-2.74,2.75,2.75,0,0,1,4.17-.14v-3.86h1.26V213h-1.16l-.06-.79a2.57,2.57,0,0,1-2.11.93,2.52,2.52,0,0,1-2.1-1.06,4.35,4.35,0,0,1-.81-2.74Zm1.27.14a3.25,3.25,0,0,0,.52,2,1.65,1.65,0,0,0,1.43.71,1.84,1.84,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.74-1,1.68,1.68,0,0,0-1.45.72A3.59,3.59,0,0,0,719.5,209.42Z" />
    <path className="cls-7"
      d="M730.91,213.18a3.22,3.22,0,0,1-2.45-1,3.66,3.66,0,0,1-.94-2.64v-.24a4.44,4.44,0,0,1,.42-2,3.22,3.22,0,0,1,1.17-1.35,2.94,2.94,0,0,1,1.64-.49,2.74,2.74,0,0,1,2.24,1,4.12,4.12,0,0,1,.8,2.72v.52h-5a2.64,2.64,0,0,0,.64,1.77,2,2,0,0,0,1.55.67,2.24,2.24,0,0,0,1.14-.27,2.77,2.77,0,0,0,.81-.72l.78.6A3.11,3.11,0,0,1,730.91,213.18Zm-.16-6.63a1.7,1.7,0,0,0-1.29.55,2.79,2.79,0,0,0-.64,1.57h3.71v-.1a2.42,2.42,0,0,0-.52-1.49A1.57,1.57,0,0,0,730.75,206.55Z" />
    <path className="cls-7"
      d="M636.71,223.58a4,4,0,0,0-.62-.05,1.67,1.67,0,0,0-1.69,1.06v5.25h-1.27v-7.4h1.24l0,.86a2,2,0,0,1,1.76-1,1.36,1.36,0,0,1,.56.09Z" />
    <path className="cls-7"
      d="M638.8,226.07a4.3,4.3,0,0,1,.43-1.95,3.11,3.11,0,0,1,1.19-1.34,3.17,3.17,0,0,1,1.74-.47,3.14,3.14,0,0,1,2.44,1,4.05,4.05,0,0,1,.93,2.79v.08a4.35,4.35,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.28,3.28,0,0,1-1.77.48,3.13,3.13,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.84,1.84,0,0,0-1.53-.75,1.82,1.82,0,0,0-1.51.74A3.4,3.4,0,0,0,640.07,226.22Z" />
    <path className="cls-7"
      d="M648.21,226.08a4.34,4.34,0,0,1,.81-2.74,2.75,2.75,0,0,1,4.17-.14v-3.86h1.26v10.5h-1.16l-.06-.79a2.58,2.58,0,0,1-2.11.93,2.52,2.52,0,0,1-2.1-1.06,4.35,4.35,0,0,1-.81-2.74Zm1.27.14a3.25,3.25,0,0,0,.52,2,1.65,1.65,0,0,0,1.43.71,1.85,1.85,0,0,0,1.76-1.08v-3.39a1.86,1.86,0,0,0-1.74-1.05,1.68,1.68,0,0,0-1.45.72A3.59,3.59,0,0,0,649.48,226.22Z" />
    <path className="cls-7"
      d="M657.48,226.07a4.3,4.3,0,0,1,.43-1.95,3.11,3.11,0,0,1,1.19-1.34,3.17,3.17,0,0,1,1.74-.47,3.14,3.14,0,0,1,2.44,1,4.05,4.05,0,0,1,.93,2.79v.08a4.35,4.35,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.28,3.28,0,0,1-1.77.48,3.13,3.13,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.84,1.84,0,0,0-1.53-.75,1.82,1.82,0,0,0-1.51.74A3.4,3.4,0,0,0,658.75,226.22Z" />
    <path className="cls-7" d="M669.54,228.12l1.83-5.68h1.29l-2.65,7.4h-1l-2.68-7.4h1.29Z" />
    <path className="cls-7"
      d="M675.29,220.48a.78.78,0,0,1,.18-.52.85.85,0,0,1,1.12,0,.74.74,0,0,1,.19.52.73.73,0,0,1-.19.52.88.88,0,0,1-1.12,0A.77.77,0,0,1,675.29,220.48Zm1.36,9.36h-1.26v-7.4h1.26Z" />
    <path className="cls-7"
      d="M684.64,229.84a3,3,0,0,1-.18-.78,2.81,2.81,0,0,1-2.1.92,2.64,2.64,0,0,1-1.8-.62,2,2,0,0,1-.7-1.57,2.09,2.09,0,0,1,.88-1.79,4.12,4.12,0,0,1,2.47-.64h1.23v-.58a1.43,1.43,0,0,0-.39-1.06,1.62,1.62,0,0,0-1.17-.39,1.86,1.86,0,0,0-1.14.34,1,1,0,0,0-.45.83H680a1.7,1.7,0,0,1,.4-1.07,2.59,2.59,0,0,1,1.06-.82,3.63,3.63,0,0,1,1.48-.3,2.92,2.92,0,0,1,2,.64,2.31,2.31,0,0,1,.75,1.76v3.4a4,4,0,0,0,.26,1.62v.11Zm-2.1-1a2.26,2.26,0,0,0,1.13-.31,1.85,1.85,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.32.46-2.32,1.36a1.15,1.15,0,0,0,.39.93A1.51,1.51,0,0,0,682.54,228.88Z" />
    <path className="cls-7"
      d="M693.4,227.88a.94.94,0,0,0-.39-.8,3.8,3.8,0,0,0-1.35-.49,6.17,6.17,0,0,1-1.52-.49,2.14,2.14,0,0,1-.83-.68,1.66,1.66,0,0,1-.27-.95,1.89,1.89,0,0,1,.77-1.53,3,3,0,0,1,2-.63,3,3,0,0,1,2,.65,2.05,2.05,0,0,1,.78,1.66h-1.27a1.13,1.13,0,0,0-.44-.9,1.64,1.64,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7.33,7.33,0,0,1,1.54.5,2.35,2.35,0,0,1,.87.71,1.74,1.74,0,0,1,.28,1,1.89,1.89,0,0,1-.79,1.59,3.34,3.34,0,0,1-2.06.6,3.7,3.7,0,0,1-1.57-.32,2.52,2.52,0,0,1-1.07-.88,2.08,2.08,0,0,1-.39-1.22h1.27a1.29,1.29,0,0,0,.51,1,1.94,1.94,0,0,0,1.25.38,2,2,0,0,0,1.15-.3A.89.89,0,0,0,693.4,227.88Z" />
    <path className="cls-7"
      d="M702.24,226.07a4.3,4.3,0,0,1,.43-1.95,3.11,3.11,0,0,1,1.19-1.34,3.2,3.2,0,0,1,1.74-.47,3.14,3.14,0,0,1,2.44,1,4.05,4.05,0,0,1,.93,2.79v.08a4.35,4.35,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.26,3.26,0,0,1-1.77.48,3.13,3.13,0,0,1-2.44-1,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.92,1.92,0,0,0-3,0A3.4,3.4,0,0,0,703.51,226.22Zm2.66-6.88h1.53l-1.83,2h-1Z" />
    <path className="cls-7"
      d="M713.68,220.65v1.79h1.38v1h-1.38V228a1.05,1.05,0,0,0,.18.67.76.76,0,0,0,.63.22,2.6,2.6,0,0,0,.6-.08v1a3.73,3.73,0,0,1-1,.14,1.57,1.57,0,0,1-1.28-.52,2.16,2.16,0,0,1-.43-1.45v-4.59h-1.35v-1h1.35v-1.79Z" />
    <path className="cls-7"
      d="M717.94,220.48a.74.74,0,0,1,.19-.52.69.69,0,0,1,.55-.21.74.74,0,0,1,.57.21.79.79,0,0,1,.19.52.68.68,0,0,1-.76.72.67.67,0,0,1-.74-.72Zm1.37,9.36H718v-7.4h1.27Z" />
    <path className="cls-7"
      d="M723.92,222.44l0,.82a2.77,2.77,0,0,1,2.2-.95,2.1,2.1,0,0,1,2.11,1.19,2.74,2.74,0,0,1,1-.86,2.84,2.84,0,0,1,1.4-.33c1.62,0,2.45.86,2.47,2.57v5h-1.26V225a1.73,1.73,0,0,0-.36-1.19,1.62,1.62,0,0,0-1.22-.39,1.68,1.68,0,0,0-1.17.42,1.71,1.71,0,0,0-.54,1.13v4.91h-1.27V225a1.41,1.41,0,0,0-1.58-1.61,1.68,1.68,0,0,0-1.7,1.06v5.4h-1.27v-7.4Z" />
    <path className="cls-7"
      d="M741,229.84a2.63,2.63,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.39,1.39,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.83h-1.27a1.74,1.74,0,0,1,.39-1.07,2.69,2.69,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.24,2.24,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.29,2.29,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,738.87,228.88Z" />
    <path className="cls-7"
      d="M749.72,227.88a1,1,0,0,0-.38-.8,3.8,3.8,0,0,0-1.35-.49,6,6,0,0,1-1.52-.49,2.18,2.18,0,0,1-.84-.68,1.66,1.66,0,0,1-.27-.95,1.92,1.92,0,0,1,.77-1.53,3,3,0,0,1,2-.63,3.08,3.08,0,0,1,2,.65,2.05,2.05,0,0,1,.78,1.66h-1.27a1.16,1.16,0,0,0-.44-.9,1.67,1.67,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.27,2.27,0,0,1,.87.71,1.74,1.74,0,0,1,.29,1,1.9,1.9,0,0,1-.8,1.59,3.28,3.28,0,0,1-2.05.6,3.74,3.74,0,0,1-1.58-.32,2.49,2.49,0,0,1-1.06-.88,2.08,2.08,0,0,1-.39-1.22h1.26a1.35,1.35,0,0,0,.51,1,2.31,2.31,0,0,0,2.41.08A.88.88,0,0,0,749.72,227.88Z" />
    <path className="cls-7"
      d="M632.8,242.87a4.3,4.3,0,0,1,.43-2,3.11,3.11,0,0,1,1.19-1.34,3.41,3.41,0,0,1,4.18.57,4.05,4.05,0,0,1,.93,2.79V243a4.35,4.35,0,0,1-.41,1.94,3.21,3.21,0,0,1-1.18,1.34,3.28,3.28,0,0,1-1.77.48,3.11,3.11,0,0,1-2.44-1.05,4,4,0,0,1-.93-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.84,1.84,0,0,0-1.53-.75,1.79,1.79,0,0,0-1.51.74A3.4,3.4,0,0,0,634.07,243Z" />
    <path className="cls-7"
      d="M647.09,245.91a2.7,2.7,0,0,1-2.17.87,2.29,2.29,0,0,1-1.8-.69,3,3,0,0,1-.63-2v-4.82h1.27V244c0,1.12.45,1.68,1.36,1.68a1.89,1.89,0,0,0,1.93-1.08v-5.38h1.27v7.4h-1.21Z" />
    <path className="cls-7"
      d="M662.76,243a4.49,4.49,0,0,1-.77,2.73,2.52,2.52,0,0,1-2.1,1,2.57,2.57,0,0,1-2.16-1l-.07.85H656.5v-10.5h1.27v3.92a2.76,2.76,0,0,1,4.23.07,4.52,4.52,0,0,1,.76,2.78Zm-1.26-.14a3.46,3.46,0,0,0-.5-2,1.66,1.66,0,0,0-1.43-.7,1.85,1.85,0,0,0-1.8,1.16v3.2a1.89,1.89,0,0,0,1.81,1.16A1.63,1.63,0,0,0,661,245,3.62,3.62,0,0,0,661.5,242.88Z" />
    <path className="cls-7"
      d="M665.42,242.87a4.3,4.3,0,0,1,.43-2,3.11,3.11,0,0,1,1.19-1.34,3.2,3.2,0,0,1,1.74-.47,3.12,3.12,0,0,1,2.44,1,4.06,4.06,0,0,1,.94,2.79V243a4.35,4.35,0,0,1-.42,1.94,3.14,3.14,0,0,1-1.18,1.34,3.26,3.26,0,0,1-1.77.48,3.09,3.09,0,0,1-2.43-1.05,4,4,0,0,1-.94-2.77Zm1.27.15a3.19,3.19,0,0,0,.57,2,1.93,1.93,0,0,0,3.06,0,3.45,3.45,0,0,0,.57-2.12,3.14,3.14,0,0,0-.58-2,1.82,1.82,0,0,0-1.53-.75,1.79,1.79,0,0,0-1.51.74A3.46,3.46,0,0,0,666.69,243Z" />
    <path className="cls-7"
      d="M679.71,246.64a2.79,2.79,0,0,1-.18-.78,2.83,2.83,0,0,1-2.11.92,2.63,2.63,0,0,1-1.79-.62,2,2,0,0,1-.7-1.57,2.07,2.07,0,0,1,.88-1.79,4.1,4.1,0,0,1,2.47-.64h1.23v-.58a1.39,1.39,0,0,0-.4-1.06,1.59,1.59,0,0,0-1.17-.39,1.81,1.81,0,0,0-1.13.34,1,1,0,0,0-.46.82h-1.27a1.74,1.74,0,0,1,.39-1.06,2.69,2.69,0,0,1,1.07-.82,3.59,3.59,0,0,1,1.48-.3,2.9,2.9,0,0,1,2,.64,2.24,2.24,0,0,1,.75,1.76v3.4a4.21,4.21,0,0,0,.26,1.62v.11Zm-2.1-1a2.29,2.29,0,0,0,1.13-.31,1.91,1.91,0,0,0,.77-.8v-1.52h-1c-1.55,0-2.33.46-2.33,1.36a1.16,1.16,0,0,0,.4.93A1.48,1.48,0,0,0,677.61,245.68Z" />
    <path className="cls-7"
      d="M688.46,244.68a1,1,0,0,0-.38-.8,3.8,3.8,0,0,0-1.35-.49,6,6,0,0,1-1.52-.49,2.18,2.18,0,0,1-.84-.68,1.66,1.66,0,0,1-.27-.95,1.92,1.92,0,0,1,.77-1.53,3,3,0,0,1,2-.63,3.08,3.08,0,0,1,2,.65,2.05,2.05,0,0,1,.78,1.66h-1.27a1.16,1.16,0,0,0-.44-.9,1.67,1.67,0,0,0-1.11-.37,1.73,1.73,0,0,0-1.08.3.94.94,0,0,0-.39.78.76.76,0,0,0,.36.69,4.25,4.25,0,0,0,1.31.45,7,7,0,0,1,1.53.5,2.27,2.27,0,0,1,.87.71,1.74,1.74,0,0,1,.29,1,1.9,1.9,0,0,1-.8,1.59,3.28,3.28,0,0,1-2,.6,3.7,3.7,0,0,1-1.57-.32,2.46,2.46,0,0,1-1.07-.88,2.08,2.08,0,0,1-.39-1.22h1.26a1.35,1.35,0,0,0,.51,1,2.31,2.31,0,0,0,2.41.08A.88.88,0,0,0,688.46,244.68Z" />
    <line className="cls-8" x1="632.18" y1="189.29" x2="751.75" y2="189.29" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleUserAtendence()} className="cls-9" cx="204.95" cy="62.65" r="51.97" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleEconomicImpact()} className="cls-9" cx="258.89" cy="194.72" r="51.97" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleAmbientalImpact()} className="cls-9" cx="390.84" cy="248.64" r="51.97" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleSecuritView()} className="cls-9" cx="522.54" cy="193.84" r="51.97" />
    <circle style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-9" cx="576.33" cy="61.37" r="51.97" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleUserAtendence()} className="cls-10" x="7.18" y="38.14" width="145.12" height="45.38" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleUserAtendence()} className="cls-10" x="7.18" y="88.07" width="122.27" height="37.05" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleUserAtendence()} className="cls-10" x="7.18" y="142.01" width="122.27" height="37.05" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleEconomicImpact()} className="cls-10" x="162.54" y="245.97" width="119.93" height="45.38" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleEconomicImpact()} className="cls-10" x="162.54" y="293.88" width="119.93" height="24.11" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleEconomicImpact()} className="cls-10" x="162.54" y="330.12" width="119.93" height="61.44" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleAmbientalImpact()} className="cls-10" x="334.1" y="301.68" width="119.93" height="68.8" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleSecuritView()} className="cls-10" x="484.49" y="244.96" width="121.96" height="49.52" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-10" x="627.98" y="37.6" width="141.47" height="47.2" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-10" x="627.98" y="86.4" width="141.47" height="37.85" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-10" x="627.98" y="139.33" width="141.47" height="42.66" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleServiceQuality()} className="cls-10" x="627.98" y="196.59" width="141.47" height="57.36" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleSecuritView()} className="cls-10" x="484.49" y="296.76" width="150.43" height="19.81" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleSecuritView()} className="cls-10" x="484.49" y="332.65" width="159.07" height="42.06" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleAmbientalImpact()} className="cls-10" x="334.1" y="372.55" width="137.84" height="24.4" />
    <rect style={{ cursor: 'pointer' }} onClick={() => handleAmbientalImpact()} className="cls-10" x="334.1" y="409.63" width="152.3" height="24.4" />
  </svg>
    </>
  );
}