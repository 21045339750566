import React from 'react';

import { Container } from './style';

const Gradient: React.FC = () => {
  return <Container>
    <p>100,0%</p>
    <p>0,0%</p>
  </Container>;
}

export default Gradient;