import { ViewSecurityIcon } from './svg/ViewSecurityIcon';
import { UserAttendenceIcon } from './svg/userAttendenceIcon';
import { EconomicImpactIcon } from './svg/EconomicImpactIcon';
import { QualityServiceIcon } from './svg/QualityServiceIcon';
import { EnviromentImpactIcon } from './svg/EnviromentImpactIcon';

interface ITObject {
  icon: any;
  selected: boolean;
}

export const MobileIndicators: ITObject[] = [
  {
    selected: false,
    icon: <UserAttendenceIcon />,
  },
  {
    selected: false,
    icon: <EconomicImpactIcon />,
  },
  {
    selected: false,
    icon: <EnviromentImpactIcon />,
  },
  {
    selected: false,
    icon: <ViewSecurityIcon />,
  },
  {
    selected: false,
    icon: <QualityServiceIcon />,
  }
];