import React from 'react';
import {
  Nav,
  Divider,
  Container,
  Typograpy,
  NavWrapper,
  WrapperReport,
} from './styles';
import { TitleHeader } from './Title';
import { NavItems } from './NavItems';
import { useSidebar } from '@/hooks/useContexts';
import { BoxLogoMacroplan } from '../desktop/styles';
import Link from 'next/link';

export const MobileSidebar: React.FC = () => {
  const { clickReport, handleClickReport, openSidebarMobile } = useSidebar();
  return (
    <Container isOpen={openSidebarMobile}>
      <TitleHeader />
      <NavWrapper isOpen={openSidebarMobile}>
        <Nav>
          <NavItems />
          <WrapperReport>
            <Divider />
            <Typograpy
              clicked={clickReport}
              onClick={handleClickReport}
            >
              Boletim ABCR+
            </Typograpy>
            <Divider />
            <BoxLogoMacroplan marginTop='0'>
              <p>Powered by</p>
              <Link href={'https://www.analytics.macroplan.com.br/'} target='_blank'>
                <img className='img' src="/macroM.jpg" alt="" />
              </Link>
            </BoxLogoMacroplan>
          </WrapperReport>
        </Nav>
      </NavWrapper>
    </Container>
  );
};