interface ToggleActiveProps {
  array: any[];
  index: number;
  subItem?: any[]
}

  /**
   * @description essa função muda propriedades boleanas de um array de objetos
   * a fim de mapear ações que usuário tenha sobre o array inserido.
   *
   * ATENÇÃO: é necessário que essa propriedade seja nomeada como clicked
   * @param index
   * @param array
   * @param callback
   */
  export function toggleActiveAction<T>({ array, index, subItem }: ToggleActiveProps): T[] {
    const newArray = [...array.map(item => ({ ...item, clicked: false }))];

    newArray[index].clicked
    ? (newArray[index].clicked = false)
    : (newArray[index].clicked = true);

    return newArray;
  };