import React from 'react';

import { Container, PointerCircle } from './style';

interface Props{
  arrowSelected: boolean;
}
const SelectIndicator: React.FC<Props> = ({arrowSelected}) => {
  return <Container >
    <PointerCircle check={!arrowSelected} />
    <PointerCircle margin  check={arrowSelected} />
  </Container>;
}

export default SelectIndicator;