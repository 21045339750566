import React from 'react';
import {
  Item,
  Option,
  Options,
  ArrowIcon,
  Container,
  SelectMenu,
  SelectButton,
} from './styles';
import { useReport } from '@/hooks/useContexts';

export const SelectInput: React.FC = () => {
  const {
    openInput,
    inputItems,
    setOpenInput,
    selectedItemInput,
    handleChangeOptionsInput,
  } = useReport();

  React.useEffect(() => {
    const lastItem = inputItems[0];
    handleChangeOptionsInput(lastItem.text, lastItem.imageUrl);
    setOpenInput(false);
  }, []);

  // Ordenar os itens do select em ordem decrescente
  const sortedInputItems = inputItems.sort((a, b) => b.text.localeCompare(a.text));


  return (
    <Container>
      <SelectMenu isOpen={openInput}>
        <SelectButton onClick={() => setOpenInput(!openInput)}>
          <span>{selectedItemInput}</span>
          <ArrowIcon />
        </SelectButton>
        <Options>
        <ul style={{ overflowY: 'scroll', height: '200px' }}>
          {sortedInputItems.map((item) => (
            <Option
              key={item.text}
              onClick={({ currentTarget: { innerText } }) =>
                handleChangeOptionsInput(innerText, item.imageUrl)}
            >
              <Item>{item.text}</Item>
            </Option>
          ))}
          </ul>
        </Options>
      </SelectMenu>
    </Container>
  );
};