
import styled from 'styled-components';
import IdeaI from './svg/icon_idea.svg'
interface GreatProps {
  type?: string;
  heigth?: boolean;
  bg?: boolean;
  isChart?: boolean;
  isMap?:boolean;
  fullWidth?: boolean;
}
interface HeaderProps{
  padding?: boolean;
}
export const CardContainer = styled.div<GreatProps>`
  max-width: ${props => props.type === 'great' ? '100%' : '263px'};
  width: 100%;
  min-width: 263px;
  height: ${props => props.heigth ? '115%' : props.fullWidth ? '100%': '56%'};
  max-height: 672px;
  background: ${props => props.bg ? '#93D50A' : '#FFFFFF'} 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #00000017;
  border-radius: 30px;
  margin-bottom: 16px;
  padding: ${props => props.isMap ? '0px': '26px'};
  display: flex;
  margin-left:  ${props => (props.type === 'great' && !props.fullWidth) ? '20px' : '0px'};
  flex-direction: column;
  justify-content: center;
  .leaflet-container{
    background-color: transparent;
  }
  h1{
    text-align: left;
    letter-spacing: NaNpx;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 5px;
    @media (max-width: 820px) {
      text-align: center ;
    }
  }

  h1:nth-child(3){
    margin-top: 14px;
  }

  small{
    text-align: left;
    letter-spacing: -1px;
    font-size: 25px;
    color: #FFFFFF;

    @media (max-width: 820px) {
    }

  }
  p{
    font: normal normal bold 12px/18px Montserrat;
    letter-spacing: 1.5px;
    color: #006680;
   :nth-child(6){
    /* font: normal normal 800 17px/23px Montserrat; */
    letter-spacing: 1.7px;
    /* text-transform: uppercase; */
    margin-top: 5px;
   }
  }

  @media (max-width: 820px) {
    max-width:  100%;
    margin-left: 0;
    justify-content: center ;
    align-items: center;
    height: ${props => props.isChart ? props.heigth ? '570px' : '397px' : '275px'};

    p{
      text-align: center ;
    }
  }
`

export const IdeaIcon = styled(IdeaI)`
  margin-bottom: 18px;
  @media (max-width: 820px) {
    height: 55px ;
    scale:1.4;
  }
`

export const Tttle = styled.h4`
  text-align: left;
  font: normal normal 900 12px/14px Roboto;
  letter-spacing: 1.2px;
  color: #1D1D1B;
  margin: -5px 0px;
  margin-left: 19px;
  @media (max-width: 820px) {
  margin-left: 5px;
  text-align: center  ;

  }
`

export const Text = styled.h4`
  text-align: left;
  font: 500 normal normal 14px/27px Roboto;
  color: #1D1D1B;
  @media (max-width: 820px) {
  text-align: center ;
  }
`

export const Header = styled.div<HeaderProps>`
  height: auto;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  min-height: 30px;
  padding: ${({padding}) => padding ?'26px' : '0px'};
  border-bottom: 2px solid #E4EFED;

  @media (max-width: 820px) {
    min-height: 80px;
    align-items: center ;
  }
`


export const CardContainerLarge = styled.div<GreatProps>`
  width: 53%;
  height:93%;

  display: flex;
  max-width: 53%;
  
  min-width: 263px;
  max-height: 672px;
  
  text-align: center;
  border-radius: 30px;
  
  margin-bottom: 16px;
  flex-direction: column;
  
  justify-content: center;
  box-shadow: 5px 5px 10px #00000017;
  
  margin-left: ${props => props.bg ? '20px' : '0ox'};
  padding: ${props => props.isMap ? '0px': '26px'};
  
  background: ${props => props.bg ? '#93D50A' : '#FFFFFF'} 0% 0% no-repeat padding-box;

  .leaflet-container {
    background-color: transparent;
  }

  h1 {
    text-align: center;
    letter-spacing: NaNpx;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 5px;

    @media (max-width: 820px) {
      text-align: center ;
    }
  }

  h1:nth-child(3){
    margin-top: 14px;
  }

  small{
    text-align: left;
    letter-spacing: -1px;
    font-size: 25px;
    color: #FFFFFF;
  }
  p{
    font: normal normal bold 12px/18px Montserrat;
    letter-spacing: 1.5px;
    color: #006680;
   :nth-child(6){
    /* font: normal normal 800 17px/23px Montserrat; */
    letter-spacing: 1.7px;
    /* text-transform: uppercase; */
    margin-top: 5px;
   }
  }

  @media (max-width: 820px) {
    max-width:  100%;
    margin-left: 0px;
    justify-content: center ;
    align-items: center;
    height: ${props => props.isChart ? props.heigth ? '570px' : '397px' : '275px'}; 
    width: 100%;
    p{
      text-align: center ;
    }
  }
`