import React from 'react';
import BannerImage from './viaria.png';
import styled from 'styled-components';
import { shapeEnum } from '@/utils/enum';
import { MakeButtonsMemo } from '@/utils/factory';
import CardsMemo from '@/global/components/cardsMemo';
import { AbcrButton } from '@/global/components/abcrButton';
import { SessionBanner } from '@/global/components/sessionBanner';
import { LayoutSession } from '@/global/components/layout/session';
import { SessionButtons } from '@/global/components/sessionButtons';
import { HandleCardOptions, HandleChartOptions } from '@/utils/factory/layouts';
import { Grafismo } from '@/global/components/grafismo';

export const IMG = styled.img`

  max-width: 100%;
  height: auto;
  margin: 23rem 0 0 0;

  @media (min-width: 1600px) {
    max-width: 110%; 
    margin-left:-2.3%;
    margin-right:0; 
  }
  @media (max-width: 1599px) {
    max-width: 110%; 
    margin-left:-2.3% ; 
  }
  @media (max-width: 1240px) {
    max-width: 111%; 
    margin-left: -3%;
    margin-right:0;
  }
  @media (max-width: 1024px) {
    max-width:111%; 
    margin-left: -3.5%;
    margin-right:0;
  }
  @media (max-width: 767px) {
    max-width: 110%; 
    margin-left: -5%;
    margin-right: 0;
  }
`;


export interface Page {
  firstColumn: HandleCardOptions[] | HandleChartOptions[];
  secondColumn: HandleChartOptions[] | HandleCardOptions[],
}

interface RoadSecurityProps {
  dados: any;
}

export const RoadSecurity: React.FC<RoadSecurityProps> = ({ dados }) => {
  const { buttonsMemo, cardSelected } = MakeButtonsMemo(dados);

  const pageOne: Page = {
    firstColumn: [
      {
        type: shapeEnum.blockText,
      },
      {
        type: shapeEnum.bigNumber,
      }
    ],
    secondColumn: [
      {
        type: shapeEnum.reverseChart,
        mapperType:"line"
      },
      {
        type: shapeEnum.donutsChart,
      }
    ]
  };

  const pages: Page[] = [
    pageOne,
    pageOne
  ];

  return (
    <LayoutSession>
      <SessionBanner
        bannerImage={BannerImage}
        title={dados?.title}
        content={dados?.text}
      />
      <SessionButtons arrayCards={buttonsMemo} />
      {<CardsMemo dados={dados} cardSelected={cardSelected} pages={pages} />}
      <AbcrButton />
      <Grafismo margin="21rem 0 0 0"/>
    </LayoutSession>
  );
};

