import React from 'react';
import { Container } from './styles';

interface InformationP{
  info: string;
}

const Information: React.FC<InformationP> = ({ info }) => {
  return (
    <Container>
      <p style={{color:"white", alignSelf:"center", marginLeft:"auto", marginRight:"auto"}}>i</p>
      <div dangerouslySetInnerHTML={{ __html: `<span>${info as unknown as string}</span>` }} />
    </Container>
  );
};

export default Information;