import React from 'react';

/**
 *@description Essa função monitora o tamanho da tela
 * do device do usuário, retornando um valor boleano
* ex: '(min-width: 768px)'
 * @param query string
 * @returns boolean
 */

export const useMediaQuery = (query: string) => {
  const getMatches = (query: string): boolean => {
    // Prevenção de problemas de SSR
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [isMobile, setIsMobile] = React.useState<boolean>(getMatches(query));

  function handleChange() {
    setIsMobile(getMatches(query));
  }

  React.useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Acionado no primeiro carregamento do lado do cliente e se a consulta for alterada
    handleChange();

    // Listen matchMedia
    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeEventListener) {
        matchMedia.removeEventListener('change', handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query]);

  return { isMobile };
};