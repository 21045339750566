import React from 'react';
import { usePagination } from '@/hooks/usePagination';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { toggleActiveAction } from '@/utils/toggleActiveAction';
import { Text, Container, WrapperItem, WrapperArrow, ArrowTi, ArrowTp } from './styles';

interface CardProps {
  text: string;
  clicked: boolean;
  onChange: () => any;
}

interface IProps {
  arrayCards: CardProps[];
}

export const SessionButtons: React.FC<IProps> = ({ arrayCards }) => {
  const [items, setItems] = React.useState<CardProps[]>(arrayCards);
  const { data, onNextPage, onPrevPage } = usePagination(items, 2);
  const { isMobile } = useMediaQuery('(max-width: 580px)');

  const handleClickAttendenceCard = (indexCard: number) => {
    const newArray = toggleActiveAction<CardProps>({
      array: items,
      index: indexCard,
      subItem: data,
    });
    setItems(newArray);
  };

  return (
    <>
      <Container>
        {!isMobile && (
          <>
          {data.map((item, index) => (
            <WrapperItem
              items={items}
              index={index}
              key={item.text}
              isMobile={isMobile}
              style={{marginRight: index === 0 ? "20px": '0px'}}
              onClick={() => {
                handleClickAttendenceCard(index);
                item.onChange();
              }}
            >
              <Text>
                {item.text}
              </Text>
            </WrapperItem>
          ))}
          </>
        )}

        {isMobile && (
          <>
            {data.map((item, index) => {
              return (
                <WrapperItem
                items={items}
                index={index}
                key={item.text}
                isMobile={isMobile}
                  onClick={() => {
                    handleClickAttendenceCard(index);
                    item.onChange();
                  }}
                >
                <Text>
                  {item.text}
                </Text>
                </WrapperItem>
              );
            })}
          </>
        )}
      </Container>
      {items.length > 2 && (
        <WrapperArrow len={data.length}>
          {data.length === 2 && (
          <ArrowTp onClick={() => {
            onNextPage('service_quality');
            handleClickAttendenceCard(0);

          }} />
          )}
          {data.length === 1 && (
          <ArrowTi onClick={() => {
            onPrevPage('service_quality');
          }}/>
          )}
        </WrapperArrow>
      )}
    </>
  );
};
