import { MenuBarItemProps } from "@/context/sidebar/interfaces";
import { useSidebar } from "@/hooks/useContexts";
import { toggleActiveAction } from "@/utils/toggleActiveAction";

export const QualityServiceIcon = () => {
    const { setItems, setClickReport, items } = useSidebar();
  const handleServiceQuality = () => {
  const newItems = toggleActiveAction<MenuBarItemProps>({
    array: items,
    index: 5
  });
    setClickReport(false);
    setItems(newItems);
  };
  return (
    <>
      <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473 747">
  <defs>
    <style>
      {
        `
        .cls-1,
        .cls-7,
        .cls-8 {
          fill: none;
          stroke: #1e303a;
          stroke-miterlimit: 10;
        }
  
        .cls-1 {
          stroke-width: 4px;
        }
  
        .cls-2 {
          fill: #2f82c1;
        }
  
        .cls-3 {
          fill: #1e303a;
        }
  
        .cls-10,
        .cls-4 {
          fill: #fff;
        }
  
        .cls-5 {
          fill: #006680;
        }
  
        .cls-6 {
          fill: #1d1d1b;
        }
  
        .cls-8 {
          stroke-width: 2.5px;
        }
  
        .cls-9 {
          fill: #93c022;
        }
  
        .cls-10 {
          opacity: 0;
        }
        `
      }
    </style>
  </defs>
  <title>Painel ABCR mobile</title>
  <line className="cls-1" x1="11.78" y1="123" x2="459.78" y2="123" />
  <path className="cls-2"
    d="M199.33,23.37v6.7h-3.14V12h6.91a7,7,0,0,1,4.81,1.58,5.31,5.31,0,0,1,1.77,4.17,5.15,5.15,0,0,1-1.74,4.14,7.35,7.35,0,0,1-4.88,1.47Zm0-2.53h3.77a3.71,3.71,0,0,0,2.55-.78,2.9,2.9,0,0,0,.88-2.28,3.13,3.13,0,0,0-.89-2.34,3.45,3.45,0,0,0-2.45-.9h-3.86Z" />
  <path className="cls-2"
    d="M222.55,30.07a4.83,4.83,0,0,1-.34-1.25,4.7,4.7,0,0,1-3.52,1.5,4.77,4.77,0,0,1-3.3-1.15,3.7,3.7,0,0,1-1.28-2.86A3.82,3.82,0,0,1,215.7,23a7.75,7.75,0,0,1,4.56-1.15h1.85V21a2.36,2.36,0,0,0-.58-1.66,2.31,2.31,0,0,0-1.78-.63,2.65,2.65,0,0,0-1.68.51,1.6,1.6,0,0,0-.66,1.31h-3a3.34,3.34,0,0,1,.74-2.06,4.8,4.8,0,0,1,2-1.51,7,7,0,0,1,2.81-.55,5.6,5.6,0,0,1,3.75,1.19,4.19,4.19,0,0,1,1.44,3.33v6a6.84,6.84,0,0,0,.51,2.89v.21Zm-3.31-2.17a3.39,3.39,0,0,0,1.68-.43,2.92,2.92,0,0,0,1.19-1.17V23.77h-1.63a4.5,4.5,0,0,0-2.51.58,1.88,1.88,0,0,0-.85,1.65,1.8,1.8,0,0,0,.58,1.39A2.24,2.24,0,0,0,219.24,27.9Z" />
  <path className="cls-2"
    d="M230.82,13.17a1.63,1.63,0,0,1,.44-1.16,2,2,0,0,1,2.53,0,1.63,1.63,0,0,1,.44,1.16,1.57,1.57,0,0,1-.44,1.13,2,2,0,0,1-2.53,0A1.57,1.57,0,0,1,230.82,13.17Zm3.2,16.9h-3V16.65h3Z" />
  <path className="cls-2"
    d="M242.64,16.65l.09,1.55a4.81,4.81,0,0,1,3.9-1.8q4.2,0,4.27,4.8v8.87h-3V21.38a2.75,2.75,0,0,0-.55-1.89,2.32,2.32,0,0,0-1.8-.62,2.91,2.91,0,0,0-2.72,1.65v9.55h-3V16.65Z" />
  <path className="cls-2"
    d="M262.42,30.32a6.23,6.23,0,0,1-4.65-1.81,6.56,6.56,0,0,1-1.78-4.8v-.37a8,8,0,0,1,.78-3.59,5.85,5.85,0,0,1,2.17-2.46,5.76,5.76,0,0,1,3.13-.89,5.26,5.26,0,0,1,4.23,1.75,7.39,7.39,0,0,1,1.5,5v1.22H259A4.05,4.05,0,0,0,260.14,27a3.36,3.36,0,0,0,2.45,1A4.08,4.08,0,0,0,266,26.24l1.63,1.55a5.57,5.57,0,0,1-2.15,1.87A6.79,6.79,0,0,1,262.42,30.32Zm-.36-11.5a2.55,2.55,0,0,0-2,.87,4.58,4.58,0,0,0-1,2.42h5.74v-.22a3.62,3.62,0,0,0-.8-2.29A2.52,2.52,0,0,0,262.06,18.82Z" />
  <path className="cls-2" d="M275.88,30.07h-3V11h3Z" />
  <path className="cls-3"
    d="M171.23,68.61H159.77l-2,6.48h-8.94L161.56,40.4h7.86l12.82,34.69h-9Zm-9.46-6.46h7.46l-3.74-12Z" />
  <path className="cls-3"
    d="M189.65,75.09V40.4h12.46q6.69,0,10.18,2.45a8.17,8.17,0,0,1,3.5,7.13,8.32,8.32,0,0,1-1.24,4.6,7.3,7.3,0,0,1-3.65,2.81,7.25,7.25,0,0,1,4.17,2.67,7.74,7.74,0,0,1,1.45,4.76c0,3.4-1.07,6-3.23,7.66s-5.34,2.58-9.56,2.61ZM198,54.86h4.36a6.18,6.18,0,0,0,3.91-1A3.56,3.56,0,0,0,207.45,51a3.76,3.76,0,0,0-1.27-3.17,6.78,6.78,0,0,0-4.07-1H198Zm0,5.48v8.32h5.48a5.22,5.22,0,0,0,3.48-1,3.62,3.62,0,0,0,1.21-2.92c0-2.89-1.43-4.34-4.31-4.36Z" />
  <path className="cls-3"
    d="M254.07,63.35a12.65,12.65,0,0,1-1.95,6.39,11.77,11.77,0,0,1-5,4.3,17,17,0,0,1-7.37,1.53q-6.84,0-10.77-4.46T225,58.53V56.82a21.08,21.08,0,0,1,1.77-8.93A13.45,13.45,0,0,1,231.91,42a14.28,14.28,0,0,1,7.72-2.08q6.31,0,10.15,3.32a13.13,13.13,0,0,1,4.36,9.16H245.8q-.09-3.16-1.59-4.55a6.58,6.58,0,0,0-4.58-1.38,5,5,0,0,0-4.57,2.34c-1,1.55-1.48,4-1.53,7.45V58.7c0,3.7.47,6.35,1.4,7.93s2.53,2.39,4.8,2.39a6.46,6.46,0,0,0,4.41-1.36,5.82,5.82,0,0,0,1.62-4.31Z" />
  <path className="cls-3"
    d="M275.8,62.82h-4.53V75.09h-8.36V40.4h13.65q6.18,0,9.65,2.74a9.26,9.26,0,0,1,3.48,7.73,11.35,11.35,0,0,1-1.46,6,10.46,10.46,0,0,1-4.59,3.86l7.24,14v.35h-9Zm-4.53-6.43h5.29a4.74,4.74,0,0,0,3.59-1.26,4.84,4.84,0,0,0,1.2-3.5,4.9,4.9,0,0,0-1.21-3.52,4.73,4.73,0,0,0-3.58-1.28h-5.29Z" />
  <path className="cls-3" d="M313.73,55.41h8.13V62.3h-8.13v9.31h-7.51V62.3h-8.14V55.41h8.14v-9h7.51Z" />
  <circle className="cls-4" cx="236.52" cy="123" r="22" />
  <path className="cls-3"
    d="M238.56,148A25.05,25.05,0,1,1,261.49,125,25.07,25.07,0,0,1,238.56,148Zm.1-45.83a21,21,0,1,0,18.72,18.71A21,21,0,0,0,238.66,102.14Z" />
  <path className="cls-5" d="M250.68,121.14a14.29,14.29,0,1,1-12.31-12.3A14.29,14.29,0,0,1,250.68,121.14Z" />
  <line className="cls-1" x1="236.52" y1="145" x2="236.52" y2="163" />
  <path className="cls-6" d="M169.28,508.17h3.06l-3.66,4h-2Zm.08,25.4h-2.63v-19.9h2.63Z" />
  <path className="cls-6"
    d="M179,518.78l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.77h-2.53v-9.78a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V518.78Z" />
  <path className="cls-6"
    d="M194.2,526.05a8.64,8.64,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.33l-.12-1.58a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.37,3.37,0,0,0,2.88,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.38,3.38,0,0,0-2.9,1.44A7.24,7.24,0,0,0,196.73,526.34Z" />
  <path className="cls-6"
    d="M213.42,514.86a1.51,1.51,0,0,1,.37-1,1.42,1.42,0,0,1,1.12-.43,1.45,1.45,0,0,1,1.12.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.43,1.42,1.42,0,0,1-1.12-.41A1.44,1.44,0,0,1,213.42,514.86Zm2.73,18.71h-2.53V518.78h2.53Z" />
  <path className="cls-6"
    d="M228.93,531.78a3.65,3.65,0,0,0,2.37-.82,2.91,2.91,0,0,0,1.12-2.05h2.39a4.66,4.66,0,0,1-.87,2.42,5.69,5.69,0,0,1-2.16,1.83,6.18,6.18,0,0,1-2.85.69,6.06,6.06,0,0,1-4.8-2,8,8,0,0,1-1.79-5.52v-.42a9,9,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.89,5.89,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.55,3.55,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1,3.94A3.58,3.58,0,0,0,228.93,531.78Z" />
  <path className="cls-6"
    d="M246.59,533.85a6.46,6.46,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.59,6.59,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,246.59,533.85Zm-.31-13.26a3.38,3.38,0,0,0-2.57,1.11,5.39,5.39,0,0,0-1.29,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.16,3.16,0,0,0,246.28,520.59Z" />
  <path className="cls-6"
    d="M267.2,526.05a8.64,8.64,0,0,1,1.62-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.33l-.12-1.58a5.15,5.15,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.37,3.37,0,0,0-2.9,1.44A7.24,7.24,0,0,0,269.73,526.34Z" />
  <path className="cls-6"
    d="M292.55,533.85a6.46,6.46,0,0,1-4.9-2,7.37,7.37,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.85,5.85,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.49,4.49,0,0,0,2.27-.54,5.85,5.85,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,292.55,533.85Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.17,3.17,0,0,0,292.23,520.59Z" />
  <path className="cls-6"
    d="M146.65,557.05a8.16,8.16,0,0,0-1.25-.09,3.32,3.32,0,0,0-3.37,2.11v10.5H139.5V554.78H142l0,1.71a3.94,3.94,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M157.65,569.85a6.43,6.43,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.53,5.53,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,157.65,569.85Zm-.31-13.26a3.36,3.36,0,0,0-2.57,1.11,5.32,5.32,0,0,0-1.29,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.16,3.16,0,0,0,157.34,556.59Z" />
  <path className="cls-6"
    d="M175.08,567.78a3.61,3.61,0,0,0,2.36-.82,2.89,2.89,0,0,0,1.13-2.05H181a4.59,4.59,0,0,1-.88,2.42,5.66,5.66,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.08,6.08,0,0,1-4.81-2,8,8,0,0,1-1.78-5.52v-.42a8.87,8.87,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93A5.91,5.91,0,0,1,179.2,556a5.34,5.34,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.54,3.54,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1,3.94A3.56,3.56,0,0,0,175.08,567.78Z" />
  <path className="cls-6" d="M189.35,569.57h-2.53v-21h2.53Z" />
  <path className="cls-6"
    d="M205.33,569.57A5.09,5.09,0,0,1,205,568a5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.55a3.52,3.52,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.59,4.59,0,0,1,1.5,3.52v6.81a8.5,8.5,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,201.13,567.65Z" />
  <path className="cls-6"
    d="M216.61,554.78l.06,1.64a5.49,5.49,0,0,1,4.39-1.91,4.23,4.23,0,0,1,4.23,2.38,5.62,5.62,0,0,1,1.92-1.73,5.75,5.75,0,0,1,2.79-.65q4.88,0,4.95,5.15v9.91h-2.53v-9.76a3.39,3.39,0,0,0-.72-2.37,3.19,3.19,0,0,0-2.43-.79,3.32,3.32,0,0,0-2.34.85,3.4,3.4,0,0,0-1.08,2.26v9.81h-2.54v-9.69c0-2.15-1.06-3.23-3.16-3.23a3.39,3.39,0,0,0-3.41,2.12v10.8h-2.53V554.78Z" />
  <path className="cls-6"
    d="M250.7,569.57a5.16,5.16,0,0,1-.35-1.55,5.66,5.66,0,0,1-4.22,1.83,5.18,5.18,0,0,1-3.58-1.24,4,4,0,0,1-1.41-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.55a3.52,3.52,0,0,1,.79-2.14,5.35,5.35,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.56,4.56,0,0,1,1.5,3.52v6.81a8.5,8.5,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,246.5,567.65Z" />
  <path className="cls-6"
    d="M265.53,567.78a3.65,3.65,0,0,0,2.37-.82,2.88,2.88,0,0,0,1.12-2.05h2.39a4.57,4.57,0,0,1-.87,2.42,5.69,5.69,0,0,1-2.16,1.83,6.18,6.18,0,0,1-2.85.69,6.07,6.07,0,0,1-4.8-2,8,8,0,0,1-1.79-5.52v-.42a8.88,8.88,0,0,1,.8-3.84,6,6,0,0,1,2.27-2.61,6.48,6.48,0,0,1,3.51-.93,5.87,5.87,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87H269a3.47,3.47,0,0,0-1.09-2.36,3.34,3.34,0,0,0-2.41-.92,3.55,3.55,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1,3.94A3.58,3.58,0,0,0,265.53,567.78Zm.41,1.92-.16.71a2.21,2.21,0,0,1,2.09,2.36,2.42,2.42,0,0,1-1.09,2.1,5.19,5.19,0,0,1-3,.77l-.1-1.46a3.39,3.39,0,0,0,1.68-.35,1.12,1.12,0,0,0,.59-1,1,1,0,0,0-.44-.89,4.34,4.34,0,0,0-1.77-.41l.43-1.83Z" />
  <path className="cls-6"
    d="M276.38,562a8.74,8.74,0,0,1,.86-3.91,6.35,6.35,0,0,1,2.38-2.68,6.43,6.43,0,0,1,3.48-.94A6.27,6.27,0,0,1,288,556.6a8.05,8.05,0,0,1,1.86,5.56v.18a8.73,8.73,0,0,1-.83,3.88,6.32,6.32,0,0,1-2.36,2.67,6.53,6.53,0,0,1-3.53,1,6.25,6.25,0,0,1-4.88-2.09,8,8,0,0,1-1.87-5.54Zm11.48-13a3.35,3.35,0,0,1-.85,2.39,2.73,2.73,0,0,1-2.09.92,3.06,3.06,0,0,1-1-.14,4.67,4.67,0,0,1-1.08-.56,4.88,4.88,0,0,0-.95-.51,2.38,2.38,0,0,0-.68-.09,1.28,1.28,0,0,0-1,.43,1.6,1.6,0,0,0-.4,1.1l-1.69-.09A3.51,3.51,0,0,1,279,550a2.76,2.76,0,0,1,3-.82,6.8,6.8,0,0,1,1,.53,6.1,6.1,0,0,0,1,.52,2.35,2.35,0,0,0,.77.12,1.23,1.23,0,0,0,1-.46,1.63,1.63,0,0,0,.39-1.08Zm-8.93,13.33a6.33,6.33,0,0,0,1.14,3.95,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.23,6.29,6.29,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.6,3.6,0,0,0-3,1.49A6.82,6.82,0,0,0,278.93,562.34Z" />
  <path className="cls-6"
    d="M302,569.85a6.46,6.46,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.59,6.59,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,302,569.85Zm-.31-13.26a3.38,3.38,0,0,0-2.57,1.11,5.39,5.39,0,0,0-1.29,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.15,3.15,0,0,0,301.65,556.59Z" />
  <path className="cls-6"
    d="M322.07,565.65a1.87,1.87,0,0,0-.77-1.59,7.34,7.34,0,0,0-2.7-1,12.62,12.62,0,0,1-3-1,4.34,4.34,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.81,3.81,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.18,6.18,0,0,1,4.08,1.3,4.12,4.12,0,0,1,1.56,3.32h-2.54a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,8.84,8.84,0,0,0,2.62.89,13.6,13.6,0,0,1,3.07,1,4.42,4.42,0,0,1,1.74,1.42,3.45,3.45,0,0,1,.57,2,3.79,3.79,0,0,1-1.59,3.18,6.62,6.62,0,0,1-4.11,1.2,7.47,7.47,0,0,1-3.15-.63,5.18,5.18,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.65,2.65,0,0,0,1,2,3.93,3.93,0,0,0,2.51.74,4,4,0,0,0,2.3-.58A1.78,1.78,0,0,0,322.07,565.65Z" />
  <path className="cls-6" d="M112.63,454.36l5.69-16.42h2.87l-7.38,19.91h-2.32l-7.37-19.91H107Z" />
  <path className="cls-6"
    d="M131.8,458.12a6.43,6.43,0,0,1-4.89-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.28-1,5.53,5.53,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.05h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,131.8,458.12Zm-.31-13.26a3.36,3.36,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.29,3.13H135v-.19a4.78,4.78,0,0,0-1-3A3.16,3.16,0,0,0,131.49,444.86Z" />
  <path className="cls-6" d="M146,457.85h-2.53v-21H146Z" />
  <path className="cls-6"
    d="M152.22,450.31a8.74,8.74,0,0,1,.85-3.91,6.28,6.28,0,0,1,5.86-3.62,6.24,6.24,0,0,1,4.89,2.09,8.09,8.09,0,0,1,1.87,5.57v.17a8.84,8.84,0,0,1-.83,3.88,6.22,6.22,0,0,1-2.37,2.67,6.51,6.51,0,0,1-3.53,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.54.3a6.38,6.38,0,0,0,1.14,4,3.68,3.68,0,0,0,3.06,1.49,3.61,3.61,0,0,0,3.06-1.52,6.87,6.87,0,0,0,1.14-4.23,6.32,6.32,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.88,6.88,0,0,0,154.76,450.61Z" />
  <path className="cls-6"
    d="M177.59,456.06a3.69,3.69,0,0,0,2.37-.82,2.92,2.92,0,0,0,1.12-2.06h2.39a4.57,4.57,0,0,1-.87,2.42,5.8,5.8,0,0,1-2.16,1.84,6.23,6.23,0,0,1-2.85.68,6.07,6.07,0,0,1-4.8-2,8,8,0,0,1-1.79-5.51v-.43a8.88,8.88,0,0,1,.8-3.84,6,6,0,0,1,2.27-2.61,6.48,6.48,0,0,1,3.51-.93,5.92,5.92,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87h-2.39a3.49,3.49,0,0,0-1.09-2.36,3.39,3.39,0,0,0-2.41-.92,3.53,3.53,0,0,0-3,1.39,6.54,6.54,0,0,0-1.06,4v.48a6.38,6.38,0,0,0,1.05,3.93A3.56,3.56,0,0,0,177.59,456.06Z" />
  <path className="cls-6"
    d="M189.13,439.13a1.51,1.51,0,0,1,.37-1,1.7,1.7,0,0,1,2.24,0,1.47,1.47,0,0,1,.38,1,1.44,1.44,0,0,1-.38,1,1.49,1.49,0,0,1-2.61-1Zm2.73,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M198.09,450.33a8.65,8.65,0,0,1,1.62-5.48,5.49,5.49,0,0,1,8.34-.29v-7.71h2.53v21h-2.33l-.12-1.59a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.2-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.59,6.59,0,0,0,1,3.94,3.36,3.36,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16V447a3.71,3.71,0,0,0-3.49-2.09,3.4,3.4,0,0,0-2.9,1.43A7.26,7.26,0,0,0,200.62,450.61Z" />
  <path className="cls-6"
    d="M226.43,457.85a5.58,5.58,0,0,1-.35-1.56,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.18,4.18,0,0,1,1.75-3.59,8.26,8.26,0,0,1,5-1.28H226v-1.16a2.86,2.86,0,0,0-.8-2.12,3.17,3.17,0,0,0-2.33-.78,3.64,3.64,0,0,0-2.27.68,2,2,0,0,0-.92,1.66h-2.54A3.49,3.49,0,0,1,218,445a5.4,5.4,0,0,1,2.14-1.64,7.15,7.15,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.6,4.6,0,0,1,1.51,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.19-1.93a4.44,4.44,0,0,0,2.25-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3,3,0,0,0,222.24,455.92Z" />
  <path className="cls-6"
    d="M234.72,450.33a8.65,8.65,0,0,1,1.61-5.48,5.49,5.49,0,0,1,8.34-.29v-7.71h2.53v21h-2.33l-.12-1.59a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.59,6.59,0,0,0,1,3.94,3.37,3.37,0,0,0,2.88,1.42,3.69,3.69,0,0,0,3.51-2.16V447a3.71,3.71,0,0,0-3.49-2.09,3.4,3.4,0,0,0-2.9,1.43A7.26,7.26,0,0,0,237.25,450.61Z" />
  <path className="cls-6"
    d="M260.06,458.12a6.44,6.44,0,0,1-4.89-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.59,6.59,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,260.06,458.12Zm-.31-13.26a3.38,3.38,0,0,0-2.57,1.11,5.45,5.45,0,0,0-1.29,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.16,3.16,0,0,0,259.75,444.86Z" />
  <path className="cls-6"
    d="M283.67,443.05l.07,1.64a5.46,5.46,0,0,1,4.38-1.91,4.23,4.23,0,0,1,4.23,2.38,5.5,5.5,0,0,1,1.92-1.72,5.66,5.66,0,0,1,2.8-.66q4.86,0,4.94,5.15v9.92h-2.52v-9.77a3.43,3.43,0,0,0-.73-2.37,3.19,3.19,0,0,0-2.43-.78,3.36,3.36,0,0,0-2.34.84,3.4,3.4,0,0,0-1.08,2.26v9.82h-2.54v-9.7c0-2.15-1.06-3.22-3.16-3.22a3.38,3.38,0,0,0-3.41,2.12v10.8h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M314.77,458.12a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.27,3.54,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,314.77,458.12Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13H318v-.19a4.78,4.78,0,0,0-1-3A3.17,3.17,0,0,0,314.45,444.86Zm1.23-8h3.07l-3.67,4h-2Z" />
  <path className="cls-6"
    d="M325.65,450.33a8.71,8.71,0,0,1,1.61-5.48,5.49,5.49,0,0,1,8.34-.29v-7.71h2.53v21h-2.32l-.13-1.59a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.52,6.52,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.51-2.16V447a3.71,3.71,0,0,0-3.49-2.09,3.38,3.38,0,0,0-2.89,1.43A7.19,7.19,0,0,0,328.18,450.61Z" />
  <path className="cls-6"
    d="M344.87,439.13a1.51,1.51,0,0,1,.37-1,1.43,1.43,0,0,1,1.12-.42,1.45,1.45,0,0,1,1.12.42,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.44,1.47,1.47,0,0,1-1.12-.41A1.48,1.48,0,0,1,344.87,439.13Zm2.73,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M363.58,457.85a5.58,5.58,0,0,1-.35-1.56,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.18,4.18,0,0,1,1.75-3.59,8.23,8.23,0,0,1,4.94-1.28h2.47v-1.16a2.86,2.86,0,0,0-.8-2.12,3.21,3.21,0,0,0-2.34-.78,3.63,3.63,0,0,0-2.26.68,2,2,0,0,0-.92,1.66h-2.54a3.49,3.49,0,0,1,.78-2.14,5.4,5.4,0,0,1,2.14-1.64,7.15,7.15,0,0,1,3-.6,5.86,5.86,0,0,1,4,1.28,4.56,4.56,0,0,1,1.5,3.52v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.45,4.45,0,0,0,2.26-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,359.38,455.92Z" />
  <path className="cls-5"
    d="M153.81,359.26a14.12,14.12,0,0,1-1,5.61,9.17,9.17,0,0,1-2.87,3.75l3.91,3.05L151.26,374l-4.84-3.82a10.26,10.26,0,0,1-2,.2,9.08,9.08,0,0,1-4.91-1.35,9,9,0,0,1-3.35-3.85,13.63,13.63,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.88,8.88,0,0,1,3.33-3.9,9,9,0,0,1,4.91-1.35,9.19,9.19,0,0,1,5,1.34,8.76,8.76,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-4-1.18a9.85,9.85,0,0,0-1.44-5.83,4.73,4.73,0,0,0-4.06-2,4.64,4.64,0,0,0-4,2,9.69,9.69,0,0,0-1.48,5.67v1.32a9.93,9.93,0,0,0,1.45,5.77,4.64,4.64,0,0,0,4.05,2.08,4.69,4.69,0,0,0,4.06-2,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-5"
    d="M177.67,347.3v15.2a7.4,7.4,0,0,1-2.32,5.74,10.17,10.17,0,0,1-12.39,0,7.42,7.42,0,0,1-2.29-5.79V347.3h3.93v15.21a4.84,4.84,0,0,0,1.16,3.49,4.53,4.53,0,0,0,3.41,1.2q4.56,0,4.56-4.81V347.3Z" />
  <path className="cls-5"
    d="M197.48,364.75h-8.82l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.71-3.19h6.6l-3.3-9.44Z" />
  <path className="cls-5" d="M213.19,366.89h10.33v3.16H209.24V347.3h3.95Z" />
  <path className="cls-5" d="M233.92,370.05H230V347.3h3.94Z" />
  <path className="cls-5"
    d="M242,370.05V347.3h6.72a10.5,10.5,0,0,1,5.35,1.34,9.24,9.24,0,0,1,3.62,3.81,12.17,12.17,0,0,1,1.28,5.66v1.14a12.06,12.06,0,0,1-1.29,5.69,9.1,9.1,0,0,1-3.67,3.78,11.09,11.09,0,0,1-5.46,1.33Zm4-19.57v16.41h2.58a5.93,5.93,0,0,0,4.78-1.95,8.43,8.43,0,0,0,1.69-5.58v-1.27a8.69,8.69,0,0,0-1.61-5.65,5.68,5.68,0,0,0-4.67-2Z" />
  <path className="cls-5"
    d="M278.49,364.75h-8.81l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.7-3.19h6.59l-3.29-9.44Z" />
  <path className="cls-5"
    d="M290.26,370.05V347.3H297a10.5,10.5,0,0,1,5.35,1.34,9.22,9.22,0,0,1,3.61,3.81,12,12,0,0,1,1.29,5.66v1.14a12.06,12.06,0,0,1-1.29,5.69,9.12,9.12,0,0,1-3.68,3.78,11.06,11.06,0,0,1-5.46,1.33Zm3.95-19.57v16.41h2.58a5.93,5.93,0,0,0,4.77-1.95,8.43,8.43,0,0,0,1.7-5.58v-1.27a8.69,8.69,0,0,0-1.61-5.65,5.69,5.69,0,0,0-4.67-2Z" />
  <path className="cls-5" d="M327.66,359.89h-9.34v7h10.92v3.16H314.36V347.3h14.77v3.18H318.32v6.28h9.34Z" />
  <path className="cls-5"
    d="M106.8,407.05V384.3h6.72a10.5,10.5,0,0,1,5.35,1.34,9.24,9.24,0,0,1,3.62,3.81,12.17,12.17,0,0,1,1.28,5.66v1.14a12.06,12.06,0,0,1-1.29,5.69,9.16,9.16,0,0,1-3.67,3.78,11.09,11.09,0,0,1-5.46,1.33Zm4-19.57v16.41h2.58a5.93,5.93,0,0,0,4.77-1.95,8.43,8.43,0,0,0,1.7-5.58v-1.27a8.69,8.69,0,0,0-1.61-5.65,5.68,5.68,0,0,0-4.67-2Z" />
  <path className="cls-5"
    d="M149.06,396.26a13.82,13.82,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.7,9.7,0,0,1-9.87,0,8.89,8.89,0,0,1-3.35-3.85,13.48,13.48,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.92,8.92,0,0,1,3.32-3.9,9.71,9.71,0,0,1,9.86,0,8.69,8.69,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-3.95-1.18a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.7,9.7,0,0,0-1.48,5.68v1.31a9.9,9.9,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10,10,0,0,0,1.42-5.85Z" />
  <path className="cls-5"
    d="M168.09,401.19a2.76,2.76,0,0,0-1.06-2.32,12.91,12.91,0,0,0-3.8-1.64,18.4,18.4,0,0,1-4.38-1.84,5.83,5.83,0,0,1-3.1-5.09,5.54,5.54,0,0,1,2.24-4.54,9.1,9.1,0,0,1,5.82-1.78,9.87,9.87,0,0,1,4.23.88,6.85,6.85,0,0,1,2.92,2.49,6.39,6.39,0,0,1,1.07,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.81,4.81,0,0,0-3,.83,2.73,2.73,0,0,0-1.07,2.31,2.5,2.5,0,0,0,1.15,2.09,13.57,13.57,0,0,0,3.82,1.62,17.67,17.67,0,0,1,4.26,1.8,6.7,6.7,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.25,6.25,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.28,3,5.58,5.58,0,0,0,3.67,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,168.09,401.19Z" />
  <path className="cls-5"
    d="M201.77,401.19a2.78,2.78,0,0,0-1.05-2.32,13.12,13.12,0,0,0-3.81-1.64,18.13,18.13,0,0,1-4.37-1.84,5.82,5.82,0,0,1-3.11-5.09,5.54,5.54,0,0,1,2.24-4.54,9.1,9.1,0,0,1,5.82-1.78,9.87,9.87,0,0,1,4.23.88,6.94,6.94,0,0,1,2.93,2.49,6.46,6.46,0,0,1,1.06,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.83,4.83,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.49,2.49,0,0,0,1.16,2.09,13.35,13.35,0,0,0,3.81,1.62,17.67,17.67,0,0,1,4.26,1.8A6.7,6.7,0,0,1,205,398.1a6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.32,6.32,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.28,3,5.59,5.59,0,0,0,3.67,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,201.77,401.19Z" />
  <path className="cls-5" d="M225.69,396.89h-9.34v7h10.92v3.16H212.39V384.3h14.77v3.18H216.35v6.28h9.34Z" />
  <path className="cls-5"
    d="M242.05,398.3h-4.41v8.75h-3.95V384.3h8a9.27,9.27,0,0,1,6.07,1.76,6.26,6.26,0,0,1,2.14,5.12,6.37,6.37,0,0,1-1.1,3.82,6.9,6.9,0,0,1-3.07,2.37l5.11,9.47v.21h-4.23Zm-4.41-3.19h4.06a4.55,4.55,0,0,0,3.13-1,3.51,3.51,0,0,0,1.12-2.76,3.76,3.76,0,0,0-1-2.83,4.42,4.42,0,0,0-3.1-1h-4.17Z" />
  <path className="cls-5" d="M264.57,402.09l5.78-17.79h4.35l-8.19,22.75h-3.83l-8.15-22.75h4.32Z" />
  <path className="cls-5" d="M284.63,407.05H280.7V384.3h3.93Z" />
  <path className="cls-5"
    d="M310.05,399.64a8.19,8.19,0,0,1-2.69,5.68,9.15,9.15,0,0,1-6.23,2,8.88,8.88,0,0,1-4.79-1.29,8.46,8.46,0,0,1-3.19-3.66,13.26,13.26,0,0,1-1.18-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.67,8.67,0,0,1,3.28-3.78,9.16,9.16,0,0,1,4.93-1.33,8.78,8.78,0,0,1,6.06,2,8.54,8.54,0,0,1,2.67,5.78h-3.94a5.54,5.54,0,0,0-1.43-3.54,4.67,4.67,0,0,0-3.36-1.09,4.61,4.61,0,0,0-4,1.89,9.33,9.33,0,0,0-1.42,5.52v2a10,10,0,0,0,1.32,5.63,4.39,4.39,0,0,0,3.87,1.94,5.1,5.1,0,0,0,3.5-1.05,5.4,5.4,0,0,0,1.48-3.48Zm-7.56,7.44-.17.84a2.51,2.51,0,0,1,2.34,2.7,3,3,0,0,1-1.34,2.57,6.65,6.65,0,0,1-3.77.92l-.11-2.14a2.92,2.92,0,0,0,1.59-.37,1.15,1.15,0,0,0,.55-1,1,1,0,0,0-.55-1,5.1,5.1,0,0,0-1.81-.35l.5-2.17Z" />
  <path className="cls-5"
    d="M334.94,396.26a14,14,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.71,9.71,0,0,1-9.88,0,8.94,8.94,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.2-5.77v-1.28a14,14,0,0,1,1.18-5.88A8.71,8.71,0,0,1,325.5,384a9.13,9.13,0,0,1,4.93,1.34,8.76,8.76,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84ZM331,395.08a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.07,0A9.77,9.77,0,0,0,320,395v1.31a9.83,9.83,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10.1,10.1,0,0,0,1.42-5.85Z" />
  <path className="cls-5"
    d="M354,401.19a2.76,2.76,0,0,0-1.06-2.32,12.91,12.91,0,0,0-3.8-1.64,18.23,18.23,0,0,1-4.38-1.84,5.84,5.84,0,0,1-3.11-5.09,5.55,5.55,0,0,1,2.25-4.54,9.1,9.1,0,0,1,5.82-1.78,9.87,9.87,0,0,1,4.23.88,6.91,6.91,0,0,1,2.92,2.49,6.38,6.38,0,0,1,1.06,3.59H354a3.58,3.58,0,0,0-1.12-2.79,4.63,4.63,0,0,0-3.2-1,4.8,4.8,0,0,0-3,.83,2.73,2.73,0,0,0-1.07,2.31,2.5,2.5,0,0,0,1.15,2.09,13.31,13.31,0,0,0,3.82,1.62,17.67,17.67,0,0,1,4.26,1.8,6.79,6.79,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.42,5.42,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.62,7.62,0,0,1-3.22-2.52,6.25,6.25,0,0,1-1.15-3.75h3.95a3.7,3.7,0,0,0,1.28,3,5.62,5.62,0,0,0,3.68,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,354,401.19Z" />
  <line className="cls-7" x1="111.78" y1="489.79" x2="351.78" y2="489.79" />
  <path className="cls-6"
    d="M134.82,636.49v7.8h-2.63V624.38h7.34a7.37,7.37,0,0,1,5.12,1.67,6.28,6.28,0,0,1,0,8.88,7.69,7.69,0,0,1-5.18,1.56Zm0-2.14h4.71a4.68,4.68,0,0,0,3.23-1,3.6,3.6,0,0,0,1.12-2.86,3.74,3.74,0,0,0-1.12-2.85,4.35,4.35,0,0,0-3.08-1.11h-4.86Z" />
  <path className="cls-6"
    d="M158.22,644.56a6.43,6.43,0,0,1-4.89-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.86,8.86,0,0,1,.84-3.93,6.49,6.49,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.28-1,5.53,5.53,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.06H154a5.22,5.22,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,158.22,644.56Zm-.31-13.26a3.4,3.4,0,0,0-2.58,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.16,3.16,0,0,0,157.91,631.3Z" />
  <path className="cls-6"
    d="M176.87,631.76a8.16,8.16,0,0,0-1.25-.09,3.33,3.33,0,0,0-3.38,2.12v10.5h-2.52v-14.8h2.46l0,1.71a3.93,3.93,0,0,1,3.52-2,2.59,2.59,0,0,1,1.13.19Z" />
  <path className="cls-6"
    d="M187.66,642.5a3.69,3.69,0,0,0,2.37-.82,2.9,2.9,0,0,0,1.12-2h2.39a4.49,4.49,0,0,1-.87,2.41,5.8,5.8,0,0,1-2.16,1.84,6.23,6.23,0,0,1-2.85.68,6.07,6.07,0,0,1-4.8-2,8,8,0,0,1-1.79-5.51v-.43a8.88,8.88,0,0,1,.8-3.84,6,6,0,0,1,2.27-2.61,6.48,6.48,0,0,1,3.51-.93,6,6,0,0,1,4.14,1.49,5.33,5.33,0,0,1,1.75,3.87h-2.39a3.49,3.49,0,0,0-1.09-2.36,3.39,3.39,0,0,0-2.41-.92,3.53,3.53,0,0,0-3,1.39,6.54,6.54,0,0,0-1.06,4v.48a6.37,6.37,0,0,0,1.06,3.94A3.55,3.55,0,0,0,187.66,642.5Z" />
  <path className="cls-6"
    d="M205.33,644.56a6.46,6.46,0,0,1-4.9-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.86,8.86,0,0,1,.84-3.93,6.58,6.58,0,0,1,2.36-2.71,5.89,5.89,0,0,1,3.27-1,5.55,5.55,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.59,5.44v1.06h-10a5.32,5.32,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.2,6.2,0,0,1,205.33,644.56ZM205,631.3a3.38,3.38,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.84,4.84,0,0,0-1-3A3.17,3.17,0,0,0,205,631.3Z" />
  <path className="cls-6"
    d="M219.21,629.49l.08,1.86a5.4,5.4,0,0,1,4.43-2.13q4.69,0,4.74,5.29v9.78h-2.53V634.5a3.41,3.41,0,0,0-.74-2.37,2.91,2.91,0,0,0-2.23-.76,3.7,3.7,0,0,0-2.16.65,4.54,4.54,0,0,0-1.45,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M238.5,625.91v3.58h2.76v2H238.5v9.18a2,2,0,0,0,.37,1.34,1.55,1.55,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.17,3.17,0,0,1-2.56-1,4.46,4.46,0,0,1-.86-2.92v-9.17h-2.69v-2H236v-3.58Z" />
  <path className="cls-6"
    d="M256.15,642.82a5.38,5.38,0,0,1-4.34,1.74,4.63,4.63,0,0,1-3.6-1.37,6,6,0,0,1-1.25-4.07v-9.63h2.53v9.56q0,3.36,2.74,3.36,2.9,0,3.85-2.16V629.49h2.53v14.8H256.2Z" />
  <path className="cls-6"
    d="M274.38,644.29a5.49,5.49,0,0,1-.35-1.56,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.18,4.18,0,0,1,1.75-3.59,8.26,8.26,0,0,1,4.95-1.28H274v-1.16a2.86,2.86,0,0,0-.8-2.12,3.17,3.17,0,0,0-2.33-.78,3.71,3.71,0,0,0-2.27.68,2,2,0,0,0-.92,1.66h-2.54a3.49,3.49,0,0,1,.78-2.14,5.4,5.4,0,0,1,2.14-1.64,7.15,7.15,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.6,4.6,0,0,1,1.51,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.19-1.93a4.44,4.44,0,0,0,2.25-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3,3,0,0,0,270.19,642.36Z" />
  <path className="cls-6" d="M286,644.29H283.5v-21H286Z" />
  <path className="cls-6"
    d="M302,636.77a8.65,8.65,0,0,1,1.62-5.48,5.09,5.09,0,0,1,4.22-2.07A5.14,5.14,0,0,1,312,631v-7.71h2.53v21h-2.33L312,642.7a5.15,5.15,0,0,1-4.23,1.86,5.06,5.06,0,0,1-4.19-2.1A8.79,8.79,0,0,1,302,637Zm2.53.28a6.59,6.59,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.52-2.16v-6.79a3.71,3.71,0,0,0-3.49-2.09,3.39,3.39,0,0,0-2.9,1.43A7.26,7.26,0,0,0,304.52,637.05Z" />
  <path className="cls-6"
    d="M327.34,644.56a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.86,8.86,0,0,1,.84-3.93,6.49,6.49,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.06h-10a5.26,5.26,0,0,0,1.27,3.53,4,4,0,0,0,3.12,1.35,4.42,4.42,0,0,0,2.26-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,327.34,644.56ZM327,631.3a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.17,3.17,0,0,0,327,631.3Z" />
  <path className="cls-6"
    d="M121.56,667.76a7.94,7.94,0,0,0-1.24-.09,3.33,3.33,0,0,0-3.38,2.12v10.5h-2.53v-14.8h2.46l0,1.71a3.92,3.92,0,0,1,3.52-2,2.51,2.51,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M125.74,672.75a8.74,8.74,0,0,1,.86-3.91,6.41,6.41,0,0,1,2.38-2.68,6.52,6.52,0,0,1,3.48-.94,6.27,6.27,0,0,1,4.89,2.09,8.08,8.08,0,0,1,1.86,5.57v.17a8.67,8.67,0,0,1-.83,3.88A6.26,6.26,0,0,1,136,679.6a6.54,6.54,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.36,6.36,0,0,0,1.14,4,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.24,6.31,6.31,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.82,6.82,0,0,0,128.29,673.05Z" />
  <path className="cls-6"
    d="M144.57,672.77a8.71,8.71,0,0,1,1.61-5.48,5.49,5.49,0,0,1,8.34-.29v-7.71h2.53v21h-2.32l-.13-1.59a5.14,5.14,0,0,1-4.22,1.86,5.06,5.06,0,0,1-4.19-2.1,8.79,8.79,0,0,1-1.62-5.5Zm2.53.28a6.52,6.52,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.71,3.71,0,0,0-3.48-2.09,3.39,3.39,0,0,0-2.9,1.43A7.19,7.19,0,0,0,147.1,673.05Z" />
  <path className="cls-6"
    d="M163.1,672.75a8.74,8.74,0,0,1,.86-3.91,6.41,6.41,0,0,1,2.38-2.68,6.52,6.52,0,0,1,3.48-.94,6.27,6.27,0,0,1,4.89,2.09,8.08,8.08,0,0,1,1.86,5.57v.17a8.67,8.67,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.54,6.54,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.36,6.36,0,0,0,1.14,4,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.24,6.31,6.31,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.82,6.82,0,0,0,165.65,673.05Z" />
  <path className="cls-6" d="M187.22,676.86l3.66-11.37h2.59l-5.31,14.8h-1.93l-5.35-14.8h2.58Z" />
  <path className="cls-6"
    d="M198.71,661.57a1.51,1.51,0,0,1,.38-1,1.41,1.41,0,0,1,1.11-.42,1.46,1.46,0,0,1,1.13.42,1.51,1.51,0,0,1,.38,1A1.37,1.37,0,0,1,200.2,663a1.44,1.44,0,0,1-1.11-.41A1.49,1.49,0,0,1,198.71,661.57Zm2.74,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M217.43,680.29a5.09,5.09,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28H217v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.44,3.44,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.34,2.34,0,0,0,.79,1.86A3,3,0,0,0,213.23,678.36Z" />
  <path className="cls-6"
    d="M234.94,676.36a1.87,1.87,0,0,0-.77-1.59,7.61,7.61,0,0,0-2.7-1,12.87,12.87,0,0,1-3.05-1,4.29,4.29,0,0,1-1.66-1.37,3.28,3.28,0,0,1-.54-1.89,3.84,3.84,0,0,1,1.53-3.07,6,6,0,0,1,3.94-1.26,6.18,6.18,0,0,1,4.08,1.3,4.13,4.13,0,0,1,1.56,3.32h-2.54a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.43,3.43,0,0,0-2.16.6,1.9,1.9,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,8.84,8.84,0,0,0,2.62.89,13.77,13.77,0,0,1,3.07,1,4.6,4.6,0,0,1,1.74,1.42,3.46,3.46,0,0,1,.57,2,3.76,3.76,0,0,1-1.59,3.17,6.62,6.62,0,0,1-4.11,1.2,7.44,7.44,0,0,1-3.15-.63,5.11,5.11,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.65,2.65,0,0,0,1,2,3.93,3.93,0,0,0,2.51.75,4.14,4.14,0,0,0,2.3-.58A1.8,1.8,0,0,0,234.94,676.36Z" />
  <path className="cls-6"
    d="M252.63,672.75a8.74,8.74,0,0,1,.85-3.91,6.41,6.41,0,0,1,2.38-2.68,6.55,6.55,0,0,1,3.48-.94,6.27,6.27,0,0,1,4.89,2.09,8.08,8.08,0,0,1,1.86,5.57v.17a8.81,8.81,0,0,1-.82,3.88,6.28,6.28,0,0,1-2.37,2.67,6.53,6.53,0,0,1-3.53,1,6.25,6.25,0,0,1-4.88-2.09,8,8,0,0,1-1.86-5.54Zm2.54.3a6.36,6.36,0,0,0,1.14,4,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.24,6.31,6.31,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.82,6.82,0,0,0,255.17,673.05Zm5.32-13.76h3.06l-3.66,4h-2Z" />
  <path className="cls-6"
    d="M275.5,661.91v3.58h2.76v2H275.5v9.18a2.1,2.1,0,0,0,.36,1.34,1.55,1.55,0,0,0,1.26.44,6.17,6.17,0,0,0,1.21-.16v2a7.5,7.5,0,0,1-1.95.27,3.14,3.14,0,0,1-2.55-1,4.4,4.4,0,0,1-.86-2.92v-9.17h-2.7v-2H273v-3.58Z" />
  <path className="cls-6"
    d="M284,661.57a1.51,1.51,0,0,1,.38-1,1.41,1.41,0,0,1,1.11-.42,1.46,1.46,0,0,1,1.13.42,1.51,1.51,0,0,1,.38,1,1.37,1.37,0,0,1-1.51,1.44,1.44,1.44,0,0,1-1.11-.41A1.49,1.49,0,0,1,284,661.57Zm2.74,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M296,665.49l.07,1.64a5.49,5.49,0,0,1,4.39-1.91,4.23,4.23,0,0,1,4.23,2.38,5.5,5.5,0,0,1,1.92-1.72,5.64,5.64,0,0,1,2.79-.66q4.88,0,4.95,5.16v9.91H311.8v-9.76a3.44,3.44,0,0,0-.72-2.38,3.23,3.23,0,0,0-2.44-.78,3.35,3.35,0,0,0-2.33.84,3.4,3.4,0,0,0-1.08,2.26v9.82h-2.55v-9.7q0-3.23-3.15-3.22a3.38,3.38,0,0,0-3.41,2.12v10.8h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M330.08,680.29a5.09,5.09,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.44,3.44,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.59,4.59,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.34,2.34,0,0,0,.79,1.86A3,3,0,0,0,325.88,678.36Z" />
  <path className="cls-6"
    d="M347.59,676.36a1.87,1.87,0,0,0-.77-1.59,7.61,7.61,0,0,0-2.7-1,12.62,12.62,0,0,1-3-1,4.25,4.25,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.82,3.82,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.18,6.18,0,0,1,4.08,1.3,4.13,4.13,0,0,1,1.56,3.32h-2.54a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.9,1.9,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,8.84,8.84,0,0,0,2.62.89,13.77,13.77,0,0,1,3.07,1,4.6,4.6,0,0,1,1.74,1.42,3.46,3.46,0,0,1,.57,2,3.76,3.76,0,0,1-1.59,3.17,6.62,6.62,0,0,1-4.11,1.2,7.47,7.47,0,0,1-3.15-.63,5.11,5.11,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.65,2.65,0,0,0,1,2,3.93,3.93,0,0,0,2.51.75,4.12,4.12,0,0,0,2.3-.58A1.8,1.8,0,0,0,347.59,676.36Z" />
  <path className="cls-6"
    d="M175,708.75a8.74,8.74,0,0,1,.86-3.91,6.41,6.41,0,0,1,2.38-2.68,6.52,6.52,0,0,1,3.48-.94,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.57v.17a8.81,8.81,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.57,6.57,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.36,6.36,0,0,0,1.14,4,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.24,6.31,6.31,0,0,0-1.15-3.94,3.85,3.85,0,0,0-6.1,0A6.88,6.88,0,0,0,177.55,709.05Z" />
  <path className="cls-6"
    d="M203.57,714.82a5.35,5.35,0,0,1-4.33,1.74,4.6,4.6,0,0,1-3.6-1.37,5.93,5.93,0,0,1-1.25-4.07v-9.63h2.53v9.56q0,3.36,2.73,3.36,2.9,0,3.86-2.16V701.49H206v14.8h-2.41Z" />
  <path className="cls-6"
    d="M234.93,709.05a8.77,8.77,0,0,1-1.56,5.45,5,5,0,0,1-4.18,2.06,5.15,5.15,0,0,1-4.33-2l-.13,1.71h-2.32v-21h2.53v7.83a5.12,5.12,0,0,1,4.22-1.9,5,5,0,0,1,4.23,2,9,9,0,0,1,1.54,5.58Zm-2.53-.28a6.84,6.84,0,0,0-1-4,3.32,3.32,0,0,0-2.88-1.41,3.71,3.71,0,0,0-3.59,2.32v6.4a3.8,3.8,0,0,0,3.62,2.32,3.3,3.3,0,0,0,2.83-1.4A7.25,7.25,0,0,0,232.4,708.77Z" />
  <path className="cls-6"
    d="M240.25,708.75a8.74,8.74,0,0,1,.85-3.91,6.47,6.47,0,0,1,2.38-2.68,6.57,6.57,0,0,1,3.48-.94,6.26,6.26,0,0,1,4.89,2.09,8.09,8.09,0,0,1,1.87,5.57v.17a8.81,8.81,0,0,1-.83,3.88,6.22,6.22,0,0,1-2.37,2.67,6.51,6.51,0,0,1-3.53,1,6.25,6.25,0,0,1-4.88-2.09,8,8,0,0,1-1.86-5.54Zm2.54.3a6.36,6.36,0,0,0,1.14,4,3.87,3.87,0,0,0,6.12,0,6.92,6.92,0,0,0,1.14-4.24,6.32,6.32,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.82,6.82,0,0,0,242.79,709.05Z" />
  <path className="cls-6"
    d="M268.82,716.29a5.41,5.41,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13A4.19,4.19,0,0,1,261,708.6a8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.5,3.5,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28A4.59,4.59,0,0,1,271,706v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.45,4.45,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.8,1.86A3,3,0,0,0,264.62,714.36Z" />
  <path className="cls-6"
    d="M286.33,712.36a1.87,1.87,0,0,0-.77-1.59,7.53,7.53,0,0,0-2.7-1,12.62,12.62,0,0,1-3-1,4.25,4.25,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.82,3.82,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.18,6.18,0,0,1,4.08,1.3,4.13,4.13,0,0,1,1.56,3.32h-2.54a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.9,1.9,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,8.84,8.84,0,0,0,2.62.89,13.6,13.6,0,0,1,3.07,1,4.6,4.6,0,0,1,1.74,1.42,3.46,3.46,0,0,1,.57,2,3.76,3.76,0,0,1-1.59,3.17,6.62,6.62,0,0,1-4.11,1.2,7.47,7.47,0,0,1-3.15-.63,5.11,5.11,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.65,2.65,0,0,0,1,2,3.93,3.93,0,0,0,2.51.75,4.12,4.12,0,0,0,2.3-.58A1.8,1.8,0,0,0,286.33,712.36Z" />
  <line className="cls-7" x1="111.78" y1="600.5" x2="351.78" y2="600.5" />
  <line className="cls-8" x1="73.9" y1="135.5" x2="73.9" y2="144" />
  <circle className="cls-4" cx="73.9" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M75.25,135.78a12.36,12.36,0,1,1,11-11.63A12.38,12.38,0,0,1,75.25,135.78Zm-.59-22.61a10.35,10.35,0,1,0,9.5,9A10.36,10.36,0,0,0,74.66,113.17Z" />
  <path className="cls-5" d="M80.86,122.38a7.05,7.05,0,1,1-6.25-5.9A7.05,7.05,0,0,1,80.86,122.38Z" />
  <circle className="cls-4" cx="398.56" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M399.56,135.81a12.36,12.36,0,1,1,11.32-11.31A12.37,12.37,0,0,1,399.56,135.81Zm.06-22.62a10.35,10.35,0,1,0,9.23,9.24A10.37,10.37,0,0,0,399.62,113.19Z" />
  <path className="cls-5" d="M405.55,122.57a7,7,0,1,1-6.08-6.07A7.06,7.06,0,0,1,405.55,122.57Z" />
  <line className="cls-8" x1="398.56" y1="135.5" x2="398.56" y2="144" />
  <path className="cls-3"
    d="M253.47,297.15a2.51,2.51,0,0,1-.72-4.92,56.44,56.44,0,1,0-70.31-37.82,2.51,2.51,0,0,1-4.81,1.44A61.47,61.47,0,1,1,254.19,297,2.62,2.62,0,0,1,253.47,297.15Z" />
  <path className="cls-3"
    d="M236.48,318.33a80.17,80.17,0,1,1,76.78-103.22,80.18,80.18,0,0,1-76.78,103.22ZM236.54,163a75.14,75.14,0,1,0,71.91,53.53A75.27,75.27,0,0,0,236.54,163Z" />
  <path className="cls-9"
    d="M235.65,215.38a18.45,18.45,0,1,0,18.46,18.45A18.46,18.46,0,0,0,235.65,215.38Zm-.09,8.47a1.34,1.34,0,0,1,1.19.65l2.63,5.23,5.8.8a1.28,1.28,0,0,1,.72,2.17l-4.17,4.11,1,5.76a1.27,1.27,0,0,1-1.84,1.35l-5.2-2.68L230.55,244a1.27,1.27,0,0,1-1.86-1.33l.95-5.77-4.22-4.06a1.26,1.26,0,0,1,.69-2.17l5.79-.88,2.56-5.26a1.25,1.25,0,0,1,1.1-.67Z" />
  <path className="cls-9"
    d="M226.44,252.67v15a1.28,1.28,0,0,0,2,1l7.19-5.06,7.21,5.06a1.27,1.27,0,0,0,2-1v-15a20.72,20.72,0,0,1-18.42,0Z" />
  <path className="cls-3"
    d="M73.87,200.08a25.15,25.15,0,0,1-24-17.89,1,1,0,0,1,2-.59,23,23,0,1,0,15.44-28.7,1,1,0,0,1-1.28-.69,1,1,0,0,1,.69-1.27,25.09,25.09,0,1,1,7.19,49.14Z" />
  <path className="cls-3"
    d="M73.89,207.7a33,33,0,0,1-31.33-23.31,32.75,32.75,0,0,1,31.35-42.14,32.73,32.73,0,0,1,0,65.45Zm0-63.4a30.85,30.85,0,0,0-8.83,1.3,30.66,30.66,0,0,0-20.55,38.19,30.85,30.85,0,0,0,29.36,21.86,30.71,30.71,0,0,0,29.38-39.5A30.86,30.86,0,0,0,73.91,144.3Z" />
  <path className="cls-9"
    d="M75,181.41h9.75l-5.36-14.26h-4.9l.07,1.92H73.28l.07-1.92H68.43l-5.36,14.26h9.75l.16-4.3h1.83Zm-.23-6.63H73.07l.13-3.38h1.42Z" />
  <path className="cls-3"
    d="M405.48,199.06a1,1,0,0,1-1-.73,1,1,0,0,1,.69-1.28,23,23,0,1,0-28.71-15.44,1,1,0,1,1-2,.59A25.1,25.1,0,1,1,405.78,199,1,1,0,0,1,405.48,199.06Z" />
  <path className="cls-3"
    d="M398.55,207.71a32.93,32.93,0,0,1-31.33-23.32,32.75,32.75,0,0,1,31.35-42.14,32.73,32.73,0,0,1,0,65.46Zm0-63.41a30.85,30.85,0,0,0-8.83,1.3,30.69,30.69,0,0,0-20.56,38.2,30.88,30.88,0,0,0,29.37,21.86,30.71,30.71,0,0,0,29.38-39.5A30.86,30.86,0,0,0,398.57,144.3Z" />
  <path className="cls-9"
    d="M408.09,178.78l-5,1.42v0a1.94,1.94,0,0,1-.2,1.49,2,2,0,0,1-1.26,1,2.14,2.14,0,0,1-1.6-.18l-2.9-1.63.26-.49,2.91,1.62a1.49,1.49,0,0,0,1.15.13,1.53,1.53,0,0,0,.91-.73,1.68,1.68,0,0,0,.19-.6,1.23,1.23,0,0,0-.61-1.26l-7-3.92a2.16,2.16,0,0,0-1.73-.21h0c-.19,0-7.75,2.19-8.06,2.27h0l1.6,5.47,5.05-1.44c.52.31,4.36,2.7,4.84,3a2.84,2.84,0,0,0,2.14.16L409,182a1.74,1.74,0,0,0,1-2.12,1.56,1.56,0,0,0-1.91-1.05Z" />
  <path className="cls-9"
    d="M408.53,169.81h-.91a5,5,0,0,0-.63-1.51l.73-.74a.3.3,0,0,0,.08-.21.34.34,0,0,0-.08-.21l-1-1a.29.29,0,0,0-.39,0l-.76.76a5.23,5.23,0,0,0-1.36-.57v-1a.29.29,0,0,0-.29-.29h-1.41a.29.29,0,0,0-.29.29v.94a4.2,4.2,0,0,0-1.41.58l-.81-.81a.27.27,0,0,0-.39,0l-1,1a.26.26,0,0,0-.08.21.26.26,0,0,0,.08.2l.78.79a4.59,4.59,0,0,0-.65,1.54h-.92a.29.29,0,0,0-.29.29v1.41a.29.29,0,0,0,.29.29h.92a4.25,4.25,0,0,0,.57,1.39l-.73.73a.32.32,0,0,0,0,.42l1,1a.32.32,0,0,0,.42,0l.7-.71a4.85,4.85,0,0,0,1.57.66v.81a.29.29,0,0,0,.29.28H404a.29.29,0,0,0,.29-.28v-.84a4.62,4.62,0,0,0,1.49-.63l.6.6a.29.29,0,0,0,.21.08.26.26,0,0,0,.21-.08l1-1a.32.32,0,0,0,0-.42l-.63-.63a4.91,4.91,0,0,0,.57-1.41h.92a.29.29,0,0,0,.29-.29V170.1c-.11-.16-.21-.29-.37-.29Zm-4.84,3.74a2.8,2.8,0,1,1,2.2-2.2A2.79,2.79,0,0,1,403.69,173.55Z" />
  <circle onClick={() => handleServiceQuality()} className="cls-10" cx="236" cy="239" r="85" />
  <rect onClick={() => handleServiceQuality()} className="cls-10" x="94" y="332" width="279" height="86" />
  <rect onClick={() => handleServiceQuality()} className="cls-10" x="93" y="427" width="286" height="45" />
  <rect onClick={() => handleServiceQuality()} className="cls-10" x="122" y="499" width="219" height="82" />
  <rect onClick={() => handleServiceQuality()} className="cls-10" x="94" y="612" width="279" height="117" />
</svg>
    </>
  );
}