import styled, { keyframes, css } from 'styled-components';
import ArrowSvg from '../svg/arrow.svg';

interface ArrowIconProps {
  isOpen: boolean;
}

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  border-radius: 1rem;
  padding: 1rem 0 1rem 0;
  
  flex-direction: column;
  justify-content: center;
  
  position: sticky;
  background: #1E303A;

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.49);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.49);
`;

export const Title = styled.h1`
  color: #2F83C2;
  font-size: 18px;

  text-align: center;
  letter-spacing: 2.2px;
  
  font-family: var(--font-main);
`;

export const SubTitle = styled.h2`
  color: #FFFFFF;
  font-size: 30px;
  
  text-align: center;
  letter-spacing: 4.2px;
  
  font-family: var(--font-main);
`;


export const WrapperArroIcon = styled.div``;

const upRotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(180deg) }
`;

const downRotate = keyframes`
  from { transform: rotate(180deg) }
  to { transform: rotate(0deg) }
`;

export const ArrowIcon = styled(ArrowSvg)<ArrowIconProps>`
  width: 25px;
  ${({ isOpen }) => isOpen ? css`
    animation: ${upRotate} .5s forwards;
  `: css`animation: ${downRotate} .5s forwards;`};
`;