import styled from "styled-components";

export interface ContainerProps {
  mt?: string;
}

export const Container = styled.div<ContainerProps>`
  width: 30px;
  height: 26px;
  display: flex;
  cursor: pointer;
  margin-top: -20px;
  border-radius: 3px;
  background: #00556E 0% 0% no-repeat padding-box;
  
  &:hover {
    background-color: #2F83C2;

    div{
      display: block;
    }
  }

  div {
    width: 150px;
    padding:10px;
    height: auto;
    display: none;
    z-index: 9999999;
    position: absolute;
    margin: -66px -154px;
    background: #E4EFED 0% 0% no-repeat padding-box;
    
    h4 {
      color: #1D1D1B;
      font-size: 11px;
      margin-top: 5px;
      letter-spacing: -0.2px;
    }

    span {
      font-size: 11px;
      color: #1D1D1B;
      letter-spacing: -0.2px;
    }
  }
`;
