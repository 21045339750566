import React from 'react';
import { Container, Image } from './style';

interface IProps {
  margin: string;
  isReportAbrc?: boolean;
}

export const Grafismo: React.FC<IProps> = ({ isReportAbrc=false, margin }) => (
  <Container>
    <Image margin={margin}  src="grafismoABCR.png" alt="" isReportAbrc={isReportAbrc}/>
  </Container>
);

