import { MakeNumberToLocale } from '@/utils/factory';
import { makeSufixBigNumber, makeSufixEndBigNumber } from '@/utils/factory/layouts';
import React from 'react';
import { CardContainer, CardContainerLarge, Header, IdeaIcon, Text, Tttle } from './style';


interface CardPros {
  type?: 'great' | 'small';
  heigth?: boolean;
  bg?: boolean;
  children?: React.ReactNode;
  text?: string;
  title?: string;
  bigNumber?: any;
  isMap?: boolean;
  tipo?: any
  fullWidth?: boolean;
}

/**
 * @description Card propociona um componente capaz de ser 
 * trasnformado tanto na vertical quanto horizontal
 * @param type string: 'small' || 'great'
 * @param heigth boolean 
 * @returns React.FC
 */
const Card: React.FC<CardPros> = ({ fullWidth, type, tipo, heigth, title, text, bg, children, bigNumber, isMap }) => {
  const generateBigNumber = (bigNumber: any) => {
    return <>
      {bigNumber.map((big: any, index: number) => {
        const unit = big.unit;
        const value = MakeNumberToLocale(bigNumber[index]?.value);
        const formatterFront = makeSufixBigNumber(bigNumber[index]?.format)
        const formartterEnd = makeSufixEndBigNumber(bigNumber[index]?.format)

        const titleOne = () => {
          if (index === 0) {
            return <p style={{ marginBottom: "18px" }}> {bigNumber[0]?.title}</p>
          }
        }


        return <>
        <div>
        {titleOne()}
          <h1>{formatterFront}{value}<small style={{ fontSize: "18px" }}> {unit}</small>{formartterEnd}</h1>
          <p>{bigNumber[index]?.subtitle}</p>
          <p>{bigNumber[index]?.text}</p>
        </div>
        
        </>
      })}
    </>
  }
  const handleContent = () => {
    return <>

      {type == 'small' && !bg &&
        <IdeaIcon />
      }
      {
        type == 'small' && !bg &&
        <Text>{text}</Text>
      }
      {bg &&
        <>
          {generateBigNumber(bigNumber)}
        </>
      }
      {
        (type == 'great' || isMap) &&
        <Header padding={isMap} >
          <Tttle>{title}</Tttle>
        </Header>
      }
      {title && (
        <div style={{ width: "100%", height: type === 'great' ? '672px' : "289px", padding: "20px", display: "flex" }}>
          {children}
        </div>
      )}

    </>
  }
  if (tipo === 'cardLarge') {

    return <CardContainerLarge  heigth={heigth} type={type} bg={bg} isChart={title ? true : false} isMap={isMap} >
      {handleContent()}
    </CardContainerLarge>
  }

  return <CardContainer fullWidth={fullWidth} heigth={heigth} type={type} bg={bg} isChart={title ? true : false} isMap={isMap} >
    {handleContent()}

  </CardContainer>;
}

export default Card;