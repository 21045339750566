import React from 'react';
import GlobalStyles from '@/global/styles/global';
import { MiddewarePages } from '@/components/app';

const AppPage = ()  => {
  return (
    <>
      <GlobalStyles />
      <MiddewarePages />
    </>
  );
};

export default AppPage;
