import React from 'react';
import { shapeEnum } from '@/utils/enum';
import BannerImage from './ambiental.png';
import { MakeButtonsMemo } from '@/utils/factory';
import CardsMemo from '@/global/components/cardsMemo';
import { AbcrButton } from '@/global/components/abcrButton';
import { SessionBanner } from '@/global/components/sessionBanner';
import { LayoutSession } from '@/global/components/layout/session';
import { SessionButtons } from '@/global/components/sessionButtons';
import { HandleCardOptions, HandleChartOptions } from '@/utils/factory/layouts';
import { Grafismo } from '@/global/components/grafismo';


interface AmbientalImpactProps {
  dados: any
}

export interface Page {
  firstColumn: HandleCardOptions[] | HandleChartOptions[];
  secondColumn: HandleChartOptions[] | HandleCardOptions[],
}

export const AmbientalImpact: React.FC<AmbientalImpactProps> = ({ dados }) => {
  const { buttonsMemo, cardSelected } = MakeButtonsMemo(dados);

  const pageOne: Page | any = {
    firstColumn: [
      {
        type: shapeEnum.blockText,
        tipo: 'cardLarge'
      },
      {
        type: shapeEnum.bigNumber,
        tipo: 'cardLarge'
      },
      true
    ],
    secondColumn: [
      {
        type: shapeEnum.areaChart,
        fullWidth: true
      }
    ]
  };

  const pageTwo: Page = {
    firstColumn: [
      {
        type: shapeEnum.blockText
      },
      {
        type: shapeEnum.bigNumber
      }
    ],
    secondColumn: [
      {
        type: shapeEnum.reverseChart
      },
      {
        type: shapeEnum.lineChart,
        period: 'month'
      }
    ]
  };

  const pages: Page[] = [pageOne, pageTwo];

  return (
    <LayoutSession>
      <SessionBanner
        bannerImage={BannerImage}
        title={dados?.title}
        content={dados?.text}
      />
      <SessionButtons arrayCards={buttonsMemo} />
      {
        <CardsMemo
          dados={dados}
          cardSelected={cardSelected}
          pages={pages}
          flex
        />
      }
      <AbcrButton />
      <Grafismo margin="20rem 0 0 0"/>
    </LayoutSession>
  );
};
