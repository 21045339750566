import styled, { css } from 'styled-components';
interface ArrowIconProps {
  isOpen: boolean;
}

export const Container = styled.div<ArrowIconProps>`
  position:relative;
  
  max-width: 100%;
  min-width: 100%;

  margin: 0 0 1rem 0;

  height: fit-content;

  background: #2A3B43;
  border-radius: 1rem;

  ${({ isOpen }) => isOpen && css`
    border-radius: 1rem 1rem 0 0;
  `};
`;

export const Nav = styled.nav`
  width: 70%;
  height: 100%;

  display: flex;
  align-items: center;
  
  flex-direction: column;
  justify-content: center;
`;

export const NavWrapper = styled.div<ArrowIconProps>`
  position:absolute;
  top:100%;
  width:100%;
  right:0;
  z-index:99999 !important;
  background: #2A3B43;
  border-radius: 1rem;

  align-items: center;
  justify-content: center;

  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  
  ${({ isOpen }) => isOpen && css`
    border-radius: 0 0 1rem 1rem;
  `};
`;

export const WrapperReport = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 1.5rem 0;

  align-items: center;
  flex-direction: column;

  justify-content: center;

  @media (max-width: 360px) {
    margin: 1rem 0 1rem 0;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  
  background: #fff;
`;

interface TypograpyProps {
  clicked: boolean;
}

export const Typograpy = styled.p<TypograpyProps>`
  cursor: pointer;
  text-align: left;
  padding: .5rem 0;

  letter-spacing: 1.4px;

  font-family: Roboto, sans-serif;
  color: ${props => props.clicked ? '#93D50A': '#E4EFED'};

  &:hover {
    color: #93D50A;
  }
`;