import React from 'react';
import { shapeEnum } from '@/utils/enum';
import BannerImage from './economico.png';
import { MakeButtonsMemo } from '@/utils/factory';
import CardsMemo from '@/global/components/cardsMemo';
import { AbcrButton } from '@/global/components/abcrButton';
import { SessionBanner } from '@/global/components/sessionBanner';
import { LayoutSession } from '@/global/components/layout/session';
import { SessionButtons } from '@/global/components/sessionButtons';
import { HandleCardOptions, HandleChartOptions } from '@/utils/factory/layouts';
import { Grafismo } from '@/global/components/grafismo';

export interface Page {
  firstColumn: HandleCardOptions[] | HandleChartOptions[];
  secondColumn: HandleChartOptions[] | HandleCardOptions[],
}

interface EconomicImpactProps {
  dados: any;
}


export const EconomicImpact: React.FC<EconomicImpactProps> = ({ dados }) => {
  const { buttonsMemo, cardSelected } = MakeButtonsMemo(dados);


  const pageOne: Page = {
    firstColumn: [
      {
        type: shapeEnum.blockText,
      },
      {
        type: shapeEnum.bigNumber,
      }
    ],
    secondColumn: [
      {
        type: shapeEnum.columnBarChart,
      },
      {
        type: shapeEnum.reverseChart
      }
    ]
  };

  const pageTwo: Page = {
    firstColumn: [
      {
        type: shapeEnum.blockText,
      },
      {
        type: shapeEnum.bigNumber,
      }
    ],
    secondColumn: [
      {
        type: shapeEnum.reverseChart

      },
      {
        type: shapeEnum.lineChart,
        period: 'year'

      }
    ]
  };

  const pages: Page[] = [
    pageOne,
    pageTwo
  ];

  return (
    <LayoutSession>
      <SessionBanner
        bannerImage={BannerImage}
        title={dados?.title}
        content={dados?.text}
      />
      <SessionButtons arrayCards={buttonsMemo} />
      {<CardsMemo dados={dados} cardSelected={cardSelected} pages={pages} />}
      <AbcrButton />
      <Grafismo margin="21rem 0 0 0" />
    </LayoutSession>
  );
};

