import styled from 'styled-components';
import ArrowLeft from './svg/arrow_left.svg';
import ArrowT from './svg/arrow_rigth.svg';

import { StaticImageData } from 'next/image';

interface IProps {
  image: StaticImageData
}


export const WrapperPainel = styled.div`
  width: 73%;
  display: flex;
  

  height: fit-content
  ;
  align-items: center;

  margin: 0 0 0 1.2rem;

  @media (max-width: 820px) {
    width: 100%;
    /* margin: 0 0 70rem 0; */
  }

  img{
    
  }
  

  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
  border-radius: 30px 30px;
  height: fit-content;
  
  @media (max-width: 820px) {
    width: 100%;
    margin: .7rem 0 0 0;
  }
`;



export const WrapperHeader = styled.div`
  display: flex;
  
  min-height: 120px;
  background: #1E303A;

  justify-content: center;
  align-items: flex-start;

  border-radius: 30px 30px 0px 0px;
`;
export const WrapperDescription = styled.div`
  width: 75%;
  display: flex;
  
  align-items: center;
  
  justify-content: space-between;
  margin: 2rem 0 0 0;

  @media (max-width: 820px) {
    width: 90%;
    align-items: flex-start;
  }
`;

export const WrapperLefIcon = styled.div`
  width: 42.4px;
  height: 34.32px;
  resize: both;

  @media screen and (max-width: 820px) {
    transform: scale(.5);
    margin: -0.3rem 0 0 0;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
`;

export const Description = styled.p`
  color: #FFFFFF;
  word-break: keep-all;

  text-align: center;
  font: normal normal 900 17px/20px Roboto;
  letter-spacing: 1.7px;

  @media screen and (max-width: 820px) {
    margin: 0 1rem 0 0;
  }
`;

export const WrapperAbcr = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0 2rem 0;
  padding: 0 3rem 0 3rem;
  align-items: center;
  justify-content: center;
`;

export const MobileAbcrWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 0 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const WrapperAttendance = styled.div`
  width: 100%;
  margin: 1rem 0 0 0;
  display: flex;
  justify-content: center;
  position: relative;

  span {
    height: 3px;
    width: 20px;
    background: #1D1D1B;
  }
`;

export const WrapperArrow = styled.div`
  width: 75%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  margin: 2rem 0 0 0;
`;
export const ArrowTi = styled(ArrowT)`
  cursor: pointer;
`;
export const ArrowTp = styled(ArrowT)`
  transform: rotate(180deg);
  cursor: pointer;

  margin: 0 0 0 1rem;
`;
