import _ from 'lodash';
import React from 'react';
import { useSidebar } from '@/hooks/useContexts';
import { ContentWrapper, Item, WrapperItems } from './styles';
import { MenuBarItemProps } from '@/context/sidebar/interfaces';
import { toggleActiveAction } from '@/utils/toggleActiveAction';
import { toggleActiveStyles } from '@/utils/toggleActiveStyles';

export const NavItems: React.FC = () => {
  const { items, setClickReport, setItems, setOpenSidebarMobile } = useSidebar();
  const handleToggleActiveSessionItens = (indexSession: number) => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: indexSession,
    });
    setItems(newItems);
    setClickReport(false);
    setOpenSidebarMobile(false);
  };

  return (
    <WrapperItems>
      {items.map((item, index) => (
        <Item
          onClick={() => handleToggleActiveSessionItens(index)}
          color={toggleActiveStyles(items, index, false, true)}
          key={item.ref}
        >
          <ContentWrapper color={toggleActiveStyles(items, index, true, false)}>
            {item.main_icon}
            <p>{item.text}</p>
          </ContentWrapper>
        </Item>
      ))}
    </WrapperItems>
  );
};