import React from 'react';
import { MakeButtonsMemo } from '@/utils/factory';
import BannerImage from './banner_atendimento.png';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grafismo } from '@/global/components/grafismo';
import { AbcrButton } from '@/global/components/abcrButton';
import { PreHospitalCare } from './indicators/PreHospitalCare';
import { SessionBanner } from '@/global/components/sessionBanner';
import { LayoutSession } from '@/global/components/layout/session';
import { MechanicalService } from './indicators/MechanicalService';
import { SessionButtons } from '@/global/components/sessionButtons';

interface UserAttendanceProps {
  dados: any;
}

export const UserAttendance: React.FC<UserAttendanceProps> = ({ dados }) => {
  const { buttonsMemo, cardSelected } = MakeButtonsMemo(dados);
  const { isMobile } = useMediaQuery('(max-width: 820px)');

  const parseData = React.useMemo(
    () => dados?.indicators[cardSelected],
    [dados, cardSelected]
  );

  return (
    <LayoutSession>
      <SessionBanner
        title={dados?.title}
        content={dados?.text}
        bannerImage={BannerImage}
      />
      <SessionButtons arrayCards={buttonsMemo} />
      {cardSelected === 0 && ( <PreHospitalCare dados={parseData} /> )}
      {cardSelected === 1 && ( <MechanicalService dados={parseData} /> )}
      <AbcrButton />
      <Grafismo margin={!isMobile ? "-2rem 0 0 0": "21rem 0 0 0"} />
    </LayoutSession>
  );
};
