import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_ptBR from "@amcharts/amcharts5/locales/pt_BR";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { buildingColumnSeries } from '@/utils/performCharts/ColumnBar/mapper';
import { getYear } from '@/utils/factory';

export const performColumnBarChart = (root: am5.Root, series: any, isMobile: boolean, title: string, dados: any) => {
  const data = buildingColumnSeries(series);
  root.setThemes([am5themes_Animated.new(root)]);
  root.locale = am5locales_ptBR;


  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  const chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: false,
    panY: false,
    wheelX: "panX",
    wheelY: "zoomX",
    layout: root.verticalLayout
  }));
  chart.get('colors')?.set("colors", [am5.color('#006680'), am5.color('#9ED03C')]);


  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  const legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(100),
      centerY: am5.percent(50),
      y: isMobile ? am5.percent(90) : am5.percent(100),
      x: isMobile ? am5.percent(100) : am5.percent(109) ,
    })
  );

  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    categoryField: "period",
    forceHidden: isMobile ? false : true,
    renderer: am5xy.AxisRendererX.new(root, {}),
  }));


  xAxis.data.setAll([data]);

  const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    renderer: am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    })
  }));

  xAxis.get('renderer').grid.template.set('forceHidden', true);

  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function makeSeries(name: any, fieldName: any) {
    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name,
      xAxis,
      yAxis,
      valueYField: fieldName,
      categoryXField: "period",
    }));

    series.columns.template.setAll({
      tooltipText: "{categoryX}: [bold]{valueY}[/]",
    });

    series.data.setAll([data]);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();

    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: 0,
          centerX: am5.p50,
        })
      });
    });

    legend.data.push(series);
  }

  makeSeries("Concedidas", "concedidas");
  makeSeries("Não concedidas", "nao_concedidas");
  
  const exporting = am5plugins_exporting.Exporting.new(root, {
    menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    dataSource: [
      { periodo: getYear(series[0].data[0]?.period), valor: series[0].data[0]?.value, abertura: 'concedidas', unidade_medida: dados.chart_unit},
      { periodo: getYear(series[1].data[0]?.period), valor: series[1].data[0]?.value, abertura: 'Não concedidas', unidade_medida: dados.chart_unit}
    ], 
    filePrefix: title

  });

  exporting.get("menu")?.set("items", [
    { type: "format", format: "xlsx", label: "xlsx" },
    { type: "format", format: "png", label: "png" }
  ]);

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  chart.appear(1000, 100);
};