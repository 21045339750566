import {
  IdeaIcon,
  WhiteCard,
  GreenCard,
  GraphicCard,
  WrapperCard,
  WrapperChart,
} from './styles';
import React from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Information from '@/global/components/layout/information';
import { MakeNumberToLocale } from '@/utils/factory';

const ClusteredBarChart = dynamic(import('src/global/components/chart/clusteredBar'), { ssr: false });

interface IProps {
  dados: any;
}

export const PercentageHighways: React.FC<IProps> = ({ dados }) => {
  const { isMobile } = useMediaQuery('(max-width: 1014px)');

  return (
    <>
    {!isMobile && (
      <>
        <WrapperCard>
          <WhiteCard>
            <IdeaIcon />
            <p>{dados?.block}</p>
          </WhiteCard>
          <GraphicCard>
            <h4>{dados?.chart[0]?.title}</h4>
            <WrapperChart>
              <ClusteredBarChart
                dados={dados?.chart[0]}
                title={dados?.chart[0]?.title}
                series={dados?.chart[0]?.series}
              />
              <Information info={dados?.chart[0]?.info} />
            </WrapperChart>
          </GraphicCard>
        </WrapperCard>
        <WrapperCard>
          <GreenCard>
            <p>{dados?.big_number[0]?.title}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[0]?.value)}
              {dados?.big_number[0]?.format}
            </h1>
            <p>{dados?.big_number[0]?.subtitle}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[1]?.value)}
              {dados?.big_number[1]?.format}
            </h1>
            <p>{dados?.big_number[1]?.subtitle}</p>
          </GreenCard>
          <GraphicCard>
            <h4>{dados?.chart[1]?.title}</h4>
            <WrapperChart>
              <ClusteredBarChart
                dados={dados?.chart[1]}
                title={dados?.chart[1]?.title}
                series={dados?.chart[1]?.series}
              />
              <Information info={dados?.chart[1]?.info} />
            </WrapperChart>
          </GraphicCard>
        </WrapperCard>

      </>
    )}
    {isMobile && (
      <>
        <WrapperCard>
          <WhiteCard>
            <IdeaIcon />
            <p>{dados?.block}</p>
          </WhiteCard>
        </WrapperCard>
        <WrapperCard>
          <GreenCard>
            <p>{dados?.big_number[0]?.title}</p>
            <h1>
              {dados?.big_number[0]?.value}
              {dados?.big_number[0]?.format}
            </h1>
            <p>{dados?.big_number[0]?.subtitle}</p>
            <h1>
              {dados?.big_number[1]?.value}
              {dados?.big_number[1]?.format}
            </h1>
            <p>{dados?.big_number[1]?.subtitle}</p>
          </GreenCard>
        </WrapperCard>
        <WrapperCard>
          <GraphicCard>
            <h4>{dados?.chart[0]?.title}</h4>
            <WrapperChart>
              <ClusteredBarChart
                dados={dados?.chart[0]}
                title={dados?.chart[0]?.title}
                series={dados?.chart[0]?.series}
              />
              <Information info={dados?.chart[0]?.info} />
            </WrapperChart>
          </GraphicCard>
          <GraphicCard>
            <h4>{dados?.chart[1]?.title}</h4>
            <WrapperChart>
              <ClusteredBarChart
                dados={dados?.chart[1]}
                title={dados?.chart[1]?.title}
                series={dados?.chart[1]?.series}
              />
              <Information info={dados?.chart[1]?.info} />
            </WrapperChart>
          </GraphicCard>
        </WrapperCard>
      </>
    )}
    </>
  );
};