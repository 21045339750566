import styled from 'styled-components';
import PainelSvg from './svg/icon_painel.svg';
import ViariaSvg from './svg/icon_seguranca_viaria.svg';
import AtendimentosSvg from './svg/icon_atendimentos.svg';
import EconomicoSvg from './svg/icon_impacto_economico.svg';
import AmbientalSvg from './svg/icon_impacto_ambiental.svg';
import QualidadeServicoSvg from './svg/icon_qualidade_servicos.svg';

export const PainelIcon = styled(PainelSvg)`
  width: 36px;

  @media (max-width: 820px) {
    margin: 0 .5rem 0 0;
  }
`;
export const AtendimentoIcon = styled(AtendimentosSvg)`
  width: 44px;
  margin: 0 .5rem 0 0;

  @media (max-width: 820px) {
    margin: 0;
  }
`;
export const EconomicoIcon = styled(EconomicoSvg)`
  width: 32px;
  margin: 0 .7rem 0 0;

  @media (max-width: 820px) {
    margin: 0 .7rem 0 0;
  }
`;
export const AmbientalIcon = styled(AmbientalSvg)`
  width: 40px;
  margin: 0 .9rem 0 0;

  @media (max-width: 820px) {
    margin: 0 .3rem 0 0;
  }
`;
export const ViariaIcon = styled(ViariaSvg)`
  width: 35px;
  margin: 0 .4rem 0 0;

  @media (max-width: 820px) {
    margin: 0 .7rem 0 0;
  }
`;
export const QualidadeServico = styled(QualidadeServicoSvg)`
  width: 35px;
  margin: 0 .6rem 0 4px;

  @media (max-width: 820px) {
    margin: 0 .7rem 0 0;
  }
`;