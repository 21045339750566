import React from 'react';
import { Container, Wrapper } from './styles';

interface IProps {
  embedId: string;
}

/**
 * @description YoutubeEmbed é um componente que trata da vizualização
 * de um frame de video integrato ao youtube.
 * @param embedId string
 * @returns React.FC
 */
export const YoutubeEmbed: React.FC<IProps> = ({ embedId }) => {
  return (
    <Wrapper>
      <Container>
        {/* <iframe
          width="853"

          height="480"
          frameBorder="0"
          allowFullScreen
          title="Embedded youtube"
          allow={`
            autoplay; 
            gyroscope; 
            accelerometer; 
            clipboard-write; 
            encrypted-media; 
            picture-in-picture
          `}
          src={`https://www.youtube.com/embed/${embedId}`}
        /> */} 
 
          <video controls>
            <source src="/abcr_reduzido_plus.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
          </video>
      </Container>
    </Wrapper>
  );
};