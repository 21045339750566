import { MenuBarItemProps } from "@/context/sidebar/interfaces";
import { useSidebar } from "@/hooks/useContexts";
import { toggleActiveAction } from "@/utils/toggleActiveAction";

export const EconomicImpactIcon = () => {
  const { setItems, setClickReport, items } = useSidebar();
  const handleEconomicImpact = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 2
    });
    setClickReport(false);
    setItems(newItems);
  };
  return (
    <>
      <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473 631">
  <defs>
    <style>
      {
        `
        .cls-1,
        .cls-7,
        .cls-8 {
          fill: none;
          stroke: #1e303a;
          stroke-miterlimit: 10;
        }

        .cls-1 {
          stroke-width: 4px;
        }

        .cls-2 {
          fill: #2f82c1;
        }

        .cls-3 {
          fill: #1e303a;
        }

        .cls-10,
        .cls-4 {
          fill: #fff;
        }

        .cls-5 {
          fill: #006680;
        }

        .cls-6 {
          fill: #1d1d1b;
        }

        .cls-8 {
          stroke-width: 2.5px;
        }

        .cls-9 {
          fill: #93c022;
        }

        .cls-10 {
          opacity: 0;
        }
        `
      }
    </style>
  </defs>
  <title>Painel ABCR mobile</title>
  <line className="cls-1" x1="11.78" y1="123" x2="459.78" y2="123" />
  <path className="cls-2"
    d="M199.33,23.37v6.7h-3.14V12h6.91a7,7,0,0,1,4.81,1.58,5.31,5.31,0,0,1,1.77,4.17,5.15,5.15,0,0,1-1.74,4.14,7.35,7.35,0,0,1-4.88,1.47Zm0-2.53h3.77a3.71,3.71,0,0,0,2.55-.78,2.9,2.9,0,0,0,.88-2.28,3.13,3.13,0,0,0-.89-2.34,3.45,3.45,0,0,0-2.45-.9h-3.86Z" />
  <path className="cls-2"
    d="M222.55,30.07a4.83,4.83,0,0,1-.34-1.25,4.7,4.7,0,0,1-3.52,1.5,4.77,4.77,0,0,1-3.3-1.15,3.7,3.7,0,0,1-1.28-2.86A3.82,3.82,0,0,1,215.7,23a7.75,7.75,0,0,1,4.56-1.15h1.85V21a2.36,2.36,0,0,0-.58-1.66,2.31,2.31,0,0,0-1.78-.63,2.65,2.65,0,0,0-1.68.51,1.6,1.6,0,0,0-.66,1.31h-3a3.34,3.34,0,0,1,.74-2.06,4.8,4.8,0,0,1,2-1.51,7,7,0,0,1,2.81-.55,5.6,5.6,0,0,1,3.75,1.19,4.19,4.19,0,0,1,1.44,3.33v6a6.84,6.84,0,0,0,.51,2.89v.21Zm-3.31-2.17a3.39,3.39,0,0,0,1.68-.43,2.92,2.92,0,0,0,1.19-1.17V23.77h-1.63a4.5,4.5,0,0,0-2.51.58,1.88,1.88,0,0,0-.85,1.65,1.8,1.8,0,0,0,.58,1.39A2.24,2.24,0,0,0,219.24,27.9Z" />
  <path className="cls-2"
    d="M230.82,13.17a1.63,1.63,0,0,1,.44-1.16,2,2,0,0,1,2.53,0,1.63,1.63,0,0,1,.44,1.16,1.57,1.57,0,0,1-.44,1.13,2,2,0,0,1-2.53,0A1.57,1.57,0,0,1,230.82,13.17Zm3.2,16.9h-3V16.65h3Z" />
  <path className="cls-2"
    d="M242.64,16.65l.09,1.55a4.81,4.81,0,0,1,3.9-1.8q4.2,0,4.27,4.8v8.87h-3V21.38a2.75,2.75,0,0,0-.55-1.89,2.32,2.32,0,0,0-1.8-.62,2.91,2.91,0,0,0-2.72,1.65v9.55h-3V16.65Z" />
  <path className="cls-2"
    d="M262.42,30.32a6.23,6.23,0,0,1-4.65-1.81,6.56,6.56,0,0,1-1.78-4.8v-.37a8,8,0,0,1,.78-3.59,5.85,5.85,0,0,1,2.17-2.46,5.76,5.76,0,0,1,3.13-.89,5.26,5.26,0,0,1,4.23,1.75,7.39,7.39,0,0,1,1.5,5v1.22H259A4.05,4.05,0,0,0,260.14,27a3.36,3.36,0,0,0,2.45,1A4.08,4.08,0,0,0,266,26.24l1.63,1.55a5.57,5.57,0,0,1-2.15,1.87A6.79,6.79,0,0,1,262.42,30.32Zm-.36-11.5a2.55,2.55,0,0,0-2,.87,4.58,4.58,0,0,0-1,2.42h5.74v-.22a3.62,3.62,0,0,0-.8-2.29A2.52,2.52,0,0,0,262.06,18.82Z" />
  <path className="cls-2" d="M275.88,30.07h-3V11h3Z" />
  <path className="cls-3"
    d="M171.23,68.61H159.77l-2,6.48h-8.94L161.56,40.4h7.86l12.82,34.69h-9Zm-9.46-6.46h7.46l-3.74-12Z" />
  <path className="cls-3"
    d="M189.65,75.09V40.4h12.46q6.69,0,10.18,2.45a8.17,8.17,0,0,1,3.5,7.13,8.32,8.32,0,0,1-1.24,4.6,7.3,7.3,0,0,1-3.65,2.81,7.25,7.25,0,0,1,4.17,2.67,7.74,7.74,0,0,1,1.45,4.76c0,3.4-1.07,6-3.23,7.66s-5.34,2.58-9.56,2.61ZM198,54.86h4.36a6.18,6.18,0,0,0,3.91-1A3.56,3.56,0,0,0,207.45,51a3.76,3.76,0,0,0-1.27-3.17,6.78,6.78,0,0,0-4.07-1H198Zm0,5.48v8.32h5.48a5.22,5.22,0,0,0,3.48-1,3.62,3.62,0,0,0,1.21-2.92c0-2.89-1.43-4.34-4.31-4.36Z" />
  <path className="cls-3"
    d="M254.07,63.35a12.65,12.65,0,0,1-1.95,6.39,11.77,11.77,0,0,1-5,4.3,17,17,0,0,1-7.37,1.53q-6.84,0-10.77-4.46T225,58.53V56.82a21.08,21.08,0,0,1,1.77-8.93A13.45,13.45,0,0,1,231.91,42a14.28,14.28,0,0,1,7.72-2.08q6.31,0,10.15,3.32a13.13,13.13,0,0,1,4.36,9.16H245.8q-.09-3.16-1.59-4.55a6.58,6.58,0,0,0-4.58-1.38,5,5,0,0,0-4.57,2.34c-1,1.55-1.48,4-1.53,7.45V58.7c0,3.7.47,6.35,1.4,7.93s2.53,2.39,4.8,2.39a6.46,6.46,0,0,0,4.41-1.36,5.82,5.82,0,0,0,1.62-4.31Z" />
  <path className="cls-3"
    d="M275.8,62.82h-4.53V75.09h-8.36V40.4h13.65q6.18,0,9.65,2.74a9.26,9.26,0,0,1,3.48,7.73,11.35,11.35,0,0,1-1.46,6,10.46,10.46,0,0,1-4.59,3.86l7.24,14v.35h-9Zm-4.53-6.43h5.29a4.74,4.74,0,0,0,3.59-1.26,4.84,4.84,0,0,0,1.2-3.5,4.9,4.9,0,0,0-1.21-3.52,4.73,4.73,0,0,0-3.58-1.28h-5.29Z" />
  <path className="cls-3" d="M313.73,55.41h8.13V62.3h-8.13v9.31h-7.51V62.3h-8.14V55.41h8.14v-9h7.51Z" />
  <circle className="cls-4" cx="236.52" cy="123" r="22" />
  <path className="cls-3"
    d="M238.56,148A25.05,25.05,0,1,1,261.49,125,25.07,25.07,0,0,1,238.56,148Zm.1-45.83a21,21,0,1,0,18.72,18.71A21,21,0,0,0,238.66,102.14Z" />
  <path className="cls-5" d="M250.68,121.14a14.29,14.29,0,1,1-12.31-12.3A14.29,14.29,0,0,1,250.68,121.14Z" />
  <line className="cls-1" x1="236.52" y1="145" x2="236.52" y2="163" />
  <path className="cls-6"
    d="M151.81,525.37h-8.34l-1.88,5.2h-2.7l7.6-19.9h2.3l7.61,19.9h-2.69Zm-7.55-2.15H151l-3.39-9.31Z" />
  <path className="cls-6"
    d="M168.63,518.05a7.94,7.94,0,0,0-1.24-.09,3.34,3.34,0,0,0-3.38,2.11v10.5h-2.53V515.78h2.46l.05,1.71a3.92,3.92,0,0,1,3.52-2,2.51,2.51,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M180.91,518.05a8,8,0,0,0-1.24-.09,3.33,3.33,0,0,0-3.38,2.11v10.5h-2.53V515.78h2.46l0,1.71a4,4,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M191.91,530.85a6.46,6.46,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.59,6.59,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,191.91,530.85Zm-.31-13.26A3.38,3.38,0,0,0,189,518.7a5.39,5.39,0,0,0-1.29,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.16,3.16,0,0,0,191.6,517.59Z" />
  <path className="cls-6"
    d="M209.34,528.78a3.65,3.65,0,0,0,2.37-.82,2.91,2.91,0,0,0,1.12-2.05h2.39a4.66,4.66,0,0,1-.87,2.42,5.69,5.69,0,0,1-2.16,1.83,6.18,6.18,0,0,1-2.85.69,6.07,6.07,0,0,1-4.8-2,8,8,0,0,1-1.79-5.52v-.42a9,9,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.89,5.89,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.55,3.55,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1.05,3.94A3.58,3.58,0,0,0,209.34,528.78Z" />
  <path className="cls-6"
    d="M230,530.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.92,1.65h-2.54a3.46,3.46,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.18,7.18,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.5,4.5,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,225.8,528.65Z" />
  <path className="cls-6"
    d="M238.28,523.05a8.7,8.7,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.32l-.13-1.58a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.52,6.52,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.71,3.71,0,0,0-3.48-2.1,3.37,3.37,0,0,0-2.9,1.44A7.16,7.16,0,0,0,240.81,523.34Z" />
  <path className="cls-6"
    d="M266.62,530.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.91,1.65h-2.55a3.46,3.46,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.22,7.22,0,0,1,3-.6,5.82,5.82,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,262.42,528.65Z" />
  <path className="cls-6"
    d="M281.45,528.78a3.61,3.61,0,0,0,2.36-.82,2.89,2.89,0,0,0,1.13-2.05h2.39a4.59,4.59,0,0,1-.88,2.42,5.58,5.58,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.08,6.08,0,0,1-4.81-2,8,8,0,0,1-1.78-5.52v-.42a8.87,8.87,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.91,5.91,0,0,1,4.13,1.49,5.34,5.34,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.54,3.54,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1.05,3.94A3.56,3.56,0,0,0,281.45,528.78Zm.41,1.92-.16.71a2.21,2.21,0,0,1,2.09,2.36,2.43,2.43,0,0,1-1.1,2.1,5.17,5.17,0,0,1-3.05.77l-.09-1.46a3.32,3.32,0,0,0,1.67-.35,1.11,1.11,0,0,0,.6-1,1,1,0,0,0-.44-.89,4.36,4.36,0,0,0-1.78-.41l.44-1.83Z" />
  <path className="cls-6"
    d="M302.1,530.57a5.49,5.49,0,0,1-.35-1.55,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.18,4.18,0,0,1,1.76-3.59,8.28,8.28,0,0,1,4.94-1.27h2.46v-1.17a2.88,2.88,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.92,1.65h-2.54a3.46,3.46,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.18,7.18,0,0,1,3-.6,5.79,5.79,0,0,1,4,1.28,4.6,4.6,0,0,1,1.51,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21ZM303.85,510a3.38,3.38,0,0,1-.84,2.39,2.73,2.73,0,0,1-2.09.92,3.17,3.17,0,0,1-1-.14,4.85,4.85,0,0,1-1.07-.56,4.61,4.61,0,0,0-1-.51,2.26,2.26,0,0,0-.67-.09,1.27,1.27,0,0,0-1,.43,1.6,1.6,0,0,0-.4,1.1l-1.69-.09A3.51,3.51,0,0,1,295,511a2.76,2.76,0,0,1,3-.82,6.8,6.8,0,0,1,1,.53,6.1,6.1,0,0,0,1,.52,2.35,2.35,0,0,0,.77.12,1.23,1.23,0,0,0,1-.46,1.63,1.63,0,0,0,.39-1.08Zm-5.94,18.64a4.44,4.44,0,0,0,2.25-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,297.91,528.65Z" />
  <path className="cls-6"
    d="M310.33,523a8.74,8.74,0,0,1,.86-3.91,6.35,6.35,0,0,1,2.38-2.68,6.43,6.43,0,0,1,3.48-.94,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.56v.18a8.87,8.87,0,0,1-.83,3.88,6.32,6.32,0,0,1-2.36,2.67,6.57,6.57,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.33,6.33,0,0,0,1.14,3.95,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.23,6.3,6.3,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.88,6.88,0,0,0,312.88,523.34Z" />
  <path className="cls-6"
    d="M152.38,559.05a8.7,8.7,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21h-2.32l-.12-1.58a5.16,5.16,0,0,1-4.23,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.35,3.35,0,0,0,2.87,1.42,3.68,3.68,0,0,0,3.51-2.16v-6.79a3.7,3.7,0,0,0-3.48-2.1,3.37,3.37,0,0,0-2.9,1.44A7.24,7.24,0,0,0,154.91,559.34Z" />
  <path className="cls-6"
    d="M177.73,566.85a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.85-3.93,6.52,6.52,0,0,1,2.35-2.7,5.83,5.83,0,0,1,3.27-1,5.55,5.55,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.32,5.32,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.19,6.19,0,0,1,177.73,566.85Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12H181v-.19a4.83,4.83,0,0,0-1-3A3.17,3.17,0,0,0,177.41,553.59Z" />
  <path className="cls-6"
    d="M202.38,548.2v3.58h2.77v2h-2.77v9.18a2.12,2.12,0,0,0,.37,1.34,1.59,1.59,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.86-2.91v-9.17h-2.7v-2h2.7V548.2Z" />
  <path className="cls-6"
    d="M218.05,554.05a8,8,0,0,0-1.24-.09,3.33,3.33,0,0,0-3.38,2.11v10.5H210.9V551.78h2.46l0,1.71a4,4,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M223.19,547.86a1.51,1.51,0,0,1,.37-1,1.44,1.44,0,0,1,1.12-.43,1.45,1.45,0,0,1,1.12.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.43,1.35,1.35,0,0,1-1.49-1.43Zm2.73,18.71h-2.53V551.78h2.53Z" />
  <path className="cls-6"
    d="M245.29,559.34a8.79,8.79,0,0,1-1.56,5.45,5,5,0,0,1-4.18,2.06,5.15,5.15,0,0,1-4.33-2l-.13,1.7h-2.32v-21h2.53v7.84a5.12,5.12,0,0,1,4.22-1.9,5,5,0,0,1,4.23,2,9,9,0,0,1,1.54,5.58Zm-2.53-.29a6.88,6.88,0,0,0-1-4,3.32,3.32,0,0,0-2.88-1.41A3.72,3.72,0,0,0,235.3,556v6.4a3.8,3.8,0,0,0,3.62,2.32,3.28,3.28,0,0,0,2.83-1.41A7.23,7.23,0,0,0,242.76,559.05Z" />
  <path className="cls-6"
    d="M260.41,565.11a5.36,5.36,0,0,1-4.33,1.74,4.61,4.61,0,0,1-3.61-1.38,6,6,0,0,1-1.25-4.06v-9.63h2.53v9.56q0,3.36,2.74,3.36,2.89,0,3.85-2.16V551.78h2.53v14.79h-2.4Z" />
  <path className="cls-6"
    d="M273,548.2v3.58h2.76v2H273v9.18a2.12,2.12,0,0,0,.36,1.34,1.59,1.59,0,0,0,1.26.44,6.17,6.17,0,0,0,1.21-.16v2a7.5,7.5,0,0,1-1.95.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.86-2.91v-9.17h-2.7v-2h2.7V548.2Z" />
  <path className="cls-6"
    d="M280.52,559a8.74,8.74,0,0,1,.85-3.91,6.35,6.35,0,0,1,2.38-2.68,6.48,6.48,0,0,1,3.48-.94,6.27,6.27,0,0,1,4.89,2.09,8.05,8.05,0,0,1,1.86,5.56v.18a8.87,8.87,0,0,1-.82,3.88,6.34,6.34,0,0,1-2.37,2.67,6.51,6.51,0,0,1-3.53,1,6.25,6.25,0,0,1-4.88-2.09,8,8,0,0,1-1.86-5.54Zm2.54.3a6.33,6.33,0,0,0,1.14,3.95,3.87,3.87,0,0,0,6.12,0,6.89,6.89,0,0,0,1.13-4.23,6.29,6.29,0,0,0-1.15-3.94,3.66,3.66,0,0,0-3.07-1.51,3.6,3.6,0,0,0-3,1.49A6.82,6.82,0,0,0,283.06,559.34Z" />
  <path className="cls-6"
    d="M308.57,562.65a1.87,1.87,0,0,0-.77-1.59,7.42,7.42,0,0,0-2.7-1,12.87,12.87,0,0,1-3.05-1,4.38,4.38,0,0,1-1.66-1.37,3.28,3.28,0,0,1-.54-1.89,3.82,3.82,0,0,1,1.53-3.07,6,6,0,0,1,3.93-1.26,6.19,6.19,0,0,1,4.09,1.3,4.12,4.12,0,0,1,1.56,3.32h-2.54a2.31,2.31,0,0,0-.88-1.79,3.32,3.32,0,0,0-2.23-.75,3.47,3.47,0,0,0-2.16.6,1.9,1.9,0,0,0-.77,1.57,1.54,1.54,0,0,0,.72,1.38,8.84,8.84,0,0,0,2.62.89,13.77,13.77,0,0,1,3.07,1,4.42,4.42,0,0,1,1.74,1.42,3.45,3.45,0,0,1,.57,2,3.79,3.79,0,0,1-1.59,3.18,6.64,6.64,0,0,1-4.11,1.2,7.44,7.44,0,0,1-3.15-.63,5.18,5.18,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44h2.53a2.62,2.62,0,0,0,1,2,3.93,3.93,0,0,0,2.51.74,4.06,4.06,0,0,0,2.3-.58A1.78,1.78,0,0,0,308.57,562.65Z" />
  <path className="cls-6"
    d="M155.55,587.78l.07,1.64a5.48,5.48,0,0,1,4.39-1.91,4.21,4.21,0,0,1,4.22,2.38,5.46,5.46,0,0,1,4.72-2.38q4.86,0,5,5.15v9.91h-2.53v-9.76a3.39,3.39,0,0,0-.72-2.37,3.23,3.23,0,0,0-2.44-.79,3.32,3.32,0,0,0-2.34.85,3.4,3.4,0,0,0-1.08,2.26v9.81h-2.54v-9.69q0-3.23-3.16-3.23a3.38,3.38,0,0,0-3.4,2.12v10.8h-2.53V587.78Z" />
  <path className="cls-6"
    d="M189.65,601.11a5.38,5.38,0,0,1-4.34,1.74,4.59,4.59,0,0,1-3.6-1.38,5.9,5.9,0,0,1-1.25-4.06v-9.63H183v9.56q0,3.36,2.73,3.36,2.9,0,3.86-2.16V587.78h2.53v14.79H189.7Z" />
  <path className="cls-6"
    d="M201.14,587.78l.08,1.86a5.4,5.4,0,0,1,4.43-2.13q4.69,0,4.73,5.29v9.77h-2.53v-9.78a3.36,3.36,0,0,0-.73-2.37,2.91,2.91,0,0,0-2.23-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V587.78Z" />
  <path className="cls-6"
    d="M217,583.86a1.51,1.51,0,0,1,.38-1,1.42,1.42,0,0,1,1.11-.43,1.44,1.44,0,0,1,1.12.43,1.48,1.48,0,0,1,.39,1,1.41,1.41,0,0,1-.39,1,1.43,1.43,0,0,1-1.12.41,1.41,1.41,0,0,1-1.11-.41A1.45,1.45,0,0,1,217,583.86Zm2.74,18.71h-2.53V587.78h2.53Z" />
  <path className="cls-6"
    d="M232.53,600.78a3.61,3.61,0,0,0,2.36-.82,2.84,2.84,0,0,0,1.12-2.05h2.4a4.59,4.59,0,0,1-.88,2.42,5.66,5.66,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.08,6.08,0,0,1-4.81-2,8,8,0,0,1-1.78-5.52v-.42a8.87,8.87,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.44,6.44,0,0,1,3.5-.93,5.91,5.91,0,0,1,4.14,1.49,5.34,5.34,0,0,1,1.76,3.87H236a3.46,3.46,0,0,0-1.08-2.36,3.38,3.38,0,0,0-2.42-.92,3.53,3.53,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1.05,3.94A3.55,3.55,0,0,0,232.53,600.78Z" />
  <path className="cls-6"
    d="M244.06,583.86a1.51,1.51,0,0,1,.38-1,1.66,1.66,0,0,1,2.23,0,1.48,1.48,0,0,1,.39,1,1.57,1.57,0,0,1-2.62,1A1.45,1.45,0,0,1,244.06,583.86Zm2.74,18.71h-2.53V587.78h2.53Z" />
  <path className="cls-6"
    d="M266.14,595.34a8.84,8.84,0,0,1-1.54,5.44,5,5,0,0,1-4.19,2.07,5.44,5.44,0,0,1-4.24-1.71v7.12h-2.53V587.78H256l.12,1.64a5.22,5.22,0,0,1,4.29-1.91,5.05,5.05,0,0,1,4.22,2,9,9,0,0,1,1.55,5.59Zm-2.53-.29a6.5,6.5,0,0,0-1.07-3.95,3.44,3.44,0,0,0-2.92-1.45,3.73,3.73,0,0,0-3.45,2v7.07a3.73,3.73,0,0,0,3.48,2,3.43,3.43,0,0,0,2.89-1.44A7.18,7.18,0,0,0,263.61,595.05Z" />
  <path className="cls-6"
    d="M281.29,602.57a5.09,5.09,0,0,1-.36-1.55,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.2,4.2,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.27h2.46v-1.17a2.84,2.84,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.67,3.67,0,0,0-2.27.69,2,2,0,0,0-.92,1.65H272a3.46,3.46,0,0,1,.79-2.14,5.28,5.28,0,0,1,2.13-1.63,7.18,7.18,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21Zm-4.2-1.92a4.5,4.5,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,277.09,600.65Z" />
  <path className="cls-6"
    d="M290.2,583.86a1.51,1.51,0,0,1,.37-1,1.44,1.44,0,0,1,1.12-.43,1.45,1.45,0,0,1,1.12.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.43,1.35,1.35,0,0,1-1.49-1.43Zm2.73,18.71H290.4V587.78h2.53Z" />
  <path className="cls-6"
    d="M308.39,598.65a1.87,1.87,0,0,0-.77-1.59,7.35,7.35,0,0,0-2.69-1,12.71,12.71,0,0,1-3.05-1,4.42,4.42,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.81,3.81,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.13,4.13,0,0,1,1.57,3.32h-2.55a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,9,9,0,0,0,2.62.89,13.6,13.6,0,0,1,3.07,1,4.29,4.29,0,0,1,1.74,1.42,3.45,3.45,0,0,1,.57,2,3.81,3.81,0,0,1-1.58,3.18,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5.06,5.06,0,0,1-2.14-1.76,4.19,4.19,0,0,1-.78-2.44h2.53a2.65,2.65,0,0,0,1,2,3.94,3.94,0,0,0,2.51.74,4,4,0,0,0,2.3-.58A1.78,1.78,0,0,0,308.39,598.65Z" />
  <path className="cls-6" d="M140.84,457.85h-2.62V437.94h2.62Z" />
  <path className="cls-6"
    d="M150.44,443.05l.08,1.86a5.4,5.4,0,0,1,4.43-2.13c3.13,0,4.7,1.76,4.73,5.29v9.78h-2.53v-9.79a3.41,3.41,0,0,0-.73-2.37,2.93,2.93,0,0,0-2.24-.76,3.7,3.7,0,0,0-2.16.65,4.36,4.36,0,0,0-1.44,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-6" d="M171.18,454.42l3.66-11.37h2.58l-5.3,14.8h-1.93l-5.36-14.8h2.59Z" />
  <path className="cls-6"
    d="M188.62,458.12a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.42,4.42,0,0,0,2.26-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,188.62,458.12Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.17,3.17,0,0,0,188.3,444.86Z" />
  <path className="cls-6"
    d="M208.73,453.92a1.87,1.87,0,0,0-.77-1.59,7.61,7.61,0,0,0-2.7-1,12.71,12.71,0,0,1-3-1,4.33,4.33,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.85,3.85,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.11,4.11,0,0,1,1.57,3.32h-2.54a2.31,2.31,0,0,0-.88-1.79,3.32,3.32,0,0,0-2.23-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.54,1.54,0,0,0,.73,1.38,8.73,8.73,0,0,0,2.62.89,13.77,13.77,0,0,1,3.07,1,4.6,4.6,0,0,1,1.74,1.42,3.46,3.46,0,0,1,.57,2,3.76,3.76,0,0,1-1.59,3.17,6.64,6.64,0,0,1-4.11,1.2,7.44,7.44,0,0,1-3.15-.63,5.11,5.11,0,0,1-2.14-1.76,4.25,4.25,0,0,1-.77-2.44H202a2.59,2.59,0,0,0,1,2,3.93,3.93,0,0,0,2.51.75,4.06,4.06,0,0,0,2.3-.59A1.77,1.77,0,0,0,208.73,453.92Z" />
  <path className="cls-6"
    d="M220.78,439.47v3.58h2.76v2h-2.76v9.18a2.1,2.1,0,0,0,.37,1.34,1.55,1.55,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.87-2.91V445h-2.69v-2h2.69v-3.58Z" />
  <path className="cls-6"
    d="M229.31,439.13a1.51,1.51,0,0,1,.38-1,1.41,1.41,0,0,1,1.11-.42,1.43,1.43,0,0,1,1.12.42,1.48,1.48,0,0,1,.39,1,1.57,1.57,0,0,1-2.62,1A1.49,1.49,0,0,1,229.31,439.13Zm2.74,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M241.27,443.05l.07,1.64a5.48,5.48,0,0,1,4.39-1.91,4.21,4.21,0,0,1,4.22,2.38,5.52,5.52,0,0,1,4.72-2.38c3.25,0,4.89,1.72,5,5.15v9.92h-2.53v-9.77a3.43,3.43,0,0,0-.72-2.37,3.23,3.23,0,0,0-2.44-.78,3.36,3.36,0,0,0-2.34.84,3.4,3.4,0,0,0-1.08,2.26v9.82H248v-9.7q0-3.22-3.16-3.22a3.37,3.37,0,0,0-3.4,2.12v10.8h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M272.37,458.12a6.46,6.46,0,0,1-4.89-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.59,6.59,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,272.37,458.12Zm-.31-13.26a3.38,3.38,0,0,0-2.57,1.11,5.45,5.45,0,0,0-1.29,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.15,3.15,0,0,0,272.06,444.86Z" />
  <path className="cls-6"
    d="M286.26,443.05l.08,1.86a5.4,5.4,0,0,1,4.43-2.13q4.69,0,4.73,5.29v9.78H293v-9.79a3.41,3.41,0,0,0-.73-2.37,2.92,2.92,0,0,0-2.23-.76,3.7,3.7,0,0,0-2.16.65,4.38,4.38,0,0,0-1.45,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M305.55,439.47v3.58h2.76v2h-2.76v9.18a2,2,0,0,0,.37,1.34,1.53,1.53,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.43,7.43,0,0,1-1.94.27,3.15,3.15,0,0,1-2.56-1,4.41,4.41,0,0,1-.86-2.91V445h-2.69v-2H303v-3.58Z" />
  <path className="cls-6"
    d="M313.12,450.31a8.74,8.74,0,0,1,.85-3.91,6.28,6.28,0,0,1,5.86-3.62,6.24,6.24,0,0,1,4.89,2.09,8.09,8.09,0,0,1,1.87,5.57v.17a8.84,8.84,0,0,1-.83,3.88,6.22,6.22,0,0,1-2.37,2.67,6.51,6.51,0,0,1-3.53,1A6.22,6.22,0,0,1,315,456a8,8,0,0,1-1.87-5.54Zm2.54.3a6.38,6.38,0,0,0,1.14,4,3.68,3.68,0,0,0,3.06,1.49,3.61,3.61,0,0,0,3.06-1.52,6.87,6.87,0,0,0,1.14-4.23,6.32,6.32,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.88,6.88,0,0,0,315.66,450.61Z" />
  <path className="cls-5" d="M160.06,370.05h-3.93V347.3h3.93Z" />
  <path className="cls-5"
    d="M173.25,347.3l6.56,17.43,6.55-17.43h5.11v22.75h-3.94v-7.5l.39-10-6.72,17.54h-2.83l-6.7-17.52.39,10v7.5h-3.94V347.3Z" />
  <path className="cls-5"
    d="M203.29,361.61v8.44h-3.95V347.3H208a8.85,8.85,0,0,1,6.06,2,6.71,6.71,0,0,1,2.24,5.26,6.44,6.44,0,0,1-2.2,5.21,9.22,9.22,0,0,1-6.15,1.86Zm0-3.19H208a4.68,4.68,0,0,0,3.22-1,3.65,3.65,0,0,0,1.11-2.87,3.92,3.92,0,0,0-1.13-2.94,4.31,4.31,0,0,0-3.09-1.14h-4.86Z" />
  <path className="cls-5"
    d="M232.94,364.75h-8.81l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75H234.8Zm-7.7-3.19h6.6l-3.3-9.44Z" />
  <path className="cls-5"
    d="M261.74,362.64a8.23,8.23,0,0,1-2.69,5.68,9.15,9.15,0,0,1-6.23,2,8.85,8.85,0,0,1-4.79-1.29,8.48,8.48,0,0,1-3.2-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.65,8.65,0,0,1,3.27-3.78A9.21,9.21,0,0,1,253,347a8.81,8.81,0,0,1,6.07,2,8.54,8.54,0,0,1,2.67,5.78H257.8a5.59,5.59,0,0,0-1.43-3.54,4.7,4.7,0,0,0-3.37-1.09,4.62,4.62,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.39,4.39,0,0,0,3.87,1.94,5.1,5.1,0,0,0,3.5-1.05,5.45,5.45,0,0,0,1.48-3.48Z" />
  <path className="cls-5" d="M284.45,350.48h-7.09v19.57h-3.92V350.48h-7V347.3h18Z" />
  <path className="cls-5"
    d="M308.36,359.26a14,14,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.71,9.71,0,0,1-9.88,0,8.87,8.87,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.2-5.77v-1.28a14,14,0,0,1,1.18-5.88,8.71,8.71,0,0,1,8.26-5.25,9.15,9.15,0,0,1,4.93,1.34,8.76,8.76,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-4-1.18a9.9,9.9,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.77,9.77,0,0,0-1.47,5.68v1.31a9.83,9.83,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-5" d="M141.63,396.89h-9.34v7h10.92v3.16H128.33V384.3H143.1v3.18H132.29v6.28h9.34Z" />
  <path className="cls-5"
    d="M167,399.64a8.23,8.23,0,0,1-2.69,5.68,9.16,9.16,0,0,1-6.24,2,8.88,8.88,0,0,1-4.79-1.29,8.46,8.46,0,0,1-3.19-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.65,8.65,0,0,1,3.27-3.78,9.19,9.19,0,0,1,4.93-1.33,8.78,8.78,0,0,1,6.06,2A8.51,8.51,0,0,1,167,391.8h-3.94a5.65,5.65,0,0,0-1.43-3.54,4.71,4.71,0,0,0-3.37-1.09,4.61,4.61,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.38,4.38,0,0,0,3.86,1.94,5.08,5.08,0,0,0,3.5-1.05,5.4,5.4,0,0,0,1.49-3.48Z" />
  <path className="cls-5"
    d="M191.87,396.26a14,14,0,0,1-1.15,5.87,8.78,8.78,0,0,1-3.31,3.88,9.7,9.7,0,0,1-9.87,0,8.89,8.89,0,0,1-3.35-3.85,13.63,13.63,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.88,8.88,0,0,1,3.33-3.9,9.05,9.05,0,0,1,4.93-1.35,9.18,9.18,0,0,1,4.93,1.34,8.82,8.82,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.18,5.84Zm-4-1.18a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0A9.7,9.7,0,0,0,177,395v1.31a9.9,9.9,0,0,0,1.46,5.8,5.06,5.06,0,0,0,8.1.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-5" d="M217.06,407.05h-4L203,390.91v16.14h-4V384.3h4l10.18,16.2V384.3h3.92Z" />
  <path className="cls-5"
    d="M243.09,396.26a14,14,0,0,1-1.16,5.87,8.72,8.72,0,0,1-3.31,3.88,9.7,9.7,0,0,1-9.87,0,8.87,8.87,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.2-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.71,8.71,0,0,1,8.26-5.25,9.15,9.15,0,0,1,4.93,1.34,8.76,8.76,0,0,1,3.32,3.84,14,14,0,0,1,1.19,5.84Zm-4-1.18a9.9,9.9,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.77,9.77,0,0,0-1.48,5.68v1.31a9.9,9.9,0,0,0,1.46,5.8,4.71,4.71,0,0,0,4.06,2.05,4.66,4.66,0,0,0,4-2,10.1,10.1,0,0,0,1.41-5.85Zm-.06-12.56v.17h-3l-2.34-2.44-2.33,2.44h-3v-.2l4.23-4.3h2.23Z" />
  <path className="cls-5"
    d="M255.35,384.3l6.56,17.43,6.55-17.43h5.11v22.75h-3.94v-7.5l.39-10-6.72,17.54h-2.83l-6.7-17.52.39,10v7.5h-3.94V384.3Z" />
  <path className="cls-5" d="M285.61,407.05h-3.94V384.3h3.94Z" />
  <path className="cls-5"
    d="M311,399.64a8.23,8.23,0,0,1-2.69,5.68,9.15,9.15,0,0,1-6.23,2,8.85,8.85,0,0,1-4.79-1.29,8.48,8.48,0,0,1-3.2-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.61,8.61,0,0,1,3.28-3.78,9.15,9.15,0,0,1,4.92-1.33,8.79,8.79,0,0,1,6.07,2A8.54,8.54,0,0,1,311,391.8h-3.94a5.59,5.59,0,0,0-1.43-3.54,4.7,4.7,0,0,0-3.37-1.09,4.63,4.63,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.39,4.39,0,0,0,3.87,1.94,5.1,5.1,0,0,0,3.5-1.05,5.45,5.45,0,0,0,1.48-3.48Z" />
  <path className="cls-5"
    d="M335.92,396.26a14,14,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.71,9.71,0,0,1-9.88,0,8.87,8.87,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.2-5.77v-1.28a14,14,0,0,1,1.18-5.88,8.71,8.71,0,0,1,8.26-5.25,9.15,9.15,0,0,1,4.93,1.34,8.76,8.76,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-4-1.18a9.9,9.9,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0A9.77,9.77,0,0,0,321,395v1.31a9.83,9.83,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <line className="cls-7" x1="111.78" y1="486.79" x2="351.78" y2="486.79" />
  <line className="cls-8" x1="73.9" y1="135.5" x2="73.9" y2="144" />
  <circle className="cls-4" cx="73.9" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M75.25,135.78a12.36,12.36,0,1,1,11-11.63A12.38,12.38,0,0,1,75.25,135.78Zm-.59-22.61a10.35,10.35,0,1,0,9.5,9A10.36,10.36,0,0,0,74.66,113.17Z" />
  <path className="cls-5" d="M80.86,122.38a7.05,7.05,0,1,1-6.25-5.9A7.05,7.05,0,0,1,80.86,122.38Z" />
  <circle className="cls-4" cx="398.56" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M399.56,135.81a12.36,12.36,0,1,1,11.32-11.31A12.37,12.37,0,0,1,399.56,135.81Zm.06-22.62a10.35,10.35,0,1,0,9.23,9.24A10.37,10.37,0,0,0,399.62,113.19Z" />
  <path className="cls-5" d="M405.55,122.57a7,7,0,1,1-6.08-6.07A7.06,7.06,0,0,1,405.55,122.57Z" />
  <line className="cls-8" x1="398.56" y1="135.5" x2="398.56" y2="144" />
  <path className="cls-3"
    d="M80.83,199.06a1,1,0,0,1-.3-2,23,23,0,1,0-28.7-15.44,1,1,0,0,1-2,.59A25.1,25.1,0,1,1,81.12,199,1,1,0,0,1,80.83,199.06Z" />
  <path className="cls-3"
    d="M73.89,207.71a32.94,32.94,0,0,1-31.33-23.32,32.75,32.75,0,0,1,31.35-42.14,32.73,32.73,0,0,1,0,65.46Zm0-63.41a30.85,30.85,0,0,0-8.83,1.3,30.67,30.67,0,0,0-20.55,38.2,30.67,30.67,0,1,0,29.38-39.5Z" />
  <path className="cls-9"
    d="M83.43,178.78l-5,1.42v0a2,2,0,0,1-.21,1.49,2,2,0,0,1-1.26,1,2.11,2.11,0,0,1-1.59-.18l-2.91-1.63.27-.49,2.9,1.62a1.5,1.5,0,0,0,1.15.13,1.55,1.55,0,0,0,.92-.73,1.88,1.88,0,0,0,.18-.6,1.23,1.23,0,0,0-.6-1.26l-7-3.92a2.16,2.16,0,0,0-1.73-.21h0c-.18,0-7.74,2.19-8.06,2.27h0L62,183.18,67,181.74c.52.31,4.37,2.7,4.84,3a2.84,2.84,0,0,0,2.14.16L84.32,182a1.72,1.72,0,0,0,1-2.12,1.56,1.56,0,0,0-1.91-1.05Z" />
  <path className="cls-9"
    d="M83.88,169.81H83a5,5,0,0,0-.63-1.51l.73-.74a.26.26,0,0,0,.08-.21.29.29,0,0,0-.08-.21l-1-1a.29.29,0,0,0-.39,0l-.76.76a5.23,5.23,0,0,0-1.36-.57v-1a.29.29,0,0,0-.28-.29H77.83a.29.29,0,0,0-.29.29v.94a4.2,4.2,0,0,0-1.41.58l-.81-.81a.27.27,0,0,0-.39,0l-1,1a.3.3,0,0,0-.08.21.3.3,0,0,0,.08.2l.78.79a4.59,4.59,0,0,0-.65,1.54h-.92a.29.29,0,0,0-.28.29v1.41a.29.29,0,0,0,.28.29H74a4.06,4.06,0,0,0,.58,1.39l-.74.73a.32.32,0,0,0,0,.42l1,1a.32.32,0,0,0,.21.08.3.3,0,0,0,.21-.08l.71-.71a4.67,4.67,0,0,0,1.57.66v.81a.29.29,0,0,0,.29.28H79.3a.29.29,0,0,0,.29-.28v-.84a4.82,4.82,0,0,0,1.49-.63l.6.6a.26.26,0,0,0,.21.08.28.28,0,0,0,.21-.08l1-1a.32.32,0,0,0,0-.42l-.63-.63A4.92,4.92,0,0,0,83,171.8H84a.29.29,0,0,0,.29-.29V170.1c-.1-.16-.21-.29-.36-.29ZM79,173.55a2.8,2.8,0,1,1,2.19-2.2A2.8,2.8,0,0,1,79,173.55Z" />
  <path className="cls-3"
    d="M398.56,200.07a25.1,25.1,0,0,1-7.23-49.13,1,1,0,0,1,1.28.69,1,1,0,0,1-.69,1.27,23.05,23.05,0,1,0,28.7,15.44,1,1,0,0,1,2-.59,25.09,25.09,0,0,1-24,32.32Z" />
  <path className="cls-3"
    d="M398.54,207.7a32.73,32.73,0,1,1,31.34-42.14,32.74,32.74,0,0,1-31.34,42.14Zm0-63.4a30.68,30.68,0,0,0,0,61.35,30.68,30.68,0,0,0,29.38-39.5A30.85,30.85,0,0,0,398.56,144.3Z" />
  <path className="cls-9"
    d="M408.91,165.71s-1.21,2.16-6.28,2.59-7.63,1.84-9.36,5.15a7.8,7.8,0,0,0-.49,5.15l7.27-3.71-2.57,2-7.08,5.43,1.48,1.54c1.07-1,2.27-1.92,3.35-2.94a9.44,9.44,0,0,0,9-.44c5.29-3.35,4.66-14.81,4.66-14.81Z" />
  <path className="cls-3"
    d="M236.46,299.67a61.49,61.49,0,1,1,59-79.19,2.51,2.51,0,0,1-4.81,1.45,56.45,56.45,0,1,0-37.82,70.31,2.51,2.51,0,0,1,1.44,4.81A61.91,61.91,0,0,1,236.46,299.67Z" />
  <path className="cls-3"
    d="M236.51,318.33a80.65,80.65,0,0,1-76.73-57.11A80.22,80.22,0,0,1,236.56,158a80.17,80.17,0,0,1-.05,160.33ZM236.56,163a75.14,75.14,0,1,0,71.92,53.54A75.26,75.26,0,0,0,236.56,163Z" />
  <path className="cls-9"
    d="M236.52,215.72a24,24,0,1,0,24,24A24,24,0,0,0,236.52,215.72Zm1.89,36.84v4.29h-4.12v-4a16.76,16.76,0,0,1-7.15-1.81l1.26-4.92a14.84,14.84,0,0,0,7,1.85c2.4,0,4-.92,4-2.6s-1.35-2.61-4.46-3.66c-4.5-1.52-7.57-3.62-7.57-7.7,0-3.7,2.61-6.6,7.11-7.48v-4h4.12v3.7a15.09,15.09,0,0,1,6.1,1.39l-1.22,4.75a14,14,0,0,0-6.06-1.43c-2.73,0-3.61,1.18-3.61,2.36,0,1.38,1.47,2.27,5,3.61,5,1.77,7,4.08,7,7.87S243.25,251.71,238.41,252.56Z" />
  <circle onClick={() => handleEconomicImpact()} className="cls-10" cx="236" cy="239" r="85" />
  <rect onClick={() => handleEconomicImpact()} className="cls-10" x="108" y="337" width="244" height="82" />
  <rect onClick={() => handleEconomicImpact()} className="cls-10" x="125" y="425" width="211" height="43" />
  <rect onClick={() => handleEconomicImpact()} className="cls-10" x="127" y="500" width="211" height="113" />
</svg>
    </>
  );
};