import styled from 'styled-components';
import IdeaSvg from '../svg/icon_idea.svg';

export const WrapperCard = styled.div`
  display: flex;

  @media(max-width: 820px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }


`;

interface WhiteCardProps {
  height?: string;
}

export const WhiteCard = styled.div<WhiteCardProps>`
  width: 100%;
  height: ${({ height }) => height ?? '56%'};

  padding: 26px;
  display: flex;

  max-width: 263px;
  min-width: 263px;

  margin-top: 1rem;
  min-height: 319px;

  text-align: center;
  border-radius: 30px;

  flex-direction: column;
  justify-content: center;

  box-shadow: rgb(0 0 0 / 9%) 5px 5px 10px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);

  p {
    text-align: left;
    color: rgb(29, 29, 27);
    font: 500 14px / 27px Roboto;
  }

  @media(max-width: 1300px) {
    min-width: 0px;
  }

  @media(max-width: 1100px) {
    min-width: 50%;
    margin-right: 5px;
    margin-left: 5px; 
  }
  @media(max-width: 820px) {
    min-width: 100%
  }
`;

export const IdeaIcon = styled(IdeaSvg)`
  margin-bottom: 18px;
`;

export const GreenCard = styled(WhiteCard)`
  height: auto;
  display: flex;
  max-width: 263px;
  align-items: flex-start;
  background: 0% 0% no-repeat padding-box padding-box rgb(147, 213, 10);

  .title {
    margin-bottom: 12px;
    letter-spacing: 1.5px;
    font: bold 12px / 18px Montserrat;
  }

  p {
    text-align: left;
    color: rgb(0, 102, 128);
    letter-spacing: 1.5px;
    font: bold 12px / 18px Montserrat;
  }

  h1 {
    margin-top: 5px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  @media(max-width: 1300px) {
    max-width: 100%;
  }

  @media(max-width: 1100px) {
    min-width: 50%;
    margin-right: 5px;
    margin-left: 5px; 
  }
  @media(max-width: 820px) {
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }

    h1 {
      text-align: center;
    }
  }
`;

interface GraphicCardProps {
  ml?: string;
  mr?: string;
  minW?: string;
  height?: string;
}

export const GraphicCard = styled(WhiteCard)<GraphicCardProps>`
  max-width: 70%;
  min-width: 520px;
  margin-right:${({ mr }) => mr ?? '0px'};
  margin-left: ${({ ml }) => ml ?? '20px'};
  height:  ${({ height }) => height ?? '319px'};

  h4 {
    text-align: left;
    letter-spacing: 1.2px;
    color: rgb(29, 29, 27);
    margin: -5px 0px 2rem 19px;
    font: 900 12px / 14px Roboto;
  }

  @media(max-width: 1300px) {
    min-width: ${({ minW }) => minW ?? '50%'};
  }

  @media(max-width: 1100px) {
    min-width: 50%;
    margin-right: 5px;
    margin-left: 5px; 
  }

  @media(max-width: 820px) {
    height: 370px;
    min-width: 100%;
    margin-left: 0px;
  }
`;

export const DonutsWrapper = styled(GraphicCard)`
  height: 360px;
  max-width: 70%;
  min-width: 280px;

  @media(max-width: 1300px) {
    min-width: 50%;
  }

  @media(max-width: 820px) {
    min-width: 100%;
  }
`;