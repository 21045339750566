import { useMemo, useState } from 'react'

export function sortArrayByMonth(a: any, b: any) {
  if (Number(a.order) < Number(b.order)) {
    return -1
  }
  if (Number(a.order) > Number(b.order)) {
    return 1
  }
  return 0
}

export function sortByLevel(a: any, b: any) {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
}

export const mapperSeriesToChartLine = (originData: any[], unit: any) => {
  const data: any[] = []
  const dataDowload: any[] = []
  let count = 0
  originData?.sort(sortByLevel)
  for (const serie of originData) {
    const dataSerie = serie.data
    const arr = []

    for (const element of dataSerie) {
      let obj: any = {}
      let objDowload: any = {}

      obj.period = new Date(element.period).getTime() + 86400000
      obj[`value${count}`] = element.value
      obj[`valueTool${count}`] = MakeNumberToLocale(element.value)
      obj[`valueTool`] = MakeNumberToLocale(element.value)
      obj.mes = new Date(obj.period).toLocaleString('pt-BR', { month: 'long' })
      obj.year = element?.period.split('-')[0]
      obj.data = element?.period

      objDowload.periodo = element.period
      objDowload.valor = element.value

      if (serie?.label === 'Concedidas' || serie?.label === 'Não concedidas') {
        objDowload.abertura = serie?.label
      }

      if (unit) {
        objDowload.unidade_medida = unit
      }

      arr.push(obj)
      dataDowload.push(objDowload)
    }

    data.push(arr)
    count += 1
  }

  return { data, dataDowload }
}

export const MakeColorOpacity = (value: number): number => {
  return value / 100
}

export const MakeButtonsMemo = (dados: any) => {
  const [cardSelected, setCardSelected] = useState(0)

  const buttonsMemo = useMemo(() => {
    const arr: any = []

    dados?.indicators.forEach((element: any, index: number) => {
      arr.push({
        text: element?.title,
        clicked: index === 0 ? true : false,
        onChange: () => setCardSelected(index)
      })
    })
    return arr
  }, [dados])

  return { buttonsMemo, cardSelected }
}

export const MakeNumberToLocale = (value: number) => {
  let flor = Number(value?.toFixed(1))
  flor = isNaN(flor) ? 0 : flor

  return flor.toLocaleString('pt-BR')
}

export function getYear(period: string) {
  try {
    return period.split('-')[0]
  } catch (error) {}
}
