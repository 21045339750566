import styled, { css } from 'styled-components';

interface ImageProps {
  margin: string;
  isReportAbrc?: boolean;
}

export const Image = styled.img<ImageProps>`

  height: auto;
  max-width: 100%;
  /* margin: 23rem 0 0 0; */
  margin: ${({ margin }) => margin};

  @media (min-width: 1600px) {
    max-width: 110%; 
    ${({ isReportAbrc }) => !isReportAbrc && css`
      margin-left: -2.2%
    `}
  }

  @media (max-width: 1599px) {
    margin-right:0;
    max-width: 110%; 
    ${({ isReportAbrc }) => !isReportAbrc && css`
      margin-left:-2.3% ; 
    `}
  }

  @media (max-width: 1240px) {
    margin-right:0;
    max-width: 111%; 
    ${({ isReportAbrc }) => !isReportAbrc && css`
      margin-left: -2.85%;
    `}
  }

  @media (max-width: 1024px) {
    max-width:110%; 
    margin-right: 0;
    ${({ isReportAbrc }) => !isReportAbrc && css`
      margin-left: -3.65%;
    `}
  }

  @media (max-width: 767px) {
    margin-right:0;
    max-width: 111%; 
    ${({ isReportAbrc }) => !isReportAbrc && css`
      margin-left: -5%;
    `}
  }
`;

export const Container = styled.div`
  width: 100%;
`;
