import styled from 'styled-components';

interface BannerProps {
  image?: string;
}

export const ContentWrapper = styled.div<BannerProps>`
  height: 100%;
  width: 100%;
  
  display: flex;
  padding: 4rem 3rem;

  align-items: center;
  flex-direction: column;
  
  justify-content: center;
  background-size: cover;

  background-repeat: no-repeat;
  background-image: url(${props => props.image});

  @media (max-width: 820px) {
    padding: 2rem 1rem;
    max-height: 262px;
  }
`;

export const BigText = styled.h1`
  color: #FFFFFF;
  text-align: center;
  letter-spacing: 4.1px;
  text-transform: uppercase;
  
  font: normal normal normal 41px/49px Roboto;

  @media (max-width: 820px) {
    text-align: center;
  
    font: normal normal normal 25px/32px Roboto;
  
    letter-spacing: 2.5px;
  }
`;

interface CaptionProps {
  color: string;
}

export const Caption = styled.p<CaptionProps>`
  text-align: center;
  line-height: 24px;
  color: ${props => props.color};
 
  letter-spacing: 1.7px;
  font: normal normal 900 16px/20px Roboto;

  @media (max-width: 820px) {
    text-align: center;
    letter-spacing: 1.4px;
    font: normal normal 900 16px/20px Roboto;
  }
`;

export const Span = styled.span`
  width: 45px;
  height: 6px;
 
  margin: .5rem 0 0 0;
  background: #93D50A 0% 0% no-repeat padding-box;
`;