import React from 'react';

interface IProps {
  page: number;
  onNextPage: (session?: string) => void;
  onPrevPage: (session?: string) => void;
}

/**
 * Hook implementing pagination logic.
 * @param data Any list.
 * @param volume Amount of items per page.
 * @param initialPage Page set on first render.
 */
export const usePagination = <T extends any>
  (data: T[], volume: number): IProps & { data: T[] } => {
  const totalPages = React.useMemo(() => Math.floor((data.length - 1) / volume), [
    volume,
    data.length
  ]);

  const [page, setPage] = React.useState(0);

  const slicedData = React.useMemo(
    () => data.slice(page * volume, page * volume + volume),
    [volume, page]
  );

  const onNextPage = React.useCallback((session?: string) => {
    setPage((prevState: number) => {
      if (session === 'panel' && prevState === 4) {
        return prevState - 4;
      }

      if (session === 'service_quality' && prevState === 0) {
        const newArray = [...data] as any[];
        newArray[2].onChange();
      }

      if (prevState < totalPages) {
        return prevState + 1;
      }

      return prevState;
    });
  }, [totalPages]);

  const onPrevPage = React.useCallback((session?: string) => {
    setPage((prevState: number) => {
      if (session === 'panel' && prevState === 0) {
        return prevState + 4;
      }

      if (session === 'service_quality') {
        const newArray = [...data] as any[];
        newArray[0].onChange();
      }

      if (prevState > 0) {
        return prevState - 1;
      }

      return prevState;
    });
  }, []);

  return { data: slicedData, page, onNextPage, onPrevPage };
};
