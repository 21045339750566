import styled from 'styled-components';
import { StaticImageData } from 'next/image';

interface IProps {
  image: StaticImageData
}

export const Container = styled.div<IProps>`
  width: 100vw;
  height: 100%;
  display: flex;

  align-items: center;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;

  background-repeat: no-repeat;
  background-image: url(${props => props.image.src});
`;

export const Header = styled.header`
  width: 100%;
  height: 70px;
  background: #006680;

  position: sticky;

  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.49);
`;

export const WrapperApp = styled.div`
  width: 87%;
  display: flex;
  overflow: hidden;
  max-width: 1240px;
  background: #e4efed;
  height: fit-content;
  justify-content: center;

  @media (max-width: 620px) {
    width: 90%;
  }

  @media (max-width: 820px) {
    height: fit-content;
  }
`;

export const WrapperContent = styled.div`
  width: 90%;
  display: flex; 

  height: 97.5%;
  margin: 2rem 0 0 0;

  @media (max-width: 820px) {
    align-items: center;
    flex-direction: column;
  }

  /* @media (max-width: 520px) {
    margin: 2rem 0 250rem 0;
  } */
`;

export const ContentSession = styled.div`
  width: 80%;
  min-height: 80%;

  background: blue;
  margin: 0 0 0 1rem;

  @media (max-width: 820px) {
    width: 100%;
    margin: 0.7rem 0 0 0;
  }
`;




