import _ from 'lodash';
import React from 'react';
import { MuiMenubarItems } from './constants';
import { MenuBarItemProps, SelectedSidebarItemProps } from './interfaces';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface SidebarProps {
  clickReport: boolean;
  items: MenuBarItemProps[];
  openSidebarMobile: boolean;
  handleClickReport: () => void;
  session: SelectedSidebarItemProps;
  setClickReport: SetState<boolean>;
  setItems: SetState<MenuBarItemProps[]>;
  setOpenSidebarMobile: SetState<boolean>;
}

export const SidebarContext = React.createContext({} as SidebarProps);

interface IProps {
  children: React.ReactNode;
}

export const SidebarContextProvider: React.FC<IProps> = ({ children }) => {
  const [clickReport, setClickReport] = React.useState<boolean>(false);
  const [openSidebarMobile, setOpenSidebarMobile] = React.useState(false);
  const [items, setItems] = React.useState<MenuBarItemProps[]>(MuiMenubarItems);

  /**
   * @description esse objeto contém os valores boleanos dos itens clicados no sidebar menu
   * e deve ser utilizado para manipular a visualização no diretórios pages
   */
  const selectedSidebarItem = _.reduce(
    items,(acc, { clicked, ref }) =>
    ({ ...acc, [ref]: clicked, ['abcr_report']: clickReport }),
    {}
    ) as SelectedSidebarItemProps;

  /**
   * @description essa função muda a propriedade color do item boletim do sidebar
   * e seu pleno funcionamento está relacionado a função toggleActive
   */
  const handleClickReport = () => {
    if (Object.keys(items).length > 0) {
      const newArray = [...items];
        newArray.forEach(item => item.clicked = false);
      setItems(newArray);
      setClickReport(true);
    }
    setClickReport(true);
    setOpenSidebarMobile(false);
  };

  const value = {
    items,
    setItems,
    clickReport,
    setClickReport,
    openSidebarMobile,
    handleClickReport,
    setOpenSidebarMobile,
    session: selectedSidebarItem,
  };

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};