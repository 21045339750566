import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0rem 0 0 0;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 0;
  width: 75%;

  overflow: hidden;
  margin: 2rem 0 0 0;

  position: relative;
  padding-bottom: 46.25%;
  

  @media screen and (max-width: 414px) {
    width: 90%;
  }
  
  video {
    top: 0;
    left: 0;

    width: 100%;
    height: auto;
    
    position: absolute;
  }
  
  @media screen and (max-width: 820px) {
    margin: 2rem 0 0 0;
  }
`;