import React from 'react';
import SelectIndicator from '../selectIndicator';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { handleCard, HandleCardOptions, handleChart, HandleChartOptions } from '@/utils/factory/layouts';
import { ArrowSelect, BoxOrchestraCard, ColumnCard, ContainerArrow, LineCard } from '@/global/styles/cards';

interface CardsMemoProps {
  dados: any;
  pages: any;
  flex?: boolean;
  cardSelected: number;
}

const CardsMemo: React.FC<CardsMemoProps> = ({
  flex,
  dados,
  pages,
  cardSelected,
}) => {
  const { isMobile } = useMediaQuery('(max-width: 820px)');
  const [arrowSelected, setArrowSelected] = React.useState(false);
  const [indexChartMobile, setIndexChartMobile] = React.useState(0);

  const handleClickArrow = () => {
    setArrowSelected(!arrowSelected);
    setIndexChartMobile(indexChartMobile === 1 ? indexChartMobile - 1 : indexChartMobile + 1);
  };

  const data = dados?.indicators[cardSelected];
  const charts = data?.chart;
  const { firstColumn, secondColumn } = pages[cardSelected];
  const isFlex = firstColumn.at(-1) === true ? true : false;

  return (
    <>
    {!isMobile && (
      <BoxOrchestraCard flex={isFlex}>
        {(flex && cardSelected == 0)  && (
          <>
            <LineCard >
              {
                firstColumn.map((shape: HandleCardOptions, index: number) => {
                  return handleCard(data, shape);
                })
              }
            </LineCard>
            <LineCard width='great'>
              {
                secondColumn.map((shape: HandleCardOptions, index: number) => {
                  return handleChart(charts[index], shape, isMobile);
                })
              }
            </LineCard>
          </>
        )}
        {(!isFlex ) && (
          <>
            <ColumnCard>
          {
            firstColumn.map((shape: HandleCardOptions, index: number) => {
              return handleCard(data, shape);
            })
          }
        </ColumnCard>
        <ColumnCard width='great'>
          {
            secondColumn.map((shape: HandleCardOptions, index: number) => {
              return handleChart(charts[index], shape, isMobile);
            })
          }
        </ColumnCard>
          </>
        )}
      </BoxOrchestraCard>
    )}
    {isMobile && (
      <BoxOrchestraCard>
        <ColumnCard >
          {
            firstColumn.map((shape: HandleChartOptions, index: number) => {
              if (indexChartMobile === 0 && index === 0) {
                return handleCard(data, shape);
              }
              if (indexChartMobile === 1 && index === 1) {
                return handleCard(data, shape);
              }
            })
          }
        </ColumnCard>
        <ColumnCard width='great'>
          {
            secondColumn.map((shape: any, index: number) => {
              if (indexChartMobile === 0 && index === 0) {
                return handleChart(charts[indexChartMobile], shape, isMobile);
              }
              if (indexChartMobile === 1 && index === 1) {
                return handleChart(charts[indexChartMobile], shape, isMobile);
              }
            })
          }
        </ColumnCard>
        <ContainerArrow turnOn={arrowSelected}>
          <ArrowSelect onClick={handleClickArrow} />
        </ContainerArrow>
        <SelectIndicator arrowSelected={arrowSelected} />
      </BoxOrchestraCard>
    )}
    </>
  );
};

export default CardsMemo;