import styled, { keyframes } from 'styled-components';
import Arrow from './icon_arrow_down.svg';

interface LineCardProps {
  width?: string;
}

interface ColumnCardProps {
  width?: string;
}
interface ArrowProps {
  turnOn: boolean;
}

interface BoxOrchestraCardP{
margin?: boolean;
flex?: boolean;
}
export const BoxOrchestraCard = styled.div<BoxOrchestraCardP>`
  width: 100%;
  height: 570px;
  display: flex;
  flex-direction: ${props => props.flex ? 'column' : 'row' } ;
  margin-top: ${props => props.margin ? '0px': '15px' };
  margin-bottom: 15%;

  @media (max-width: 820px) {
    height: auto;
    margin-bottom: 5%;
    
    flex-direction: column;
  }
`;

export const ColumnCard = styled.div<ColumnCardProps>`
  /* margin-right: 30px; */
  width: ${props => props.width ? '70%' : '34%'};
  
  @media (max-width: 820px) {
    width: 100%;
  }
`;
export const LineCard = styled.div<LineCardProps>`
  /* margin-right: 30px; */
  width: 100%;
  display: flex ;
  height: 76%;
  @media (max-width: 820px) {
    width: 100%;
  }
`;


const upRotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(180deg) }
`;

const downRotate = keyframes`
  from { transform: rotate(180deg) }
  to { transform: rotate(0deg) }
`;

export const ArrowSelect = styled(Arrow)`
align-self: center ;

`;

export const ContainerArrow = styled.div<ArrowProps>`
width: 100% ;
height: auto ;
display: flex ;
justify-content: center;
margin-bottom: 30px;
transform: ${({ turnOn }) => turnOn ? "rotate(180deg)" : "rotate(0deg)"};
animation: ${({ turnOn }) => turnOn ? upRotate : downRotate} .5s forwards;

@media (min-width: 820px) {
  display: none;
}
`;

