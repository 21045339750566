import React from 'react';
import ColumnBarChart from '@/global/components/chart/ColumnBar'
import Gradient from '@/global/components/gradient'
import Card from '@/global/components/layout/cards'
import Information from '@/global/components/layout/information'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import dynamic from 'next/dynamic'
import { shapeEnum } from '../enum'

const ChartLine = dynamic(import('src/global/components/chart/line'), {
  ssr: false
})
const ChartReversedAxies = dynamic(
  import('src/global/components/chart/reverseLine'),
  { ssr: false }
)
const ChartVelocimeter = dynamic(
  import('src/global/components/chart/velocimeter'),
  { ssr: false }
)
const DonutChart = dynamic(import('src/global/components/chart/donuts'), {
  ssr: false
})
const ClusteredBarChart = dynamic(
  import('src/global/components/chart/clusteredBar'),
  { ssr: false }
)
const StackedAreaChart = dynamic(
  import('src/global/components/chart/stackedArea'),
  { ssr: false }
)

let Map: any = []

if (typeof window !== 'undefined') {
  Map = dynamic(import('src/global/components/map') as any, { ssr: false })
}

export interface BigNumber {
  value: number
  text: string
  title: string
}

export interface Datum {
  period: string
  value: number
}

export interface Series {
  label: string
  data: Datum[]
}

export interface ChartProps {
  title: string
  source: string
  info: string
  series: Series[]
}
interface HandleCard {
  title: string
  block: string
  big_number: BigNumber[]
  chart: ChartProps[]
}

export interface HandleChartOptions {
  cardType?: 'great' | 'small'
  fullHeigth?: boolean
  type: shapeEnum
  mapperType?: mapperType
  period?: periodType
  color?: string | undefined
  tipo?: any
  fullWidth?: boolean
}
export interface HandleCardOptions {
  cardType?: 'great' | 'small'
  fullHeigth?: boolean
  type: shapeEnum
  tipo?: 'card' | 'chart' | 'cardLarge'
}

type mapperType = 'line' | 'reverse' | undefined
export type periodType = 'month' | 'year' | undefined

/**
 * @description returnChart retorna um tipo especifico de chart de acordo com o type;
 * @param type shapeEnum: especifica o type de grafico;
 * @param series any: é a serie de dados;
 * @returns React.FC;
 */
const returnChart = (
  type: shapeEnum,
  series: any,
  mapper: mapperType,
  dados: any,
  isMobile: boolean,
  period?: periodType,
  color?: any
) => {
  switch (type) {
    case shapeEnum.areaChart:
      return (
        <StackedAreaChart
          dados={dados}
          series={series ? series[0] : []}
          title={dados?.title}
        />
      )
    case shapeEnum.barChart:
      return (
        <ClusteredBarChart dados={dados} series={series} title={dados?.title} />
      )
    case shapeEnum.donutsChart:
      return (
        <DonutChart
          dados={dados}
          series={series}
          title={dados?.title}
        />
      )
    case shapeEnum.lineChart:
      return (
        <ChartLine
          series={series}
          dados={dados}
          title={dados?.title}
          period={period}
        />
      )
    case shapeEnum.velocimeterChart:
      return (
        <ChartVelocimeter
          dados={dados}
          data={series}
          color={color}
          title={dados?.title}
        />
      )
    case shapeEnum.reverseChart:
      return (
        <ChartReversedAxies
          dados={dados}
          series={series}
          mapperLine={mapper == 'line' ? true : false}
          title={dados?.title}
        />
      )
    case shapeEnum.columnBarChart:
      return (
        <ColumnBarChart dados={dados} series={series} title={dados?.title} />
      )
  }

  return <p>aaaa</p>
}

export const handleChart = (
  dados: ChartProps,
  options: HandleChartOptions,
  isMobile: boolean
) => {
  const id = Math.random()
  const series = dados?.series

  const { type, fullHeigth, mapperType, period, color, fullWidth } = options

  return (
    <Card
      isMap
      key={id}
      title={dados?.title}
      type="great"
      fullWidth={fullWidth}
      heigth={fullHeigth === true ? fullHeigth : false}
    >
      <div style={{ width: '100%', height: 'auto', display: 'flex' }}>
        {returnChart(type, series, mapperType, dados, isMobile, period, color)}
        <Information info={dados?.info} />
      </div>
    </Card>
  )
}

export const handleCard = (data: HandleCard, options: HandleCardOptions) => {
  const id = Math.random()

  const { type, fullHeigth, tipo } = options

  switch (type) {
    case shapeEnum.blockText:
      return (
        <Card
          tipo={tipo}
          key={id}
          text={data?.block}
          type="small"
          heigth={fullHeigth === true ? fullHeigth : false}
        />
      )
    case shapeEnum.bigNumber:
      return (
        <Card
          tipo={tipo}
          key={id}
          bg
          bigNumber={data?.big_number}
          type="small"
        />
      )
    case shapeEnum.map:
      return (
        <Card key={id} title={data?.chart[2]?.title} isMap>
          {window && (
            <>
              <Map dados={data?.chart[2]?.series[0]?.data} />
              <Gradient />
            </>
          )}
        </Card>
      )
  }
}

export const makeSufixBigNumber = (format: any) => {
  if (format == 'R$') {
    return format + ' '
  }
  if (format == '+') {
    return format
  }
  return ''
}
export const makeSufixEndBigNumber = (format: any) => {
  if (format === '%') {
    return format
  }

  return ''
}
