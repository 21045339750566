import ArrowSvg from './svg/arrow.svg';
import styled, { css } from 'styled-components';

interface IProps {
  isOpen: boolean;
}

export const Container = styled.div`
  width: 75%;

  @media (max-width: 414px) {
    width: 140%;
  }
`;

export const SelectMenu = styled.div<IProps>`
  width: 100%;
  margin: 1rem 0 1rem 0;
  position:relative;

  svg {
    ${({ isOpen }) => !isOpen && css`transform: rotate(-180deg);`}
  }

  ul {
    ${({ isOpen }) => !isOpen && css`display: none;`}
  }
`;

export const SelectButton = styled.div`
  width: 100%;
  height: 33px;
  display: flex;

  padding:  20px;
  cursor: pointer;
  /* background: #fff; */

  font-size: 18px;
  border: 1px solid #B7B7B7;
  
  font-weight: 400;
  border-radius: 8px;
  
  align-items: center;
  justify-content: space-between;
  
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  span {
    color: #1D1D1B;

    &:hover {
      color: #93D50A;
    }
  }

  @media (max-width: 414px) {
    padding: 0;

    span {
      margin: 0 0 0 .5rem;
    }
  }
`;

export const ArrowIcon = styled(ArrowSvg)<IProps>`
  @media (max-width: 414px) {
    margin: 0 .3rem 0 0;
  }
`;

export const Options = styled.ul`
  top:100%;
  left:0;

  right:0;
  z-index:1;

  width: 100%;
  border: 1px solid #B7B7B7;
  
  padding: 20px;
  position:absolute;

  @media (max-width: 414px) {
    padding: 0;
  }
  
  background: #fff;
  margin-top: 10px;

  border-radius: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);;
`;

export const Option = styled.li`
  width: 100%;
  display: flex;
  height: 35px;

  cursor: pointer;
  
  border-radius: 8px;
  align-items: center;

  @media (max-width: 414px) {
    margin: 1rem 0 1rem 0.5rem;
  }
`;

export const Item = styled.span`
  color: #333;
  font-size: 18px;

  &:hover {
    color: #93D50A;
  }
`;