import { outsideNavigation } from '@/utils/outsideNavigation';
import React from 'react';
import { Button, Container, SearchIcon, Text } from './styled';

export const AbcrButton: React.FC = () => {
  return (
    <Container onClick={() => outsideNavigation('https://melhoresrodovias.org.br/indice-abcr/', '_blank')}>
      <Button>
        <SearchIcon />
        <Text>Acesse o Índice ABCR</Text>
      </Button>
    </Container>
  );
};