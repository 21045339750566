import { useSidebar } from '@/hooks/useContexts';
import React from 'react';
import { ArrowIcon, SubTitle, Title, TitleWrapper, WrapperArroIcon } from './styles';

export const TitleHeader: React.FC = () => {
  const { setOpenSidebarMobile, openSidebarMobile } = useSidebar();
  return (
    <TitleWrapper>
      <Title>Painel</Title>
      <SubTitle>ABCR+</SubTitle>
      <WrapperArroIcon onClick={() => setOpenSidebarMobile(!openSidebarMobile)} >
        <ArrowIcon isOpen={openSidebarMobile} />
      </WrapperArroIcon>
    </TitleWrapper>
  );
};