import styled from 'styled-components';
import Search from './svg/icon_search.svg';

export const Container = styled.div`
  margin: 2rem 0 1rem 0;
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const Button = styled.button`
  width: 240px;
  display: flex;

  padding: .7rem;
  
  border: none;
  cursor: pointer;

  background: #1E303A;
  border-radius: 20px;

  justify-content: space-evenly;

  &:hover {
    background: #93D50A;
  }
`;

export const SearchIcon = styled(Search)``;

export const Text = styled.span`
  color: #FFFFFF;
  text-align: center;

  letter-spacing: 1.4px;
  font: normal normal bold 14px/20px Roboto;
`;