import {
  Title,
  Container,
  DownloadIcon,
  WrapperImage,
  DownloadButton,
  ContentWrapper,
  ContainerWrapper
} from './styles';
import React from 'react';
import Image from 'next/image';
import { useReport } from '@/hooks/useContexts';
import { Grafismo } from '@/global/components/grafismo';
import { AbcrButton } from '@/global/components/abcrButton';
import { SelectInput } from '@/global/components/inputs/select';
import { SessionBanner } from '@/global/components/sessionBanner';


export const AbcrReport: React.FC = () => {
  const { selectedItemInput, inputItems, selectedItemImage } = useReport();
  const isIFrame = selectedItemInput === 'Selecionar edição';
  const srcIframeItem = inputItems.filter(item => item.text ===  selectedItemInput)[0];

  return (
    <ContainerWrapper>
      <Container>
        <SessionBanner
          content={`
            O Boletim ABCR+ é uma publicação mensal da Melhores Rodovias do 
            Brasil - ABCR. No documento, são disponibilizados dados relevantes 
            e indicadores relativos às concessões de rodovias no Brasil, organizados
            em cinco temas.
          `}
          colorCaption='#1D1D1B'
        />
        <ContentWrapper isIframe={isIFrame}>
          <Title>Baixar Boletim ABCR+</Title>
          <SelectInput />
          <DownloadButton>
            <DownloadIcon />
            <a href={srcIframeItem?.url} download target='_blank'>{selectedItemInput === 'Selecionar edição' ? '...' : selectedItemInput}</a>
          </DownloadButton>
          <WrapperImage isItem={isIFrame}>
            <Image
              width={400}
              height={500}
              src={selectedItemImage}
              alt={srcIframeItem?.alt}
            />
          </WrapperImage>
        </ContentWrapper>
      </Container>
      <AbcrButton />
      <Grafismo margin="28rem 0 0 0" isReportAbrc={true} />
    </ContainerWrapper>
  );
};