import { StaticImageData } from 'next/image';
import styled, { css } from 'styled-components';
import { MenuBarItemProps } from '@/context/sidebar/interfaces';

interface ContainerProps {
  isReport: boolean;
}

export const Container = styled.div<ContainerProps>`
  /**
  * Altura correspondente ao 100% do componente WrapperContent
  * o componente se encontra em @/global/components/layout
  **/
  background: #2A3B43;
  min-width: min(30%, 280px);
  border-radius: 30px 30px 0px 0px;

  ${({ isReport }) => isReport && css`
    height: vh;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  height: 150px;
  border-radius: 30px 30px 0px 0px;

  
  align-items: center;
  background: #1E303A;

  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  color: #2F83C2;
  font-size: 18px;

  text-align: center;
  letter-spacing: 2.2px;
  
  font-family: var(--font-main);
`;

export const SubTitle = styled.h2`
  color: #FFFFFF;
  font-size: 30px;
  
  text-align: center;
  letter-spacing: 4.2px;
  
  font-family: var(--font-main);
`;

export const NavWrapper = styled.nav`
  height: 111%;
  margin: 2rem 0 0 0;
  background: #2A3B43;
  
`;

interface MenuItemProps {
  color: string;
  index: number;
  bg: StaticImageData;
  items: MenuBarItemProps[];
}

export const WrapperItems = styled.div<MenuItemProps>`
  height: 3.5rem;
  margin: 0 0 0 1.2rem;
  width: 85%;

  display: flex;
  align-items: center;

  cursor: pointer;
  text-transform: uppercase;
  transition: all .2s ease-in-out;

  ${({ items, index, bg }) => items[index].clicked && css`
    width: 85%;
    background: #E4EFED;
    border-radius: 30px;
    transition: all 0.1s ease-out;

    p:hover {
      color:  #2A3B43;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  max-width: 195px;
  
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
  
  @media screen and (max-width: 1200px) {
    max-width: 165px;
  }

`;

interface ItemProps {
  color?: string;
}

export const Item = styled.p<ItemProps>`
  font-size: 14px;
  letter-spacing: .4px;

  color: ${props => props.color};
  
  font-family: Roboto, sans-serif;

  &:hover {
    color: #93D50A;
  }
`;

export const ReportWrapper = styled.div`
  display: flex;
  margin: 1rem 0 0 0;

  align-items: center;
  flex-direction: column;

  justify-content: center;
`;

export const Divider = styled.div`
  width: 80%;
  height: 2px;
  
  background: #fff;
  margin: 0 0 0 2rem;
`;

interface TypograpyProps {
  clicked: boolean;
}

export const Typograpy = styled.p<TypograpyProps>`
  cursor: pointer;
  text-align: left;

  margin: 0 0 1rem 0;
  letter-spacing: 1.4px;

  font-family: Roboto, sans-serif;
  color: ${props => props.clicked ? '#93D50A': '#E4EFED'};

  &:hover {
    color: #93D50A;
  }
`;

interface IProps {
  marginTop: string;
}

export const BoxLogoMacroplan = styled.div<IProps>`
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.marginTop};
  
  .img {
    width: 74px;
    height: 36.553px;
  
  }

  p {
    color: white;
    font-size: 11px;
    font-family: Roboto,sans-serif;
  }
`;