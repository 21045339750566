import _ from 'lodash';
import React from 'react';
import * as am5 from '@amcharts/amcharts5';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { performColumnBarChart } from '@/utils/performCharts/ColumnBar';

interface IProps {
  series: any;
  title: string;
  dados: any
}

const ColumnBarChart: React.FC<IProps> = ({ series, title, dados }) => {
  const { isMobile } = useMediaQuery('(max-width: 820px)');
  const id = Math.random();

  React.useLayoutEffect(() => {
    const root = am5.Root.new(`chart_div_${id}`);
    if(series.length > 0) {
      performColumnBarChart(root, series, isMobile, title, dados);
    }
    return () => root.dispose();
  }, [series]);

  return <div id={`chart_div_${id}`} style={{ width: '100%', height: '100%' }}></div>;
};

export default ColumnBarChart;
