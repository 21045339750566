import styled from "styled-components";

interface PointerCircleProps{
  check?: boolean;
  margin?:boolean;
}

export const Container = styled.div`

width: 30px;
height: 45px;
background: #B3D2D9 0% 0% no-repeat padding-box;
position: fixed;
left:0px;
bottom: 30px;
display: flex;
justify-content: center;
align-items:center ;
flex-direction: column;
@media (min-width: 820px) {
  display: none ;
}
`
export const PointerCircle = styled.div<PointerCircleProps>`
width: 10px;
height: 10px;
background: ${props => props.check ? '#006680' : 'transparent'};
border-radius: 100% ;
border: 2px solid #006680;
margin: ${props => props.margin ? '5px':'0x'}
`