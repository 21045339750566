
import {
  ArrowTp,
  ArrowTi,
  Container,
  Description,
  WrapperAbcr,
  WrapperArrow,
  WrapperHeader,
  ArrowLeftIcon,
  WrapperPainel,
  WrapperLefIcon,
  MobileAbcrWrapper,
  WrapperAttendance,
  WrapperDescription,
} from './styles';
import React from 'react';
import { AbcrFigure } from './svg/AbcrFigure';
import { MobileIndicators } from './constants';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { usePagination } from '@/hooks/usePagination';
import { Grafismo } from '@/global/components/grafismo';
import { AbcrButton } from '@/global/components/abcrButton';
import { YoutubeEmbed } from '@/global/components/youtubeEmbed';

export const Panel: React.FC = () => {
  const { isMobile } = useMediaQuery('(max-width: 820px)');
  const { data, onNextPage, onPrevPage } = usePagination(MobileIndicators, 1);

  return (
    <WrapperPainel>
      <Container>
        <WrapperHeader>
          <WrapperDescription>
            <WrapperLefIcon>
              <ArrowLeftIcon />
            </WrapperLefIcon>
            <Description>Aqui você vai acompanhar os principais indicadores de desempenho das Melhores Rodovias do Brasil</Description>
          </WrapperDescription>
        </WrapperHeader>
        <YoutubeEmbed  embedId='CmyW7BmYqA0' />
        <div style={{ height: '2px', background: '#E4EFED', width: '100%', margin: '2rem 0 2rem 0' }} ></div>
        {!isMobile && (<WrapperAbcr><AbcrFigure /></WrapperAbcr>)}
        {isMobile && (
          <MobileAbcrWrapper>
            <WrapperAttendance>
              {data.map(item => (
                <>
                  {item.icon}
                </>
              ))}
            </WrapperAttendance>
            <WrapperArrow>
              <div style={{ zIndex: 99999 }} onClick={() => onPrevPage('panel')}>
                <ArrowTi />
              </div>
              <div style={{ zIndex: 99999 }} onClick={() => onNextPage('panel')}>
                <ArrowTp />
              </div>
            </WrapperArrow>
          </MobileAbcrWrapper>
        )}
      </Container>
      <AbcrButton />
      <Grafismo margin='21rem 0 0 0' />
    </WrapperPainel>
  );
};
