import styled from 'styled-components';
import DownloadSvg from './svg/download.svg';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;

  @media (max-width: 820px) {
    width: 100%;
    /* margin: 0 0 70rem 0; */
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  height: fit-content;
  border-radius: 30px;

  margin: 0 0 0 1.2rem;
  box-shadow: 5px 5px 10px #00000017;

  background: #FFFFFF 0% 0% no-repeat padding-box;

  @media (max-width: 820px) {
    width: 100%;
    margin: 0;
  }
`;

interface ContentWrapperProps {
  isIframe: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 50%; 
  display: flex;
  margin: 0 0 3rem 0;
  
  align-items: center;
  flex-direction: column;
  
  justify-content: center;

  @media (max-width: 414px) {
    width: 60%;
  }
`;

export const Title = styled.span`
  color: #006680;
  text-align: center;
  
  letter-spacing: 2px;
  font: normal normal 900 20px/23px Roboto;
`;

export const DownloadButton = styled.button`
  width: 75%;
  border: none;

  display: flex;
  cursor: pointer;

  border-radius: 5px;
  padding: .5rem 0 .3rem .5rem;
  background: #93D50A 0% 0% no-repeat padding-box;

  a {
    color: #FFFFFF;
    text-align: left;
    margin: 0 0 0 .7rem;
    text-decoration: none;

    letter-spacing: 1.2px;
    font: normal normal bold 12px/23px Roboto;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 414px) {
    width: 140%;
  }
`;

export const DownloadIcon = styled(DownloadSvg)`
  margin: 0 0 0 .3rem;
`;

interface WrapperImageProps {
  isItem: boolean;
}

export const WrapperImage = styled.div<WrapperImageProps>`
  display: ${({ isItem }) => isItem ? 'none' : 'flex'};
  width: 75%;
  margin: 2rem 0 0 0;
  justify-content: center;

  &:hover {
    /* background: black; */
  }

  img {
    width: 100%;
    /* cursor: pointer; */

    &:hover {
      opacity: .8;
    }
  }

  @media (max-width: 414px) {
    width: 140%;
  }
`;