import React from 'react';
import { StaticImageData } from 'next/image';
import {
  Span,
  BigText,
  Caption,
  ContentWrapper, 
} from './styles';

interface IProps {
  title?: string;
  content: string;
  colorCaption?: string;
  bannerImage?: StaticImageData;
}

export const SessionBanner: React.FC<IProps> = ({
  bannerImage,
  content,
  title,
  colorCaption = '#FFFFFF'
}) => {
  return (
    <ContentWrapper image={bannerImage?.src}>
      {title && (<BigText>{title}</BigText>)}
      <Caption color={colorCaption}>{content}</Caption>
      <Span />
    </ContentWrapper>
  );
};