import {
  Item,
  Title,
  Content,
  Divider,
  SubTitle,
  Container,
  Typograpy,
  NavWrapper,
  TitleWrapper,
  WrapperItems,
  ReportWrapper,
  BoxLogoMacroplan,
} from './styles';
import _ from 'lodash';
import React from 'react';
import Link from 'next/link';
import { useSidebar } from '@/hooks/useContexts';
import BgButtonUrl from '@/context/sidebar/svg/bg_button.png';
import { toggleActiveAction } from '@/utils/toggleActiveAction';
import { toggleActiveStyles } from '@/utils/toggleActiveStyles';
import { MenuBarItemProps } from '@/context/sidebar/interfaces';

interface IProps {
  isReport: boolean;
}

export const SidebarDesktop: React.FC<IProps> = ({ isReport }) => {
  const {
    items,
    session,
    setItems,
    clickReport,
    setClickReport,
    handleClickReport,
  } = useSidebar();

  const handleToggleActiveSessionItens = (indexSession: number) => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: indexSession,
    });
    setClickReport(false);
    setItems(newItems);
  };

  const handleLogoPosition = () => {
    if (session.impacto_ambiental) {
      return '50%';
    };

    if (session.qualidade_servicos) {
      return '285%';
    };

    if (session.user_attendance) {
      return '298%';
    }

    return '167%';
  };

  return (
    <Container isReport={isReport}>
      <TitleWrapper>
        <Title>Painel</Title>
        <SubTitle>ABCR+</SubTitle>
      </TitleWrapper>

      <NavWrapper>
        {items.map((item, index) => (
          <WrapperItems
            index={index}
            items={items}
            key={item.ref}
            bg={BgButtonUrl}
            onClick={() => handleToggleActiveSessionItens(index)}
            color={toggleActiveStyles(items, index, false, false)}
          >
            <Content>
              {item.main_icon}
              <Item color={toggleActiveStyles(items, index, true, false)}>{item.text}</Item>
            </Content>
          </WrapperItems>
        ))}
        <Divider style={{ marginTop: '1rem' }} />
        <ReportWrapper>
          <Typograpy clicked={clickReport} onClick={handleClickReport}>Boletim ABCR+</Typograpy>
        </ReportWrapper>
        <Divider style={{ marginBottom: '2rem' }} />
        <BoxLogoMacroplan marginTop='0'>
          <p>Powered by</p>
          <Link href={'https://www.analytics.macroplan.com.br/'} target='_blank'>
          <img  className='img' src="/macroM.jpg" alt="" />
          </Link>
        </BoxLogoMacroplan>
      </NavWrapper>
    </Container>
  );
};