import {
  IdeaIcon,
  WhiteCard,
  GreenCard,
  GraphicCard,
  WrapperCard,
  WrapperGraphic,
} from './styles';
import React from 'react';
import dynamic from 'next/dynamic';
import { MakeNumberToLocale } from '@/utils/factory';
import Information from '@/global/components/layout/information';

const ChartVelocimeter = dynamic(import('src/global/components/chart/velocimeter'), { ssr: false });

interface IProps {
  dados: any;
}

export const AverageSpeed: React.FC<IProps> = ({ dados }) => {
  return (
    <>
      <WrapperCard>
        <WhiteCard>
          <IdeaIcon />
          <p>{dados?.block}</p>
        </WhiteCard>
        <GreenCard>
          <p>{dados?.big_number[0]?.title}</p>
          <h1>
            {MakeNumberToLocale(dados?.big_number[0]?.value)}
            {dados?.big_number[0]?.format}
          </h1>
          <p>{dados?.big_number[0]?.text}</p>
        </GreenCard>
      </WrapperCard>
      <WrapperGraphic>
        <GraphicCard>
          <h4>{dados?.chart[0]?.title}</h4>
          <div style={{ height: '372px', display: 'flex', width: '100%' }}>
            <ChartVelocimeter
              dados={dados?.chart[0]}
              data={dados?.chart[0]?.series}
              title={dados?.chart[0]?.title}
              color="#006680​"
            />
            <Information info={dados?.chart[0]?.info} />
          </div>
        </GraphicCard>
        <GraphicCard ml="20px">
          <h4>{dados?.chart[1]?.title}</h4>
          <div style={{ height: '372px', display: 'flex', width: '100%' }}>
            <ChartVelocimeter
              dados={dados?.chart[1]}
              data={dados?.chart[1]?.series}
              title={dados?.chart[1]?.title}
              color='#A2D243'
            />
            <Information info={dados?.chart[1]?.info} />
          </div>
        </GraphicCard>
      </WrapperGraphic>
      <WrapperGraphic>
        <GraphicCard>
          <h4>{dados?.chart[2]?.title}</h4>
          <div style={{ height: '372px', display: 'flex', width: '100%' }}>
            <ChartVelocimeter
              dados={dados?.chart[2]}
              data={dados?.chart[2]?.series}
              title={dados?.chart[2]?.title}
              color="#006680​"
            />
            <Information info={dados?.chart[2]?.info}/>
          </div>
        </GraphicCard>
        <GraphicCard ml="20px">
          <h4>{dados?.chart[3]?.title}</h4>
          <div style={{ height: '372px', display: 'flex', width: '100%' }}>
            <ChartVelocimeter
              dados={dados?.chart[3]}
              data={dados?.chart[3]?.series}
              title={dados?.chart[3]?.title}
              color="#A2D243"
            />
            <Information info={dados?.chart[3]?.info} />
          </div>
        </GraphicCard>
      </WrapperGraphic>
    </>
  );
};