import styled from 'styled-components';
import IdeaSvg from '../../svg/icon_idea.svg';


export const WrapperCard = styled.div`
  display: flex;

  @media(max-width: 820px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const WhiteCard = styled.div`
  width: 100%;
  height: 56%;

  padding: 26px;
  display: flex;

  max-width: 53%;
  min-width: 263px;

  margin-top: 1rem;
  min-height: 319px;

  text-align: center;
  border-radius: 30px;

  flex-direction: column;
  justify-content: center;

  box-shadow: rgb(0 0 0 / 9%) 5px 5px 10px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);

  p {
    text-align: left;
    color: rgb(29, 29, 27);
    font: 500 14px / 27px Roboto;
  }

  @media(max-width: 1300px) {
    min-width: 0px;
  }

  @media(max-width: 820px) {
    min-width: 100%
  }
`;

export const IdeaIcon = styled(IdeaSvg)`
  margin-bottom: 18px;
`;

export const GreenCard = styled(WhiteCard)`
  height: auto;
  max-width: 277px;
  background: 0% 0% no-repeat padding-box padding-box rgb(147, 213, 10);

  p {
    text-align: center;
    color: rgb(0, 102, 128);
    font: bold 12px / 18px Montserrat;
  }

  h1 {
    opacity: 1;
    margin-top: 5px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  @media(max-width: 1300px) {
    max-width: 100%;
  }
`;

export const GraphicCard = styled(WhiteCard)`
  height: 319px;
  min-width: 520px;
  margin-left: 20px;

  h4 {
    text-align: left;
    letter-spacing: 1.2px;
    color: rgb(29, 29, 27);
    margin: -5px 0px 2rem 19px;
    font: 900 12px / 14px Roboto;
  }

  @media(max-width: 1300px) {
    min-width: 50%;
  }

  @media(max-width: 820px) {
    height: 370px;
    min-width: 100%;
    margin-left: 0px;
  }
`;