import { MenuBarItemProps } from "@/context/sidebar/interfaces";
import { useSidebar } from "@/hooks/useContexts";
import { toggleActiveAction } from "@/utils/toggleActiveAction";

export const EnviromentImpactIcon = () => {
  const { setItems, setClickReport, items } = useSidebar();
  const handleAmbientalImpact = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 3
    });
    setClickReport(false);
    setItems(newItems);
  };
  return (
    <>
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473 674">
  <defs>
    <style>
      {
        `
        .cls-1,
        .cls-7,
        .cls-8 {
          fill: none;
          stroke: #1e303a;
          stroke-miterlimit: 10;
        }
  
        .cls-1 {
          stroke-width: 4px;
        }
  
        .cls-2 {
          fill: #2f82c1;
        }
  
        .cls-3 {
          fill: #1e303a;
        }
  
        .cls-10,
        .cls-4 {
          fill: #fff;
        }
  
        .cls-5 {
          fill: #006680;
        }
  
        .cls-6 {
          fill: #1d1d1b;
        }
  
        .cls-8 {
          stroke-width: 2.5px;
        }
  
        .cls-9 {
          fill: #93c022;
        }
  
        .cls-10 {
          opacity: 0;
        }
        `
      }
    </style>
  </defs>
  <title>Painel ABCR mobile</title>
  <line className="cls-1" x1="11.78" y1="123" x2="459.78" y2="123" />
  <path className="cls-2"
    d="M199.33,23.37v6.7h-3.14V12h6.91a7,7,0,0,1,4.81,1.58,5.31,5.31,0,0,1,1.77,4.17,5.15,5.15,0,0,1-1.74,4.14,7.35,7.35,0,0,1-4.88,1.47Zm0-2.53h3.77a3.71,3.71,0,0,0,2.55-.78,2.9,2.9,0,0,0,.88-2.28,3.13,3.13,0,0,0-.89-2.34,3.45,3.45,0,0,0-2.45-.9h-3.86Z" />
  <path className="cls-2"
    d="M222.55,30.07a4.83,4.83,0,0,1-.34-1.25,4.7,4.7,0,0,1-3.52,1.5,4.77,4.77,0,0,1-3.3-1.15,3.7,3.7,0,0,1-1.28-2.86A3.82,3.82,0,0,1,215.7,23a7.75,7.75,0,0,1,4.56-1.15h1.85V21a2.36,2.36,0,0,0-.58-1.66,2.31,2.31,0,0,0-1.78-.63,2.65,2.65,0,0,0-1.68.51,1.6,1.6,0,0,0-.66,1.31h-3a3.34,3.34,0,0,1,.74-2.06,4.8,4.8,0,0,1,2-1.51,7,7,0,0,1,2.81-.55,5.6,5.6,0,0,1,3.75,1.19,4.19,4.19,0,0,1,1.44,3.33v6a6.84,6.84,0,0,0,.51,2.89v.21Zm-3.31-2.17a3.39,3.39,0,0,0,1.68-.43,2.92,2.92,0,0,0,1.19-1.17V23.77h-1.63a4.5,4.5,0,0,0-2.51.58,1.88,1.88,0,0,0-.85,1.65,1.8,1.8,0,0,0,.58,1.39A2.24,2.24,0,0,0,219.24,27.9Z" />
  <path className="cls-2"
    d="M230.82,13.17a1.63,1.63,0,0,1,.44-1.16,2,2,0,0,1,2.53,0,1.63,1.63,0,0,1,.44,1.16,1.57,1.57,0,0,1-.44,1.13,2,2,0,0,1-2.53,0A1.57,1.57,0,0,1,230.82,13.17Zm3.2,16.9h-3V16.65h3Z" />
  <path className="cls-2"
    d="M242.64,16.65l.09,1.55a4.81,4.81,0,0,1,3.9-1.8q4.2,0,4.27,4.8v8.87h-3V21.38a2.75,2.75,0,0,0-.55-1.89,2.32,2.32,0,0,0-1.8-.62,2.91,2.91,0,0,0-2.72,1.65v9.55h-3V16.65Z" />
  <path className="cls-2"
    d="M262.42,30.32a6.23,6.23,0,0,1-4.65-1.81,6.56,6.56,0,0,1-1.78-4.8v-.37a8,8,0,0,1,.78-3.59,5.85,5.85,0,0,1,2.17-2.46,5.76,5.76,0,0,1,3.13-.89,5.26,5.26,0,0,1,4.23,1.75,7.39,7.39,0,0,1,1.5,5v1.22H259A4.05,4.05,0,0,0,260.14,27a3.36,3.36,0,0,0,2.45,1A4.08,4.08,0,0,0,266,26.24l1.63,1.55a5.57,5.57,0,0,1-2.15,1.87A6.79,6.79,0,0,1,262.42,30.32Zm-.36-11.5a2.55,2.55,0,0,0-2,.87,4.58,4.58,0,0,0-1,2.42h5.74v-.22a3.62,3.62,0,0,0-.8-2.29A2.52,2.52,0,0,0,262.06,18.82Z" />
  <path className="cls-2" d="M275.88,30.07h-3V11h3Z" />
  <path className="cls-3"
    d="M171.23,68.61H159.77l-2,6.48h-8.94L161.56,40.4h7.86l12.82,34.69h-9Zm-9.46-6.46h7.46l-3.74-12Z" />
  <path className="cls-3"
    d="M189.65,75.09V40.4h12.46q6.69,0,10.18,2.45a8.17,8.17,0,0,1,3.5,7.13,8.32,8.32,0,0,1-1.24,4.6,7.3,7.3,0,0,1-3.65,2.81,7.25,7.25,0,0,1,4.17,2.67,7.74,7.74,0,0,1,1.45,4.76c0,3.4-1.07,6-3.23,7.66s-5.34,2.58-9.56,2.61ZM198,54.86h4.36a6.18,6.18,0,0,0,3.91-1A3.56,3.56,0,0,0,207.45,51a3.76,3.76,0,0,0-1.27-3.17,6.78,6.78,0,0,0-4.07-1H198Zm0,5.48v8.32h5.48a5.22,5.22,0,0,0,3.48-1,3.62,3.62,0,0,0,1.21-2.92c0-2.89-1.43-4.34-4.31-4.36Z" />
  <path className="cls-3"
    d="M254.07,63.35a12.65,12.65,0,0,1-1.95,6.39,11.77,11.77,0,0,1-5,4.3,17,17,0,0,1-7.37,1.53q-6.84,0-10.77-4.46T225,58.53V56.82a21.08,21.08,0,0,1,1.77-8.93A13.45,13.45,0,0,1,231.91,42a14.28,14.28,0,0,1,7.72-2.08q6.31,0,10.15,3.32a13.13,13.13,0,0,1,4.36,9.16H245.8q-.09-3.16-1.59-4.55a6.58,6.58,0,0,0-4.58-1.38,5,5,0,0,0-4.57,2.34c-1,1.55-1.48,4-1.53,7.45V58.7c0,3.7.47,6.35,1.4,7.93s2.53,2.39,4.8,2.39a6.46,6.46,0,0,0,4.41-1.36,5.82,5.82,0,0,0,1.62-4.31Z" />
  <path className="cls-3"
    d="M275.8,62.82h-4.53V75.09h-8.36V40.4h13.65q6.18,0,9.65,2.74a9.26,9.26,0,0,1,3.48,7.73,11.35,11.35,0,0,1-1.46,6,10.46,10.46,0,0,1-4.59,3.86l7.24,14v.35h-9Zm-4.53-6.43h5.29a4.74,4.74,0,0,0,3.59-1.26,4.84,4.84,0,0,0,1.2-3.5,4.9,4.9,0,0,0-1.21-3.52,4.73,4.73,0,0,0-3.58-1.28h-5.29Z" />
  <path className="cls-3" d="M313.73,55.41h8.13V62.3h-8.13v9.31h-7.51V62.3h-8.14V55.41h8.14v-9h7.51Z" />
  <circle className="cls-4" cx="236.52" cy="123" r="22" />
  <path className="cls-3"
    d="M238.56,148A25.05,25.05,0,1,1,261.49,125,25.07,25.07,0,0,1,238.56,148Zm.1-45.83a21,21,0,1,0,18.72,18.71A21,21,0,0,0,238.66,102.14Z" />
  <path className="cls-5" d="M250.68,121.14a14.29,14.29,0,1,1-12.31-12.3A14.29,14.29,0,0,1,250.68,121.14Z" />
  <line className="cls-1" x1="236.52" y1="145" x2="236.52" y2="163" />
  <path className="cls-5" d="M152.78,370.05h-3.93V347.3h3.93Z" />
  <path className="cls-5"
    d="M166,347.3l6.56,17.43,6.55-17.43h5.11v22.75h-3.94v-7.5l.39-10-6.72,17.54h-2.83l-6.7-17.52.39,10v7.5h-3.94V347.3Z" />
  <path className="cls-5"
    d="M196,361.61v8.44h-3.95V347.3h8.7a8.85,8.85,0,0,1,6.06,2,6.71,6.71,0,0,1,2.24,5.26,6.44,6.44,0,0,1-2.2,5.21,9.22,9.22,0,0,1-6.15,1.86Zm0-3.19h4.75a4.68,4.68,0,0,0,3.22-1,3.65,3.65,0,0,0,1.11-2.87,3.92,3.92,0,0,0-1.13-2.94,4.31,4.31,0,0,0-3.09-1.14H196Z" />
  <path className="cls-5"
    d="M225.66,364.75h-8.81l-1.84,5.3H210.9l8.59-22.75H223l8.61,22.75h-4.13Zm-7.7-3.19h6.59l-3.29-9.44Z" />
  <path className="cls-5"
    d="M254.46,362.64a8.23,8.23,0,0,1-2.69,5.68,9.15,9.15,0,0,1-6.23,2,8.85,8.85,0,0,1-4.79-1.29,8.48,8.48,0,0,1-3.2-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.65,8.65,0,0,1,3.27-3.78,9.21,9.21,0,0,1,4.93-1.33,8.83,8.83,0,0,1,6.07,2,8.54,8.54,0,0,1,2.67,5.78h-3.94a5.59,5.59,0,0,0-1.43-3.54,4.71,4.71,0,0,0-3.37-1.09,4.61,4.61,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.39,4.39,0,0,0,3.87,1.94,5.12,5.12,0,0,0,3.5-1.05,5.45,5.45,0,0,0,1.48-3.48Z" />
  <path className="cls-5" d="M277.17,350.48h-7.09v19.57h-3.92V350.48h-7V347.3h18Z" />
  <path className="cls-5"
    d="M301.08,359.26a14,14,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.71,9.71,0,0,1-9.88,0,8.87,8.87,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.2-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.71,8.71,0,0,1,8.26-5.25,9.15,9.15,0,0,1,4.93,1.34,8.76,8.76,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-4-1.18a9.9,9.9,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.85,9.85,0,0,0-1.48,5.68v1.31a9.82,9.82,0,0,0,1.47,5.8,5.05,5.05,0,0,0,8.09.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-5"
    d="M139.22,401.75h-8.81l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.7-3.19h6.59l-3.29-9.44Z" />
  <path className="cls-5"
    d="M156.11,384.3l6.56,17.43,6.55-17.43h5.11v22.75h-3.94v-7.5l.39-10-6.72,17.54h-2.82l-6.71-17.52.39,10v7.5H151V384.3Z" />
  <path className="cls-5"
    d="M182.2,407.05V384.3H190a9.51,9.51,0,0,1,5.87,1.54,5.44,5.44,0,0,1,2,4.61,4.92,4.92,0,0,1-.84,2.81,5.46,5.46,0,0,1-2.47,1.94,5,5,0,0,1,2.85,1.88,5.53,5.53,0,0,1,1,3.33,6.07,6.07,0,0,1-2.06,4.92,9,9,0,0,1-5.88,1.72Zm4-13.18H190a4.45,4.45,0,0,0,2.88-.83,2.88,2.88,0,0,0,1-2.37,2.92,2.92,0,0,0-1-2.44,4.85,4.85,0,0,0-3-.75h-3.85Zm0,2.91v7.11h4.4a4.27,4.27,0,0,0,2.9-.92,3.24,3.24,0,0,0,1.05-2.56q0-3.56-3.63-3.63Z" />
  <path className="cls-5" d="M209.76,407.05h-3.94V384.3h3.94Z" />
  <path className="cls-5" d="M231.12,396.89h-9.35v7H232.7v3.16H217.82V384.3h14.77v3.18H221.77v6.28h9.35Z" />
  <path className="cls-5" d="M257.16,407.05h-4l-10.14-16.14v16.14h-4V384.3h4l10.17,16.2V384.3h3.92Z" />
  <path className="cls-5" d="M281,387.48h-7.1v19.57H270V387.48h-7V384.3h18Z" />
  <path className="cls-5"
    d="M298.06,401.75h-8.81l-1.85,5.3h-4.1l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.7-3.19H297l-3.3-9.44Z" />
  <path className="cls-5" d="M313.78,403.89H324.1v3.16H309.82V384.3h4Z" />
  <path className="cls-5" d="M160.77,433.89h-9.35v7h10.93v3.16H147.47V421.3h14.77v3.18H151.42v6.28h9.35Z" />
  <path className="cls-5"
    d="M191.82,438.19a2.78,2.78,0,0,0-1-2.32,12.91,12.91,0,0,0-3.8-1.64,18.05,18.05,0,0,1-4.38-1.84,5.84,5.84,0,0,1-3.11-5.09,5.56,5.56,0,0,1,2.24-4.54,9.14,9.14,0,0,1,5.82-1.78,9.89,9.89,0,0,1,4.24.88,6.91,6.91,0,0,1,2.92,2.49,6.38,6.38,0,0,1,1.06,3.59h-3.94a3.57,3.57,0,0,0-1.11-2.79,4.63,4.63,0,0,0-3.2-1,4.85,4.85,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.51,2.51,0,0,0,1.16,2.09A13.35,13.35,0,0,0,188.4,431a17.77,17.77,0,0,1,4.27,1.8A6.79,6.79,0,0,1,195,435.1a6,6,0,0,1,.75,3.06,5.42,5.42,0,0,1-2.18,4.52,9.46,9.46,0,0,1-5.92,1.68,11,11,0,0,1-4.53-.92,7.56,7.56,0,0,1-3.22-2.52,6.25,6.25,0,0,1-1.15-3.75h3.95a3.7,3.7,0,0,0,1.28,3,5.61,5.61,0,0,0,3.67,1.06,4.89,4.89,0,0,0,3.11-.83A2.71,2.71,0,0,0,191.82,438.19Z" />
  <path className="cls-5"
    d="M220.6,433.26a14,14,0,0,1-1.15,5.87,8.78,8.78,0,0,1-3.31,3.88,9.7,9.7,0,0,1-9.87,0,8.89,8.89,0,0,1-3.35-3.85,13.63,13.63,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.88,8.88,0,0,1,3.33-3.9,9.05,9.05,0,0,1,4.93-1.35,9.18,9.18,0,0,1,4.93,1.34,8.82,8.82,0,0,1,3.32,3.84A13.83,13.83,0,0,1,220.6,432Zm-3.95-1.18a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.7,9.7,0,0,0-1.48,5.68v1.31a9.9,9.9,0,0,0,1.46,5.8,5.06,5.06,0,0,0,8.1.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-5"
    d="M245.1,436.64a8.23,8.23,0,0,1-2.69,5.68,9.15,9.15,0,0,1-6.23,2,8.88,8.88,0,0,1-4.79-1.29,8.48,8.48,0,0,1-3.2-3.66,13.26,13.26,0,0,1-1.17-5.52v-2.13a13.35,13.35,0,0,1,1.14-5.67,8.61,8.61,0,0,1,3.28-3.78,9.16,9.16,0,0,1,4.93-1.33,8.78,8.78,0,0,1,6.06,2,8.54,8.54,0,0,1,2.67,5.78h-3.94a5.59,5.59,0,0,0-1.43-3.54,4.69,4.69,0,0,0-3.36-1.09,4.64,4.64,0,0,0-4,1.89,9.4,9.4,0,0,0-1.41,5.52v2a10,10,0,0,0,1.32,5.63,4.39,4.39,0,0,0,3.87,1.94,5.1,5.1,0,0,0,3.5-1.05,5.45,5.45,0,0,0,1.48-3.48Z" />
  <path className="cls-5" d="M256,444.05h-3.93V421.3H256Z" />
  <path className="cls-5"
    d="M277.08,438.75h-8.81l-1.85,5.3h-4.11l8.6-22.75h3.54l8.61,22.75h-4.12Zm-7.7-3.19H276l-3.3-9.44Z" />
  <path className="cls-5" d="M292.79,440.89h10.33v3.16H288.84V421.3h4Z" />
  <path className="cls-6" d="M172.6,596.09H164v7h10v2.15H161.35V585.38h12.51v2.15H164v6.41h8.63Z" />
  <path className="cls-6"
    d="M182,590.49l.07,1.64a5.49,5.49,0,0,1,4.39-1.91,4.21,4.21,0,0,1,4.22,2.38,5.56,5.56,0,0,1,4.72-2.38q4.88,0,4.95,5.16v9.91h-2.53v-9.76a3.44,3.44,0,0,0-.72-2.38,3.23,3.23,0,0,0-2.44-.78,3.32,3.32,0,0,0-2.33.84,3.4,3.4,0,0,0-1.08,2.26v9.82h-2.55v-9.7q0-3.23-3.16-3.22a3.37,3.37,0,0,0-3.4,2.12v10.8h-2.53v-14.8Z" />
  <path className="cls-6"
    d="M219.5,598.05A8.87,8.87,0,0,1,218,603.5a5,5,0,0,1-4.19,2.06,5.44,5.44,0,0,1-4.24-1.71V611H207V590.49h2.31l.12,1.64a5.22,5.22,0,0,1,4.29-1.91,5,5,0,0,1,4.22,2,9,9,0,0,1,1.55,5.59Zm-2.53-.28a6.52,6.52,0,0,0-1.06-3.95,3.44,3.44,0,0,0-2.93-1.45,3.74,3.74,0,0,0-3.45,2v7.07a3.74,3.74,0,0,0,3.48,2,3.41,3.41,0,0,0,2.89-1.45A7.16,7.16,0,0,0,217,597.77Z" />
  <path className="cls-6"
    d="M232.66,592.76a7.94,7.94,0,0,0-1.24-.09,3.33,3.33,0,0,0-3.38,2.12v10.5h-2.53v-14.8H228l0,1.71a3.93,3.93,0,0,1,3.52-2,2.51,2.51,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M243.67,605.56a6.46,6.46,0,0,1-4.9-2,7.33,7.33,0,0,1-1.88-5.28v-.46a8.86,8.86,0,0,1,.84-3.93,6.49,6.49,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.06h-10a5.26,5.26,0,0,0,1.27,3.53,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.21,6.21,0,0,1,243.67,605.56Zm-.32-13.26a3.39,3.39,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13h7.41v-.19a4.84,4.84,0,0,0-1-3A3.17,3.17,0,0,0,243.35,592.3Z" />
  <path className="cls-6"
    d="M254.56,597.77a8.7,8.7,0,0,1,1.6-5.51,5.52,5.52,0,0,1,8.46-.13l.13-1.64h2.31v14.44a6,6,0,0,1-1.7,4.53,6.31,6.31,0,0,1-4.58,1.65,7.71,7.71,0,0,1-3.13-.68,5.41,5.41,0,0,1-2.34-1.88l1.32-1.51a4.93,4.93,0,0,0,4,2,3.9,3.9,0,0,0,2.88-1,4,4,0,0,0,1-2.93v-1.27a5.18,5.18,0,0,1-4.14,1.75,5.06,5.06,0,0,1-4.2-2.09A9.17,9.17,0,0,1,254.56,597.77Zm2.54.28a6.7,6.7,0,0,0,1,3.94,3.32,3.32,0,0,0,2.87,1.42,3.68,3.68,0,0,0,3.51-2.17v-6.75a3.72,3.72,0,0,0-3.48-2.12,3.39,3.39,0,0,0-2.89,1.43A7.26,7.26,0,0,0,257.1,598.05Z" />
  <path className="cls-6"
    d="M273,597.75a8.74,8.74,0,0,1,.86-3.91,6.41,6.41,0,0,1,2.38-2.68,6.51,6.51,0,0,1,3.47-.94,6.24,6.24,0,0,1,4.89,2.09,8,8,0,0,1,1.87,5.57v.17a8.81,8.81,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.57,6.57,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.54.3a6.36,6.36,0,0,0,1.15,4,3.86,3.86,0,0,0,6.11,0,6.92,6.92,0,0,0,1.14-4.24,6.32,6.32,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.88,6.88,0,0,0,275.54,598.05Z" />
  <path className="cls-6"
    d="M301.05,601.36a1.85,1.85,0,0,0-.77-1.59,7.46,7.46,0,0,0-2.69-1,12.71,12.71,0,0,1-3-1,4.33,4.33,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.82,3.82,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.14,4.14,0,0,1,1.57,3.32H300.9a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.9,1.9,0,0,0-.78,1.57,1.52,1.52,0,0,0,.73,1.38,8.75,8.75,0,0,0,2.61.89,13.43,13.43,0,0,1,3.07,1,4.56,4.56,0,0,1,1.75,1.42,3.53,3.53,0,0,1,.56,2,3.78,3.78,0,0,1-1.58,3.17,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5,5,0,0,1-2.14-1.76,4.18,4.18,0,0,1-.77-2.44h2.52a2.65,2.65,0,0,0,1,2,4,4,0,0,0,2.51.75,4.16,4.16,0,0,0,2.31-.58A1.81,1.81,0,0,0,301.05,601.36Z" />
  <path className="cls-6"
    d="M178.67,641.29V628.45h-2.33v-2h2.33V625A5,5,0,0,1,180,621.3a4.76,4.76,0,0,1,3.59-1.3,6.93,6.93,0,0,1,1.74.23l-.14,2a7.43,7.43,0,0,0-1.37-.12,2.47,2.47,0,0,0-1.9.72,3,3,0,0,0-.67,2.06v1.56h3.16v2H181.2v12.84Z" />
  <path className="cls-6"
    d="M189.28,633.75a8.74,8.74,0,0,1,.86-3.91,6.39,6.39,0,0,1,2.37-2.68,6.57,6.57,0,0,1,3.48-.94,6.24,6.24,0,0,1,4.89,2.09,8,8,0,0,1,1.87,5.57v.17a8.81,8.81,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.57,6.57,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.54.3a6.3,6.3,0,0,0,1.15,4,3.86,3.86,0,0,0,6.11,0,6.92,6.92,0,0,0,1.14-4.24,6.32,6.32,0,0,0-1.16-3.94,3.84,3.84,0,0,0-6.09,0A6.88,6.88,0,0,0,191.82,634.05Z" />
  <path className="cls-6"
    d="M215.87,628.76a8,8,0,0,0-1.24-.09,3.33,3.33,0,0,0-3.38,2.12v10.5h-2.53v-14.8h2.46l0,1.71a4,4,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M223.37,626.49l.07,1.64a5.49,5.49,0,0,1,4.39-1.91,4.21,4.21,0,0,1,4.22,2.38,5.56,5.56,0,0,1,4.72-2.38q4.88,0,4.95,5.16v9.91h-2.53v-9.76a3.44,3.44,0,0,0-.72-2.38,3.23,3.23,0,0,0-2.44-.78,3.32,3.32,0,0,0-2.33.84,3.4,3.4,0,0,0-1.08,2.26v9.82h-2.55v-9.7q0-3.23-3.16-3.22a3.37,3.37,0,0,0-3.4,2.12v10.8H221v-14.8Z" />
  <path className="cls-6"
    d="M257.47,641.29a5.09,5.09,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28h2.46v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.44,3.44,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.34,2.34,0,0,0,.79,1.86A3,3,0,0,0,253.27,639.36Z" />
  <path className="cls-6"
    d="M266.38,622.57a1.47,1.47,0,0,1,.38-1,1.41,1.41,0,0,1,1.11-.42,1.43,1.43,0,0,1,1.12.42,1.47,1.47,0,0,1,.38,1,1.44,1.44,0,0,1-.38,1,1.47,1.47,0,0,1-1.12.41,1.36,1.36,0,0,1-1.49-1.44Zm2.73,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-6"
    d="M284.57,637.36a1.85,1.85,0,0,0-.77-1.59,7.46,7.46,0,0,0-2.69-1,12.71,12.71,0,0,1-3.05-1,4.33,4.33,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.82,3.82,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.14,4.14,0,0,1,1.57,3.32h-2.55a2.28,2.28,0,0,0-.88-1.79,3.31,3.31,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.9,1.9,0,0,0-.78,1.57,1.54,1.54,0,0,0,.72,1.38,9,9,0,0,0,2.62.89,13.43,13.43,0,0,1,3.07,1,4.56,4.56,0,0,1,1.75,1.42,3.53,3.53,0,0,1,.56,2,3.78,3.78,0,0,1-1.58,3.17,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5,5,0,0,1-2.14-1.76,4.18,4.18,0,0,1-.77-2.44h2.52a2.65,2.65,0,0,0,1,2,4,4,0,0,0,2.51.75,4.16,4.16,0,0,0,2.31-.58A1.81,1.81,0,0,0,284.57,637.36Z" />
  <path className="cls-6"
    d="M212.33,495.35H204l-1.87,5.21h-2.71l7.6-19.91h2.3l7.61,19.91h-2.69Zm-7.55-2.15h6.77l-3.39-9.31Zm4.68-17.88h3.06l-3.66,4h-2Z" />
  <path className="cls-6"
    d="M229.16,488a7,7,0,0,0-1.25-.1,3.33,3.33,0,0,0-3.37,2.12v10.5H222V485.77h2.46l0,1.71a3.92,3.92,0,0,1,3.53-2,2.72,2.72,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M240.16,500.83a6.47,6.47,0,0,1-4.89-2,7.34,7.34,0,0,1-1.89-5.29v-.46a8.86,8.86,0,0,1,.84-3.93,6.49,6.49,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.28-1,5.56,5.56,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.06h-10a5.2,5.2,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.36,4.36,0,0,0,2.27-.55,5.57,5.57,0,0,0,1.62-1.45l1.55,1.21A6.22,6.22,0,0,1,240.16,500.83Zm-.31-13.26a3.35,3.35,0,0,0-2.57,1.12,5.32,5.32,0,0,0-1.29,3.12h7.41v-.19a4.8,4.8,0,0,0-1-3A3.16,3.16,0,0,0,239.85,487.57Z" />
  <path className="cls-6"
    d="M260.79,500.56a5.09,5.09,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.25,5.25,0,0,1-3.59-1.23,4,4,0,0,1-1.4-3.14,4.19,4.19,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.28h2.46v-1.16a2.81,2.81,0,0,0-.79-2.11,3.17,3.17,0,0,0-2.34-.79,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.46,3.46,0,0,1,.79-2.14,5.39,5.39,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.65,4.65,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.46,4.46,0,0,0,2.26-.61,3.75,3.75,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3,3,0,0,0,256.59,498.63Z" />
  <path className="cls-6"
    d="M159.45,524a7,7,0,0,0-1.25-.1,3.33,3.33,0,0,0-3.37,2.12v10.5H152.3V521.77h2.46l0,1.71a3.94,3.94,0,0,1,3.53-2,2.72,2.72,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M170.45,536.83a6.47,6.47,0,0,1-4.89-2,7.34,7.34,0,0,1-1.89-5.29v-.46a8.86,8.86,0,0,1,.84-3.93,6.56,6.56,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.28-1,5.55,5.55,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.06h-10a5.25,5.25,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.36,4.36,0,0,0,2.27-.55,5.57,5.57,0,0,0,1.62-1.45L176,534A6.22,6.22,0,0,1,170.45,536.83Zm-.31-13.26a3.35,3.35,0,0,0-2.57,1.12,5.39,5.39,0,0,0-1.29,3.12h7.41v-.19a4.8,4.8,0,0,0-1-3A3.16,3.16,0,0,0,170.14,523.57Z" />
  <path className="cls-6" d="M186.65,533.13l3.66-11.36h2.59l-5.31,14.79h-1.92l-5.36-14.79h2.58Z" />
  <path className="cls-6"
    d="M204.09,536.83a6.49,6.49,0,0,1-4.89-2,7.34,7.34,0,0,1-1.89-5.29v-.46a8.86,8.86,0,0,1,.84-3.93,6.56,6.56,0,0,1,2.35-2.71,5.94,5.94,0,0,1,3.28-1,5.55,5.55,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.06h-10a5.25,5.25,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.36,4.36,0,0,0,2.27-.55,5.74,5.74,0,0,0,1.63-1.45l1.54,1.21A6.22,6.22,0,0,1,204.09,536.83Zm-.31-13.26a3.35,3.35,0,0,0-2.57,1.12,5.45,5.45,0,0,0-1.29,3.12h7.41v-.19a4.8,4.8,0,0,0-1-3A3.15,3.15,0,0,0,203.78,523.57Z" />
  <path className="cls-6"
    d="M215,529a8.65,8.65,0,0,1,1.6-5.5,5.5,5.5,0,0,1,8.46-.13l.12-1.64h2.31V536.2a6,6,0,0,1-1.7,4.53,6.28,6.28,0,0,1-4.57,1.65,7.52,7.52,0,0,1-3.13-.68,5.46,5.46,0,0,1-2.34-1.87l1.31-1.52a5,5,0,0,0,4,2,3.9,3.9,0,0,0,2.88-1,4,4,0,0,0,1-2.93v-1.27a5.18,5.18,0,0,1-4.14,1.75,5,5,0,0,1-4.21-2.09A9.13,9.13,0,0,1,215,529Zm2.54.29a6.58,6.58,0,0,0,1,3.93,3.33,3.33,0,0,0,2.87,1.43,3.71,3.71,0,0,0,3.51-2.18v-6.75a3.75,3.75,0,0,0-3.49-2.12,3.35,3.35,0,0,0-2.88,1.44A7.22,7.22,0,0,0,217.53,529.33Z" />
  <path className="cls-6"
    d="M240.24,536.83a6.49,6.49,0,0,1-4.9-2,7.34,7.34,0,0,1-1.89-5.29v-.46a8.73,8.73,0,0,1,.85-3.93,6.49,6.49,0,0,1,2.35-2.71,5.91,5.91,0,0,1,3.27-1,5.58,5.58,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.06H236a5.3,5.3,0,0,0,1.28,3.53,4,4,0,0,0,3.11,1.35,4.34,4.34,0,0,0,2.27-.55,5.74,5.74,0,0,0,1.63-1.45l1.54,1.21A6.21,6.21,0,0,1,240.24,536.83Zm-.32-13.26a3.35,3.35,0,0,0-2.57,1.12,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.86,4.86,0,0,0-1-3A3.17,3.17,0,0,0,239.92,523.57Z" />
  <path className="cls-6"
    d="M255.16,518.18v3.59h2.76v2h-2.76v9.19a2.07,2.07,0,0,0,.37,1.33,1.56,1.56,0,0,0,1.26.45,6.16,6.16,0,0,0,1.2-.17v2a7.37,7.37,0,0,1-1.94.27,3.17,3.17,0,0,1-2.56-1,4.45,4.45,0,0,1-.86-2.91v-9.18h-2.69v-2h2.69v-3.59Z" />
  <path className="cls-6"
    d="M272.81,536.56a5.16,5.16,0,0,1-.35-1.56,5.66,5.66,0,0,1-4.22,1.83,5.22,5.22,0,0,1-3.58-1.23,4,4,0,0,1-1.41-3.14,4.19,4.19,0,0,1,1.76-3.59,8.3,8.3,0,0,1,4.94-1.28h2.46v-1.16a2.81,2.81,0,0,0-.79-2.11,3.17,3.17,0,0,0-2.34-.79,3.73,3.73,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.52,3.52,0,0,1,.79-2.14,5.46,5.46,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.58,4.58,0,0,1,1.5,3.52v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.43,4.43,0,0,0,2.26-.61,3.75,3.75,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.29,2.29,0,0,0,.8,1.86A3,3,0,0,0,268.61,534.63Z" />
  <path className="cls-6"
    d="M281.09,529a8.67,8.67,0,0,1,1.62-5.48,5.09,5.09,0,0,1,4.22-2.07,5.17,5.17,0,0,1,4.12,1.78v-7.71h2.53v21h-2.33l-.12-1.59a5.15,5.15,0,0,1-4.23,1.86,5.06,5.06,0,0,1-4.19-2.1,8.79,8.79,0,0,1-1.62-5.5Zm2.53.29a6.55,6.55,0,0,0,1,3.93,3.36,3.36,0,0,0,2.87,1.43,3.69,3.69,0,0,0,3.52-2.16v-6.8a3.73,3.73,0,0,0-3.49-2.09,3.37,3.37,0,0,0-2.9,1.44A7.22,7.22,0,0,0,283.62,529.33Z" />
  <path className="cls-6"
    d="M309.43,536.56a5.49,5.49,0,0,1-.35-1.56,5.65,5.65,0,0,1-4.21,1.83,5.23,5.23,0,0,1-3.59-1.23,4,4,0,0,1-1.4-3.14,4.18,4.18,0,0,1,1.75-3.59,8.3,8.3,0,0,1,4.94-1.28H309v-1.16a2.82,2.82,0,0,0-.8-2.11,3.17,3.17,0,0,0-2.34-.79,3.7,3.7,0,0,0-2.26.68,2,2,0,0,0-.92,1.66h-2.54a3.51,3.51,0,0,1,.78-2.14,5.49,5.49,0,0,1,2.14-1.64,7.15,7.15,0,0,1,3-.6,5.86,5.86,0,0,1,4,1.28,4.58,4.58,0,0,1,1.5,3.52v6.81a8.43,8.43,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.43,4.43,0,0,0,2.26-.61,3.78,3.78,0,0,0,1.55-1.6v-3h-2q-4.65,0-4.65,2.72a2.29,2.29,0,0,0,.8,1.86A3,3,0,0,0,305.23,534.63Z" />
  <line className="cls-7" x1="112" y1="561.5" x2="352" y2="561.5" />
  <line className="cls-8" x1="73.9" y1="135.5" x2="73.9" y2="144" />
  <circle className="cls-4" cx="73.9" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M75.25,135.78a12.36,12.36,0,1,1,11-11.63A12.38,12.38,0,0,1,75.25,135.78Zm-.59-22.61a10.35,10.35,0,1,0,9.5,9A10.36,10.36,0,0,0,74.66,113.17Z" />
  <path className="cls-5" d="M80.86,122.38a7.05,7.05,0,1,1-6.25-5.9A7.05,7.05,0,0,1,80.86,122.38Z" />
  <circle className="cls-4" cx="398.56" cy="123.49" r="10.86" />
  <path className="cls-3"
    d="M399.56,135.81a12.36,12.36,0,1,1,11.32-11.31A12.37,12.37,0,0,1,399.56,135.81Zm.06-22.62a10.35,10.35,0,1,0,9.23,9.24A10.37,10.37,0,0,0,399.62,113.19Z" />
  <path className="cls-5" d="M405.55,122.57a7,7,0,1,1-6.08-6.07A7.06,7.06,0,0,1,405.55,122.57Z" />
  <line className="cls-8" x1="398.56" y1="135.5" x2="398.56" y2="144" />
  <path className="cls-3"
    d="M236.54,299.65a61.48,61.48,0,0,1-17.71-120.36,2.51,2.51,0,0,1,1.44,4.81,56.46,56.46,0,1,0,70.3,37.83,2.51,2.51,0,1,1,4.81-1.45,61.48,61.48,0,0,1-58.84,79.17Z" />
  <path className="cls-3"
    d="M236.48,318.33a80.17,80.17,0,1,1,76.78-103.22,80.18,80.18,0,0,1-76.78,103.22ZM236.53,163a75.14,75.14,0,1,0,71.92,53.53A75.26,75.26,0,0,0,236.53,163Z" />
  <path className="cls-9"
    d="M261.89,215.48s-3,5.28-15.39,6.34-18.7,4.49-22.93,12.6c-2.22,4.26-1.95,9.55-1.19,12.63L240.19,238l-6.31,5-17.33,13.32,3.62,3.76c2.63-2.36,5.54-4.69,8.2-7.19,4.95,2.14,13.59,4.32,22.1-1.08,13-8.22,11.42-36.28,11.42-36.28Z" />
  <path className="cls-3"
    d="M73.87,200.08a25.1,25.1,0,1,1,24.06-32.33,1,1,0,1,1-2,.59A23.05,23.05,0,1,0,80.53,197a1,1,0,0,1,.59,2A25.11,25.11,0,0,1,73.87,200.08Z" />
  <path className="cls-3"
    d="M73.89,207.7a32.94,32.94,0,0,1-31.33-23.32,32.74,32.74,0,0,1,31.35-42.13,32.73,32.73,0,0,1,0,65.45Zm0-63.4a30.85,30.85,0,0,0-8.83,1.3,30.66,30.66,0,0,0-20.55,38.19,30.85,30.85,0,0,0,29.36,21.86,30.71,30.71,0,0,0,29.38-39.5A30.86,30.86,0,0,0,73.91,144.3Z" />
  <path className="cls-9"
    d="M73.89,165.81a9.79,9.79,0,1,0,9.78,9.78A9.78,9.78,0,0,0,73.89,165.81Zm.77,15v1.75H73V181a6.85,6.85,0,0,1-2.92-.74l.52-2a6,6,0,0,0,2.85.76c1,0,1.65-.38,1.65-1.07s-.55-1.06-1.82-1.49c-1.84-.62-3.09-1.48-3.09-3.14a3.17,3.17,0,0,1,2.9-3.06v-1.63h1.68v1.51a6.08,6.08,0,0,1,2.49.57l-.5,1.94a5.69,5.69,0,0,0-2.47-.59c-1.12,0-1.48.48-1.48,1s.6.93,2.06,1.48c2.05.72,2.87,1.67,2.87,3.21A3.3,3.3,0,0,1,74.66,180.85Z" />
  <path className="cls-3"
    d="M398.53,200.08a25.13,25.13,0,0,1-24-17.89,1,1,0,0,1,2-.59,23,23,0,1,0,15.44-28.7,1,1,0,0,1-.59-2,25.1,25.1,0,1,1,7.19,49.14Z" />
  <path className="cls-3"
    d="M398.55,207.7a32.93,32.93,0,0,1-31.32-23.31,32.71,32.71,0,0,1,21.92-40.75,32.72,32.72,0,1,1,9.4,64.06Zm0-63.4a30.9,30.9,0,0,0-8.83,1.3,30.68,30.68,0,0,0-20.55,38.19,30.67,30.67,0,1,0,29.38-39.49Z" />
  <path className="cls-9"
    d="M399.63,181.41h9.74L404,167.15h-4.89l.07,1.92H398l.07-1.92H393.1l-5.36,14.26h9.74l.16-4.3h1.84Zm-.23-6.63h-1.66l.13-3.38h1.42Z" />
  <circle onClick={() => handleAmbientalImpact()} className="cls-10" cx="236" cy="239" r="85" />
  <rect onClick={() => handleAmbientalImpact()} className="cls-10" x="106" y="334" width="241" height="124" />
  <rect onClick={() => handleAmbientalImpact()} className="cls-10" x="137" y="468" width="194" height="81" />
  <rect onClick={() => handleAmbientalImpact()} className="cls-10" x="135" y="573" width="194" height="81" />
</svg>
    </>
  );
}