import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  height: 100%;

  margin: 0 0 0 1.2rem;

  @media (max-width: 820px) {
    width: 100%;
    margin: 0;
  }
`;


