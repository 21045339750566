import styled from 'styled-components';

interface ItemProps {
  color: string;
}

export const WrapperItems = styled.div`
  height: 60%;
  width: 120%;

  display: flex;
  margin: 1rem 0 0 0;
  flex-direction: column;

  flex-wrap: wrap;
  align-items: center;

  justify-items: center;
`;

export const Item = styled.div<ItemProps>`
  width: 100%;
  height: 60px;
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  margin: .1rem 0 .1rem 0;
  
  border-radius: 30px;
  justify-content: center;
  
  transition: all .2s ease-in-out;
  background: ${props => props.color};
`;

export const ContentWrapper = styled.div<ItemProps>`
  width: 100%;
  display: flex;
  align-items: center;

  word-wrap: break-word;
  margin: 0 .2rem 0 1rem;
  
  p {
    width: 100%;
    font-size: 12px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: ${props => props.color};
  }
`;