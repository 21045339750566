
import styled, { css } from 'styled-components';
import ArrowT from './svg/arrow_rigth.svg';
interface WrapperItemProps {
  items: any[];
  index: number;
  isMobile: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  margin: 1rem 0 0 0;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
`;


export const WrapperItem = styled.div<WrapperItemProps>`
  width: ${({ isMobile }) => isMobile ? '48' : '53'}%;
  display: flex;
  height: 110px;
  z-index: 1;
  
  cursor: pointer;
  align-items: center;
  
  border-radius: 30px;
  
  justify-content: center;
  background: transparent;
  border: 2px solid #006680;
  p { color: #1D1D1B; }
  
  ${({ items, index }) => items[index].clicked && css`
    border: none;
    background: #1E303A;
    p { color: #fff; }
  `}
`;

export const Text = styled.p`
  color: #FFFFFF;
  text-align: center;
  word-wrap: break-word;
  width: 80%;
  
  letter-spacing: 1.4px;
  font: normal normal 900 14px/17px Roboto;
`;

interface WrapperArrowProps {
  len: number
}

export const WrapperArrow = styled.div<WrapperArrowProps>`
  top: -60px;
  display: flex;
  position: relative;
  justify-content: ${props => props.len === 2 ? 'flex-end' : 'flex-start'};
  margin-bottom: -20px;
`;

export const ArrowTi = styled(ArrowT)`
  cursor: pointer;
  margin: 0 0 0 -1rem;
`;
export const ArrowTp = styled(ArrowT)`
  transform: rotate(180deg);
  cursor: pointer;
  margin: 0 -1rem 0 0;
`;