import React from 'react';
import { SidebarContextProvider } from '@/context/sidebar/';
import { ReportAbcrContextProvider } from '@/context/report_abcr';

interface IProps {
  children: React.ReactNode;
}

export const MiddlewareContextProvider: React.FC<IProps> = ({ children }) => {
  return (
    <SidebarContextProvider>
      <ReportAbcrContextProvider>
        {children}
      </ReportAbcrContextProvider>
    </SidebarContextProvider>
  );
};