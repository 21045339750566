import type { AppProps } from 'next/app';
import { MiddlewareContextProvider } from '@/providers/middleware.context';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <MiddlewareContextProvider>
      <Component {...pageProps} />
    </MiddlewareContextProvider>
  );
}
