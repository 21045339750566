import React from 'react';
import Head from 'next/head';
import BgImage from 'public/bg.png';
import { useSidebar } from '@/hooks/useContexts';
import GlobalStyles from '@/global/styles/global';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Container, WrapperApp, WrapperContent } from './styles';
import { MobileSidebar } from '@/global/components/sidebar/mobile';
import { SidebarDesktop } from '@/global/components/sidebar/desktop';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
  const { isMobile } = useMediaQuery('(max-width:820px)');
  const { session } = useSidebar();

  return (
    <>
      <Head>
        <title>Painel ABCR+</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Container image={BgImage}>
        <WrapperApp>
          <WrapperContent>
          {!isMobile && ( <SidebarDesktop isReport={session.abcr_report} />)}
          {isMobile && ( <MobileSidebar />)}
          {children}
          </WrapperContent>
        </WrapperApp>
      </Container>
      <GlobalStyles />
    </>
  );
};