import React from 'react';
import axios from 'axios';
import { Panel } from '@/components/panel';
import { ObjectApi } from '@/interface/api';
import { RoadSecurity } from '../road_security';
import { useSidebar } from '@/hooks/useContexts';
import { ServiceQuality } from '../service_quality';
import { AmbientalImpact } from '../ambiental_impact';
import { AbcrReport } from '@/components/report_abcr';
import { Layout } from '@/global/components/layout/panel';
import { EconomicImpact } from '@/components/economic_impact';
import { UserAttendance } from '@/components/user_attendance';

/**
 * @description Esse compoennete é utilizado como um middleware para o
 * conteúdo dentro de cada sessão do painel abcr e seu parâmetro porps
 * diz respeito aos valores buscados no getstaticprops do componente
 * AppPage
 * @returns React.FC
 */
export const MiddewarePages: React.FC = () => {
  const { session } = useSidebar();
  const [data, setData] = React.useState<any>([]);

  React.useEffect(() => {
    axios.get<ObjectApi>(`${process.env.NEXT_PUBLIC_API}`)
    .then(res => setData(res.data));
  }, []);

  return (
    <Layout>
        {session.panel && <Panel />}
        {session.abcr_report && <AbcrReport />}
        {session.seguranca_viaria && (
          <RoadSecurity dados={data?.seguranca_viaria} />
        )}
        {session.user_attendance && (
          <UserAttendance dados={data?.atendimento_usuarios} />
        )}
        {session.impacto_economico && (
          <EconomicImpact dados={data?.impacto_economico} />
        )}
        {session.impacto_ambiental && (
          <AmbientalImpact dados={data?.impacto_ambiental} />
        )}
        {session.qualidade_servicos && (
          <ServiceQuality dados={data?.qualidade_servicos} />
        )}
    </Layout>
  );
};
