import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --font-main: Roboto, sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-main);
  }

  body, html {
    overflow-x: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }

  ul {
    list-style-type: none;
  }

  a.am5exporting-icon,.am5exporting-list {
    background: #00556E;
    opacity: 1 !important;
  }
  a.am5exporting-menu.am5exporting-valign-top, .am5exporting-icon.am5exporting-valign-top, .am5exporting-list.am5exporting-align-top {
    top: -25px !important;
  }
  a.am5exporting-icon:hover,
  .am5exporting-menu-open a.am5exporting-icon,
  .am5exporting-item a:hover {
    background: #2F83C2;
  }

  .am5exporting-item {
  }
  
  .am5exporting-list.am5exporting-align-right  {
    background: #E4EFED;
    margin: -1.3rem 2.2rem 0 0 !important;
  }
  
  .am5exporting-menu path {
    fill: white !important;
}
`;
