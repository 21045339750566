interface IProps {
  items: any;
  index: number;
  isText: boolean;
  isMobile: boolean;
}

/**
 * @description essa função muda a propriedade color dos itens do sidebar
 * e seu pleno funcionamento está relacionado a função toggleActive
 * @param index number
 * @param isText boolean
 * @param isMobile boolean
 * @param items any
*/

export const toggleActiveStyles = (items: any, index: number, isText: boolean, isMobile: boolean): string => {
  if (!isMobile) {
    if (!isText) {
      return items[index].clicked
      ? items[index]?.colors?.main
      : items[index]?.colors?.hover;
    }

    return items[index].clicked
    ? items[index]?.colors?.hover
    : items[index]?.colors?.main;
  }

  if (!isText) {
    return items[index].clicked
    ? items[index]?.colors?.main
    : '#2A3B43';
  }

  return items[index].clicked
  ? items[index]?.colors?.main
  : items[index]?.colors?.hover;
};