import React from 'react';
import BannerImage from './servico.png';
import { MakeButtonsMemo } from '@/utils/factory';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grafismo } from '@/global/components/grafismo';
import { AverageSpeed } from './indicators/averageSpeed';
import { AbcrButton } from '@/global/components/abcrButton';
import { ComplaintIndex } from './indicators/complaintIndex';
import { SessionBanner } from '@/global/components/sessionBanner';
import { LayoutSession } from '@/global/components/layout/session';
import { SessionButtons } from '@/global/components/sessionButtons';
import { PercentageHighways } from './indicators/percentageHighways';

interface ServiceQualityProps {
  dados: any;
}

export const ServiceQuality: React.FC<ServiceQualityProps> = ({ dados }) => {
  const { buttonsMemo, cardSelected } = MakeButtonsMemo(dados);
  const { isMobile } = useMediaQuery('(max-width: 820px)');
  const parseData = React.useMemo(() => dados?.indicators[cardSelected], [dados, cardSelected]);


  return (
    <LayoutSession>
      <SessionBanner
        title={dados?.title}
        content={dados?.text}
        bannerImage={BannerImage}
      />
      <SessionButtons arrayCards={buttonsMemo} />
      {cardSelected === 0 && ( <AverageSpeed dados={parseData} /> )}
      {cardSelected === 1 && ( <ComplaintIndex dados={parseData} /> )}
      {cardSelected === 2 && ( <PercentageHighways dados={parseData} /> )}
      <AbcrButton />
      <Grafismo margin={!isMobile ? "1rem 0 0 0" : "21rem 0 0 0"} />
    </LayoutSession>
  );
};

