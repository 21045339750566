import { MenuBarItemProps } from "@/context/sidebar/interfaces";
import { useSidebar } from "@/hooks/useContexts";
import { toggleActiveAction } from "@/utils/toggleActiveAction";

export const UserAttendenceIcon = () => {
  const { setItems, setClickReport, items } = useSidebar();
  const handleUserAtendence = () => {
    const newItems = toggleActiveAction<MenuBarItemProps>({
      array: items,
      index: 1
    });
    setClickReport(false);
    setItems(newItems);
  };

  return (
    <>
<svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 473 631">
  <defs>
    <style>
      {
        `
          .cls-1 {
            fill: #1e303a;
          }
    
          .cls-2 {
            fill: #93c022;
          }
    
          .cls-3,
          .cls-8,
          .cls-9 {
            fill: none;
            stroke: #1e303a;
            stroke-miterlimit: 10;
          }
    
          .cls-3 {
            stroke-width: 4px;
          }
    
          .cls-4 {
            fill: #2f82c1;
          }
    
          .cls-10,
          .cls-5 {
            fill: #fff;
          }
    
          .cls-6 {
            fill: #006680;
          }
    
          .cls-7 {
            fill: #1d1d1b;
          }
    
          .cls-9 {
            stroke-width: 2.5px;
          }
    
          .cls-10 {
            opacity: 0;
          }
          
        `
      }
    </style>
  </defs>
  <title>Painel ABCR mobile</title>
  <path className="cls-1"
    d="M398.53,200.08a25.1,25.1,0,1,1,24.07-32.33,1,1,0,1,1-2,.59A23.05,23.05,0,1,0,405.19,197a1,1,0,1,1,.59,2A25.06,25.06,0,0,1,398.53,200.08Z" />
  <path className="cls-1"
    d="M398.55,207.7a32.92,32.92,0,0,1-31.32-23.32,32.71,32.71,0,0,1,21.92-40.74,32.72,32.72,0,1,1,9.4,64.06Zm0-63.4a30.9,30.9,0,0,0-8.83,1.3,30.68,30.68,0,0,0-20.55,38.19,30.67,30.67,0,1,0,29.38-39.49Z" />
  <path className="cls-2"
    d="M398.56,165.81a9.79,9.79,0,1,0,9.78,9.78A9.78,9.78,0,0,0,398.56,165.81Zm.77,15v1.75h-1.68V181a6.85,6.85,0,0,1-2.92-.74l.51-2a6,6,0,0,0,2.85.76c1,0,1.65-.38,1.65-1.07s-.55-1.06-1.82-1.49c-1.84-.62-3.09-1.48-3.09-3.14a3.17,3.17,0,0,1,2.9-3.06v-1.63h1.68v1.51a6,6,0,0,1,2.49.57l-.49,1.94a5.7,5.7,0,0,0-2.48-.59c-1.11,0-1.47.48-1.47,1s.6.93,2.06,1.48c2,.72,2.86,1.67,2.86,3.21A3.29,3.29,0,0,1,399.33,180.85Z" />
  <line className="cls-3" x1="11.78" y1="123" x2="459.78" y2="123" />
  <path className="cls-4"
    d="M199.33,23.37v6.7h-3.14V12h6.91a7,7,0,0,1,4.81,1.58,5.31,5.31,0,0,1,1.77,4.17,5.15,5.15,0,0,1-1.74,4.14,7.35,7.35,0,0,1-4.88,1.47Zm0-2.53h3.77a3.71,3.71,0,0,0,2.55-.78,2.9,2.9,0,0,0,.88-2.28,3.13,3.13,0,0,0-.89-2.34,3.45,3.45,0,0,0-2.45-.9h-3.86Z" />
  <path className="cls-4"
    d="M222.55,30.07a4.83,4.83,0,0,1-.34-1.25,4.7,4.7,0,0,1-3.52,1.5,4.77,4.77,0,0,1-3.3-1.15,3.7,3.7,0,0,1-1.28-2.86A3.82,3.82,0,0,1,215.7,23a7.75,7.75,0,0,1,4.56-1.15h1.85V21a2.36,2.36,0,0,0-.58-1.66,2.31,2.31,0,0,0-1.78-.63,2.65,2.65,0,0,0-1.68.51,1.6,1.6,0,0,0-.66,1.31h-3a3.34,3.34,0,0,1,.74-2.06,4.8,4.8,0,0,1,2-1.51,7,7,0,0,1,2.81-.55,5.6,5.6,0,0,1,3.75,1.19,4.19,4.19,0,0,1,1.44,3.33v6a6.84,6.84,0,0,0,.51,2.89v.21Zm-3.31-2.17a3.39,3.39,0,0,0,1.68-.43,2.92,2.92,0,0,0,1.19-1.17V23.77h-1.63a4.5,4.5,0,0,0-2.51.58,1.88,1.88,0,0,0-.85,1.65,1.8,1.8,0,0,0,.58,1.39A2.24,2.24,0,0,0,219.24,27.9Z" />
  <path className="cls-4"
    d="M230.82,13.17a1.63,1.63,0,0,1,.44-1.16,2,2,0,0,1,2.53,0,1.63,1.63,0,0,1,.44,1.16,1.57,1.57,0,0,1-.44,1.13,2,2,0,0,1-2.53,0A1.57,1.57,0,0,1,230.82,13.17Zm3.2,16.9h-3V16.65h3Z" />
  <path className="cls-4"
    d="M242.64,16.65l.09,1.55a4.81,4.81,0,0,1,3.9-1.8q4.2,0,4.27,4.8v8.87h-3V21.38a2.75,2.75,0,0,0-.55-1.89,2.32,2.32,0,0,0-1.8-.62,2.91,2.91,0,0,0-2.72,1.65v9.55h-3V16.65Z" />
  <path className="cls-4"
    d="M262.42,30.32a6.23,6.23,0,0,1-4.65-1.81,6.56,6.56,0,0,1-1.78-4.8v-.37a8,8,0,0,1,.78-3.59,5.85,5.85,0,0,1,2.17-2.46,5.76,5.76,0,0,1,3.13-.89,5.26,5.26,0,0,1,4.23,1.75,7.39,7.39,0,0,1,1.5,5v1.22H259A4.05,4.05,0,0,0,260.14,27a3.36,3.36,0,0,0,2.45,1A4.08,4.08,0,0,0,266,26.24l1.63,1.55a5.57,5.57,0,0,1-2.15,1.87A6.79,6.79,0,0,1,262.42,30.32Zm-.36-11.5a2.55,2.55,0,0,0-2,.87,4.58,4.58,0,0,0-1,2.42h5.74v-.22a3.62,3.62,0,0,0-.8-2.29A2.52,2.52,0,0,0,262.06,18.82Z" />
  <path className="cls-4" d="M275.88,30.07h-3V11h3Z" />
  <path className="cls-1"
    d="M171.23,68.61H159.77l-2,6.48h-8.94L161.56,40.4h7.86l12.82,34.69h-9Zm-9.46-6.46h7.46l-3.74-12Z" />
  <path className="cls-1"
    d="M189.65,75.09V40.4h12.46q6.69,0,10.18,2.45a8.17,8.17,0,0,1,3.5,7.13,8.32,8.32,0,0,1-1.24,4.6,7.3,7.3,0,0,1-3.65,2.81,7.25,7.25,0,0,1,4.17,2.67,7.74,7.74,0,0,1,1.45,4.76c0,3.4-1.07,6-3.23,7.66s-5.34,2.58-9.56,2.61ZM198,54.86h4.36a6.18,6.18,0,0,0,3.91-1A3.56,3.56,0,0,0,207.45,51a3.76,3.76,0,0,0-1.27-3.17,6.78,6.78,0,0,0-4.07-1H198Zm0,5.48v8.32h5.48a5.22,5.22,0,0,0,3.48-1,3.62,3.62,0,0,0,1.21-2.92c0-2.89-1.43-4.34-4.31-4.36Z" />
  <path className="cls-1"
    d="M254.07,63.35a12.65,12.65,0,0,1-1.95,6.39,11.77,11.77,0,0,1-5,4.3,17,17,0,0,1-7.37,1.53q-6.84,0-10.77-4.46T225,58.53V56.82a21.08,21.08,0,0,1,1.77-8.93A13.45,13.45,0,0,1,231.91,42a14.28,14.28,0,0,1,7.72-2.08q6.31,0,10.15,3.32a13.13,13.13,0,0,1,4.36,9.16H245.8q-.09-3.16-1.59-4.55a6.58,6.58,0,0,0-4.58-1.38,5,5,0,0,0-4.57,2.34c-1,1.55-1.48,4-1.53,7.45V58.7c0,3.7.47,6.35,1.4,7.93s2.53,2.39,4.8,2.39a6.46,6.46,0,0,0,4.41-1.36,5.82,5.82,0,0,0,1.62-4.31Z" />
  <path className="cls-1"
    d="M275.8,62.82h-4.53V75.09h-8.36V40.4h13.65q6.18,0,9.65,2.74a9.26,9.26,0,0,1,3.48,7.73,11.35,11.35,0,0,1-1.46,6,10.46,10.46,0,0,1-4.59,3.86l7.24,14v.35h-9Zm-4.53-6.43h5.29a4.74,4.74,0,0,0,3.59-1.26,4.84,4.84,0,0,0,1.2-3.5,4.9,4.9,0,0,0-1.21-3.52,4.73,4.73,0,0,0-3.58-1.28h-5.29Z" />
  <path className="cls-1" d="M313.73,55.41h8.13V62.3h-8.13v9.31h-7.51V62.3h-8.14V55.41h8.14v-9h7.51Z" />
  <path className="cls-1"
    d="M253.5,297.15a2.51,2.51,0,0,1-.72-4.92,56.45,56.45,0,1,0-70.31-37.83,2.51,2.51,0,0,1-4.81,1.45A61.47,61.47,0,1,1,254.22,297,2.29,2.29,0,0,1,253.5,297.15Z" />
  <path className="cls-1"
    d="M236.51,318.33a80.67,80.67,0,0,1-76.73-57.11A80.21,80.21,0,0,1,236.56,158a80.17,80.17,0,0,1-.05,160.33ZM236.56,163a75.14,75.14,0,1,0,71.92,53.53A75.26,75.26,0,0,0,236.56,163Z" />
  <path className="cls-2"
    d="M259.87,247.49l-12.3,3.45V251a4.86,4.86,0,0,1-.51,3.65A4.92,4.92,0,0,1,244,257.1a5.22,5.22,0,0,1-3.9-.45l-7.12-4,.65-1.21,7.11,4a3.61,3.61,0,0,0,2.82.32,3.69,3.69,0,0,0,2.24-1.79,4.24,4.24,0,0,0,.45-1.48,3,3,0,0,0-1.48-3.07l-17.23-9.61a5.33,5.33,0,0,0-4.23-.52h-.06c-.45.07-19,5.39-19.74,5.58h-.06l3.91,13.39,12.36-3.53c1.28.77,10.7,6.6,11.85,7.31a7,7,0,0,0,5.26.38c.06,0,25.24-7.17,25.24-7.17a4.23,4.23,0,0,0,2.5-5.19,3.8,3.8,0,0,0-4.68-2.57Z" />
  <path className="cls-2"
    d="M261,225.51h-2.24a12.56,12.56,0,0,0-1.54-3.72L259,220a.79.79,0,0,0,0-1l-2.5-2.49a.67.67,0,0,0-1,0l-1.86,1.85a12.83,12.83,0,0,0-3.33-1.41v-2.37a.71.71,0,0,0-.71-.7h-3.46a.71.71,0,0,0-.7.7v2.31a10.42,10.42,0,0,0-3.46,1.41l-2-2a.69.69,0,0,0-1,0l-2.5,2.5a.72.72,0,0,0-.19.51.73.73,0,0,0,.19.52l1.93,1.92a11,11,0,0,0-1.61,3.78h-2.24a.7.7,0,0,0-.7.7v3.46a.7.7,0,0,0,.7.71h2.24a10.59,10.59,0,0,0,1.41,3.39l-1.79,1.8a.78.78,0,0,0,0,1l2.5,2.5a.78.78,0,0,0,1,0l1.73-1.73a11.42,11.42,0,0,0,3.85,1.6v2a.7.7,0,0,0,.7.7h3.46a.7.7,0,0,0,.71-.7v-2a11.54,11.54,0,0,0,3.65-1.54l1.47,1.47a.76.76,0,0,0,1,0L259,236.4a.72.72,0,0,0,.19-.51.74.74,0,0,0-.19-.52l-1.54-1.53a12,12,0,0,0,1.41-3.46h2.25a.71.71,0,0,0,.7-.71v-3.46c-.26-.38-.51-.7-.9-.7Zm-11.85,9.16a6.86,6.86,0,1,1,5.38-5.38A6.87,6.87,0,0,1,249.11,234.67Z" />
  <circle className="cls-5" cx="236.52" cy="123" r="22" />
  <path className="cls-1"
    d="M238.56,148A25.05,25.05,0,1,1,261.49,125,25.07,25.07,0,0,1,238.56,148Zm.1-45.83a21,21,0,1,0,18.72,18.71A21,21,0,0,0,238.66,102.14Z" />
  <path className="cls-6" d="M250.68,121.14a14.29,14.29,0,1,1-12.31-12.3A14.29,14.29,0,0,1,250.68,121.14Z" />
  <line className="cls-3" x1="236.52" y1="145" x2="236.52" y2="163" />
  <path className="cls-7"
    d="M151.23,557.37h-8.34l-1.88,5.2h-2.7l7.6-19.9h2.3l7.61,19.9h-2.69Zm-7.55-2.15h6.77l-3.39-9.31Z" />
  <path className="cls-7"
    d="M164.1,544.2v3.58h2.76v2H164.1v9.18a2.12,2.12,0,0,0,.37,1.34,1.59,1.59,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.15,3.15,0,0,1-2.56-1,4.41,4.41,0,0,1-.86-2.91v-9.17h-2.69v-2h2.69V544.2Z" />
  <path className="cls-7"
    d="M178.76,562.85a6.46,6.46,0,0,1-4.9-2,7.32,7.32,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.83,5.83,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.32,5.32,0,0,0,1.28,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.54,1.2A6.19,6.19,0,0,1,178.76,562.85Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12H182v-.19a4.83,4.83,0,0,0-1-3A3.17,3.17,0,0,0,178.44,549.59Z" />
  <path className="cls-7"
    d="M192.64,547.78l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.77h-2.53v-9.78a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V547.78Z" />
  <path className="cls-7"
    d="M207.89,555.05a8.64,8.64,0,0,1,1.61-5.47,5.49,5.49,0,0,1,8.34-.29v-7.72h2.53v21H218l-.12-1.58a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.29a6.59,6.59,0,0,0,1,3.94,3.37,3.37,0,0,0,2.88,1.42,3.69,3.69,0,0,0,3.51-2.16v-6.79a3.72,3.72,0,0,0-3.49-2.1,3.38,3.38,0,0,0-2.9,1.44A7.24,7.24,0,0,0,210.42,555.34Z" />
  <path className="cls-7"
    d="M227.11,543.86a1.51,1.51,0,0,1,.37-1,1.42,1.42,0,0,1,1.12-.43,1.45,1.45,0,0,1,1.12.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.43,1.42,1.42,0,0,1-1.12-.41A1.44,1.44,0,0,1,227.11,543.86Zm2.73,18.71h-2.53V547.78h2.53Z" />
  <path className="cls-7"
    d="M239.07,547.78l.06,1.64a5.49,5.49,0,0,1,4.39-1.91,4.23,4.23,0,0,1,4.23,2.38,5.62,5.62,0,0,1,1.92-1.73,5.75,5.75,0,0,1,2.79-.65q4.88,0,5,5.15v9.91h-2.53v-9.76a3.39,3.39,0,0,0-.72-2.37,3.19,3.19,0,0,0-2.43-.79,3.32,3.32,0,0,0-2.34.85,3.4,3.4,0,0,0-1.08,2.26v9.81h-2.55v-9.69q0-3.23-3.15-3.23a3.39,3.39,0,0,0-3.41,2.12v10.8h-2.53V547.78Z" />
  <path className="cls-7"
    d="M270.17,562.85a6.46,6.46,0,0,1-4.9-2,7.32,7.32,0,0,1-1.88-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.83,5.83,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.25,8.25,0,0,1,1.6,5.44v1h-10a5.27,5.27,0,0,0,1.27,3.54,4.06,4.06,0,0,0,3.11,1.34,4.44,4.44,0,0,0,2.27-.54,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.22,6.22,0,0,1,270.17,562.85Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.83,4.83,0,0,0-1-3A3.17,3.17,0,0,0,269.85,549.59Z" />
  <path className="cls-7"
    d="M284.05,547.78l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.77h-2.53v-9.78a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V547.78Z" />
  <path className="cls-7"
    d="M303.34,544.2v3.58h2.76v2h-2.76v9.18a2.12,2.12,0,0,0,.37,1.34,1.59,1.59,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.15,3.15,0,0,1-2.56-1,4.41,4.41,0,0,1-.86-2.91v-9.17h-2.69v-2h2.69V544.2Z" />
  <path className="cls-7"
    d="M310.91,555a8.74,8.74,0,0,1,.86-3.91,6.35,6.35,0,0,1,2.38-2.68,6.43,6.43,0,0,1,3.48-.94,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.56v.18a8.73,8.73,0,0,1-.83,3.88,6.32,6.32,0,0,1-2.36,2.67,6.54,6.54,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.33,6.33,0,0,0,1.14,3.95,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.23,6.29,6.29,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.6,3.6,0,0,0-3,1.49A6.82,6.82,0,0,0,313.46,555.34Z" />
  <path className="cls-7"
    d="M165.21,583.78l.07,1.64a5.48,5.48,0,0,1,4.39-1.91,4.22,4.22,0,0,1,4.22,2.38,5.62,5.62,0,0,1,1.92-1.73,5.76,5.76,0,0,1,2.8-.65q4.86,0,4.95,5.15v9.91H181v-9.76a3.35,3.35,0,0,0-.73-2.37,3.19,3.19,0,0,0-2.43-.79,3.32,3.32,0,0,0-2.34.85,3.4,3.4,0,0,0-1.08,2.26v9.81h-2.54v-9.69q0-3.23-3.16-3.23a3.38,3.38,0,0,0-3.4,2.12v10.8h-2.53V583.78Z" />
  <path className="cls-7"
    d="M196.31,598.85a6.43,6.43,0,0,1-4.89-2,7.33,7.33,0,0,1-1.89-5.28v-.47a8.93,8.93,0,0,1,.84-3.93,6.52,6.52,0,0,1,2.35-2.7,5.86,5.86,0,0,1,3.28-1,5.53,5.53,0,0,1,4.48,1.9,8.19,8.19,0,0,1,1.6,5.44v1h-10a5.22,5.22,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.34,4.46,4.46,0,0,0,2.27-.54,5.72,5.72,0,0,0,1.62-1.45l1.55,1.2A6.21,6.21,0,0,1,196.31,598.85ZM196,585.59a3.4,3.4,0,0,0-2.58,1.11,5.38,5.38,0,0,0-1.28,3.12h7.41v-.19a4.77,4.77,0,0,0-1-3A3.16,3.16,0,0,0,196,585.59Z" />
  <path className="cls-7"
    d="M213.74,596.78a3.61,3.61,0,0,0,2.36-.82,2.89,2.89,0,0,0,1.13-2.05h2.39a4.59,4.59,0,0,1-.88,2.42,5.58,5.58,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.08,6.08,0,0,1-4.81-2,8,8,0,0,1-1.78-5.52v-.42a8.87,8.87,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.91,5.91,0,0,1,4.13,1.49,5.34,5.34,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.54,3.54,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1,3.94A3.56,3.56,0,0,0,213.74,596.78Z" />
  <path className="cls-7"
    d="M234.39,598.57A5.49,5.49,0,0,1,234,597a5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.14,4.19,4.19,0,0,1,1.75-3.59,8.33,8.33,0,0,1,4.95-1.27H234v-1.17a2.88,2.88,0,0,0-.79-2.11,3.21,3.21,0,0,0-2.34-.79,3.65,3.65,0,0,0-2.27.69,2,2,0,0,0-.92,1.65h-2.54a3.51,3.51,0,0,1,.78-2.14,5.38,5.38,0,0,1,2.14-1.63,7.18,7.18,0,0,1,3-.6,5.79,5.79,0,0,1,4,1.28,4.6,4.6,0,0,1,1.51,3.52v6.81a8.31,8.31,0,0,0,.52,3.24v.21Zm1-17.26v.14h-2.09l-2.05-2.33-2,2.33h-2.09v-.16l3.37-3.72h1.53Zm-5.23,15.34a4.44,4.44,0,0,0,2.25-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2c-3.1,0-4.65.9-4.65,2.72a2.31,2.31,0,0,0,.79,1.86A3.09,3.09,0,0,0,230.2,596.65Z" />
  <path className="cls-7"
    d="M245.68,583.78l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.77H252.4v-9.78a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.77,3.64,3.64,0,0,0-2.16.66,4.5,4.5,0,0,0-1.45,1.72v10.54h-2.53V583.78Z" />
  <path className="cls-7"
    d="M261.55,579.86a1.51,1.51,0,0,1,.38-1,1.42,1.42,0,0,1,1.11-.43,1.46,1.46,0,0,1,1.13.43,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.51,1.43,1.41,1.41,0,0,1-1.11-.41A1.45,1.45,0,0,1,261.55,579.86Zm2.74,18.71h-2.53V583.78h2.53Z" />
  <path className="cls-7"
    d="M277.07,596.78a3.61,3.61,0,0,0,2.36-.82,2.89,2.89,0,0,0,1.13-2.05H283a4.59,4.59,0,0,1-.88,2.42,5.58,5.58,0,0,1-2.15,1.83,6.18,6.18,0,0,1-2.85.69,6.08,6.08,0,0,1-4.81-2,8,8,0,0,1-1.78-5.52v-.42a8.87,8.87,0,0,1,.79-3.84,6.05,6.05,0,0,1,2.28-2.61,6.48,6.48,0,0,1,3.51-.93,5.91,5.91,0,0,1,4.13,1.49,5.3,5.3,0,0,1,1.76,3.87h-2.39a3.51,3.51,0,0,0-1.09-2.36,3.36,3.36,0,0,0-2.41-.92,3.54,3.54,0,0,0-3,1.38,6.53,6.53,0,0,0-1.06,4v.47a6.4,6.4,0,0,0,1.05,3.94A3.56,3.56,0,0,0,277.07,596.78Z" />
  <path className="cls-7"
    d="M287.92,591a8.74,8.74,0,0,1,.86-3.91,6.35,6.35,0,0,1,2.38-2.68,6.43,6.43,0,0,1,3.48-.94,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.56v.18a8.87,8.87,0,0,1-.83,3.88,6.32,6.32,0,0,1-2.36,2.67,6.57,6.57,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.33,6.33,0,0,0,1.14,3.95,3.87,3.87,0,0,0,6.12,0,7,7,0,0,0,1.13-4.23,6.36,6.36,0,0,0-1.15-3.94,3.69,3.69,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.88,6.88,0,0,0,290.47,591.34Z" />
  <path className="cls-7"
    d="M151.23,452.64h-8.34L141,457.85h-2.7l7.6-19.91h2.3l7.61,19.91h-2.69Zm-7.55-2.15h6.77l-3.39-9.31Z" />
  <path className="cls-7"
    d="M164.1,439.47v3.58h2.76v2H164.1v9.18a2.1,2.1,0,0,0,.37,1.34,1.55,1.55,0,0,0,1.26.44,6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.15,3.15,0,0,1-2.56-1,4.41,4.41,0,0,1-.86-2.91V445h-2.69v-2h2.69v-3.58Z" />
  <path className="cls-7"
    d="M178.76,458.12a6.46,6.46,0,0,1-4.9-2,7.29,7.29,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.91,5.91,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.32,5.32,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.54,1.2A6.19,6.19,0,0,1,178.76,458.12Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11,5.44,5.44,0,0,0-1.28,3.13H182v-.19a4.84,4.84,0,0,0-1-3A3.17,3.17,0,0,0,178.44,444.86Z" />
  <path className="cls-7"
    d="M192.64,443.05l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.78h-2.53v-9.79a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.76,3.7,3.7,0,0,0-2.16.65,4.46,4.46,0,0,0-1.45,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-7"
    d="M207.89,450.33a8.65,8.65,0,0,1,1.61-5.48,5.49,5.49,0,0,1,8.34-.29v-7.71h2.53v21H218l-.12-1.59a5.14,5.14,0,0,1-4.22,1.86,5,5,0,0,1-4.19-2.11,8.74,8.74,0,0,1-1.62-5.49Zm2.53.28a6.59,6.59,0,0,0,1,3.94,3.37,3.37,0,0,0,2.88,1.42,3.69,3.69,0,0,0,3.51-2.16V447a3.71,3.71,0,0,0-3.49-2.09,3.4,3.4,0,0,0-2.9,1.43A7.26,7.26,0,0,0,210.42,450.61Z" />
  <path className="cls-7"
    d="M227.11,439.13a1.51,1.51,0,0,1,.37-1,1.42,1.42,0,0,1,1.12-.42,1.45,1.45,0,0,1,1.12.42,1.51,1.51,0,0,1,.38,1,1.36,1.36,0,0,1-1.5,1.44,1.45,1.45,0,0,1-1.12-.41A1.48,1.48,0,0,1,227.11,439.13Zm2.73,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-7"
    d="M239.07,443.05l.06,1.64a5.49,5.49,0,0,1,4.39-1.91,4.23,4.23,0,0,1,4.23,2.38,5.5,5.5,0,0,1,1.92-1.72,5.64,5.64,0,0,1,2.79-.66q4.88,0,5,5.15v9.92h-2.53v-9.77a3.43,3.43,0,0,0-.72-2.37,3.19,3.19,0,0,0-2.43-.78,3.36,3.36,0,0,0-2.34.84,3.4,3.4,0,0,0-1.08,2.26v9.82h-2.55v-9.7q0-3.22-3.15-3.22a3.38,3.38,0,0,0-3.41,2.12v10.8h-2.53v-14.8Z" />
  <path className="cls-7"
    d="M270.17,458.12a6.46,6.46,0,0,1-4.9-2,7.29,7.29,0,0,1-1.88-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.52,6.52,0,0,1,2.35-2.7,5.91,5.91,0,0,1,3.27-1,5.54,5.54,0,0,1,4.49,1.9,8.27,8.27,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.27,3.54,4,4,0,0,0,3.11,1.35,4.44,4.44,0,0,0,2.27-.55,5.75,5.75,0,0,0,1.63-1.45l1.55,1.2A6.22,6.22,0,0,1,270.17,458.12Zm-.32-13.26a3.38,3.38,0,0,0-2.57,1.11A5.44,5.44,0,0,0,266,449.1h7.41v-.19a4.84,4.84,0,0,0-1-3A3.17,3.17,0,0,0,269.85,444.86Z" />
  <path className="cls-7"
    d="M284.05,443.05l.09,1.86a5.38,5.38,0,0,1,4.43-2.13q4.68,0,4.73,5.29v9.78h-2.53v-9.79a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.76,3.7,3.7,0,0,0-2.16.65,4.46,4.46,0,0,0-1.45,1.73v10.54h-2.53v-14.8Z" />
  <path className="cls-7"
    d="M303.34,439.47v3.58h2.76v2h-2.76v9.18a2.1,2.1,0,0,0,.37,1.34A1.55,1.55,0,0,0,305,456a6,6,0,0,0,1.2-.16v2a7.37,7.37,0,0,1-1.94.27,3.15,3.15,0,0,1-2.56-1,4.41,4.41,0,0,1-.86-2.91V445h-2.69v-2h2.69v-3.58Z" />
  <path className="cls-7"
    d="M310.91,450.31a8.74,8.74,0,0,1,.86-3.91,6.28,6.28,0,0,1,5.86-3.62,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.57v.17a8.7,8.7,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.54,6.54,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.38,6.38,0,0,0,1.14,4,3.86,3.86,0,0,0,6.12,0,6.93,6.93,0,0,0,1.13-4.23,6.31,6.31,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.82,6.82,0,0,0,313.46,450.61Z" />
  <path className="cls-7"
    d="M142.15,486.61a8.88,8.88,0,0,1-1.55,5.45,5,5,0,0,1-4.18,2.06,5.42,5.42,0,0,1-4.24-1.71v7.12h-2.53V479.05H132l.12,1.64a5.23,5.23,0,0,1,4.3-1.91,5,5,0,0,1,4.21,2,8.91,8.91,0,0,1,1.56,5.59Zm-2.53-.28a6.52,6.52,0,0,0-1.07-3.95,3.43,3.43,0,0,0-2.93-1.45,3.71,3.71,0,0,0-3.44,2V490a3.73,3.73,0,0,0,3.47,2,3.4,3.4,0,0,0,2.89-1.45A7.09,7.09,0,0,0,139.62,486.33Z" />
  <path className="cls-7"
    d="M155.31,481.32a8.07,8.07,0,0,0-1.25-.09,3.32,3.32,0,0,0-3.37,2.12v10.5h-2.53v-14.8h2.46l0,1.71a4,4,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-7"
    d="M166.31,494.12a6.46,6.46,0,0,1-4.89-2,7.3,7.3,0,0,1-1.89-5.28v-.46a8.94,8.94,0,0,1,.84-3.94,6.59,6.59,0,0,1,2.35-2.7,5.94,5.94,0,0,1,3.28-1,5.52,5.52,0,0,1,4.48,1.9,8.21,8.21,0,0,1,1.6,5.44v1.05h-10a5.27,5.27,0,0,0,1.28,3.54,4,4,0,0,0,3.11,1.35,4.46,4.46,0,0,0,2.27-.55,5.89,5.89,0,0,0,1.63-1.45l1.54,1.2A6.21,6.21,0,0,1,166.31,494.12ZM166,480.86a3.38,3.38,0,0,0-2.57,1.11,5.45,5.45,0,0,0-1.29,3.13h7.41v-.19a4.78,4.78,0,0,0-1-3A3.16,3.16,0,0,0,166,480.86Zm1.23-8h3.06l-3.66,4h-2Z" />
  <path className="cls-7" d="M183.07,486.42H176.4v-2.06h6.67Z" />
  <path className="cls-7"
    d="M190.86,480.84a5.39,5.39,0,0,1,4.38-2.06q4.68,0,4.73,5.29v9.78h-2.53v-9.79a3.46,3.46,0,0,0-.73-2.37,3,3,0,0,0-2.24-.76,3.7,3.7,0,0,0-2.16.65,4.46,4.46,0,0,0-1.45,1.73v10.54h-2.53v-21h2.53Z" />
  <path className="cls-7"
    d="M205.88,486.31a8.74,8.74,0,0,1,.86-3.91,6.28,6.28,0,0,1,5.86-3.62,6.24,6.24,0,0,1,4.88,2.09,8,8,0,0,1,1.87,5.57v.17a8.7,8.7,0,0,1-.83,3.88,6.26,6.26,0,0,1-2.36,2.67,6.54,6.54,0,0,1-3.54,1,6.23,6.23,0,0,1-4.87-2.09,8,8,0,0,1-1.87-5.54Zm2.55.3a6.38,6.38,0,0,0,1.14,4,3.86,3.86,0,0,0,6.12,0,6.93,6.93,0,0,0,1.13-4.23,6.31,6.31,0,0,0-1.15-3.94,3.67,3.67,0,0,0-3.07-1.51,3.63,3.63,0,0,0-3,1.49A6.82,6.82,0,0,0,208.43,486.61Z" />
  <path className="cls-7"
    d="M233.94,489.92a1.86,1.86,0,0,0-.78-1.59,7.46,7.46,0,0,0-2.69-1,12.71,12.71,0,0,1-3.05-1,4.33,4.33,0,0,1-1.67-1.37,3.28,3.28,0,0,1-.54-1.89,3.85,3.85,0,0,1,1.54-3.07,6,6,0,0,1,3.93-1.26,6.14,6.14,0,0,1,4.08,1.3,4.11,4.11,0,0,1,1.57,3.32h-2.54a2.29,2.29,0,0,0-.89-1.79,3.28,3.28,0,0,0-2.22-.75,3.47,3.47,0,0,0-2.16.6,1.88,1.88,0,0,0-.78,1.57,1.52,1.52,0,0,0,.73,1.38,8.75,8.75,0,0,0,2.61.89,13.43,13.43,0,0,1,3.07,1,4.56,4.56,0,0,1,1.75,1.42,3.46,3.46,0,0,1,.56,2,3.75,3.75,0,0,1-1.58,3.17,6.65,6.65,0,0,1-4.12,1.2,7.43,7.43,0,0,1-3.14-.63,5,5,0,0,1-2.14-1.76,4.18,4.18,0,0,1-.77-2.44h2.53a2.61,2.61,0,0,0,1,2,4,4,0,0,0,2.51.75,4.08,4.08,0,0,0,2.31-.59A1.77,1.77,0,0,0,233.94,489.92Z" />
  <path className="cls-7"
    d="M255.06,486.61a8.88,8.88,0,0,1-1.55,5.45,5,5,0,0,1-4.18,2.06,5.42,5.42,0,0,1-4.24-1.71v7.12h-2.53V479.05h2.31l.12,1.64a5.24,5.24,0,0,1,4.3-1.91,5,5,0,0,1,4.21,2,8.91,8.91,0,0,1,1.56,5.59Zm-2.53-.28a6.52,6.52,0,0,0-1.07-3.95,3.43,3.43,0,0,0-2.92-1.45,3.73,3.73,0,0,0-3.45,2V490a3.73,3.73,0,0,0,3.47,2,3.4,3.4,0,0,0,2.89-1.45A7.09,7.09,0,0,0,252.53,486.33Z" />
  <path className="cls-7"
    d="M261.08,475.13a1.51,1.51,0,0,1,.38-1,1.69,1.69,0,0,1,2.23,0,1.48,1.48,0,0,1,.39,1,1.57,1.57,0,0,1-2.62,1A1.49,1.49,0,0,1,261.08,475.13Zm2.74,18.72h-2.53v-14.8h2.53Z" />
  <path className="cls-7"
    d="M274.1,475.47v3.58h2.76v2H274.1v9.18a2.1,2.1,0,0,0,.36,1.34,1.55,1.55,0,0,0,1.26.44,6.08,6.08,0,0,0,1.21-.16v2a7.5,7.5,0,0,1-1.95.27,3.11,3.11,0,0,1-2.55-1,4.36,4.36,0,0,1-.86-2.91V481h-2.7v-2h2.7v-3.58Z" />
  <path className="cls-7"
    d="M291.74,493.85a5.58,5.58,0,0,1-.35-1.56,5.65,5.65,0,0,1-4.21,1.83,5.19,5.19,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.18,4.18,0,0,1,1.75-3.59,8.24,8.24,0,0,1,4.95-1.28h2.46v-1.16a2.86,2.86,0,0,0-.8-2.12,3.17,3.17,0,0,0-2.33-.78,3.64,3.64,0,0,0-2.27.68,2,2,0,0,0-.92,1.66h-2.54a3.49,3.49,0,0,1,.78-2.14,5.4,5.4,0,0,1,2.14-1.64,7.15,7.15,0,0,1,3-.6,5.84,5.84,0,0,1,4,1.28,4.6,4.6,0,0,1,1.51,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.19-1.93a4.44,4.44,0,0,0,2.25-.62,3.72,3.72,0,0,0,1.55-1.6v-3h-2q-4.64,0-4.64,2.72a2.31,2.31,0,0,0,.79,1.86A3,3,0,0,0,287.55,491.92Z" />
  <path className="cls-7" d="M303.39,493.85h-2.53v-21h2.53Z" />
  <path className="cls-7"
    d="M319.37,493.85a5.17,5.17,0,0,1-.36-1.56,5.64,5.64,0,0,1-4.21,1.83,5.21,5.21,0,0,1-3.59-1.24,4,4,0,0,1-1.4-3.13,4.19,4.19,0,0,1,1.76-3.59,8.23,8.23,0,0,1,4.94-1.28H319v-1.16a2.85,2.85,0,0,0-.79-2.12,3.21,3.21,0,0,0-2.34-.78,3.66,3.66,0,0,0-2.27.68,2,2,0,0,0-.91,1.66h-2.55a3.5,3.5,0,0,1,.79-2.14,5.3,5.3,0,0,1,2.13-1.64,7.22,7.22,0,0,1,3-.6,5.87,5.87,0,0,1,4,1.28,4.63,4.63,0,0,1,1.5,3.52v6.81a8.24,8.24,0,0,0,.52,3.24v.22Zm-4.2-1.93a4.47,4.47,0,0,0,2.26-.62,3.7,3.7,0,0,0,1.54-1.6v-3h-2q-4.65,0-4.65,2.72a2.34,2.34,0,0,0,.79,1.86A3,3,0,0,0,315.17,491.92Z" />
  <path className="cls-7"
    d="M335.42,481.32a8.16,8.16,0,0,0-1.25-.09,3.31,3.31,0,0,0-3.37,2.12v10.5h-2.53v-14.8h2.46l0,1.71a3.94,3.94,0,0,1,3.53-2,2.57,2.57,0,0,1,1.12.19Z" />
  <path className="cls-6"
    d="M110.05,364.75h-8.81l-1.85,5.3H95.28l8.6-22.75h3.54L116,370.05h-4.12Zm-7.71-3.19h6.6l-3.3-9.44Z" />
  <path className="cls-6" d="M136.37,350.48h-7.09v19.57h-3.92V350.48h-7V347.3h18Z" />
  <path className="cls-6" d="M155.85,359.89h-9.34v7h10.92v3.16H142.56V347.3h14.76v3.18H146.51v6.28h9.34Z" />
  <path className="cls-6" d="M181.9,370.05h-4L167.8,353.91v16.14h-4V347.3h4L178,363.5V347.3h3.92Z" />
  <path className="cls-6"
    d="M189.77,370.05V347.3h6.72a10.5,10.5,0,0,1,5.35,1.34,9.24,9.24,0,0,1,3.62,3.81,12.17,12.17,0,0,1,1.28,5.66v1.14a12.06,12.06,0,0,1-1.29,5.69,9.1,9.1,0,0,1-3.67,3.78,11.09,11.09,0,0,1-5.46,1.33Zm3.95-19.57v16.41h2.58a5.93,5.93,0,0,0,4.77-1.95,8.43,8.43,0,0,0,1.7-5.58v-1.27a8.69,8.69,0,0,0-1.61-5.65,5.68,5.68,0,0,0-4.67-2Z" />
  <path className="cls-6" d="M218.05,370.05h-3.94V347.3h3.94Z" />
  <path className="cls-6"
    d="M231.23,347.3l6.56,17.43,6.55-17.43h5.11v22.75h-3.94v-7.5l.39-10-6.71,17.54h-2.83l-6.71-17.52.39,10v7.5h-3.93V347.3Z" />
  <path className="cls-6" d="M270.62,359.89h-9.34v7H272.2v3.16H257.32V347.3h14.77v3.18H261.28v6.28h9.34Z" />
  <path className="cls-6" d="M296.66,370.05h-4l-10.14-16.14v16.14h-3.95V347.3h3.95l10.17,16.2V347.3h3.92Z" />
  <path className="cls-6" d="M320.52,350.48h-7.09v19.57H309.5V350.48h-7V347.3h18Z" />
  <path className="cls-6"
    d="M344.42,359.26a14,14,0,0,1-1.15,5.87A8.78,8.78,0,0,1,340,369a9.7,9.7,0,0,1-9.87,0,8.89,8.89,0,0,1-3.35-3.85,13.63,13.63,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.88,8.88,0,0,1,3.33-3.9A9.05,9.05,0,0,1,335,347a9.18,9.18,0,0,1,4.93,1.34,8.82,8.82,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.18,5.84Zm-3.95-1.18a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0A9.7,9.7,0,0,0,329.5,358v1.31a9.9,9.9,0,0,0,1.46,5.8,5.06,5.06,0,0,0,8.1.05,10.1,10.1,0,0,0,1.41-5.85Z" />
  <path className="cls-6"
    d="M363.45,364.19a2.78,2.78,0,0,0-1.05-2.32,13.12,13.12,0,0,0-3.81-1.64,18.13,18.13,0,0,1-4.37-1.84,5.82,5.82,0,0,1-3.11-5.09,5.54,5.54,0,0,1,2.24-4.54,9.1,9.1,0,0,1,5.82-1.78,9.84,9.84,0,0,1,4.23.88,6.94,6.94,0,0,1,2.93,2.49,6.46,6.46,0,0,1,1.06,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.83,4.83,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.51,2.51,0,0,0,1.16,2.09A13.35,13.35,0,0,0,360,357a17.67,17.67,0,0,1,4.26,1.8,6.7,6.7,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.13,11.13,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.32,6.32,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.28,3,5.59,5.59,0,0,0,3.67,1.06,4.84,4.84,0,0,0,3.1-.83A2.72,2.72,0,0,0,363.45,364.19Z" />
  <path className="cls-6"
    d="M117.86,401.75h-8.81l-1.85,5.3h-4.11l8.6-22.75h3.55l8.6,22.75h-4.12Zm-7.7-3.19h6.59l-3.3-9.44Z" />
  <path className="cls-6"
    d="M147.45,396.26a13.82,13.82,0,0,1-1.16,5.87A8.76,8.76,0,0,1,143,406a9.7,9.7,0,0,1-9.87,0,8.89,8.89,0,0,1-3.35-3.85,13.63,13.63,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.92,8.92,0,0,1,3.32-3.9,9.71,9.71,0,0,1,9.86,0,8.69,8.69,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-3.95-1.18a10,10,0,0,0-1.43-5.8,5,5,0,0,0-8.06,0,9.7,9.7,0,0,0-1.48,5.68v1.31a9.9,9.9,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10,10,0,0,0,1.42-5.85Z" />
  <path className="cls-6"
    d="M166.48,401.19a2.76,2.76,0,0,0-1.06-2.32,12.91,12.91,0,0,0-3.8-1.64,18.13,18.13,0,0,1-4.37-1.84,5.82,5.82,0,0,1-3.11-5.09,5.54,5.54,0,0,1,2.24-4.54A9.1,9.1,0,0,1,162.2,384a9.87,9.87,0,0,1,4.23.88,6.85,6.85,0,0,1,2.92,2.49,6.39,6.39,0,0,1,1.07,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.83,4.83,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.49,2.49,0,0,0,1.16,2.09,13.35,13.35,0,0,0,3.81,1.62,17.67,17.67,0,0,1,4.26,1.8,6.7,6.7,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.32,6.32,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.28,3,5.59,5.59,0,0,0,3.67,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,166.48,401.19Z" />
  <path className="cls-6"
    d="M204.91,384.3v15.2a7.37,7.37,0,0,1-2.32,5.74,10.15,10.15,0,0,1-12.38,0,7.43,7.43,0,0,1-2.3-5.79V384.3h3.94v15.21A4.83,4.83,0,0,0,193,403a4.53,4.53,0,0,0,3.41,1.2q4.56,0,4.56-4.81V384.3Z" />
  <path className="cls-6"
    d="M224.22,401.19a2.78,2.78,0,0,0-1.05-2.32,13.12,13.12,0,0,0-3.81-1.64,18.13,18.13,0,0,1-4.37-1.84,5.82,5.82,0,0,1-3.11-5.09,5.54,5.54,0,0,1,2.24-4.54,9.1,9.1,0,0,1,5.82-1.78,9.87,9.87,0,0,1,4.23.88,6.85,6.85,0,0,1,2.92,2.49,6.39,6.39,0,0,1,1.07,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.83,4.83,0,0,0-3,.83,2.76,2.76,0,0,0-1.07,2.31,2.49,2.49,0,0,0,1.16,2.09A13.35,13.35,0,0,0,220.8,394a17.67,17.67,0,0,1,4.26,1.8,6.7,6.7,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.32,6.32,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.28,3,5.59,5.59,0,0,0,3.67,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,224.22,401.19Z" />
  <path className="cls-6"
    d="M251.48,384.3v15.2a7.37,7.37,0,0,1-2.32,5.74,10.15,10.15,0,0,1-12.38,0,7.43,7.43,0,0,1-2.3-5.79V384.3h3.94v15.21a4.84,4.84,0,0,0,1.16,3.49,4.5,4.5,0,0,0,3.4,1.2q4.56,0,4.56-4.81V384.3Z" />
  <path className="cls-6"
    d="M271.29,401.75h-8.81l-1.84,5.3h-4.11l8.59-22.75h3.55l8.61,22.75h-4.13Zm-7.7-3.19h6.59l-3.29-9.44Zm4.48-20.36h4.33l-4.33,4.74h-3Z" />
  <path className="cls-6"
    d="M291.41,398.3H287v8.75h-4V384.3h8a9.28,9.28,0,0,1,6.08,1.76,6.26,6.26,0,0,1,2.14,5.12,6.37,6.37,0,0,1-1.1,3.82,7,7,0,0,1-3.07,2.37l5.11,9.47v.21H296Zm-4.4-3.19h4.06a4.55,4.55,0,0,0,3.13-1,3.51,3.51,0,0,0,1.12-2.76,3.76,3.76,0,0,0-1-2.83,4.44,4.44,0,0,0-3.1-1H287Z" />
  <path className="cls-6" d="M310.39,407.05h-3.93V384.3h3.93Z" />
  <path className="cls-6"
    d="M336.61,396.26a14,14,0,0,1-1.16,5.87,8.76,8.76,0,0,1-3.3,3.88,9.71,9.71,0,0,1-9.88,0,8.94,8.94,0,0,1-3.34-3.85,13.48,13.48,0,0,1-1.19-5.77v-1.28a13.81,13.81,0,0,1,1.18-5.88,8.92,8.92,0,0,1,3.32-3.9,9.71,9.71,0,0,1,9.86,0,8.69,8.69,0,0,1,3.32,3.84,13.83,13.83,0,0,1,1.19,5.84Zm-3.95-1.18a10,10,0,0,0-1.43-5.8,4.71,4.71,0,0,0-4.06-2,4.64,4.64,0,0,0-4,2,9.7,9.7,0,0,0-1.48,5.68v1.31a9.9,9.9,0,0,0,1.46,5.8,5.05,5.05,0,0,0,8.09.05,10,10,0,0,0,1.42-5.85Z" />
  <path className="cls-6"
    d="M355.64,401.19a2.76,2.76,0,0,0-1.06-2.32,12.91,12.91,0,0,0-3.8-1.64,18.4,18.4,0,0,1-4.38-1.84,5.84,5.84,0,0,1-3.11-5.09,5.55,5.55,0,0,1,2.25-4.54,9.1,9.1,0,0,1,5.82-1.78,9.87,9.87,0,0,1,4.23.88,6.85,6.85,0,0,1,2.92,2.49,6.39,6.39,0,0,1,1.07,3.59h-3.94a3.55,3.55,0,0,0-1.12-2.79,4.61,4.61,0,0,0-3.19-1,4.81,4.81,0,0,0-3,.83,2.73,2.73,0,0,0-1.07,2.31,2.5,2.5,0,0,0,1.15,2.09,13.44,13.44,0,0,0,3.82,1.62,17.67,17.67,0,0,1,4.26,1.8,6.7,6.7,0,0,1,2.36,2.31,6,6,0,0,1,.75,3.06,5.4,5.4,0,0,1-2.18,4.52,9.43,9.43,0,0,1-5.91,1.68,11.1,11.1,0,0,1-4.54-.92,7.68,7.68,0,0,1-3.22-2.52,6.25,6.25,0,0,1-1.15-3.75h4a3.67,3.67,0,0,0,1.29,3,5.58,5.58,0,0,0,3.67,1.06,4.86,4.86,0,0,0,3.1-.83A2.72,2.72,0,0,0,355.64,401.19Z" />
  <line className="cls-8" x1="111.78" y1="518.79" x2="351.78" y2="518.79" />
  <path className="cls-1"
    d="M80.82,199.06a1,1,0,0,1-.3-2,23,23,0,1,0-28.7-15.44,1,1,0,0,1-2,.59A25.1,25.1,0,1,1,81.11,199,1,1,0,0,1,80.82,199.06Z" />
  <path className="cls-1"
    d="M73.88,207.71a32.73,32.73,0,1,1,31.35-42.15,32.78,32.78,0,0,1-31.35,42.15Zm0-63.41a30.68,30.68,0,1,0,8.82,60,30.66,30.66,0,0,0,20.54-38.2A30.87,30.87,0,0,0,73.9,144.3Z" />
  <path className="cls-2"
    d="M73.54,165.67a7.54,7.54,0,1,0,7.54,7.54A7.54,7.54,0,0,0,73.54,165.67Zm0,3.46a.55.55,0,0,1,.49.27l1.07,2.13,2.37.33a.53.53,0,0,1,.3.89L76,174.42l.42,2.35a.52.52,0,0,1-.75.56l-2.12-1.1-2.11,1.13a.52.52,0,0,1-.76-.55l.39-2.35-1.73-1.66a.52.52,0,0,1,.28-.89l2.37-.36,1-2.14a.5.5,0,0,1,.44-.28Z" />
  <path className="cls-2"
    d="M69.78,180.9V187a.52.52,0,0,0,.82.42l2.94-2.07,2.94,2.07a.52.52,0,0,0,.82-.42V180.9a8.47,8.47,0,0,1-7.52,0Z" />
  <line className="cls-9" x1="73.9" y1="135.5" x2="73.9" y2="144" />
  <circle className="cls-5" cx="73.9" cy="123.49" r="10.86" />
  <path className="cls-1"
    d="M75.25,135.78a12.36,12.36,0,1,1,11-11.63A12.38,12.38,0,0,1,75.25,135.78Zm-.59-22.61a10.35,10.35,0,1,0,9.5,9A10.36,10.36,0,0,0,74.66,113.17Z" />
  <path className="cls-6" d="M80.86,122.38a7.05,7.05,0,1,1-6.25-5.9A7.05,7.05,0,0,1,80.86,122.38Z" />
  <circle className="cls-5" cx="398.56" cy="123.49" r="10.86" />
  <path className="cls-1"
    d="M399.56,135.81a12.36,12.36,0,1,1,11.32-11.31A12.37,12.37,0,0,1,399.56,135.81Zm.06-22.62a10.35,10.35,0,1,0,9.23,9.24A10.37,10.37,0,0,0,399.62,113.19Z" />
  <path className="cls-6" d="M405.55,122.57a7,7,0,1,1-6.08-6.07A7.06,7.06,0,0,1,405.55,122.57Z" />
  <line className="cls-9" x1="398.56" y1="135.5" x2="398.56" y2="144" />
  <circle onClick={() => handleUserAtendence()} className="cls-10" cx="236" cy="239" r="85" />
  <rect onClick={() => handleUserAtendence()} className="cls-10" x="84" y="334" width="296" height="87" />
  <rect onClick={() => handleUserAtendence()} className="cls-10" x="122" y="426" width="229" height="84" />
  <rect onClick={() => handleUserAtendence()} className="cls-10" x="122" y="529" width="229" height="84" />
</svg>
    </>
  );
};