import React from 'react';
import dynamic from 'next/dynamic';
import { MakeNumberToLocale } from '@/utils/factory';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import Information from '@/global/components/layout/information';
import { WhiteCard, IdeaIcon, GraphicCard, GreenCard, WrapperCard, DonutsWrapper } from './styles';

const LineChart = dynamic(import('src/global/components/chart/line'), { ssr: false });
const DonutsChart = dynamic(import('src/global/components/chart/donuts'), { ssr: false });
const ReverseChart = dynamic(import('src/global/components/chart/reverseLine'), { ssr: false });

interface IProps {
  dados: any
}

export const PreHospitalCare: React.FC<IProps> = ({ dados }) => {
  const { isMobile } = useMediaQuery('(max-width: 1014px)');

  return (
    <>
    {!isMobile && (
      <>
        <WrapperCard>
          <WhiteCard>
            <IdeaIcon />
            <p>{dados?.block}</p>
          </WhiteCard>
          {/* Primeiro exibido em tela Gráfico */}
          <GraphicCard>
          <h4>{dados?.chart[1]?.title}</h4>
          <div style={{ height: '319px', display: 'flex', width: '100%' }}>
            <LineChart
              dados={dados?.chart[1]}
              title={dados?.chart[1]?.title}
              series={dados?.chart[1]?.series}
            />
            <Information info={dados?.chart[1]?.info} />
          </div>

          </GraphicCard>
        </WrapperCard>
        <WrapperCard>
          <GreenCard>
            <p className='title'>{dados?.big_number[0]?.title}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[0]?.value)}
              <p>{dados?.big_number[0]?.subtitle}</p>
              {dados?.big_number[0]?.format}
            </h1>
            <p>{dados?.big_number[0]?.text}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[1]?.value)}
              <p>{dados?.big_number[1]?.subtitle}</p>
              {dados?.big_number[1]?.format}
            </h1>
            <p>{dados?.big_number[1]?.text}</p>
          </GreenCard>
          {/* Segundo exibido em tela Gráfico */}
          <GraphicCard>
            <h4>{dados?.chart[2]?.title}</h4>
            <div style={{ height: '319px', display: 'flex', width: '100%' }}>
              <ReverseChart
                mapperLine={false}
                dados={dados?.chart[2]}
                title={dados?.chart[2]?.title}
                series={dados?.chart[2]?.series}
              />
              <Information info={dados?.chart[2]?.info} />
            </div>
          </GraphicCard>
        </WrapperCard>
        <WrapperCard>
          <GraphicCard height="360px" ml='0px' mr='5px' minW='43%'>
              <h4>{dados?.chart[0]?.title}</h4>
              <div style={{ height: '360px', display: 'flex', width: '100%' }}>
              <LineChart
                period='month'
                newPresets={true}
                dados={dados?.chart[0]}
                title={dados?.chart[0]?.title}
                series={dados?.chart[0]?.series}
              />
              <Information info={dados?.chart[0]?.info} />
              </div>
            </GraphicCard>
            <DonutsWrapper>
              <h4>{dados?.chart[3]?.title}</h4>
              <div style={{ display: 'flex', width: '100%', height: '360px' }}>
                <DonutsChart
                  mb={30}
                  x={130}
                  breakTooltip={true}
                  dados={dados?.chart[3]}
                  title={dados?.chart[3]?.title}
                  series={dados?.chart[3]?.series}
                />
                  <Information info={dados?.chart[3]?.info} />
              </div>
          </DonutsWrapper  >
        </WrapperCard>
      </>
    )}
    {isMobile && (
      <>
        <WrapperCard>
          <WhiteCard>
            <IdeaIcon />
            <p>{dados?.block}</p>
          </WhiteCard>
          <GreenCard>
            <p>{dados?.big_number[0]?.title}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[0]?.value)}
              <p>{dados?.big_number[0]?.subtitle}</p>
              {dados?.big_number[0]?.format}
            </h1>
            <p>{dados?.big_number[0]?.text}</p>
            <h1>
              {MakeNumberToLocale(dados?.big_number[1]?.value)}
              <p>{dados?.big_number[1]?.subtitle}</p>
              {dados?.big_number[1]?.format}
            </h1>
            <p>{dados?.big_number[1]?.text}</p>
          </GreenCard>

        </WrapperCard>
        <WrapperCard>
          <GraphicCard>
            <h4>{dados?.chart[1]?.title}</h4>
            <div style={{ height: '319px', display: 'flex', width: '100%' }}>
              <LineChart
                period="month"
                dados={dados?.chart[1]}
                title={dados?.chart[1]?.title}
                series={dados?.chart[1]?.series}
              />
              <Information info={dados?.chart[1]?.info} />
            </div>

          </GraphicCard>
          <GraphicCard>
            <h4>{dados?.chart[2]?.title}</h4>
            <div style={{ height: '319px', display: 'flex', width: '100%' }}>
              <ReverseChart
                mapperLine={false}
                dados={dados?.chart[2]}
                title={dados?.chart[2]?.title}
                series={dados?.chart[2]?.series}
              />
              <Information info={dados?.chart[2]?.info} />
            </div>
          </GraphicCard>
        </WrapperCard>
        <WrapperCard>
          <GraphicCard height="360px" ml='0px' mr='5px' minW='43%'>
              <h4>{dados?.chart[0]?.title}</h4>
              <div style={{ height: '360px', display: 'flex', width: '100%' }}>
                <LineChart
                  period='month'
                  newPresets={true}
                  dados={dados?.chart[0]}
                  title={dados?.chart[0]?.title}
                  series={dados?.chart[0]?.series}
                />
                <Information info={dados?.chart[0]?.info} />
              </div>
            </GraphicCard>
            <DonutsWrapper>
              <h4>{dados?.chart[3]?.title}</h4>
              <div style={{ display: 'flex', width: '100%', height: '360px' }}>
                <DonutsChart
                  mb={30}
                  x={130}
                  dados={dados?.chart[3]}
                  title={dados?.chart[3]?.title}
                  series={dados?.chart[3]?.series}
                />
                  <Information info={dados?.chart[3]?.info} />
              </div>
          </DonutsWrapper  >
        </WrapperCard>
      </>
    )}
    </>
  );
};
